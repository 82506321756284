import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BundesligaMatchStates, LiveBlogClubs, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import idx from 'idx';
import * as _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'bl-stats-event-input',
	templateUrl: './bl-stats-event-input.component.html',
	styleUrls: ['./bl-stats-event-input.component.scss', '../liveticker-events.common.scss']
})
export class BlStatsEventInputComponent implements OnInit, OnChanges {
	@Input() liveEntry: any;
	@Input() clubData: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() headline: string;
	@Input() textBody: string;
	@Input() side: LivetickerSides;
	@Input() orderType = 'auto';
	@Input() orderValue = 'auto';
	@Input() editMode = false;
	@Input() customMatch = false;

	public previewEntry: any;
	@Input() tributeOptions: any;

	public players = [];
	public filteredPlayers = [];
	public playerSearch = '';
	public selectedPlayer: any;
	constructor() {}

	public update() {
		this.previewEntry = Object.assign({}, JSON.parse(JSON.stringify(this.liveEntry)));
	}
	public updatePlaytime() {
		if (this.editMode === true && (this.liveEntry.playtime.minute !== this.minuteOfPlay.minute || this.liveEntry.playtime.injuryTime !== this.minuteOfPlay.injuryTime)) {
			if (this.orderType === 'keep') {
				this.orderType = 'auto';
			}
			this.updateOrderType();
		}
	}

	public updateOrderType() {
		switch (this.orderType) {
			case 'auto':
				this.orderValue = 'auto';
				break;
			case 'keep':
				this.orderValue = 'keep';
				break;
			case 'parent':
				this.orderValue = '';
				break;
			case 'child':
				this.orderValue = '';
				break;
			case 'number':
				this.orderValue = '';
				break;
			default:
				this.orderValue = '';
		}
		this.updateOrderValue();
	}

	public updateOrderValue() {
		let prependedText = '';
		if (this.orderType === 'parent' || this.orderType === 'child') {
			prependedText = this.orderType;
		}
		this.liveEntry.order = prependedText + this.orderValue;
	}

	ngOnInit() {
		this.liveEntry = {
			...this.liveEntry,
			entryType: 'stats',
			detail: {
				...(idx(this.liveEntry, (_) => _.detail) || {}),
				headline: this.headline || '',
				text: this.textBody || '',
				type: idx(this.liveEntry, (_) => _.detail.type) || 'bar',
				matchFact: idx(this.liveEntry, (_) => _.detail.matchFact) || false,
				title: idx(this.liveEntry, (_) => _.detail.title) || '',
				metric: idx(this.liveEntry, (_) => _.detail.metric) || '',
				escapeRate: idx(this.liveEntry, (_) => _.detail.escapeRate) || '',
				pressureCount: idx(this.liveEntry, (_) => _.detail.pressureCount) || '',
				progression: (idx(this.liveEntry, (_) => _.detail.progression) || []).map((item) => ({
					...item,
					playtime: {
						minute: idx(item, (_) => _.playtime.minute) >= 0 ? item.playtime.minute : '',
						injuryTime: idx(item, (_) => _.playtime.injuryTime) >= 0 ? item.playtime.injuryTime : ''
					},
					matchSection: idx(item, (_) => _.matchSection) || ''
				})),
				times: (idx(this.liveEntry, (_) => _.detail.times) || [{}, {}]).map((item) => ({
					...item,
					label: idx(item, (_) => _.label) || '',
					value: idx(item, (_) => _.value) || 0
				})),
				person: {
					dflDatalibraryObjectId: idx(this.liveEntry, (_) => _.detail.person.dflDatalibraryObjectId) || '',
					imageUrl: idx(this.liveEntry, (_) => _.detail.person.imageUrl) || '',
					name: idx(this.liveEntry, (_) => _.detail.person.name) || ''
				},
				home: {
					person: {
						dflDatalibraryObjectId: idx(this.liveEntry, (_) => _.detail.home.person.dflDatalibraryObjectId) || '',
						imageUrl: idx(this.liveEntry, (_) => _.detail.home.person.imageUrl) || '',
						name: idx(this.liveEntry, (_) => _.detail.home.person.name) || ''
					},
					value: idx(this.liveEntry, (_) => _.detail.home.value) || 0,
					relativeValue: idx(this.liveEntry, (_) => _.detail.home.relativeValue) || 0,
					primaryValue: idx(this.liveEntry, (_) => _.detail.home.primaryValue) || 0,
					secondaryValue: idx(this.liveEntry, (_) => _.detail.home.secondaryValue) || 0,
					primaryLabel: idx(this.liveEntry, (_) => _.detail.home.primaryLabel) || '',
					secondaryLabel: idx(this.liveEntry, (_) => _.detail.home.secondaryLabel) || ''
				},
				away: {
					person: {
						dflDatalibraryObjectId: idx(this.liveEntry, (_) => _.detail.away.person.dflDatalibraryObjectId) || '',
						imageUrl: idx(this.liveEntry, (_) => _.detail.away.person.imageUrl) || '',
						name: idx(this.liveEntry, (_) => _.detail.away.person.name) || ''
					},
					value: idx(this.liveEntry, (_) => _.detail.away.value) || 0,
					relativeValue: idx(this.liveEntry, (_) => _.detail.away.relativeValue) || 0,
					primaryValue: idx(this.liveEntry, (_) => _.detail.away.primaryValue) || 0,
					secondaryValue: idx(this.liveEntry, (_) => _.detail.away.secondaryValue) || 0,
					primaryLabel: idx(this.liveEntry, (_) => _.detail.away.primaryLabel) || '',
					secondaryLabel: idx(this.liveEntry, (_) => _.detail.away.secondaryLabel) || ''
				},
				ranking: (idx(this.liveEntry, (_) => _.detail.ranking) || Array(6).fill({})).map((item, index) => this.createPersonRankingFiller(item, index))
			},
			order: 'auto',
			entryDate: idx(this.liveEntry, (_) => _.entryDate) || '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.matchStatus || 'PRE_MATCH',
			side: this.side,
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			},
			conference: this.customMatch === true ? false : idx(this.liveEntry, (_) => _.conference) || false,
			enableHtml: idx(this.liveEntry, (_) => _.enableHtml) || false,
			disableCrosspost: this.editMode || idx(this.liveEntry, (_) => _.disableCrosspost) || false,
			hidden: idx(this.liveEntry, (_) => _.hidden) || false
		} as any;
		this.initializeOrderDefault();
		this.update();
	}

	initializeOrderDefault() {
		if (this.editMode === true) {
			this.orderType = 'keep';
			this.updateOrderType();
		}
	}

	public getPayload() {
		return this.liveEntry;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('tributeOptions') && this.tributeOptions && this.tributeOptions.hasOwnProperty('collection') && this.tributeOptions.collection.length >= 3) {
			this.players = this.tributeOptions.collection.find((col) => col?.trigger === (this.clubData[this.side]?.dflDatalibraryClubId ? '@' : '#'))?.values ?? [];
			this.filterPlayers();
		}
		this.changeChartType();
	}

	public changeChartType() {
		if (this.liveEntry?.detail?.type === 'playerProgressRanking' && this.liveEntry?.detail?.ranking?.length !== 6) {
			this.liveEntry.detail.ranking = Array(6)
				.fill({})
				.map((item, index) => this.createPersonRankingFiller(item, index));
		}
		if (this.liveEntry?.detail?.type === 'playerRanking' && this.liveEntry?.detail?.ranking?.length !== 3) {
			this.liveEntry.detail.ranking = Array(3)
				.fill({})
				.map((item, index) => this.createPersonRankingFiller(item, index));
		}
	}

	private createPersonRankingFiller(item: any, index: number) {
		return {
			person: {
				dflDatalibraryObjectId: idx(item, (_) => _.person.dflDatalibraryObjectId) || '',
				imageUrl: idx(item, (_) => _.person.imageUrl) || '',
				name: idx(item, (_) => _.person.name) || ''
			},
			side: idx(item, (_) => _.side) || 'home',
			value: idx(item, (_) => _.value) || 0,
			unit: idx(item, (_) => _.unit) || '',
			rank: idx(item, (_) => _.rank) || (index % 3) + 1
		};
	}

	public filterPlayers() {
		if (!this.players) {
			return;
		}
		// get the search keyword
		if (!this.playerSearch || this.playerSearch === '') {
			this.filteredPlayers = this.players;
			return;
		} else {
			this.playerSearch = this.playerSearch.toLowerCase();
		}
		// filter the players
		this.filteredPlayers = this.players.filter((item) => (item.name.hasOwnProperty('alias') && item.name.alias ? item.name.alias : item.name.full).toLowerCase().trim().includes(this.playerSearch.toLowerCase().trim()));
	}
	public trackByPlayerId(index, item) {
		if (item.id) {
			return item.id;
		}
	}

	public setPlayerOnPersonProp() {
		this.liveEntry.detail.person.dflDatalibraryObjectId = this.selectedPlayer.externalPersonIds.dflDatalibraryPersonId;
		this.liveEntry.detail.person.imageUrl = this.selectedPlayer.playerImages.FACE_CIRCLE.replace('-circle', '');
		this.liveEntry.detail.person.name = this.selectedPlayer.name.hasOwnProperty('alias') && this.selectedPlayer.name.alias ? this.selectedPlayer.name.alias : this.selectedPlayer.name.full;
		this.update();
	}

	public setPlayerCardPlayer(side: LivetickerSides) {
		this.liveEntry.detail[side].person.dflDatalibraryObjectId = this.selectedPlayer.externalPersonIds.dflDatalibraryPersonId;
		this.liveEntry.detail[side].person.imageUrl = this.selectedPlayer.playerImages.FACE_CIRCLE.replace('-circle', '');
		this.liveEntry.detail[side].person.name = this.selectedPlayer.name.hasOwnProperty('alias') && this.selectedPlayer.name.alias ? this.selectedPlayer.name.alias : this.selectedPlayer.name.full;
		this.update();
	}

	public setPlayerRankingsPlayer(side: LivetickerSides, position: number, rank?: number) {
		this.liveEntry.detail.ranking[position].side = side;
		this.liveEntry.detail.ranking[position].rank = rank ? rank : position + 1;
		this.liveEntry.detail.ranking[position].person.dflDatalibraryObjectId = this.selectedPlayer.externalPersonIds.dflDatalibraryPersonId;
		this.liveEntry.detail.ranking[position].person.imageUrl = this.selectedPlayer.playerImages.FACE_CIRCLE.replace('-circle', '');
		this.liveEntry.detail.ranking[position].person.name = this.selectedPlayer.name.hasOwnProperty('alias') && this.selectedPlayer.name.alias ? this.selectedPlayer.name.alias : this.selectedPlayer.name.full;
		this.update();
	}

	public addClubMetricProgression() {
		this.liveEntry.detail.progression.push({
			value: 0,
			unit: '',
			playtime: {
				minute: 0,
				injuryTime: 0
			},
			matchSection: ''
		});
	}

	public removeClubMetricProgression(entryIndex: number) {
		this.liveEntry.detail.progression.splice(entryIndex, 1);
	}

	public moveClubMetricProgression(index, newIndex) {
		const progression = this.liveEntry.detail.progression;
		progression[index] = progression.splice(newIndex, 1, progression[index])[0];
	}
}
