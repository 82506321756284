import { OpenSearchCompactClub } from './open-search-club';
import { OpenSearchSeason } from './open-search-season';

export interface OpenSearchMatch {
	readonly matchId: string;
	readonly season: OpenSearchSeason;
	readonly competitionName: string;
	readonly competitionId: string;
	readonly home: OpenSearchCompactClub;
	readonly guest: OpenSearchCompactClub;
	readonly matchDayId: string;
	readonly matchDay: number;
	readonly plannedKickOff?: string;
	readonly dateRange?: {
		gte?: string;
		lte?: string;
	};
}
