import { NgModule } from '@angular/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { StatsPlayerrankingCardComponent } from './component/stats-playerranking-card/stats-playerranking-card.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule, CommonsLibModule],
	exports: [StatsPlayerrankingCardComponent],
	declarations: [StatsPlayerrankingCardComponent],
	providers: []
})
export class StatsPlayerrankingCardModule {}
