import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LivetickerEventsModule, LivetickerEventsPipeModule } from '@nx-bundesliga/bundesliga-com/components/liveticker';
import { LivetickerEventComponent } from './liveticker-event.component';

@NgModule({
	imports: [CommonModule, LivetickerEventsPipeModule, LivetickerEventsModule],
	exports: [LivetickerEventComponent],
	declarations: [LivetickerEventComponent]
})
export class LivetickerEventModule {}
