import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';

interface TachyonParameters {
	fit?: string | number;
	resize?: string;
	w?: number;
	h?: number;
	quality?: number;
	crop_strategy?: 'smart' | 'entropy' | 'attention';
	gravity?: string;
	crop?: boolean | string;
	zoom?: number;
	webp?: boolean;
	lb?: string;
	background?: string;
}

/**
 * Interface for JW Parameter Objects.
 */
interface JWImageParameters {
	width?: string | number;
}

@Pipe({
	name: 'assetService'
})
export class AssetServicePipe implements PipeTransform {
	/**
	 *
	 * @param src
	 */
	static isJWDomain(value: string): boolean {
		if (value && value !== '') {
			return value.indexOf('cdn.jwplayer.com') > 0 || value.indexOf('assets-jpcust.jwpsrv.com') > 0;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param src
	 */
	static isTachyonDomain(value: string): boolean {
		if (value && value !== '') {
			return value.indexOf('img.bundesliga.com') > 0 || value.indexOf('assets.bundesliga') > 0 || value.indexOf('tchyn.') > 0 || value.indexOf('tachyon.') > 0;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param {string} value the url parameters without ? to be transformed into a hashmap
	 */
	static getUrlParamsMap = (value = ``) => {
		const hashes = value.split(`&`);
		return hashes.reduce((params, hash) => {
			const [key, val] = hash.split('=');
			return Object.assign(params, { [key]: decodeURIComponent(val) });
		}, {});
	};

	private isNotNull(n: number | undefined): boolean {
		if (!n) return false;
		return !isNaN(n) && n > 0;
	}

	constructor(@Inject(CORE_ENVIRONMENT) private readonly environment: Pick<BundesmasterEnvironment, 'stage'>) {}

	transform(value: string, width?: number, height?: number, additionalParams?: TachyonParameters, stripOnly?: boolean): string {
		if (value.startsWith('/assets/')) {
			return encodeURI(value);
		}

		// @todo/launch: remove this switch again when we've migrated all our images from the CIS/HumanMade Bucket into our own ds-asset-bucket
		let url = '';
		if (value.startsWith('http')) {
			url = value;
		} else if (value.startsWith('contender/') || value.startsWith('tachyon/')) {
			const stage = this.environment.stage === 'integration' ? '-appr' : this.environment.stage === 'development' ? '-dev' : '';
			url = `https://assets.bundesliga${stage}.com/${encodeURI(value)}`;
		} else if (!AssetServicePipe.isTachyonDomain(value)) {
			url = `https://img.bundesliga.com/tachyon/sites/2/${encodeURI(value)}`;
		}

		// remove existing parameters
		const [srcBase, paramString] = url.split('?', 2);

		if (AssetServicePipe.isTachyonDomain(url)) {
			const params: TachyonParameters = paramString ? Object.assign(AssetServicePipe.getUrlParamsMap(paramString), additionalParams || {}) : {};
			if (stripOnly) {
				return srcBase;
			}
			// convert resize parameters to fit if they are not set already
			if (params.resize) {
				if (!params.fit) {
					params.fit = params.resize;
				}
				delete params.resize;
			}
			// apply the provided params to the already existing ones, eventually replacing them
			if (this.isNotNull(width) && this.isNotNull(height)) {
				params.fit = `${width},${height}`;
			} else if (this.isNotNull(width)) {
				params.fit = `${width},${width}`;
			} else if (this.isNotNull(height)) {
				params.fit = `${height},${height}`;
			}

			return srcBase + '?' + this._addParameterStringToUrl(params);
		}

		if (AssetServicePipe.isJWDomain(value)) {
			const paramsJW: JWImageParameters = value.split('?').length > 1 ? Object.assign(AssetServicePipe.getUrlParamsMap(value.slice(value.indexOf('?') + 1)), {}) : {};
			if (stripOnly) {
				return srcBase;
			}
			if (this.isNotNull(width)) {
				paramsJW.width = width;
			}
			return srcBase + '?' + this._addParameterStringToUrl(paramsJW);
		}
		return value;
	}

	private _addParameterStringToUrl(params: TachyonParameters | JWImageParameters): string {
		const createQueryParams = (parameters: any) =>
			Object.keys(parameters)
				.map((k) => `${k}=${encodeURI(parameters[k])}`)
				.join('&');

		return createQueryParams(params);
	}
}
