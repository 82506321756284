<div class="container">
	<a mat-flat-button color="primary" class="currentmatchday" [routerLink]="['matches', 'de', 'DFL-COM-000001']">
		<img src="/assets/logo/bundesliga.svg" height="28px" />
		<br />
		GO TO CURRENT MATCHDAY ({{currentMatchdayNumberBL1}})
	</a>
	<a mat-flat-button color="primary" class="currentmatchday" [routerLink]="['matches', 'de', 'DFL-COM-000002']">
		<img src="/assets/logo/2bundesliga.svg" height="24px" />
		<br />
		GO TO CURRENT MATCHDAY ({{currentMatchdayNumberBL2}})
	</a>
	<hr />
	<iframe src="https://giphy.com/embed/xTiTnhnM639JYZ0xXy" width="480" height="415" frameborder="0" class="giphy-embed" allowfullscreen></iframe>
</div>
