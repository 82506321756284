import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { BundesligaMatchStates, LiveBlogClubs, LiveBlogEntries, LiveBlogInfos, LiveBlogMatch, LiveBlogScores, Playtime } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { IOutputData } from 'angular-split/lib/interface';
import { fromEvent, interval, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, switchMap, take, takeWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { secondsToCountdown } from '../../../framework/common/functions/liveticker.functions';
import { getAllGoalsFromEvents, getStadiumImageFromTlc } from '../../../framework/common/functions/match';
import { insertColonToTimezone } from '../../../framework/common/functions/time';
import { ClubService } from '../../../services/club/club.service';
import { DflDatalibraryMatchdayService } from '../../../services/dfldatalibrary/dflDatalibraryMatchday.service';
import { DflDatalibrarySeasonService } from '../../../services/dfldatalibrary/dflDatalibrarySeason.service';
import { MatchesService } from '../../../services/matches/matches.service';

export interface Countdown {
	days?: number | string;
	hours: number | string;
	minutes: number | string;
	seconds?: number | string;
}

/**
 * Possible states the matchcenter can have. Not exactly the official STS/Liveticker states, because the MatchCenter has additional PRE_MATCH
 * states, but doesn't care about the matchState once it's live. Even 'LIVE_PAUSED' isn't used currently.
 */
enum MatchcenterStatesEnum {
	'PRE_MATCH' = 1,
	'PRE_MATCH_KICKOFF_PLANNED' = 2,
	'PRE_MATCH_COUNTDOWN' = 3,
	'LIVE' = 4,
	'FINAL' = 5,
	'LIVE_PAUSED' = 6,
	'CANCELED' = 7,
	'PRE_EXTRA' = 8,
	'FIRST_HALF_EXTRA' = 9,
	'HALF_EXTRA' = 10,
	'SECOND_HALF_EXTRA' = 11,
	'PRE_PENALTY' = 12,
	'PENALTY' = 13
}

@Component({
	selector: 'app-match-detail-page',
	templateUrl: './match-detail-page.component.html',
	styleUrls: ['./match-detail-page.component.scss']
})
export class MatchDetailPageComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('matchBox', { static: false }) matchBox: ElementRef;
	@ViewChild('split', { static: false }) split: SplitComponent;
	@ViewChild('area1', { static: false }) area1: SplitAreaDirective;
	@ViewChild('area2', { static: false }) area2: SplitAreaDirective;

	public competitionIds = {
		'bundesliga': 'DFL-COM-000001',
		'2bundesliga': 'DFL-COM-000002',
		'supercup': 'DFL-COM-000003',
		'relegation-bundesliga': 'DFL-COM-000005',
		'relegation-2bundesliga': 'DFL-COM-000004'
	};
	public competitionNames = {
		'DFL-COM-000001': 'bundesliga',
		'DFL-COM-000002': '2bundesliga',
		'DFL-COM-000003': 'supercup',
		'DFL-COM-000005': 'relegation-bundesliga',
		'DFL-COM-000004': 'relegation-2bundesliga'
	};

	/**
	 * tabs display states.
	 */
	public displayTab = 'liveticker';

	/**
	 * draggable areas props.
	 */
	public dragAreaLocalStorageName = 'BundesStreakerDragAreaConfig';
	public areaControlsVisible = false;
	public dragDirection: 'horizontal' | 'vertical' = 'horizontal';
	public maxDragAreas = 4;
	public minDragAreas = 2;
	public defaultDragAreas: { size: number; component: string; order: number }[] = [
		{ size: 70, component: 'composer', order: 0 },
		{ size: 30, component: 'liveticker', order: 1 }
	];
	public dragAreas: { size: number; component: string; order: number }[] = [];

	/**
	 * Whether the entire component is still loading.
	 */
	public isLoading = true;

	/**
	 * All LiveBlogEntries from Firebase. Used as input for the Liveticker component.
	 */
	public liveBlogEntries: LiveBlogEntries;

	/**
	 * Infos about the current liveticker, including the isTyping indicators.
	 */
	public liveBlogInfos: LiveBlogInfos;

	/**
	 * Both teams coming from firebase.
	 */
	public teams: LiveBlogClubs;

	/**
	 * The UUID for this particular match.
	 */
	public matchId: string;

	/**
	 * The UUID for this particular matchday.
	 */
	public matchdayId: string;

	/**
	 * The UUID for this particular season.
	 */
	public seasonId: string;

	/**
	 * The UUID for this particular competition.
	 */
	public competitionId: string;

	/**
	 * Current score of the given match
	 */
	public score: LiveBlogScores;

	/**
	 * Current state of the match. I.e. whether or not this is currently live.
	 */
	public matchStatus: BundesligaMatchStates;
	/**
	 * Current minute of play of the match.
	 */
	public minuteOfPlay: Playtime;

	/**
	 * - PRE_MATCH
	 * - kick off not yet planned = PRE_MATCH
	 * - kick off planned = PRE_MATCH_KICKOFF_PLANNED
	 * - kick off planned & countdown shown = PRE_MATCH_COUNTDOWN
	 *
	 * - live_match
	 * - first_half, second_half, extra_time = LIVE
	 * - halftime, penalty = LIVE_PAUSED
	 *
	 * - final_whistle = FINAL
	 */
	public matchcenterStatus: MatchcenterStatesEnum;

	/**
	 * Enum of all possible matchcenter states. Ordered from PRE_MATCH with undefined kickOffDate to FINAL_WHISTLE.
	 */
	public matchcenterStates = MatchcenterStatesEnum;

	/**
	 * Time only. Parsed from "plannedKickOff". I.e. "15:30"
	 */
	public kickOffTime: string;

	/**
	 * The entire match node
	 */
	public match: LiveBlogMatch;

	/**
	 * URL to the stadium image for the home team. Used as a background image for the matchcenter header in large desktop views.
	 */
	public stadiumImagePath: string;

	/**
	 * Various labels to show when the game will be live. Only shown during while state is PRE_MATCH or PRE_MATCH_KICKOFF_PLANNED
	 */
	public dateLabel: string;
	public subDateLabel: string;

	/**
	 * Whether or not the matchcenter header should stick to the top of the viewport.
	 */
	public isSticky = false;
	public matchBoxIsSticky = false;

	/**
	 *
	 */
	public matchcenterOffset: number;

	/**
	 *
	 */
	public countdown: Countdown;

	/**
	 * Displayed on top of the matchcenter header.
	 */
	public matchdayDateLabel: string;

	/**
	 * RouterLinks to be used in the template.
	 */
	public clubLinkAway: string[];
	public clubLinkHome: string[];

	/**
	 *
	 */
	public fixturesLink: string[];

	/**
	 *
	 */
	public language: string;

	/**
	 *
	 */
	public competition: any;

	/**
	 *
	 */
	public highlightStats: any;

	/**
	 *
	 */
	private readonly isBrowser: boolean;

	/**
	 *
	 */
	private matchFirebaseSubscription: Subscription;
	private counterSubscription: Subscription;

	/**
	 * Props used to paginate API requests
	 */
	private pageSize = 12;
	private pageNumber = 0;

	/**
	 * URL naming form both Clubs
	 */
	public homeClubUrlName: string;
	public awayClubUrlName: string;

	/**
	 *
	 */
	constructor(private route: ActivatedRoute, private matchesService: MatchesService, private matchdayService: DflDatalibraryMatchdayService, private seasonService: DflDatalibrarySeasonService, private readonly configService: ConfigService, private clubService: ClubService, public theme: ThemingService) {}

	/**
	 *
	 * * resolve match slug to firebase node
	 * * * subscribe to firebase matchdays/matchday node
	 * * * * get editorial/news with match uuid, if tab === news
	 * * * * store liveBlogEntries and liveBlogInfos and give to liveticker as (new) inputs, if tab === liveticker (maybe supress updates if not === liveticker?)
	 */
	ngOnInit() {
		this.initDragAreas();
		this.route.params.subscribe((param) => {
			this.language = param['language'];
			this.competitionId = param['competitionId'];
			this.seasonId = param['seasonId'];
			this.matchdayId = param['matchdayId'];
			this.matchId = param['matchId'];
			this.fetchLiveticker();
		});

		// this.initStickyHeader();
	}

	fetchLiveticker() {
		// tslint:disable-next-line:max-line-length
		this.matchFirebaseSubscription = this.matchesService.getMatch(this.language, this.competitionId, this.seasonId, this.matchdayId, this.matchId).subscribe(
			(data) => {
				if (!data) {
					return;
				}
				this.match = data;
				this.liveBlogInfos = this.match.liveBlogInfos || { homeIsTyping: false, awayIsTyping: false, isTyping: false, name: '' };
				const liveBlogEntriesNodeName = environment.liveBlogEntries;
				this.liveBlogEntries = this.match[liveBlogEntriesNodeName] || null;
				this.teams = this.match.teams;
				this.score = this.match.score;
				this.matchStatus = this.match.matchStatus;
				this.minuteOfPlay = this.match.hasOwnProperty('minuteOfPlay') ? this.match.minuteOfPlay : { injuryTime: 0, minute: 0 };
				this.stadiumImagePath = getStadiumImageFromTlc(this.teams?.home?.threeLetterCode ?? 'unknown');
				this.highlightStats = getAllGoalsFromEvents(this.liveBlogEntries);

				// @todo share all this shit w/ liveticker component
				// set the matchcenter state
				const liveStates = ['FIRST_HALF', 'SECOND_HALF', 'FIRST_HALF_EXTRA', 'SECOND_HALF_EXTRA', 'PRE_PENALTY', 'PENALTY'];

				const livePausedStates = ['HALF', 'PRE_EXTRA', 'HALF_EXTRA'];

				let datePipeLanguage = this.language;
				if (datePipeLanguage === 'jp') {
					datePipeLanguage = 'ja';
				}
				const datePipe = new DatePipe(datePipeLanguage);

				let kickOffTimestamp = 0;
				if (this.match.plannedKickOff) {
					kickOffTimestamp = Date.parse(insertColonToTimezone(this.match.plannedKickOff));
					this.matchdayDateLabel = datePipe.transform(kickOffTimestamp, 'EEEE, dd.LL', undefined, datePipeLanguage); // '15:30'
					this.kickOffTime = datePipe.transform(kickOffTimestamp, 'HH:mm', undefined, datePipeLanguage); // '15:30'
				}

				let matchcenterStatus: MatchcenterStatesEnum;
				if (this.match.matchStatus === 'PRE_MATCH') {
					if (this.match.plannedKickOff) {
						const now = +new Date();
						const oneWeek = 10 * 24 * 60 * 60 * 1000;

						if (now + oneWeek > kickOffTimestamp) {
							matchcenterStatus = MatchcenterStatesEnum.PRE_MATCH_COUNTDOWN;
							this.startCountDown(kickOffTimestamp);
						} else {
							matchcenterStatus = MatchcenterStatesEnum.PRE_MATCH_KICKOFF_PLANNED;

							this.dateLabel = datePipe.transform(kickOffTimestamp, 'dd.LL.yy', undefined, datePipeLanguage); // '15.02.18'
							this.subDateLabel = datePipe.transform(kickOffTimestamp, 'EEEE', undefined, datePipeLanguage); // 'Sunday'
						}
					} else {
						matchcenterStatus = MatchcenterStatesEnum.PRE_MATCH;
						const rangeStartDate = new Date(this.match.matchdayRange.start);
						const startDate = datePipe.transform(rangeStartDate, 'dd.LL', undefined, datePipeLanguage);
						const startDay = datePipe.transform(rangeStartDate, 'EEEEEE', undefined, datePipeLanguage);

						const rangeEndDate = new Date(this.match.matchdayRange.end);
						const endDate = datePipe.transform(rangeEndDate, 'dd.LL.yy', undefined, datePipeLanguage);
						const endDay = datePipe.transform(rangeEndDate, 'EEEEEE', undefined, datePipeLanguage);

						this.matchdayDateLabel = `${startDay} ${startDate} - ${endDate} ${endDay}`;
						this.dateLabel = startDay + ' - ' + endDay;
						this.subDateLabel = startDate + ' - ' + endDate;
					}
				} else if (livePausedStates.includes(this.match.matchStatus)) {
					matchcenterStatus = MatchcenterStatesEnum.LIVE_PAUSED;
				} else if (liveStates.includes(this.match.matchStatus)) {
					matchcenterStatus = MatchcenterStatesEnum.LIVE;
				} else if (this.match.matchStatus === 'FINAL_WHISTLE') {
					matchcenterStatus = MatchcenterStatesEnum.FINAL;
				}

				this.matchcenterStatus = matchcenterStatus;

				// Set Links
				// @todo/dfl move "competition" to dflDataLibraryCompetitionService
				let competition = 'bundesliga';
				if (this.match.dflDatalibraryCompetitionId === 'DFL-COM-000002' || this.match.dflDatalibraryCompetitionId === 'DFL-COM-000005') {
					competition = '2bundesliga';
				}
				const season = this.seasonService.getSeasonNameById(data.dflDatalibrarySeasonId, true);

				this.clubService
					.getClubById(data.teams?.home?.dflDatalibraryClubId)
					.pipe(take(1))
					.subscribe(
						(homeClub) => {
							if (homeClub.name?.slugifiedFull && homeClub.name?.slugifiedFull !== '') {
								this.homeClubUrlName = homeClub.name?.slugifiedFull;
							} else {
								this.homeClubUrlName = null;
							}
						},
						(error) => {
							this.homeClubUrlName = null;
						}
					);

				this.clubService
					.getClubById(data.teams?.away?.dflDatalibraryClubId)
					.pipe(take(1))
					.subscribe(
						(awayClub) => {
							if (awayClub.name?.slugifiedFull && awayClub.name?.slugifiedFull !== '') {
								this.awayClubUrlName = awayClub.name?.slugifiedFull;
							} else {
								this.awayClubUrlName = null;
							}
						},
						(error) => {
							this.awayClubUrlName = null;
						}
					);
				const routeMatchday = 'route-matchday';

				this.fixturesLink = ['', 'matches', this.competitionNames[data.dflDatalibraryCompetitionId], season, '' + data.matchday];

				this.isLoading = false;
			},

			(error) => {
				this.isLoading = false;
			},

			() => {
				this.isLoading = false;
			}
		);
	}

	/**
	 *
	 */
	ngOnDestroy() {
		if (this.matchFirebaseSubscription) {
			this.matchFirebaseSubscription.unsubscribe();
		}
		if (this.counterSubscription) {
			this.counterSubscription.unsubscribe();
		}
	}

	/**
	 *
	 */
	ngOnChanges() {}

	/**
	 *
	 */
	private initStickyHeader() {
		fromEvent(window, 'scroll')
			.pipe(
				debounceTime(10),
				switchMap(() => {
					if (window.innerWidth > 575) {
						return of(false);
					} else {
						if (!this.matchcenterOffset || this.matchcenterOffset === 0) {
							this.matchcenterOffset = this.matchBox.nativeElement.getBoundingClientRect().y;
						}

						return of(window.pageYOffset).pipe(map((offset) => offset > this.matchcenterOffset));
					}
				}),
				distinctUntilChanged(),
				share()
			)
			.subscribe((status) => {
				this.matchBoxIsSticky = status;
			});
	}

	/**
	 *
	 */
	startCountDown(kickOffTimestamp: number) {
		let remainingSeconds = (kickOffTimestamp - +new Date()) / 1000;

		if (remainingSeconds > 0) {
			this.countdown = secondsToCountdown(remainingSeconds);
		}

		// is this really needed here?
		if (this.counterSubscription) {
			this.counterSubscription.unsubscribe();
		}

		this.counterSubscription = interval(1000)
			.pipe(
				map(() => (remainingSeconds -= 1)),
				takeWhile((x) => x > 0)
			)
			.subscribe(
				(t) => {
					this.countdown = secondsToCountdown(t);
				},
				(error) => {
					console.error(error);
				},
				() => {}
			);
	}

	private initDragAreas() {
		if (localStorage && localStorage.getItem(this.dragAreaLocalStorageName)) {
			this.dragAreas = JSON.parse(localStorage.getItem(this.dragAreaLocalStorageName));
		} else {
			this.resetDragAreaConfig();
		}
	}

	private resetDragAreaConfig() {
		this.dragAreas = [...this.defaultDragAreas];
		if (localStorage) {
			localStorage.removeItem(this.dragAreaLocalStorageName);
		}
	}

	private saveDragAreaConfig() {
		if (localStorage) {
			localStorage.setItem(this.dragAreaLocalStorageName, JSON.stringify(this.dragAreas));
		}
	}

	public dragEnd(dragEndEvent: IOutputData) {
		this.dragAreas.forEach((area, index) => {
			this.dragAreas[index].size = dragEndEvent.sizes[this.dragAreas[index].order] as number;
		});
		this.saveDragAreaConfig();
	}

	public setDragAreaTab(order: number, tabName) {
		this.dragAreas.forEach((area, index) => {
			if (area.order === order) {
				this.dragAreas[index].component = tabName;
			}
		});
		this.saveDragAreaConfig();
	}

	public layoutChange(key: string): void {
		if (key === 'direction') {
			this.toggleDragDirection();
		} else if (key === 'toggle_ticker') {
			this.hideInfoBar();
		} else if (key === 'add_col') {
			this.addDragArea();
		} else if (key === 'toggle_controls') {
			this.toggleDragAreaControls();
		} else if (key === 'reset_areaconfig') {
			this.resetDragAreaConfig();
		}
	}

	private addDragArea() {
		if (this.dragAreas.length < this.maxDragAreas) {
			const uniformSpace = 100 / (this.dragAreas.length + 1);
			this.dragAreas.forEach((area, index) => {
				this.dragAreas[index].size = uniformSpace;
			});
			this.dragAreas.push({ size: uniformSpace, component: 'empty', order: this.dragAreas.length });
		}
		this.saveDragAreaConfig();
	}

	public removeDragArea(orderIndex: number) {
		if (this.dragAreas.length > this.minDragAreas) {
			const uniformSpace = 100 / (this.dragAreas.length - 1);
			let newDragAreas = this.dragAreas;
			newDragAreas = newDragAreas.filter((item) => item.order !== orderIndex);
			newDragAreas.forEach((area) => {
				area.size = uniformSpace;
				area.order = area.order > orderIndex ? area.order - 1 : area.order;
			});
			this.dragAreas = newDragAreas;
		}
		this.saveDragAreaConfig();
	}

	public moveDragArea(order, steps) {
		const newOrder = order + steps;
		const oldOrder = order;
		if (newOrder >= 0 && newOrder <= this.dragAreas.length - 1 && newOrder <= this.maxDragAreas - 1) {
			const newDragAreas = this.dragAreas;
			newDragAreas.forEach((area) => {
				if (area.order === oldOrder) {
					area.order = newOrder;
				} else if (area.order === newOrder) {
					area.order = oldOrder;
				}
			});
			this.dragAreas = newDragAreas;
		}
		this.saveDragAreaConfig();
	}

	private toggleDragDirection(forceState?: 'horizontal' | 'vertical') {
		if (forceState) {
			this.dragDirection = forceState;
		} else if (this.dragDirection === 'horizontal') {
			this.dragDirection = 'vertical';
		} else if (this.dragDirection === 'vertical') {
			this.dragDirection = 'horizontal';
		}
	}

	private toggleDragAreaControls() {
		this.areaControlsVisible = !this.areaControlsVisible;
	}

	private hideInfoBar(forceOpen?: any) {
		if (this.dragAreas.some((area) => area.size === 100) || forceOpen) {
			const uniformSpace = 100 / this.dragAreas.length;
			this.dragAreas.forEach((area, index) => {
				this.dragAreas[index].size = uniformSpace;
			});
		} else {
			this.dragAreas.forEach((area, index) => {
				if (area.component === 'composer') {
					this.dragAreas[index].size = 100;
				} else {
					this.dragAreas[index].size = 0;
				}
			});
		}
		this.saveDragAreaConfig();
	}
}
