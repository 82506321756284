export type BundesmasterBroadcasterLogoAspectRatio = 'wide' | 'regular' | 'compact';
export type BundesmasterBroadcasterLogoType = 'LIGHT' | 'DARK';

export interface BundesmasterBroadcasterLogo {
	readonly type: BundesmasterBroadcasterLogoType;
	readonly aspectRatio: BundesmasterBroadcasterLogoAspectRatio;
	readonly url: string;
}

export interface BundesmasterBroadcaster {
	readonly name: string;
	readonly globalMMKName: string | null;
	readonly rank: number;
	readonly hiddenInEPG: boolean;
	readonly url: string;
	readonly logos: null | readonly BundesmasterBroadcasterLogo[];
	readonly broadcasterId: number;
	readonly country: string;
	readonly promoteInHeader: boolean;
	readonly takerId: string | null;
}

export interface PartialBundesmasterBroadcaster extends Partial<Omit<BundesmasterBroadcaster, 'logos'>> {
	readonly logos?: null | readonly Partial<BundesmasterBroadcasterLogo>[];
}

export type BundesmasterBroadcasterDeleteResponse = Pick<BundesmasterBroadcaster, 'broadcasterId' | 'country'>;

export type BundesmasterBroadcasterListResponse = readonly BundesmasterBroadcaster[];
