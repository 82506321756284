<h2 mat-dialog-title>MatchHighlight Video</h2>
<mat-dialog-content class="mat-typography action-edit">
	<dfl-loader [loading]="!video" surrounding="medium"></dfl-loader>
	<div class="container" *ngIf="video">
		<div class="row">
			<div class="col-12">
				<mat-form-field class="full-width">
					<mat-label>Headline (optional/not used yet)</mat-label>
					<input matInput [(ngModel)]="video.headline" (ngModelChange)="update()" placeholder="Headline (optional/not used yet)" tabindex="1" />
				</mat-form-field>
			</div>
			<div class="col-12">
				<mat-form-field class="full-width">
					<mat-label>Description (optional/not used yet)</mat-label>
					<textarea matInput [(ngModel)]="video.text" (ngModelChange)="update()" placeholder="Description (optional/not used yet)" rows="3" tabindex="2"></textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<mat-form-field class="full-width">
					<mat-label>JW Video ID</mat-label>
					<input matInput [(ngModel)]="video.videoId" (ngModelChange)="update()" (focusout)="reloadEmbed()" placeholder="JW Video ID" type="text" tabindex="3" />
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<mat-form-field class="full-width">
					<mat-label>Video duration in seconds</mat-label>
					<input matInput [(ngModel)]="video.duration" (ngModelChange)="update()" [disabled]="loadingManifest" placeholder="Video duration in seconds" type="number" tabindex="4" />
					<mat-spinner *ngIf="loadingManifest" diameter="24"></mat-spinner>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="preview">
		<div class="liveticker-entryContainer" *ngIf="previewEnabled$ | async">
			<dfl-jw-player class="liveEntryVideo" [video]="previewEntry" [showHeadline]="false" [tracking]="false" [ads]="false"></dfl-jw-player>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false">Cancel</button>
	<button mat-button color="warn" [disabled]="processing" (click)="onDelete()">Delete</button>
	<button mat-button [disabled]="processing" cdkFocusInitial (click)="onSubmit()">Publish changes</button>
</mat-dialog-actions>
