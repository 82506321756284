import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogMatch, LivetickerSides } from '@nx-bundesliga/models';
import idx from 'idx';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';
import { BlCrosspostDialogComponent } from '../bl-crosspost-dialog/crosspost-dialog.component';
import { BlLivetickereventDialogsDeleteComponent, BlLivetickereventDialogsEditComponent } from '../bl-livetickerevent-dialogs/bl-livetickerevent-dialogs.component';
import { AttachStorySlideImageDialogComponent } from '../bl-story-dialogs/attach-slide-image/bl-story-dialog-attach-slide-image.component';
import { CreateBasicSlideDialogComponent } from '../bl-story-dialogs/create-basic-slide/bl-story-dialog-create-basic-slide.component';

@Component({
	selector: 'bl-livetickerevent-wrapper',
	templateUrl: './bl-livetickerevent-wrapper.component.html',
	styleUrls: ['./bl-livetickerevent-wrapper.component.scss']
})
export class BlLivetickereventWrapperComponent implements OnChanges {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	@Input() language: string;
	@Input() postId: string;
	@Input() clubData: LiveBlogClubs;
	@Input() customMatch = false;
	@Input() enableOverlay = true;
	public conferenceProcessing = false;
	public hiddenProcessing = false;
	public side: LivetickerSides;
	public conference = false;

	constructor(public dialog: MatDialog, private http: HttpClient, private toastr: ToastrService, private loading: LoadingService) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.side = idx(this.liveEntry, (_) => _.value.side) || 'none';
		this.conference = idx(this.liveEntry, (_) => _.value.conference) || false;
	}

	public isPostControlsEnabled() {
		const enabledEntryTypes = ['freetext', 'image', 'video', 'embed', 'stats'];
		return enabledEntryTypes.includes(this.liveEntry.value.entryType) || this.customMatch;
	}

	public isCrosspostEnabled() {
		const enabledEntryTypes = ['image', 'video', 'embed', 'stats'];
		return enabledEntryTypes.includes(this.liveEntry.value.entryType);
	}

	public isStoryControlsImageEnabled() {
		const enabledEntryTypes = ['goal', 'ownGoal', 'redCard', 'yellowRedCard', 'lineup', 'start_firstHalf', 'end_firstHalf', 'start_secondHalf', 'finalWhistle'];
		return enabledEntryTypes.includes(this.liveEntry.value.entryType) && !this.customMatch;
	}

	public toggleConference(conference: boolean) {
		this.loading.setLoading(true);
		this.conferenceProcessing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId) + '/' + this.postId;

		const url = urlBase + '/' + urlSuffix;
		const payload = { ...this.liveEntry.value, conference };

		console.log(`Sending edit with conference flag to API: `, url);
		console.log('conference flag editEvent', payload);

		this.http.put(url, payload).subscribe(
			(success) => {
				this.toastr.success('Conference flag in Post has successfully been edited!', 'Published');
				this.loading.setLoading(false);
				this.conferenceProcessing = false;
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.conferenceProcessing = false;
			}
		);
	}

	public toggleHidden(hidden: boolean) {
		this.loading.setLoading(true);
		this.hiddenProcessing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId) + '/' + this.postId;

		const url = urlBase + '/' + urlSuffix;
		const payload = { ...this.liveEntry.value, hidden };

		console.log(`Sending edit with hidden flag to API: `, url);
		console.log('hidden flag editEvent', payload);

		this.http.put(url, payload).subscribe(
			(success) => {
				this.toastr.success('Hidden flag in Post has successfully been edited!', 'Published');
				this.loading.setLoading(false);
				this.hiddenProcessing = false;
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.hiddenProcessing = false;
			}
		);
	}

	openDeleteDialog() {
		const dialogRef = this.dialog.open(BlLivetickereventDialogsDeleteComponent, {
			data: {
				match: this.match,
				liveEntry: this.liveEntry,
				language: this.language,
				postId: this.postId,
				clubData: this.clubData
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	openEditDialog() {
		const dialogRef = this.dialog.open(BlLivetickereventDialogsEditComponent, {
			data: {
				match: this.match,
				liveEntry: this.liveEntry,
				language: this.language,
				postId: this.postId,
				clubData: this.clubData,
				customMatch: this.customMatch
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	openStorySlideDialog() {
		const dialogRef = this.dialog.open(CreateBasicSlideDialogComponent, {
			height: 'calc(100% - 60px)',
			width: 'calc(100% - 60px)',
			maxWidth: '100%',
			maxHeight: '100%',
			data: {
				match: this.match,
				liveEntry: this.liveEntry,
				language: this.language,
				postId: this.postId,
				clubData: this.clubData,
				customMatch: this.customMatch
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	openAttachSlideImageDialog() {
		const needsPrefix = ['lineup', 'start_firstHalf', 'end_firstHalf', 'start_secondHalf', 'finalWhistle'].includes(this.liveEntry.value.entryType);
		const dialogRef = this.dialog.open(AttachStorySlideImageDialogComponent, {
			height: 'calc(100% - 60px)',
			width: 'calc(100% - 60px)',
			maxWidth: '100%',
			maxHeight: '100%',
			data: {
				match: this.match,
				liveEntry: this.liveEntry,
				language: this.language,
				postId: `${needsPrefix ? this.match?.dflDatalibraryMatchId?.toLowerCase()?.replace('dfl-mat-', '') + '-' : ''}${this.postId}`,
				clubData: this.clubData,
				customMatch: this.customMatch
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	openCrosspostDialog() {
		const dialogRef = this.dialog.open(BlCrosspostDialogComponent, {
			minWidth: '880px',
			data: {
				match: this.match,
				liveEntry: this.liveEntry,
				language: this.language,
				postId: this.postId,
				clubData: this.clubData
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	public setIsTyping() {
		const payload = {
			language: this.language,
			competitionId: this.match.dflDatalibraryCompetitionId,
			seasonId: this.match.dflDatalibrarySeasonId,
			matchdayId: this.match.dflDatalibraryMatchdayId,
			matchId: this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId
		};
	}
}
