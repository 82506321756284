import { Injectable } from '@angular/core';
import { LiveBlogMatch, Match } from '@nx-bundesliga/models';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DflDatalibraryMatchdayService } from '../dfldatalibrary/dflDatalibraryMatchday.service';
import { DflDatalibrarySeasonService } from '../dfldatalibrary/dflDatalibrarySeason.service';
import { IFirebaseService } from '../firebase/IFirebase.service';

@Injectable({
	providedIn: 'root'
})
export class MatchesService extends IFirebaseService {
	public matchday = 1;
	public competitionId: string;
	public defaultCompetitionId = 'DFL-COM-000001';
	public defaultSeasonId: string;
	public language = 'en';

	constructor(private seasonService: DflDatalibrarySeasonService, private matchdayService: DflDatalibraryMatchdayService) {
		super();
		this.defaultSeasonId = this.seasonService.seasonId;
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param match
	 */
	public getBasicMatchinfosByMatchday(matchday?: number, competitionId?: string, seasonId?: string): Observable<Match[]> {
		if (!matchday) {
			matchday = this.matchday;
		}
		if (!seasonId || seasonId === '') {
			seasonId = this.defaultSeasonId;
		}
		if (!competitionId || competitionId === '') {
			competitionId = this.defaultCompetitionId;
		}

		const listPathBase = ['', 'all', competitionId, 'seasons', seasonId, 'matches'];
		const listPath = listPathBase.join('/');
		return this._getDataFromFirebaseWithFilter(listPath, 'matchday', matchday, 9);
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param match
	 * @param list of competitions
	 * @param list of season
	 */
	public getFlatBasicMatchinfosByCompetions(competitions?: any[], seasonId?: string): Observable<Match[]> {
		const competitionFirebasePaths: string[] = [];
		let listPathBase = [];
		let i = 0;
		let seasonIdQuery = this.defaultSeasonId;

		if (seasonId && seasonId !== '') {
			seasonIdQuery = seasonId;
		}

		for (const competition of competitions) {
			// receive data from firebase
			listPathBase = ['', 'all', competition['competitionId'], 'seasons', seasonIdQuery, 'matches'];
			competitionFirebasePaths[i] = listPathBase.join('/');
			i++;
		}

		return combineLatest(...competitionFirebasePaths.map((competitionFirebasePath) => this._getDataFromFirebase(competitionFirebasePath))).pipe(
			map((matches) => {
				const matchObjects = [];
				for (let i = 0; i < matches.length; ++i) {
					for (const [matchId, matchObject] of Object.entries(matches[i])) {
						const isMatch = matchId.match(/DFL\-MAT\-\w{6}/g); /*transferstate is part of the array :/ */
						if (isMatch) {
							matchObjects.push(matchObject);
						}
					}
				}
				return matchObjects;
			})
		);
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param match
	 * @param list of competitions
	 * @param list of season
	 */
	public getMatchinfosByCompetions(competition?: string, seasonId?: string): Observable<Match[]> {
		let listPathBase = [];
		let seasonIdQuery = this.defaultSeasonId;

		if (seasonId && seasonId !== '') {
			seasonIdQuery = seasonId;
		}
		listPathBase = ['', 'all', competition, 'seasons', seasonIdQuery, 'matches'];
		const competitionFirebasePath = listPathBase.join('/');
		return this._getDataFromFirebase(competitionFirebasePath).pipe(map((matches) => Object.values(matches)));
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param match
	 * @param lang
	 */
	public getAllMatchesByClubname(clubName: string, competitionId?: string, seasonId?: string): Observable<any[]> {
		if (seasonId === '') {
			seasonId = this.defaultSeasonId;
		}
		if (!competitionId || competitionId === '') {
			competitionId = this.defaultCompetitionId;
		}

		const listPathBase = ['', 'all', competitionId, 'seasons', seasonId, 'matches'];
		const listPath = listPathBase.join('/');
		const clubMatchesHome = this._getDataFromFirebaseWithFilter(listPath, 'teams/home/threeLetterCode', clubName, 99);
		const clubMatchesAway = this._getDataFromFirebaseWithFilter(listPath, 'teams/away/threeLetterCode', clubName, 99);

		return combineLatest(clubMatchesHome, clubMatchesAway).pipe(map((matches) => [...matches[0], ...matches[1]]));
	}

	/**
	 * Returns an observable for the entire firebase matchday/match node, including all clubData, liveEvents
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 * @param matchId
	 *
	 * @webviewOnly
	 */
	public getMatch(lang: string, competitionId: string, seasonId: string, matchdayId: string, matchId: string): Observable<LiveBlogMatch> {
		const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', matchdayId, matchId].join('/');
		return this._getDataFromFirebase(path);
	}

	/**
	 * Returns an observable for the entire firebase matchday node, including all matches for that day with all its clubData, liveEvents
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 *
	 */
	public getAllMatchesOfMatchday(competitionId: string, seasonId: string, matchdayId: string): Observable<any> {
		const lang = this.getMatchLanguage();
		const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', matchdayId].join('/');
		return this._getDataFromFirebase(path);
	}

	/**
	 * getMatchLanguage
	 */
	public getMatchLanguage(): string {
		let language = this.language;
		if (language === 'jp') {
			language = 'en';
		}
		return language;
	}

	/**
	 * getSupercupMatchBySeason
	 * @param seasonName
	 */
	public getSupercupMatchBySeason(seasonName?: string): Observable<LiveBlogMatch> {
		const lang = this.getMatchLanguage();

		let seasonId = this.defaultSeasonId;
		if (seasonName !== '') {
			seasonId = this.seasonService.getSeasonIdBySeasonName(seasonName);
		}
		const path = ['', lang, 'DFL-COM-000003', 'seasons', seasonId, 'matchdays'].join('/');
		return this._getDataFromFirebase(path).pipe(
			map((matches) => {
				/* supercup has alwasy one matchday with one match */
				const matchDayMatch = matches[Object.keys(matches)[0]]; /* pick the first matchday */
				return matchDayMatch[Object.keys(matchDayMatch)[0]] as LiveBlogMatch; /* pick the first match */
			})
		);
	}

	/**
	 * getRelegationMatchBySlug
	 * @param matchSlug
	 * @param seasonName
	 * @param competitionId
	 */
	public getRelegationMatchBySlug(matchSlug: string, seasonName?: string, competitionId?: string): Observable<LiveBlogMatch> {
		const lang = this.getMatchLanguage();
		let seasonId = this.defaultSeasonId;
		if (seasonName !== '') {
			seasonId = this.seasonService.getSeasonIdBySeasonName(seasonName);
		}

		if (competitionId === 'DFL-COM-000001') {
			competitionId = 'DFL-COM-000004';
		} else {
			competitionId = 'DFL-COM-000005';
		}
		const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays'].join('/');
		return this._getDataFromFirebase(path).pipe(
			map((matchdayMatches) => {
				for (const [matchDayId, matchDayMatches] of Object.entries(matchdayMatches)) {
					for (const [matchId, matchDayMatch] of Object.entries(matchDayMatches)) {
						if (matchDayMatch['slugs']['slugLong'] === matchSlug) {
							return matchDayMatch;
						}
					}
				}
				return false;
			})
		);
	}

	/**
	 * getMatchByMatchSlug
	 * @param matchSlug
	 * @param matchday
	 * @param seasonName
	 * @param competitionId
	 */
	public getMatchByMatchSlug(matchSlug: string, matchday: any, seasonName?: string, competitionId?: string): Observable<LiveBlogMatch> {
		const lang = this.getMatchLanguage();

		if (competitionId === '') {
			competitionId = this.competitionId;
		}

		if (matchday >= 0 && matchday <= 34) {
			/* normal matchday number */
			let seasonId = this.defaultSeasonId;
			if (seasonName !== '') {
				seasonId = this.seasonService.getSeasonIdBySeasonName(seasonName);
			}

			const dflDataLibraryMatchdayId = this.matchdayService.getMatchdayIdByNumber(matchday, seasonId);
			const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', dflDataLibraryMatchdayId].join('/');
			return this._getDataFromFirebaseWithFilter(path, 'slugs/slugLong', matchSlug, 1).pipe(map((matches) => matches[0] as LiveBlogMatch));
		} else if (this.isSupercup(matchday)) {
			return this.getSupercupMatchBySeason(seasonName);
		} else if (this.isRelegation(matchday)) {
			return this.getRelegationMatchBySlug(matchSlug, seasonName, competitionId);
		}
		return of(null);
	}

	/**
	 * isRelegation
	 * @param matchdayFromUrl
	 */
	public isRelegation(matchdayFromUrl: string): boolean {
		if (!matchdayFromUrl || matchdayFromUrl === '') {
			return false;
		}

		if (matchdayFromUrl.toLowerCase() === 'relegation' || matchdayFromUrl.toLowerCase() === 'descenso') {
			return true;
		}
		return false;
	}

	/**
	 * isSupercup
	 * @param matchdayFromUrl
	 */
	public isSupercup(matchdayFromUrl: string): boolean {
		if (!matchdayFromUrl || matchdayFromUrl === '') {
			return false;
		}

		if (matchdayFromUrl.toLowerCase() === 'supercup' || matchdayFromUrl.toLowerCase() === 'supercopa' || matchdayFromUrl.toLowerCase() === 'super-coupe') {
			return true;
		}
		return false;
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isLiveMatch(match: any): boolean {
		if (match['matchStatus'] !== 'PRE_MATCH' && match['matchStatus'] !== 'FINAL_WHISTLE') {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isPenalty(match: any): boolean {
		if (match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY') {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * checks if match has overtime state
	 * @param match
	 */
	public isOverTimeLiveMatch(match: any): boolean {
		if (
			(match.dflDatalibraryCompetitionId === 'DFL-COM-000004' || match.dflDatalibraryCompetitionId === 'DFL-COM-000005') &&
			(match['matchStatus'] === 'PRE_EXTRA' || match['matchStatus'] === 'FIRST_HALF_EXTRA' || match['matchStatus'] === 'HALF_EXTRA' || match['matchStatus'] === 'SECOND_HALF_EXTRA' || match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY')
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * getMatchCenterRouterLink
	 * @param match
	 */
	public getMatchCenterRouterLink(match, language?): string[] {
		const urlSeason = match.dflDatalibrarySeasonId;
		const urlCompetition = match.dflDatalibraryCompetitionId;
		const urlMatchday = match.dflDatalibraryMatchdayId;
		const urlMatch = match.dflDatalibraryMatchId;

		const matchCenterRouterLink = ['/', 'match', language || this.language, urlCompetition, urlSeason, urlMatchday, urlMatch];
		return matchCenterRouterLink;
	}
}
