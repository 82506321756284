export class BundesmasterLanguageRole {
	public constructor(public readonly language: string) {}

	public toString() {
		return this.language;
	}

	public isLanguage(language: string): boolean {
		return language?.toUpperCase() === this.language?.toUpperCase();
	}

	public static getFromRoles(roleNames: readonly string[] = []): BundesmasterLanguageRole[] {
		return (roleNames ?? []).map((roleName) => this.tryGetFromRole(roleName)).filter((role): role is BundesmasterLanguageRole => role !== null);
	}

	public static tryGetFromRole(roleName: string): BundesmasterLanguageRole | null {
		const match = roleName.match(/bundesmaster\.lang\.(\w+)/);
		const possibleLanguage = match?.[1];

		if (possibleLanguage) {
			return new BundesmasterLanguageRole(possibleLanguage);
		}

		return null;
	}
}
