import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ArticleContentBlockMoveNotificationService {
	private readonly blockMovedSubject = new Subject<Set<FormGroup>>();

	triggerBlockMoved(formGroups: Set<FormGroup>): void {
		this.blockMovedSubject.next(formGroups);
	}

	blockMoved(): Observable<Set<FormGroup>> {
		return this.blockMovedSubject.asObservable();
	}
}
