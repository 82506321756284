import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
	BundesmasterClub,
	BundesmasterClubApiService,
	BundesmasterMatch,
	BundesmasterMatchApiService,
	BundesmasterMatchday,
	BundesmasterMatchdayApiService,
	BundesmasterPerson,
	BundesmasterPersonApiService,
	BundesmasterSeason,
	BundesmasterSeasonApiService,
	TaggingDialogInput,
	TaggingDialogResult,
	Tags,
	completeCompetitionList,
	emptyBundesmasterDataCollection
} from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiDialogSelectClubsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-clubs';
import { BundesmasterUiDialogSelectCompetitionsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-competitions';
import { BundesmasterUiDialogSelectMatchdaysComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-matchdays';
import { BundesmasterUiDialogSelectMatchesComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-matches';
import { BundesmasterUiDialogSelectPersonsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-persons';
import { BundesmasterUiDialogSelectSeasonsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-seasons';
import { Observable, ReplaySubject, Subject, debounceTime, distinctUntilChanged, filter, fromEvent, interval, map, pipe, startWith, switchMap, take, takeUntil } from 'rxjs';

interface Category {
	name: string;
	id: string;
}

@Component({
	selector: 'bundesmaster-ui-article-tags',
	templateUrl: './bundesmaster-ui-article-tags.component.html',
	styleUrls: ['./bundesmaster-ui-article-tags.component.scss']
})
export class BundesmasterUiArticleTagsComponent implements OnDestroy {
	@Input() form: FormGroup<Record<keyof Tags, FormControl<string[]>>>;

	public readonly personsScrollSubject = new ReplaySubject<void>(1);
	public readonly personsClosedSubject = new Subject<void>();

	private readonly destroying$ = new Subject<void>();

	public readonly clubsInputFormControl = new FormControl('');
	public readonly matchesInputFormControl = new FormControl('');
	public readonly matchdaysInputFormControl = new FormControl('');
	public readonly personsInputFormControl = new FormControl('');

	public readonly competitions: Category[] = completeCompetitionList.map((comp) => ({ name: comp.displayName, id: comp.id }));

	public readonly seasons$: Observable<readonly BundesmasterSeason[]>;
	public matchdays = emptyBundesmasterDataCollection<BundesmasterMatchday>('loading');
	public matches = emptyBundesmasterDataCollection<BundesmasterMatch>('loading');
	public persons = emptyBundesmasterDataCollection<BundesmasterPerson>('loading');
	public clubs = emptyBundesmasterDataCollection<BundesmasterClub>('loading');

	public tags: string[] = [];

	public get canBulkTagMatches(): boolean {
		const { competitions, matchdays, seasons } = this.form.controls;
		return (competitions.value ?? []).length === 1 && (matchdays.value ?? []).length === 1 && (seasons.value ?? []).length === 1;
	}

	separatorKeysCodes: number[] = [ENTER, COMMA];

	constructor(
		private readonly dialog: MatDialog,
		formBuilder: FormBuilder,
		private readonly matchService: BundesmasterMatchApiService,
		private readonly personService: BundesmasterPersonApiService,
		seasonService: BundesmasterSeasonApiService,
		clubService: BundesmasterClubApiService,
		matchdayService: BundesmasterMatchdayApiService
	) {
		this.form = new FormGroup({
			clubs: formBuilder.control([]),
			competitions: formBuilder.control([]),
			matchdays: formBuilder.control([]),
			matches: formBuilder.control([]),
			players: formBuilder.control([]),
			seasons: formBuilder.control([]),
			tags: formBuilder.control([])
		});

		this.seasons$ = seasonService.getSeasons();

		const throttledStringInputPipe = () => pipe(takeUntil<string>(this.destroying$), distinctUntilChanged(), debounceTime(500), startWith(''));

		this.clubsInputFormControl.valueChanges
			.pipe(
				takeUntil(this.destroying$),
				startWith(''),
				switchMap((name) => clubService.getClubsCollection({ name }))
			)
			.subscribe((clubs) => (this.clubs = clubs));

		this.personsInputFormControl.valueChanges
			.pipe(
				throttledStringInputPipe(),
				switchMap((query) => personService.getPersons({ query }))
			)
			.subscribe((persons) => (this.persons = persons));

		this.matchdaysInputFormControl.valueChanges
			.pipe(
				throttledStringInputPipe(),
				switchMap((query) => matchdayService.getMatchdays({ query }))
			)
			.subscribe((response) => (this.matchdays = response));

		this.matchesInputFormControl.valueChanges
			.pipe(
				throttledStringInputPipe(),
				switchMap((query) => matchService.getMatches({ query }))
			)
			.subscribe((response) => (this.matches = response));
	}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	joinClubs({ allClubs }: BundesmasterPerson): string {
		return allClubs.map(({ threeLetterCode }) => threeLetterCode).join(', ');
	}

	bulkTagMatches(): void {
		const { competitions, matchdays, seasons } = this.form.controls;

		const seasonId = (seasons.value ?? [])[0] ?? '';
		const competitionId = (competitions.value ?? [])[0] ?? '';
		const matchDayId = (matchdays.value ?? [])[0] ?? '';

		this.matchService
			.getMatches({ seasonId, matchDayId, competitionId })
			.pipe(takeUntil(this.destroying$))
			.subscribe(({ items }) => items.forEach(({ matchId }) => this.addTag('matches', matchId)));
	}

	getPersonFaceImageUrl(person: BundesmasterPerson): string {
		return this.personService.buildCircleImageUrl(person);
	}

	public personSelectorClosed(): void {
		this.personsClosedSubject.next();
	}

	public personSelectorOpenend(data?: { panel?: { nativeElement?: HTMLElement } }): void {
		interval(25)
			.pipe(
				take(100),
				takeUntil(this.destroying$),
				filter(() => data?.panel?.nativeElement != null),
				map(() => data.panel.nativeElement),
				filter((element) => element instanceof HTMLElement),
				take(1)
			)
			.subscribe((element) =>
				fromEvent(element, 'scroll')
					.pipe(takeUntil(this.destroying$), takeUntil(this.personsClosedSubject), startWith(''))
					.subscribe(() => this.personsScrollSubject.next())
			);
	}

	removeTag(tagName: keyof Tags, tagToRemove: string): void {
		const items = this.form.controls[tagName].value ?? [];
		this.form.controls[tagName].setValue([...items.filter((item) => item !== tagToRemove)]);
	}

	addTag(tagName: keyof Tags, tagToAdd: string, inputFormControl?: FormControl<string>): void {
		const formControl = this.form.controls[tagName];

		if (tagToAdd && !formControl.value.includes(tagToAdd)) {
			formControl.setValue([...(formControl.value ?? []), tagToAdd]);
			//formControl.setValue([tagToAdd]);
		}

		inputFormControl?.setValue('', { emitEvent: true });
	}

	addFilterByDialog(tag: keyof Tags, event?: Event) {
		if (event) {
			event.stopPropagation();
		}
		let dialogComponent = null;
		switch (tag) {
			case 'clubs':
				dialogComponent = BundesmasterUiDialogSelectClubsComponent;
				break;
			case 'players':
				dialogComponent = BundesmasterUiDialogSelectPersonsComponent;
				break;
			case 'competitions':
				dialogComponent = BundesmasterUiDialogSelectCompetitionsComponent;
				break;
			case 'seasons':
				dialogComponent = BundesmasterUiDialogSelectSeasonsComponent;
				break;
			case 'matchdays':
				dialogComponent = BundesmasterUiDialogSelectMatchdaysComponent;
				break;
			case 'matches':
				dialogComponent = BundesmasterUiDialogSelectMatchesComponent;
				break;
			default:
				break;
		}

		const height = dialogComponent?.dialogHeightMode === 'dynamic' ? undefined : '90%';

		if (tag && tag.toString() !== '' && dialogComponent) {
			this.dialog
				.open<unknown, TaggingDialogInput, TaggingDialogResult>(dialogComponent, {
					data: { selected: this.form.get(tag).value ?? [] },
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%',
					height
				})
				.afterClosed()
				.pipe(filter((result) => !!result))
				.subscribe((result) => this.form.get(tag).setValue([...result]));
		}
	}
}
