<div class="container-fluid liveticker-main">
	<div *ngIf="liveBlogEntries && !loadingFailed; else error" id="liveticker_{{liveBlogEntries.matchId}}" class="liveticker-entryContainer" @blockInitialRenderAnimation #livetickerEntryContainer>
		<button mat-icon-button class="liveEntry-toggle" [class.inactive]="!liveEntryOverlay" [ngxTippy]="liveEntryOverlay ? 'Disable LiveEntry Overlay' : 'Enable LiveEntry Overlay'" [color]="liveEntryOverlay ? 'primary' : 'accent'" (click)="liveEntryOverlay = !liveEntryOverlay">
			<mat-icon>filter_frames</mat-icon>
		</button>
		<livetickerevent-isTyping *ngIf="enableTypingIndicator && liveBlogInfos" [typingData]="liveBlogInfos" [clubData]="teams" (animationStarted)="animationStarted($event)" (animationDone)="animationDone($event)"></livetickerevent-isTyping>

		<ng-container *ngFor="let liveEntry of liveBlogEntries | keyvalue | liveticker; trackBy: trackLivetickerEvent">
			<bl-livetickerevent-wrapper [enableOverlay]="liveEntryOverlay" [match]="match" [language]="language" [liveEntry]="liveEntry" [postId]="liveEntry.key" [clubData]="teams" [customMatch]="customMatch">
				<liveticker-event [liveEntry]="liveEntry.value" [teams]="teams" [match]="match"></liveticker-event>
			</bl-livetickerevent-wrapper>
		</ng-container>
	</div>
</div>

<ng-template #error>
	<div class="errorPanel">
		<div class="errorPanelInfos">
			<h2 class="errorPanelHeadline">{{ 'ERROR' }}</h2>
			<span class="errorPanelText">{{ loadingFailedError }}</span>
		</div>
		<div class="errorPanelEmpty"></div>
		<div class="errorPanelButton" (click)="tryReload()">
			<span class="tryAgainText">{{ 'TRY_AGAIN' }}</span>
		</div>
	</div>
</ng-template>
