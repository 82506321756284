<div class="container" id="welcome">
	<div class="row">
		<div class="col-12">
			<page-title>Competition {{competitionId}} | Season {{seasonId}} | Language {{language}}</page-title>
		</div>
	</div>

	<div class="row">
		<div class="col-12 d-flex flex-column flex-md-row justify-content-center">
			<div class="fixtures-dropdown-wrapper">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(ngModel)]="competitionId" (ngModelChange)="_fetchMatchData()" [disableOptionCentering]="true">
						<mat-option [value]="''" disabled>Competitions</mat-option>
						<mat-option *ngFor="let comp of competitionIds | keyvalue" [value]="comp.key">{{comp.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="seasonsIds">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(ngModel)]="seasonId" (ngModelChange)="_fetchMatchData()" [disableOptionCentering]="true">
						<mat-option *ngFor="let seasonOption of seasonsIds | keyvalue" [value]="seasonOption.key">{{seasonOption.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="availableLanguages && availableLanguages.length > 1">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(ngModel)]="language" (ngModelChange)="_fetchMatchData()" [disableOptionCentering]="true">
						<mat-option *ngFor="let langOption of availableLanguages" [value]="langOption">{{langOption}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<p>Use placeholders to replace values in the match itself. Examples:</p>
			<pre>
        $matchday$: 1,
        $away.nameFull$: "Hertha Berlin",
        $away.nameShort$: "Berlin",
        $away.threeLetterCode$: "BSC"
        $home.nameFull$: "FC Bayern München",
        $home.nameShort$: "Bayern",
        $home.threeLetterCode$: "FCB"
      </pre>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width">
				<input matInput [(ngModel)]="liveEntry.detail.headline" (ngModelChange)="update()" placeholder="Headline" tabindex="1" />
			</mat-form-field>
		</div>
		<div class="col-12">
			<mat-form-field class="full-width">
				<textarea matInput [(ngModel)]="liveEntry.detail.text" (ngModelChange)="update()" placeholder="Body" rows="3" tabindex="2"></textarea>
			</mat-form-field>
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>entryDate</mat-label>
				<input matInput [(ngModel)]="liveEntry.entryDate" (ngModelChange)="update()" type="input" placeholder="entryDate" />
			</mat-form-field>
		</div>
	</div>

	<div class="row preview">
		<div class="liveticker-entryContainer">
			<livetickerevent-basic class="event side_none" [liveEntry]="liveEntry" [clubData]="null"></livetickerevent-basic>
		</div>
	</div>

	<div class="row d-flex align-items-center justify-content-end">
		<button (click)="publish()" tabindex="3" mat-flat-button color="primary" [disabled]="publishing || loading">
			<mat-icon>gavel</mat-icon>
			PUBLISH
		</button>
	</div>

	<div class="fixturesWrapper" *ngIf="matches && matches.length > 0 && !loading">
		<div class="container-fluid">
			<button (click)="selectAll()" mat-raised-button>
				<mat-icon>select_all</mat-icon>
				Select All
			</button>
			<button (click)="unselectAll()" mat-raised-button>
				<mat-icon>browser_not_supported</mat-icon>
				Unselect All
			</button>
			<button (click)="showPreviews = !showPreviews" mat-raised-button style="float: right">
				<mat-icon>{{ showPreviews ? 'crop_free' : 'crop_din' }}</mat-icon>
				{{showPreviews ? 'Hide Previews' : 'Show Previews'}}
			</button>

			<hr style="margin: 8px auto" />

			<mat-accordion>
				<mat-expansion-panel *ngFor="let matchday of matchdays">
					<mat-expansion-panel-header>
						<mat-panel-title>Matchday: {{matchday}}</mat-panel-title>
						<mat-panel-description>Selected : {{calculateSelected(matchday)}} | {{calculateSubmissions(matchday)}}</mat-panel-description>
					</mat-expansion-panel-header>
					<button (click)="selectAllMD(matchday)" mat-raised-button>
						<mat-icon>select_all</mat-icon>
						Select All (MD {{matchday}})
					</button>
					<button (click)="unselectAllMD(matchday)" mat-raised-button>
						<mat-icon>browser_not_supported</mat-icon>
						Unselect All (MD {{matchday}})
					</button>
					<ng-container *ngFor="let match of matches; trackBy: trackByMatchId; let i=index">
						<div class="matchInfos" *ngIf="match.matchday === matchday">
							<mat-checkbox [disabled]="publishing" color="primary" [(ngModel)]="welcomeData[match.dflDatalibraryMatchId].selected" [ngModelOptions]="{standalone: true}">
								<div class="matchDataRow" id="fixtures_{{match.dflDatalibraryMatchId}}">
									<div class="teamHome clubHome">
										<a class="matchcenterLink">
											<div class="clubName">{{match.teams.home.nameFull}}</div>
											<div class="clubNameShort">{{match.teams.home.nameShort}}</div>
											<div class="clubLogo">
												<clublogo [dimension]="40" [title]="match.teams.home.nameFull" [clubId]="match.teams.home.dflDatalibraryClubId" [clubLogoUrl]="match.teams.home.logoUrl"></clublogo>
											</div>
										</a>
									</div>

									<div class="matchResults text-center">
										<a class="matchcenterLink">
											<div *ngIf="match.score; then tplScore else tplNoScore"></div>
											<ng-template #tplScore>
												<div class="score">
													<span class="scoreLive">
														<span>{{ match.score.home.live }}</span>
														:
														<span>{{ match.score.away.live }}</span>
													</span>
													<span class="scoreHt">
														(
														<span>{{ match.score.home.halftime }}</span>
														:
														<span>{{ match.score.away.halftime }}</span>
														)
													</span>
												</div>
											</ng-template>
											<ng-template #tplNoScore>
												<div class="score">
													<div class="scoreDefault">
														<span>-</span>
														:
														<span>-</span>
													</div>
												</div>
											</ng-template>
										</a>
									</div>

									<div class="teamAway clubAway">
										<a class="matchcenterLink">
											<div class="clubLogo">
												<clublogo [dimension]="40" [title]="match.teams.away.nameFull" [clubId]="match.teams.away.dflDatalibraryClubId" [clubLogoUrl]="match.teams.away.logoUrl"></clublogo>
											</div>
											<div class="clubName">{{match.teams.away.nameFull}}</div>
											<div class="clubNameShort">{{match.teams.away.nameShort}}</div>
										</a>
									</div>

									<div *ngIf="match.plannedKickOff" class="kickoffDatetime">
										<span>{{ match.plannedKickOff | date: 'EE, dd/MM/yy | H:mm' : undefined : language}}</span>
									</div>
									<div *ngIf="!match.plannedKickOff" class="kickoffDatetime">
										<span>{{ match.matchdayRange.start | date: 'EE, dd/MM/yy | H:mm' : undefined : language}} - {{ match.matchdayRange.end | date: 'EE, dd/MM/yy | H:mm' : undefined : language}}</span>
									</div>
								</div>
							</mat-checkbox>

							<div class="col-12 preview" *ngIf="welcomeData[match.dflDatalibraryMatchId]?.liveEntry && showPreviews === true">
								<div class="liveticker-entryContainer">
									<livetickerevent-basic class="event side_none" [liveEntry]="welcomeData[match.dflDatalibraryMatchId]['liveEntry']" [clubData]="null"></livetickerevent-basic>
								</div>
							</div>
							<div class="col-12" *ngIf="welcomeData[match.dflDatalibraryMatchId].selected || welcomeData[match.dflDatalibraryMatchId]?.success !== null">
								<mat-icon *ngIf="!publishing && welcomeData[match.dflDatalibraryMatchId]?.success === true" style="color: limegreen">check</mat-icon>
								<mat-icon *ngIf="!publishing && welcomeData[match.dflDatalibraryMatchId]?.success === false" style="color: red">warning</mat-icon>
								<mat-spinner *ngIf="publishing" diameter="24"></mat-spinner>
								<div *ngIf="!publishing && welcomeData[match.dflDatalibraryMatchId]?.success === false && welcomeData[match.dflDatalibraryMatchId]?.error">{{welcomeData[match.dflDatalibraryMatchId]?.error}}</div>
							</div>
						</div>
					</ng-container>
				</mat-expansion-panel>
			</mat-accordion>

			<div *ngIf="!matches[0].plannedKickOff" class="row kickoffDatetime">
				<div class="col-12">
					<span>{{ "Matchday not fixed" }}</span>
				</div>
			</div>
		</div>
	</div>
	<dfl-loader [loading]="loading"></dfl-loader>
</div>
