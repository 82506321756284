import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogMatch } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { getSide } from '../../../framework/common/functions/liveticker.functions';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'bl-livetickerevent-dialog-edit',
	templateUrl: 'bl-livetickerevent-dialogs.edit.component.html',
	styleUrls: ['./bl-livetickerevent-dialogs.component.scss']
})
export class BlLivetickereventDialogsEditComponent {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	@Input() language: string;
	@Input() postId: string;
	@Input() clubData: LiveBlogClubs;
	@Input() customMatch = false;
	@ViewChild('composer') composer: Component;
	public processing = false;
	public previousLiveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	public newLiveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	constructor(private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlLivetickereventDialogsEditComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.postId = this.data.hasOwnProperty('postId') ? this.data.postId : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
			this.customMatch = this.data.hasOwnProperty('customMatch') ? this.data.customMatch : null;

			// keep an unchanged copy of liveentry which will not be edited
			this.previousLiveEntry = this.liveEntry ? JSON.parse(JSON.stringify(this.liveEntry)) : null;
			this.newLiveEntry = this.liveEntry ? JSON.parse(JSON.stringify(this.liveEntry)) : null;
		}
	}

	getUnifiedSide(entry: LiveBlogAutoEvents, prefix = 'side_') {
		return getSide(entry, prefix);
	}

	public onEditClick(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId) + '/' + this.postId;

		const url = urlBase + '/' + urlSuffix;
		const payload = (this.composer as any).getPayload();

		console.log(`Sending edit to API: `, url);
		console.log('editEvent', payload);

		this.http.put(url, payload).subscribe(
			(success) => {
				this.toastr.success('Post has successfully been edited!', 'Published');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		);
	}
}

@Component({
	selector: 'bl-livetickerevent-dialog-delete',
	templateUrl: 'bl-livetickerevent-dialogs.delete.component.html',
	styleUrls: ['./bl-livetickerevent-dialogs.component.scss']
})
export class BlLivetickereventDialogsDeleteComponent {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	@Input() language: string;
	@Input() postId: string;
	@Input() clubData: LiveBlogClubs;
	public processing = false;
	constructor(private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlLivetickereventDialogsDeleteComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.postId = this.data.hasOwnProperty('postId') ? this.data.postId : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
		}
	}

	getUnifiedSide(entry: LiveBlogAutoEvents, prefix = 'side_') {
		return getSide(entry, prefix);
	}

	public onDeleteClick() {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId) + '/' + this.postId;
		const url = urlBase + '/' + urlSuffix;

		const httpOptions = { observe: 'body', responseType: 'json' } as any;
		console.log(`Sending delete to API: `, url);

		this.http.delete<any>(url, httpOptions).subscribe(
			(success: any) => {
				console.log('delete success', success);
				this.toastr.success(`Successfully deleted ${this.postId}`, 'Success');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},
			(error: any) => {
				console.log('delete error', error);
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.toastr.error(message, `Delete ${this.postId}`);
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			}
		);
	}
}

@Component({
	selector: 'bl-livetickerevent-dialog-create',
	templateUrl: 'bl-livetickerevent-dialogs.create.component.html',
	styleUrls: ['./bl-livetickerevent-dialogs.component.scss']
})
export class BlLivetickereventDialogsCreateComponent {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: LiveBlogAutoEvents | any;
	@Input() language: string;
	@Input() clubData: LiveBlogClubs;
	@Input() customMatch = false;
	@ViewChild('composer') composer: Component;
	public newLiveEntry: LiveBlogAutoEvents | any;
	public processing = false;
	constructor(private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlLivetickereventDialogsCreateComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
			this.customMatch = this.data.hasOwnProperty('customMatch') ? this.data.customMatch : null;

			// keep an unchanged copy of liveentry which will not be edited
			this.newLiveEntry = this.liveEntry ? JSON.parse(JSON.stringify(this.liveEntry)) : null;
		}
	}

	getUnifiedSide(entry: LiveBlogAutoEvents, prefix = 'side_') {
		return getSide(entry, prefix);
	}

	public onCreateClick(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId);

		const url = urlBase + '/' + urlSuffix;
		const payload = (this.composer as any).getPayload();

		console.log(`Sending post to API: `, url);
		console.log('postEvent', payload);

		this.http.post(url, payload).subscribe(
			(success) => {
				this.toastr.success('Post has successfully been pusblished!', 'Published');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		);
	}
}
