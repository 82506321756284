import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user/user.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	constructor(private broadcastService: MsalBroadcastService, private userService: UserService, private activatedRoute: ActivatedRoute, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService) {}

	private readonly destroying$ = new Subject<void>();
	public user: any;
	public loginFailure: any = null;

	ngOnInit() {
		this.userService.user$.pipe(takeUntil(this.destroying$)).subscribe((user) => {
			this.user = user;
			if (this.user) {
				this.loginFailure = null;
			}
		});
		this.activatedRoute.url.pipe(takeUntil(this.destroying$)).subscribe((url: UrlSegment[]) => {
			if (url.length && url.length >= 2 && url[1].path === 'error') {
				this.loginFailure = {
					error: 'There was an error logging you in or acquiring an access token. Please disable your popup-blocker and try again to see the full errormessage.'
				};
			}
		});
		this.broadcastService.msalSubject$
			.pipe(
				takeUntil(this.destroying$),
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
			)
			.subscribe((payload: EventMessage) => {
				this.loginFailure = payload;
			});
	}
	ngOnDestroy(): void {
		this.destroying$.next(undefined);
		this.destroying$.complete();
	}

	doLogin() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
					.pipe(takeUntil(this.destroying$))
					.subscribe(() => {});
			} else {
				this.authService
					.loginPopup()
					.pipe(takeUntil(this.destroying$))
					.subscribe(() => {});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as PopupRequest);
			} else {
				this.authService.loginRedirect();
			}
		}
	}
}
