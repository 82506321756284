<div class="header" [ngClass]="{'noFadeIn': noFadeIn}">
	<ng-content class="image"></ng-content>

	<div
		class="box"
		*ngFor="let box of persons"
		[ngStyle]="{
    top: ((box.face.BoundingBox?.Top || 0) * 100) + '%',
    left: ((box.face.BoundingBox?.Left || 0) * 100) + '%',
    height: ((box.face.BoundingBox?.Height || 0) * 100) + '%',
    width: ((box.face.BoundingBox?.Width || 0) * 100) + '%',
    }"
		><div><span>{{ box.user.User!.UserId! | resolvePersonId | async }}</span></div></div
	>
</div>
