import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlRapidTickerStatusModule } from '../../molecules/bl-liveticker-rapid-composer/status/bl-rapid-ticker-status.module';
import { BlTickerRapidCreatorComponent } from './bl-ticker-rapid-creator.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
// tslint:disable-next-line:max-line-length
import { BlRapidTickerSideChooserModule } from '../../molecules/bl-liveticker-rapid-composer/side-chooser/bl-rapid-ticker-side-chooser.module';
import { BlRapidTickerComposerModule } from '../../molecules/bl-liveticker-rapid-composer/composer/bl-rapid-ticker-composer.module';

@NgModule({
	declarations: [BlTickerRapidCreatorComponent],
	exports: [BlTickerRapidCreatorComponent],
	imports: [
		CommonModule,
		BlRapidTickerSideChooserModule,
		BlRapidTickerComposerModule,
		BlRapidTickerStatusModule,
		// @todo <- i need to be refactored pleeeeease

		MatIconModule,
		MatButtonModule,
		MatCardModule,
		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatCheckboxModule,
		MatStepperModule,
		MatProgressBarModule,
		MatProgressSpinnerModule
	]
})
export class BlTickerRapidCreatorModule {}
