import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { BundesmasterPerson, BundesmasterPersonApiService } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'resolvePersonId'
})
export class ResolvePersonIdPipe implements PipeTransform {
	constructor(private readonly service: BundesmasterPersonApiService) {}

	transform(personId: string): Observable<string> {
		return this.service.getPerson(personId).pipe(
			map((person) => (person ? person.aliasName ?? person.displayName : personId)),
			startWith(personId)
		);
	}
}

@Pipe({
	name: 'resolvePersonById'
})
export class ResolvePersonByIdPipe implements PipeTransform {
	constructor(private readonly service: BundesmasterPersonApiService) {}

	transform(personId: string): Observable<BundesmasterPerson> {
		return this.service.getPerson(personId);
	}
}
