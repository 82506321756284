import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { MatchesService } from '../../../services/matches/matches.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'bl-lineups',
	templateUrl: './bl-lineups.component.html',
	styleUrls: ['./bl-lineups.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class BlLineupsComponent implements OnInit, OnDestroy {
	@Input() firebase: string[] | string;
	public lineups: any;
	public matchFirebaseSubscription: Subscription;

	constructor(private matchesService: MatchesService) {}

	ngOnInit() {
		this.matchFirebaseSubscription = this.matchesService.getMatch('all', this.firebase[1], this.firebase[3], this.firebase[5], this.firebase[6]).subscribe((data) => {
			this.lineups = data;
		});
	}

	ngOnDestroy() {
		if (this.matchFirebaseSubscription) {
			this.matchFirebaseSubscription.unsubscribe();
		}
	}
}
