import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AngularSplitModule } from 'angular-split';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { LineupFeedModule } from '../../molecules/bl-lineup-feed/lineup-feed.module';
import { StatsFeedModule } from '../../molecules/bl-stats-feed/stats-feed.module';
import { TwitterFeedModule } from '../../molecules/bl-twitter-feed/twitter-feed.module';
import { BlTickerRapidCreatorModule } from '../../organisms/bl-ticker-rapid-creator/bl-ticker-rapid-creator.module';
import { LivetickerModule } from '../../organisms/liveticker/liveticker.module';
import { MatMatchHeaderModule } from '../../organisms/mat-match-header/mat-match-header.module';
import { CustomMatchDetailPageComponent } from './custom-match-detail-page.component';

const routes: Routes = [
	{
		path: 'custom-match/:language/:competitionId/:seasonId/:matchdayId/:matchId',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		component: CustomMatchDetailPageComponent
	},
	{
		path: 'custom-match',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		component: CustomMatchDetailPageComponent
	}
];

@NgModule({
	declarations: [CustomMatchDetailPageComponent],
	imports: [CommonsModule, RouterModule.forChild(routes), AngularSplitModule, LivetickerModule, MatMatchHeaderModule, MatTabsModule, MatIconModule, BlTickerRapidCreatorModule, StatsFeedModule, LineupFeedModule, TwitterFeedModule, OverlayscrollbarsModule]
})
export class CustomMatchDetailPageModule {}
