import { NgModule } from '@angular/core';
import { TextChartComponent } from './text-chart.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule],
	exports: [TextChartComponent],
	declarations: [TextChartComponent],
	providers: []
})
export class TextChartModule {}
