export interface Changelog {
	version: string;
	summary: string;
	date?: string;
	hash?: string;
	changes: {
		text: string;
		title: string;
		ticket?: string;
		commits?: string[];
	}[];
}
