import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import localeAr from '@angular/common/locales/ar';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { Store } from '@ngrx/store';
import { languageInit, useCompetition, useLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { Observable, shareReplay, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LoadingService } from './services/loading/loading.service';
import { UserService } from './services/user/user.service';

@Component({
	selector: 'bundesstreaker-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public isLoading: Observable<boolean>;
	public stage = environment.stage;
	public route$: Observable<string[]>;
	public matchesLink = '/matches';
	isIframe = false;
	loggedIn = false;
	public user: AccountInfo | null;
	private readonly destroying$ = new Subject<void>();
	isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).pipe(
		map((result) => result.matches),
		shareReplay()
	);

	constructor(
		private loadingService: LoadingService,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		public location: Location,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private themingService: ThemingService,
		public router: Router,
		private readonly store: Store<any>,
		private sanitizer: DomSanitizer,
		private breakpointObserver: BreakpointObserver
	) {
		registerLocaleData(localeDe, 'de');
		registerLocaleData(localeJa, 'ja');
		registerLocaleData(localeEs, 'es');
		registerLocaleData(localeFr, 'fr');
		registerLocaleData(localePt, 'pt');
		registerLocaleData(localeAr, 'ar');
		this.isLoading = this.loadingService.loading$.pipe(startWith(false));
		this.route$ = this.router.events.pipe(
			filter((e: Event) => e instanceof NavigationEnd),
			map(() => this.location.path(false)),
			/*map((e: NavigationEnd) => this.activatedRoute),
      map((route) => route.firstChild),
      filter((route) => route.outlet === 'primary'),
      mergeMap(route => route.url),
      map((urlSegment) => urlSegment.map(seg => seg.path)),*/
			filter((val) => val !== ''),
			map((url) => url.split('/'))
		);
	}

	ngOnInit(): void {
		this.store.dispatch(
			languageInit({
				defaultLanguage: {
					code: 'en',
					name: 'English',
					localName: 'English',
					culture: 'en-EN',
					ietf: 'en_US'
				},
				availableLanguages: [
					{
						code: 'en',
						name: 'English',
						localName: 'English',
						culture: 'en-EN',
						ietf: 'en_US'
					}
				]
			})
		);
		this.store.dispatch(useLanguage({ language: 'en' }));
		this.store.dispatch(useCompetition({ competition: 'bundesliga' }));
		this.route$.subscribe((data: string[]) => {
			if (data && data.length && data.length > 1 && data[1] === 'match') {
				this.matchesLink = '/matches/' + data.splice(2, 3).join('/');
			} else {
				this.matchesLink = '/matches';
			}
		});
		this.isIframe = window !== window.parent && !window.opener;

		this.userService.init();
		this.userService.isLoggedIn$.pipe(takeUntil(this.destroying$)).subscribe((isLoggedIn) => {
			this.loggedIn = isLoggedIn;
		});
		this.userService.user$.pipe(takeUntil(this.destroying$)).subscribe((user) => {
			this.user = user;
		});
	}

	login() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...(this.msalGuardConfig.authRequest as PopupRequest) })
					.pipe(takeUntil(this.destroying$))
					.subscribe();
			} else {
				this.authService.loginPopup().pipe(takeUntil(this.destroying$)).subscribe();
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as PopupRequest);
			} else {
				this.authService.loginRedirect();
			}
		}
	}

	logout() {
		this.authService.logout();
	}

	ngOnDestroy(): void {
		this.destroying$.next(undefined);
		this.destroying$.complete();
	}
}
