import { Label } from '@aws-sdk/client-rekognition';
import exif from 'exifreader';
import { FaceDetectionUser } from './asset.model';
import { Tags } from './tags.model';

export interface ImageMetadata {
	height: number;
	width: number;
	size: number;
	title: string;
	caption: string;
	copyright: string;
	exif?: exif.Tags;
	keywords?: string[];
	author?: string;
	createdTime?: string;
	createdDate?: string;
	externalTags?: string[];
	photoDbAssetId?: string;
}

export interface BundesmasterMediaLibraryUploadItem {
	id: string; // 123456-1234-1234-123456
	contentId?: string; // 1241241512 (id in opensearch)
	filename: string; // 123456-1234-1234-123456.image.jpg
	contentType: string; // image/jpg
	path: string; // 2023/03/123456-1234-1234-123456.image.jpg
	publicPath?: string; // https://img.bundesliga.com/c/2023/03/123456-1234-1234-123456.image.jpg
	status: 'REQUESTING_UPLOAD_URL' | 'UPLOADING' | 'PROCESSING' | 'ANALYZING' | 'DONE' | 'FAILED' | 'DELETED';
	author?: string;
	postProcessingDone: boolean;
	indexed: boolean;
	postProccesssingStart?: string;
	createdAt: string;
	lastUpdate: string;
	payload: ImageMetadata;
	labels: Label[];
	tags: Tags;
	persons: FaceDetectionUser[];
}

export interface BundesmasterMediaLibraryUploadError {
	error: string;
}
