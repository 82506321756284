<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div class="event cardEvent side_{{side}}">
	<div class="head">
		<div class="playtime">
			<livetickerevent-playtime [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
			<a *ngIf="liveEntry.entryType === 'yellowCard' && enablePlayerLinking" [routerLink]="['/', language, competition, 'route-stats', 'route-players', 'route-cardsYellow'] | translateRoute">
				<livetickerevent-headline [headline]="'LIVETICKER.EVENTS.' + liveEntry.entryType | translate" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
			</a>
			<a *ngIf="liveEntry.entryType === 'redCard' && enablePlayerLinking || liveEntry.entryType === 'yellowRedCard' && enablePlayerLinking" [routerLink]="['/', language, competition, 'route-stats', 'route-players', 'route-cards'] | translateRoute">
				<livetickerevent-headline [headline]="'LIVETICKER.EVENTS.' + liveEntry.entryType | translate" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
			</a>
			<livetickerevent-headline *ngIf="!enablePlayerLinking" [headline]="'LIVETICKER.EVENTS.' + liveEntry.entryType | translate" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
		</div>
	</div>
	<div class="body">
		<div class="card">
			<img [alt]="liveEntry.entryType" src="/assets/liveticker/{{liveEntry.entryType}}.svg" />
		</div>
		<div class="image" *ngIf="clubData && clubData[side]?.dflDatalibraryClubId">
			<player-image
				[playerImage]="liveEntry.detail.person.imageUrl| playerImage: 'FACE_CIRCLE' | imageService:0:64"
				[dimension]="64"
				placeHolderImg="/assets/placeholder/player-circle-default.png"
				[language]="language"
				[competition]="competition"
				[playerName]="liveEntry?.detail?.person?.name"
				[enabledLinking]="enablePlayerLinking"></player-image>
			<span class="clublogo" [title]="clubData[side]?.nameFull">
				<clublogo *ngIf="isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
				<clublogo *ngIf="!isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [width]="27" [height]="18"></clublogo>
			</span>
		</div>
		<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', liveEntry.detail.person.name | slugify] | translateRoute) : null">
			<span class="first">{{liveEntry.detail.person.name | livetickerEventPlayername:'first'}}</span>
			<span class="last">{{liveEntry.detail.person.name | livetickerEventPlayername:'last'}}</span>
		</a>
	</div>
</div>
