import { NgModule } from '@angular/core';
import { StatsClubprogressrankingCardComponent } from './component/stats-clubprogressranking-card/stats-clubprogressranking-card.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule],
	exports: [StatsClubprogressrankingCardComponent],
	declarations: [StatsClubprogressrankingCardComponent],
	providers: []
})
export class StatsClubprogressrankingCardModule {}
