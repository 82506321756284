import { AfterContentInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { map } from 'rxjs/operators';
import { LivestreamService } from '../livestream.service';
import { LIVESTREAM_MODAL_DATA, LIVESTREAM_MODALS, LivestreamData } from '../livestream.tokens';

@Component({
	selector: 'bundesstreaker-livestream-hts',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './livestream-hls.component.html',
	styleUrls: ['./livestream-hls.component.scss']
})
export class LivestreamHlsComponent implements OnInit, OnDestroy, AfterContentInit {
	@ViewChild('target', { static: true }) target: ElementRef;
	constructor(@Inject(LIVESTREAM_MODAL_DATA) public data: LivestreamData, @Inject(DOCUMENT) private document: Document, @Inject(WINDOW) private window: Window, private livestreamService: LivestreamService, private renderer: Renderer2) {}

	public player: any;
	public error: any;
	public videoJsConfigObj = {
		preload: 'auto',
		controls: true,
		autoplay: 'any',
		overrideNative: true,
		aspectRatio: '16:9',
		fluid: true,
		liveui: true,
		poster: '/assets/placeholder/resp_live_placeholder_image_home.jpg',
		techOrder: ['html5'],
		html5: {
			nativeVideoTracks: false,
			nativeAudioTracks: false,
			nativeTextTracks: false,
			hls: {
				withCredentials: false,
				overrideNative: true,
				debug: true
			}
		}
	};

	ngAfterContentInit() {
		this.livestreamService
			.getLivestream(this.data?.matchId ?? '')
			.pipe(map((ls) => ls?.hls))
			.subscribe({
				next: (streamUrl: string) => {
					this.player = (LIVESTREAM_MODALS['windowInstance'] as any)?.videojs('livestream-hls-video', {
						...this.videoJsConfigObj,
						sources: [
							{
								src: streamUrl,
								type: 'application/x-mpegURL'
							}
						]
					});
				},
				error: (err) => {
					this.error = err;
				}
			});
	}

	ngOnInit() {
		this.loadJsScript();
	}

	// Dispose the player OnDestroy
	ngOnDestroy() {
		if (this.player) {
			this.player?.dispose();
		}
	}

	private loadJsScript(): HTMLScriptElement {
		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://vjs.zencdn.net/8.5.2/video.min.js';
		this.renderer.appendChild(this.document.body, script);
		return script;
	}
}
