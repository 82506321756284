import { Component } from '@angular/core';

@Component({
	selector: 'livetickerevent-matchfacts-banner',
	templateUrl: './liveticker-event-matchfacts-banner.component.html',
	styleUrls: ['./liveticker-event-matchfacts-banner.component.scss']
})
export class LivetickerEventMatchfactsBannerComponent {
	public awsPartnerPageUrl = 'https://www.bundesliga.com/en/bundesliga/route-info/provider/aws';
	public language = 'en';

	constructor() {}
}
