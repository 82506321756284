import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'videoduration'
})
export class VideoDurationPipe implements PipeTransform {
	transform(value: number): string {
		if (!value || value === 0) {
			return '';
		} else {
			let minutes = 0;
			let seconds = 0;
			let secondsCalc = '00';
			let minutesCalc = '0';
			if (value < 60) {
				seconds = value;
			} else {
				seconds = value % 60;
				minutes = (value - seconds) / 60;
				minutesCalc = minutes.toString(10);
			}
			secondsCalc = seconds < 10 ? '0' + seconds.toString(10) : seconds.toString(10);
			return `${minutesCalc}:${secondsCalc}`;
		}
	}
}
