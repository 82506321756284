<div class="lineups" *ngIf="lineups && lineups.lineup">
	<div class="side home">
		<div class="starting">
			<span class="badge" *ngIf="lineups.lineup.home?.startingEleven?.isTactical">{{ lineups.lineup.home.startingEleven.tacticalFormationName }}</span>
			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.home?.startingEleven?.persons">{{ person.tacticalName }}</a>
		</div>

		<div class="bench">
			<span class="badge">SUBS</span>
			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.home?.bench?.persons">{{ person.name }}</a>
		</div>

		<div class="coaches">
			<span class="badge">COACHES</span>
			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.home?.coaches?.persons">{{ person.name }}</a>
		</div>
	</div>
	<div class="side away">
		<div class="starting">
			<span class="badge" *ngIf="lineups.lineup.away?.startingEleven?.isTactical">{{ lineups.lineup.away.startingEleven.tacticalFormationName }}</span>

			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.away?.startingEleven?.persons">{{ person.tacticalName }}</a>
		</div>

		<div class="bench">
			<span class="badge">SUBS</span>
			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.away?.bench?.persons">{{ person.name }}</a>
		</div>

		<div class="coaches">
			<span class="badge">COACHES</span>
			<a target="_blank" [href]="'https://www.bundesliga.com/en/player/current-season/' + person.dflDatalibraryObjectId + '/toni-polster/stats'" class="person" *ngFor="let person of lineups.lineup.away?.coaches?.persons">{{ person.name }}</a>
		</div>
	</div>
</div>
