import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'bl-rapid-ticker-status',
	templateUrl: './bl-ticker-status.component.html',
	styleUrls: ['./bl-ticker-status.component.scss']
})
export class BlTickerStatusComponent implements OnInit, OnChanges {
	@Input() liveBlogTeams: LiveBlogClubs;
	@Input() error: any = null;
	@Input() side: LivetickerSides;
	@Output() back: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();
	public postIsPublishingVideoSource: string;
	public errorStatus: string;
	public errorName: string;
	public errorMessage: string;
	constructor() {}

	ngOnInit() {
		this.postIsPublishingVideoSource = '/assets/videos/publish/' + (Math.floor(Math.random() * 6) + 1) + '.mp4';
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.error) {
			const status = this.error.hasOwnProperty('status') ? this.error.status : 'Unknown StatusCode';
			const name = this.error.hasOwnProperty('name') ? this.error.name : 'Error';
			const message = this.error.error.hasOwnProperty('message') ? this.error.error.message : this.error.hasOwnProperty('message') ? this.error.message : 'Unknown Error';
			this.errorStatus = status;
			this.errorName = name;
			this.errorMessage = message;
		} else {
			this.errorStatus = '';
			this.errorName = '';
			this.errorMessage = '';
		}
	}
}
