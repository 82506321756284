<h2 mat-dialog-title>Delete Liveticker entry</h2>
<mat-dialog-content class="mat-typography action-delete">
	<h3>Id: {{postId}}</h3>

	<liveticker-event [liveEntry]="liveEntry.value" [teams]="clubData" [match]="match"></liveticker-event>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
	<button mat-button [disabled]="processing" (click)="onDeleteClick()">Delete</button>
</mat-dialog-actions>
