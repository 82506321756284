import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LiveBlogAutoEventLineup, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-lineup',
	templateUrl: './livetickerevent-lineup.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-lineup.component.scss'],
	animations: [liveblogPostAnimation('.head livetickerevent-headline, .head livetickerevent-score, .head livetickerevent-headline, .body .player')]
})
export class LivetickereventLineupComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogAutoEventLineup;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() language = 'en';
	@Input() competition = 'bundesliga';
	@Input() enablePlayerLinking = true;
	@Input() isWebview = false;

	public side: LivetickerSides;
	public nodgeColor: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		this.side = (this.liveEntry?.side as LivetickerSides) ?? 'none';
		this.nodgeColor = this.clubData?.[this.side]?.gradientStartColor ?? 'transparent';
	}
}
