export interface ContenderRawPerson {
	active: boolean;
	alias?: string;
	additionalNationalityOneEnglish: string;
	additionalNationalityOneGerman: string;
	additionalNationalityOneSpanish: string;
	birthDate: string;
	birthPlace: string;
	clubId: string;
	clubName: string;
	countryOfBirthEnglish: string;
	countryOfBirthGerman: string;
	countryOfBirthSpanish: string;
	created: string;
	dlProviderId: string;
	firstName: string;
	height: number;
	lastName: string;
	localPlayer: string;
	name: string;
	nationalityEnglish: string;
	nationalityGerman: string;
	nationalitySpanish: string;
	objectId: string;
	playingPositionEnglish: string;
	playingPositionGerman: string;
	playingPositionSpanish: string;
	primaryPool: boolean;
	seasonClubTypeVersion: string;
	seasonId: string;
	seasonTypeActive: string;
	shirtNumber: number;
	type: 'player' | 'person';
	typeActive: string;
	updated: string;
	version: number;
	weight: number;
}
