import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatchesService } from '../../../services/matches/matches.service';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { BlTickerMatchsettingsControlsModule } from '../../molecules/bl-ticker-matchsettings-controls/bl-ticker-matchsettings-controls.module';
import { FixturesComponent } from './component/fixtures/fixtures.component';

@NgModule({
	imports: [CommonsModule, RouterModule, MatButtonModule, BlTickerMatchsettingsControlsModule],
	exports: [FixturesComponent],
	providers: [DatePipe, MatchesService],
	declarations: [FixturesComponent]
})
export class FixturesModule {}
