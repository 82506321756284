<div *ngIf="liveEntry" class="event embedEvent side_{{side}}">
	<div class="liveEntryEmbed">
		<embed-wrapper [embed]="liveEntry.detail"></embed-wrapper>
	</div>
	<div class="body d-none">
		<livetickerevent-score class="score" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
		<livetickerevent-headline class="headline" *ngIf="liveEntry.detail?.headline" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
		<div class="text" *ngIf="liveEntry?.enableHtml === true && liveEntry?.detail?.text" [innerHTML]="isWebview ? (liveEntry.detail.text | striphtml:true:'external' | safeHtml) : (liveEntry.detail.text | safeHtml)"></div>
		<div class="text" *ngIf="!(liveEntry?.enableHtml === true) && liveEntry?.detail?.text">{{ liveEntry.detail.text }}</div>
	</div>
</div>
