<h2 mat-dialog-title>Custom Match Progress</h2>
<mat-dialog-content class="mat-typography action-edit">
	<div class="container" *ngIf="match">
		<div class="row">
			<div class="col-12">
				<mat-form-field class="full-width">
					<mat-label>MatchStatus</mat-label>
					<mat-select [(ngModel)]="customMatch.matchStatus" tabindex="1">
						<mat-option value="PRE_MATCH">PRE_MATCH</mat-option>
						<mat-option value="FIRST_HALF">FIRST_HALF</mat-option>
						<mat-option value="HALF">HALF</mat-option>
						<mat-option value="SECOND_HALF">SECOND_HALF</mat-option>
						<mat-option value="PRE_EXTRA">PRE_EXTRA</mat-option>
						<mat-option value="FIRST_HALF_EXTRA">FIRST_HALF_EXTRA</mat-option>
						<mat-option value="HALF_EXTRA">HALF_EXTRA</mat-option>
						<mat-option value="SECOND_HALF_EXTRA">SECOND_HALF_EXTRA</mat-option>
						<mat-option value="PRE_PENALTY">PRE_PENALTY</mat-option>
						<mat-option value="PENALTY">PENALTY</mat-option>
						<mat-option value="FINAL_WHISTLE">FINAL_WHISTLE</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">LIVE</div>
		</div>
		<div class="row">
			<div class="col-6">
				<mat-form-field class="full-width">
					<mat-label>Home team live score</mat-label>
					<input matInput [(ngModel)]="customMatch.score.home.live" [disabled]="processing" placeholder="Home team live score" type="number" tabindex="2" />
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="full-width">
					<mat-label>Away team live score</mat-label>
					<input matInput [(ngModel)]="customMatch.score.away.live" [disabled]="processing" placeholder="Away team live score" type="number" tabindex="3" />
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-12">HALFTIME</div>
		</div>
		<div class="row">
			<div class="col-6">
				<mat-form-field class="full-width">
					<mat-label>Home team halftime score</mat-label>
					<input matInput [(ngModel)]="customMatch.score.home.halftime" [disabled]="processing" placeholder="Home team halftime score" type="number" tabindex="4" />
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-form-field class="full-width">
					<mat-label>Away team halftime score</mat-label>
					<input matInput [(ngModel)]="customMatch.score.away.halftime" [disabled]="processing" placeholder="Away team halftime score" type="number" tabindex="5" />
				</mat-form-field>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false">Cancel</button>
	<button mat-button [disabled]="processing" cdkFocusInitial (click)="onSubmit()">Publish changes</button>
</mat-dialog-actions>
