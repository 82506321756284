import { BundesmasterPartialCollection } from '../bundesmaster-data-collection';

export interface BundesmasterMatchday {
	readonly matchdayId: string;
	readonly matchday: string;
	readonly matchdayLabel: string;
	readonly seasonId: string;
	readonly season: string;
}

export interface BundesmasterMatchDaysLambdaResponse {
	readonly matchdays: readonly BundesmasterMatchday[];
	readonly partial: BundesmasterPartialCollection;
}
