//eslint-disable import/first
//eslint lines-around-directive: ["error", { "before": "always", "after": "never" }]
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { BundesligaEmbedPlaceholder } from '@nx-bundesliga/models';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
import { Observable, ReplaySubject, retry, Subscription, switchMap, timer } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';

@Component({
	selector: 'embed-apester',
	templateUrl: './embed-apester.component.html',
	styles: [':host { margin: 20px auto; width: 100%; min-height:570px;}'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class EmbedApesterComponent implements AfterViewInit, OnDestroy {
	@Input() set idOrUrl(value: string) {
		this.idOrUrl$.next(value);
	}
	@Input() placeholder: BundesligaEmbedPlaceholder;
	@ViewChild('embed') embed: ElementRef;

	@AutoUnsubscribe() private apsterReloadSubscription: Subscription;

	public apesterId$: Observable<string> = new ReplaySubject<string>(1);
	public apesterReady$: Observable<boolean> = new ReplaySubject<boolean>(1);
	public idOrUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);

	constructor(private loader: ScriptLoaderService, private cdr: ChangeDetectorRef, private ngZone: NgZone) {
		this.apesterId$ = this.idOrUrl$.pipe(
			map((el: string) => this.getApesterId(el)),
			filter((id) => !!id)
		);
	}

	ngAfterViewInit() {
		this.apesterReady$ = this.loader.load('apester', true)[0].pipe(
			share(),
			switchMap(() => this.apesterId$),
			map(() => {
				if (typeof (window as any).APESTER === 'object') {
					return true;
				}
				throw new Error('apester not yet ready');
			}),
			retry({ count: 5, delay: () => timer(2500) }),
			tap(() => {
				if (typeof (window as any).APESTER === 'object') {
					(window as any).APESTER.reload();
					this.cdr.detectChanges();
				}
			})
		);
	}

	ngOnDestroy() {
		if ((window as any)?.APESTER?.apesterElements?.length > 0) {
			(window as any).APESTER.apesterElements = (window as any).APESTER?.apesterElements.filter((el) => {
				return el?.element !== this.embed?.nativeElement;
			});
		}
		this.embed?.nativeElement?.remove();
		(window as any).APESTER.reload();
	}

	public getApesterId(idOrUrl: string): string {
		return idOrUrl ? idOrUrl.split('/').pop() : null;
	}
}
