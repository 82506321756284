<nx-bundesliga-bundesmaster-ui-loading-bar [loading]="loading" [text]="false"></nx-bundesliga-bundesmaster-ui-loading-bar>
<nx-bundesliga-bundesmaster-ui-loading-bar-text *ngIf="initialLoad"></nx-bundesliga-bundesmaster-ui-loading-bar-text>

<ng-container *ngIf="(videos$ | async) as videos">
	<div class="shadow">
		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Search</mat-expansion-panel-header>
			<bundesmaster-ui-search-bar [enableFilter]="false" [query]="query$ | async" (queryChanged)="onSearchQueryChanged($event)" [selectFilter]="selectFilter"></bundesmaster-ui-search-bar>
		</mat-expansion-panel>

		<nx-bundesliga-bundesmaster-ui-mat-paginator-goto class="inline" (page)="onPageinationChanged($event)" [length]="videos.total" [pageIndex]="page - 1" [pageSize]="size" [pageSizeOptions]="[24, 48, 72, 96]" [showFirstLastButtons]="true" aria-label="Select page"></nx-bundesliga-bundesmaster-ui-mat-paginator-goto>
	</div>

	<div class="items">
		<nx-bundesliga-bundesmaster-ui-loading-bar-text *ngIf="!videos.media || videos.media.length === 0" message="No assets found matching your query 🥺"></nx-bundesliga-bundesmaster-ui-loading-bar-text>

		<nx-bundesliga-bundesmaster-ui-teaser-preview *ngFor="let item of videos?.media" (click)="videoClicked.emit({video: item, videoproperty: videoproperty})" [title]="item?.metadata?.title">
			<img loading="lazy" [src]="cdnPrefix + item.id + '.jpg?w=320'" alt="" teaser />
			<span head>{{ item?.metadata?.title | truncate: [60] }}</span>
			<div content [title]="item?.metadata?.description">
				<hr />
				<strong>VideoId: {{item?.id}}</strong>
				<hr />
				{{ item?.metadata?.description && (item.metadata.description | truncate: [160]) }}
			</div>
			<div actions>
				<div class="property">
					<div class="icon"><mat-icon inline="true">image</mat-icon></div>
					<strong>Duration: {{item?.duration * 1000 | date:'mm:ss'}}</strong>
				</div>
				<div class="property">
					<div class="icon"><mat-icon inline="true">calendar_month</mat-icon></div>
					<strong>Date: {{ item?.created | date }}</strong>
				</div>
			</div>
		</nx-bundesliga-bundesmaster-ui-teaser-preview>
	</div>

	<div class="shadow bottom">
		<nx-bundesliga-bundesmaster-ui-mat-paginator-goto (page)="onPageinationChanged($event)" [length]="videos.total" [pageIndex]="page - 1" [pageSize]="size" [pageSizeOptions]="[24, 48, 72, 96]" [showFirstLastButtons]="true" aria-label="Select page"></nx-bundesliga-bundesmaster-ui-mat-paginator-goto>
	</div>
</ng-container>
