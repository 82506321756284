import { EditorialTypes } from './article.model';

export type VIEW_TYPES = 'grid' | 'list';
export enum HOMEPAGE_CATEGORIES {
	FEATURED_ARTICLES = 'featuredArticles',
	MOST_WATCHED = 'mostWatched',
	MOST_SHARED = 'mostShared',
	NEWS_AND_FEATURES = 'newsAndFeatures'
}

export interface IHomeControlBreakingNews {
	cta: string | null;
	display_from: string | null;
	display_until: string | null;
	text: string | null;
	url: string | null;
	target: '_self' | '_blank' | null;
	relationships: string[] | null;
}

export interface IHomeControlArticle {
	type: EditorialTypes;
	categories: string[];
	cisId: string | number;
	editorialAbstract: string;
	headlines: {
		defaultHeadline: string;
		shortHeadline: string;
	};
	isExclusive: boolean;
	isVideo: boolean;
	publishedAt: string;
	slug: string;
	speakingUrl: string;
	teaser: string;
	teaserAbstract: {
		teaserAbstractShort: string;
		teaserAbstractDefault: string;
	};
}

export interface IHomeControlEmbedTile {
	type: 'embed_tile';
	embedId: string;
	autoplay: 'true' | 'false'; // BAPI Home is only capable of handling strings right now :/
	embedPlatform: 'youtube' | 'video' | 'facebook';
	headline?: string;
	editorialAbstract?: string;
	url?: string;
	[x: string]: string | undefined;
}

export interface IHomeControlCustomTile {
	type: 'custom_tile';
	url: string;
	headline: string;
	editorialAbstract: string;
	teaser: string;

	target?: '_self' | '_blank';
	relationships?: string[];
	isVideo?: boolean;
}

export interface IHomeControlUnresolvedCisArticle {
	type: 'cis_article';
	id: string;
}

export type HomeControlTileBasic = IHomeControlArticle | IHomeControlCustomTile;
export type HomeControlTile = IHomeControlArticle | IHomeControlCustomTile | IHomeControlEmbedTile;

/**
 * Response from BAPI. Used by both Bundesmaster and Bundesliga.com to display the homepage.
 */
export interface IHomeControlResponse {
	breakingNews: IHomeControlBreakingNews;
	featuredArticles: HomeControlTile[];
	newsAndFeatures: HomeControlTile[];
	mostShared: HomeControlTileBasic[];
	mostWatched: HomeControlTileBasic[];
}

/**
 * Internal representation of the homepage.
 */
export interface IHomeControlConfig extends Omit<IHomeControlResponse, 'breakingNews'> {
	[key: string]: HomeControlTileBasic[] | HomeControlTile[];
}
export interface IHomeControlConfigBreakingNews {
	enabled: boolean;
	data?: IHomeControlBreakingNews;
}

/**
 * Expected request format of the backend.
 */
export interface IHomeControlPayload {
	breakingNews?: IHomeControlBreakingNews | null;
	featuredArticles?: IHomeControlUnresolvedCisArticle[] | IHomeControlCustomTile[] | IHomeControlEmbedTile[];
	newsAndFeatures?: IHomeControlUnresolvedCisArticle[] | IHomeControlCustomTile[] | IHomeControlEmbedTile[];
	mostShared?: IHomeControlUnresolvedCisArticle[] | IHomeControlCustomTile[];
	mostWatched?: IHomeControlUnresolvedCisArticle[] | IHomeControlCustomTile[];
}
