<div class="btn-group-container">
	<span class="icon">
		<mat-icon>textsms</mat-icon>
	</span>

	<div class="btn-group typing-status">
		<a [ngClass]="{active: typing?.homeIsTyping}" [matMenuTriggerFor]="menuHomeTypingIndicator" mat-flat-button>
			<span class="btn-label">HOME</span>
			<mat-icon>keyboard_arrow_left</mat-icon>
		</a>
		<a [ngClass]="{active: typing?.isTyping}" [matMenuTriggerFor]="menuNeutralTypingIndicator" mat-flat-button>
			<span class="btn-label">NEUTRAL</span>
			<mat-icon>keyboard_arrow_up</mat-icon>
		</a>
		<a [ngClass]="{active: typing?.awayIsTyping}" [matMenuTriggerFor]="menuAwayTypingIndicator" mat-flat-button>
			<span class="btn-label">AWAY</span>
			<mat-icon>keyboard_arrow_right</mat-icon>
		</a>
	</div>

	<mat-menu #menuHomeTypingIndicator="matMenu">
		<button (click)="setTyping('home', true)" mat-menu-item>
			<mat-icon>textsms</mat-icon>
			On
		</button>
		<button (click)="setTyping('home', false)" mat-menu-item>
			<mat-icon>clear</mat-icon>
			Off
		</button>
	</mat-menu>

	<mat-menu #menuAwayTypingIndicator="matMenu">
		<button (click)="setTyping('away', true)" mat-menu-item>
			<mat-icon>textsms</mat-icon>
			On
		</button>
		<button (click)="setTyping('away', false)" mat-menu-item>
			<mat-icon>clear</mat-icon>
			Off
		</button>
	</mat-menu>

	<mat-menu #menuNeutralTypingIndicator="matMenu">
		<button (click)="setTyping('none', true)" mat-menu-item>
			<mat-icon>textsms</mat-icon>
			On
		</button>
		<button (click)="setTyping('none', false)" mat-menu-item>
			<mat-icon>clear</mat-icon>
			Off
		</button>
	</mat-menu>
</div>
