<div class="bar-progress-chart">
	<div class="bars">
		<div class="relativeBar left" [ngClass]="{'surround-with-border': leftColor.toLowerCase() === '#ffffff'}">
			<div class="bar left" [ngStyle]="{'backgroundColor': leftColor, 'width': leftWidth + '%'}">
				<span *ngIf="leftWidth >= 20" class="value left" [ngStyle]="{'color': leftTextColor}">{{leftValue}}</span>
			</div>
			<span *ngIf="leftWidth < 20" class="value left">{{leftValue}}</span>
		</div>
		<div class="relativeBar right" [ngClass]="{'surround-with-border': rightColor.toLowerCase() === '#ffffff'}">
			<span *ngIf="rightWidth < 20" class="value right">{{rightValue}}</span>
			<div class="bar right" [ngStyle]="{'backgroundColor': rightColor, 'width': rightWidth + '%'}">
				<span *ngIf="rightWidth >= 20" class="value right" [ngStyle]="{'color': rightTextColor}">{{rightValue}}</span>
			</div>
		</div>
	</div>
	<div class="labels">
		<div class="label left">
			<span class="first">{{leftLabel | livetickerEventPlayername:'first'}}</span>
			<span class="last">{{leftLabel | livetickerEventPlayername:'last'}}</span>
		</div>
		<div class="label right">
			<span class="first">{{rightLabel | livetickerEventPlayername:'first'}}</span>
			<span class="last">{{rightLabel | livetickerEventPlayername:'last'}}</span>
		</div>
	</div>
</div>
