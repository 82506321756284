import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { CustomMatchOverviewPageComponent } from './custom-match-overview-page.component';

const routes: Routes = [
	{
		path: 'custom-matches',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		children: [
			{
				path: '',
				redirectTo: 'en',
				pathMatch: 'full'
			},
			{
				path: ':language',
				component: CustomMatchOverviewPageComponent
			}
		]
	}
];

@NgModule({
	declarations: [CustomMatchOverviewPageComponent],
	imports: [CommonsModule, RouterModule.forChild(routes), MatButtonModule, MatFormFieldModule, MatSelectModule, MatIconModule, MatTableModule, MatTooltipModule, PipesModule, MatPaginatorModule, MatSortModule, MatInputModule]
})
export class CustomMatchOverviewPageModule {}
