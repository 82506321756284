import { Component, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Competition, Language, LiveBlogAutoEvents } from '@nx-bundesliga/models';
import { combineLatest, Subscription, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';

@Component({
	selector: 'livetickerevent-matchfacts-info',
	templateUrl: './liveticker-event-matchfacts-info.component.html',
	styleUrls: ['./liveticker-event-matchfacts-info.component.scss']
})
export class LivetickerEventMatchfactsInfoComponent implements OnInit, OnChanges {
	@Input() matchFact: string;
	public isWebview = false;
	public matchFactsInfo = false;
	public overlayActive = false;
	public matchFactTitle = '';
	public matchFactDescription = '';
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() textColor = 'text_black';
	private language: string;
	public infoIcon = 'information';
	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if (this.overlayActive === true && event.code === 'Escape') {
			this.overlayActive = false;
		}
	}
	@AutoUnsubscribe() private subscription: Subscription;

	constructor(@Inject(WINDOW) private window: any, private config: ConfigService, private translate: TranslateService, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.subscription = combineLatest(this.lstore.pipe(select(getWorkingLanguage)))
			.pipe(
				tap(([langstate]: [Language]) => {
					this.language = langstate.code;
				})
			)
			.subscribe(null);

		if (this.language === 'ar') {
			this.infoIcon = 'information-ar';
		}

		this.isWebview = this.config.getSettings('webview', false);
		if (!this.isWebview) {
			this.matchFactsInfo = true;
		} else {
			this.matchFactsInfo = this.window.hasOwnProperty('dfl_app') && this.window.dfl_app.hasOwnProperty('showMatchFactsInfo');
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.translate
			.get('LIVETICKER.MATCHFACTS.' + this.matchFact + '.TITLE', {
				minute: this.liveEntry ? this.liveEntry.playtime.minute : ''
			})
			.pipe(
				take(1),
				map((translationResponse) => {
					if (translationResponse === 'LIVETICKER.MATCHFACTS.' + this.matchFact + '.TITLE' || translationResponse === '') {
						return this.matchFact;
					}
					return translationResponse;
				})
			)
			.subscribe((translation) => {
				this.matchFactTitle = translation;
			});
		this.translate
			.get('LIVETICKER.MATCHFACTS.' + this.matchFact + '.DESCRIPTION')
			.pipe(
				take(1),
				map((translationResponse) => {
					if (translationResponse === 'LIVETICKER.MATCHFACTS.' + this.matchFact + '.DESCRIPTION') {
						return '';
					}
					return translationResponse;
				})
			)
			.subscribe((translation) => {
				this.matchFactDescription = translation;
			});
	}

	public showMatchFactsInfo() {
		if (this.isWebview) {
			this.window.dfl_app.showMatchFactsInfo(this.matchFactTitle, this.matchFactDescription);
		} else {
			this.overlayActive = true;
		}
	}
}
