import { Injectable } from '@angular/core';
import { IFirebaseService } from '../firebase/IFirebase.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LivetickerService extends IFirebaseService {
	private _competitionId: string;
	private _seasonId: string;
	private _matchdayId: string;
	private _matchId: string;
	private _language: string;

	constructor() {
		super();
		// @todo/dfl replace hardcoded match with current-matchday
		this._seasonId = 'DFL-SEA-0001K2';
		this._competitionId = 'DFL-COM-000001';
		this._matchdayId = 'DFL-DAY-004BPL';
		this._matchId = 'DFL-MAT-0028O4';
		this._language = 'en';
	}

	public getMatchDetailInfos(lang?: string, matchId?: string, matchdayId?: string, seasonId?: string, competitionId?: string): Observable<any> {
		if (!lang) {
			lang = this._language;
		}
		if (!matchId) {
			matchId = this._matchId;
		}
		if (!competitionId) {
			competitionId = this._competitionId;
		}
		if (!seasonId) {
			seasonId = this._seasonId;
		}
		if (!matchdayId) {
			matchdayId = this._matchdayId;
		}

		if (lang === 'jp') {
			lang = 'en';
		}

		const listPathBase = [lang, competitionId, 'seasons', seasonId, 'matchdays', matchdayId, matchId];
		const listPath = listPathBase.join('/');

		return this._getDataFromFirebase(listPath);
	}

	// getter and setter should update the returned firebaseobservable in the future without the need to resubscribe to the stream
	get SeasonId(): string {
		return this._seasonId;
	}

	set SeasonId(value: string) {
		this._seasonId = value;
	}

	get MatchdayId(): string {
		return this._matchdayId;
	}

	set MatchdayId(value: string) {
		this._matchdayId = value;
	}

	get MatchId(): string {
		return this._matchId;
	}

	set MatchId(value: string) {
		this._matchId = value;
	}

	get CompetitionId(): string {
		return this._competitionId;
	}

	set CompetitionId(value: string) {
		this._competitionId = value;
	}

	get Language(): string {
		return this._language;
	}

	set Language(value: string) {
		this._language = value;
	}
}
