import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Club, ClubStateData } from '@nx-bundesliga/models';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BundesligaRestService } from '../common/bundesliga-rest.service';

@Injectable({
	providedIn: 'root'
})
export class ClubService extends BundesligaRestService {
	public cachedClubData: ClubStateData;
	constructor(private http: HttpClient) {
		super(http);
		this.settings = environment.endpoints.club;
	}

	/**
	 *
	 */
	public getAllClubsByCompetitionAndSeason(competition: string, sort: 'editorialorder' | 'alphabetical' = 'alphabetical', season = '2021-2022'): Observable<Club[]> {
		return this.getAllClubs().pipe(
			filter((data: ClubStateData) => data['bundesliga'].length > 0 && data['2bundesliga'].length > 0),
			map((clubStateData: ClubStateData) => clubStateData[competition])
		);
	}

	/**
	 *
	 */
	public getAllClubs(sort: 'editorialorder' | 'alphabetical' = 'alphabetical', season = '2021-2022'): Observable<ClubStateData> {
		const params = new HttpParams().set('sort', sort);
		// .set('season', season);
		// TODO: Uncomment line above as soon as API actually supports different seasons (without breaking)

		if (this.cachedClubData) {
			return of(this.cachedClubData);
		}

		return this.get<ClubStateData>('', false, { params: params }).pipe(
			tap((clubdata: ClubStateData) => {
				this.cachedClubData = clubdata;
			})
		);
	}

	/**
	 *
	 */
	public getClub(slugifiedFull: string): Observable<Club> {
		return this.getAllClubs().pipe(
			filter((data: ClubStateData) => data['bundesliga'].length > 0 && data['2bundesliga'].length > 0),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.name.slugifiedFull === slugifiedFull)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	/**
	 * id {string} the DFL Datalibrary ID
	 */
	public getClubById(id: string): Observable<Club> {
		return this.getAllClubs().pipe(
			filter((data: ClubStateData) => data['bundesliga'].length > 0 && data['2bundesliga'].length > 0),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.externalClubIds.dflDatalibraryClubId === id)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}

	/**
	 *
	 */
	public getClubByTLC(tlc: string): Observable<Club> {
		return this.getAllClubs().pipe(
			filter((data: ClubStateData) => data['bundesliga'].length > 0 && data['2bundesliga'].length > 0),
			map((clubStateData: ClubStateData) => [...clubStateData['bundesliga'], ...clubStateData['2bundesliga']]),
			// filter by threeLetterCode
			map((clubs: Club[]) => clubs.filter((club: Club) => club.threeLetterCode === tlc)),
			// hopefully only one correct item is left now which can be returned
			switchMap((clubs: Club[]) => {
				if (clubs.length > 0) {
					return of(clubs[0]);
				} else {
					return throwError('club not found');
				}
			})
		);
	}
}
