import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheckGuard } from '@ngneat/dirty-check-forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BundesmasterUiDialogConfirmComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-confirm';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DiscardChangesGuard extends DirtyCheckGuard {
	constructor(public dialog: MatDialog) {
		super();
	}

	confirmChanges(): Observable<boolean> {
		return this.dialog.open(BundesmasterUiDialogConfirmComponent, { data: { question: 'Are you sure you want to discard changes?' } }).afterClosed();
		// return confirm('Are you sure you want to discard changes?');
	}
}
