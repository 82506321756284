import { CommonModule } from '@angular/common';
import { Component, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Directive({
	selector: '[bundesmasterDropzone]',
	standalone: true
})
export class BundesmasterDropzoneDirective {
	@HostBinding('class.active') active = false;
	@Output() dropped = new EventEmitter<FileList>();

	@HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.active = true;
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.active = false;
	}

	@HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.active = false;
		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			this.dropped.emit(event.dataTransfer.files);
		}
	}
}

@Component({
	selector: 'bundesmaster-ui-upload-zone',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, BundesmasterDropzoneDirective],
	templateUrl: './bundesmaster-ui-upload-zone.component.html',
	styleUrls: ['./bundesmaster-ui-upload-zone.component.scss']
})
export class BundesmasterUiUploadZoneComponent {
	@Input() multiple = true;
	@Input() small = false;
	@Input() fullHeight = false;
	@Input() disabled = false;
	@Input() accept = '*';
	@Input() fileTypeHint = ['jp(e)g', 'png', 'gif', 'webp'];
	@Input() buttonText = 'Select Media';
	@Input() dropZoneText = "Drop it like it's hot 🍾";
	@ViewChild('fileDropRef', { static: true }) overlay?: ElementRef<any>;
	@Output() fileAdded: EventEmitter<File> = new EventEmitter<File>();
	@Output() filesAdded: EventEmitter<FileList> = new EventEmitter<FileList>();

	clickHandler() {
		this.overlay?.nativeElement.click();
	}

	fileBrowseHandler(event: any) {
		this.filesAdded.emit(event.target.files);
		for (const file of event.target.files) {
			this.fileAdded.emit(file);
		}

		if (this.overlay) {
			this.overlay.nativeElement.value = null;
		}
	}

	fileDragHandler(files: FileList) {
		this.filesAdded.emit(files);

		for (let i = 0; i < files.length; i++) {
			this.fileAdded.emit(files[i]);
		}
	}
}
