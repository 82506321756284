import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveBlogInfos, LiveBlogMatch, LivetickerSides, Match } from '@nx-bundesliga/models';
import { BlMatchHighlightDialogComponent } from '../bl-match-highlight-dialog/match-highlight-dialog.component';
import { BlTranslationConfigDialogComponent } from '../bl-translation-config-dialog/translation-config-dialog.component';

@Component({
	selector: 'bl-ticker-matchsettings-controls',
	templateUrl: './bl-ticker-matchsettings-controls.component.html',
	styleUrls: ['./bl-ticker-matchsettings-controls.component.scss']
})
export class BlTickerMatchsettingsControlsComponent {
	@Input() match: LiveBlogMatch | Match;
	@Input() language: string;
	constructor(public dialog: MatDialog) {}

	openMatchHighlightDialog() {
		const dialogRef = this.dialog.open(BlMatchHighlightDialogComponent, {
			data: {
				match: this.match,
				language: this.language
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	openTranslationConfigDialog() {
		const dialogRef = this.dialog.open(BlTranslationConfigDialogComponent, {
			data: {
				matchId: this.match.dflDatalibraryMatchId
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
}
