import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BlRapidTickerComposerComponent } from './bl-rapid-ticker-composer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { BlRapidTickerEventsModule } from '../event-inputs/bl-rapid-ticker-events.module';

@NgModule({
	declarations: [BlRapidTickerComposerComponent],
	exports: [BlRapidTickerComposerComponent],
	imports: [
		CommonsModule,
		BlRapidTickerEventsModule,

		// Material
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		MatCheckboxModule,
		MatTabsModule,
		NgxTippyModule
	]
})
export class BlRapidTickerComposerModule {}
