import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-playerranking-card',
	templateUrl: './stats-playerranking-card.component.html',
	styleUrls: ['./stats-playerranking-card.component.scss']
})
export class StatsPlayerrankingCardComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() homeColor: string;
	@Input() awayColor: string;
	@Input() homeTextColor: string;
	@Input() awayTextColor: string;
	@Input() clubData: LiveBlogClubs;
	@Input() ranking: {
		rank: number;
		value: number;
		unit?: string;
		person: {
			dflDatalibraryClubId: string;
			name: string;
			imageUrl: string;
		};
		side: LivetickerSides;
	}[];
	@Input() isLiveticker = false;
	@Input() enablePlayerLinking = false;
	public language: string;
	public competition: string;
	private languageSubscription: Subscription;
	private competitionSubscription: Subscription;

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.languageSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate: Language) => {
			this.language = langstate.code;
		});
		this.competitionSubscription = this.cstore.pipe(select(getWorkingCompetition)).subscribe((compState) => {
			this.competition = compState.name;
		});
	}

	ngOnDestroy(): void {
		if (this.languageSubscription) {
			this.languageSubscription.unsubscribe();
		}
		if (this.competitionSubscription) {
			this.competitionSubscription.unsubscribe();
		}
	}
}
