<div class="container">
	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width">
				<mat-label>Headline</mat-label>
				<input matInput *ngIf="tributeOptions" [(ngModel)]="liveEntry.detail.headline" [ngxTribute]="tributeOptions" (ngModelChange)="update()" placeholder="Headline" tabindex="1" />
				<input matInput *ngIf="!tributeOptions" [(ngModel)]="liveEntry.detail.headline" (ngModelChange)="update()" placeholder="Headline" tabindex="1" />
			</mat-form-field>
		</div>
		<div class="col-12">
			<mat-form-field class="full-width">
				<mat-label>Body</mat-label>
				<textarea matInput *ngIf="tributeOptions" [(ngModel)]="liveEntry.detail.text" [ngxTribute]="tributeOptions" (ngModelChange)="update()" placeholder="Body" rows="3" tabindex="2"></textarea>
				<textarea matInput *ngIf="!tributeOptions" [(ngModel)]="liveEntry.detail.text" (ngModelChange)="update()" placeholder="Body" rows="3" tabindex="2"></textarea>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-12 d-flex justify-content-start align-items-center">
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.conference" [ngModelOptions]="{standalone: true}" *ngIf="!customMatch">Conference</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.enableHtml" [ngModelOptions]="{standalone: true}">Enable HTML</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.hidden" [ngModelOptions]="{standalone: true}">Hide Post</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.disableCrosspost" [ngModelOptions]="{standalone: true}" *ngIf="!customMatch">Do not Translate/Crosspost</mat-checkbox>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width">
				<mat-label>Url</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.url" (ngModelChange)="update()" placeholder="Url" type="url" tabindex="3" />
				<button matSuffix mat-flat-button color="primary" (click)="cropImage()" *ngIf="liveEntry.detail.url && liveEntry.detail.url !== 'https://'">
					<mat-icon>crop</mat-icon>
					Crop Image
				</button>
				<button matSuffix mat-flat-button color="primary" (click)="selectImage()">
					<mat-icon>get_app</mat-icon>
					Select Image
				</button>
			</mat-form-field>
		</div>
		<div class="col-4 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Copyright</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.copyright" (ngModelChange)="update()" placeholder="Copyright" type="text" tabindex="4" />
			</mat-form-field>
		</div>
	</div>

	<div class="row">
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Playtime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.minute" (ngModelChange)="updatePlaytime(); update();" placeholder="Playtime" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Injurytime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.injuryTime" (ngModelChange)="updatePlaytime(); update();" placeholder="Injurytime" type="number" tabindex="4" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>MatchStatus</mat-label>
				<mat-select [(ngModel)]="liveEntry.matchSection" (ngModelChange)="update()" tabindex="5">
					<mat-option value="PRE_MATCH">PRE_MATCH</mat-option>
					<mat-option value="FIRST_HALF">FIRST_HALF</mat-option>
					<mat-option value="HALF">HALF</mat-option>
					<mat-option value="SECOND_HALF">SECOND_HALF</mat-option>
					<ng-container *ngIf="customMatch === true || (competitionId !== 'DFL-COM-000001' && competitionId !== 'DFL-COM-000002')">
						<mat-option value="PRE_EXTRA">PRE_EXTRA</mat-option>
						<mat-option value="FIRST_HALF_EXTRA">FIRST_HALF_EXTRA</mat-option>
						<mat-option value="HALF_EXTRA">HALF_EXTRA</mat-option>
						<mat-option value="SECOND_HALF_EXTRA">SECOND_HALF_EXTRA</mat-option>
						<mat-option value="PRE_PENALTY">PRE_PENALTY</mat-option>
						<mat-option value="PENALTY">PENALTY</mat-option>
					</ng-container>
					<mat-option value="FINAL_WHISTLE">FINAL_WHISTLE</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>entryDate</mat-label>
				<input matInput [(ngModel)]="liveEntry.entryDate" (ngModelChange)="update()" type="input" placeholder="entryDate" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6 d-flex">
			<mat-form-field>
				<mat-label>Order</mat-label>
				<mat-select [(ngModel)]="orderType" (ngModelChange)="updateOrderType()">
					<mat-option value="auto">(auto) top of given minute</mat-option>
					<mat-option value="keep" *ngIf="editMode === true" [disabled]="liveEntry.playtime.minute !== minuteOfPlay.minute || liveEntry.playtime.injuryTime !== minuteOfPlay.injuryTime">(keep) maintain order on edited posts</mat-option>
					<mat-option value="parent">(parent) place above given postId</mat-option>
					<mat-option value="child">(child) place below given postId</mat-option>
					<mat-option value="number">(number) assign given order directly</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<span matPrefix *ngIf="orderType ==='parent'">parent&nbsp;</span>
				<span matPrefix *ngIf="orderType ==='child'">child&nbsp;</span>
				<input matInput [disabled]="orderType === 'auto' || orderType === 'keep'" [(ngModel)]="orderValue" (ngModelChange)="updateOrderValue()" type="input" placeholder="order" />
			</mat-form-field>
		</div>
	</div>
</div>

<div class="preview">
	<div class="liveticker-entryContainer">
		<livetickerevent-image class="event side_{{side}}" [liveEntry]="previewEntry" [clubData]="clubData"></livetickerevent-image>
	</div>
</div>
