<ng-container [ngSwitch]="getEventName(liveEntry)">
	<livetickerevent-basic *ngSwitchCase="'basic'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-basic>

	<livetickerevent-lineup *ngSwitchCase="'lineup'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-lineup>

	<livetickerevent-var *ngSwitchCase="'var'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-var>

	<livetickerevent-image *ngSwitchCase="'image'" class="event" [liveEntry]="liveEntry"></livetickerevent-image>

	<livetickerevent-video *ngSwitchCase="'video'" class="event" [liveEntry]="liveEntry"></livetickerevent-video>

	<livetickerevent-embed *ngSwitchCase="'embed'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-embed>

	<livetickerevent-goal *ngSwitchCase="'goal'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-goal>

	<livetickerevent-penalty *ngSwitchCase="'penalty'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-penalty>

	<livetickerevent-substitution *ngSwitchCase="'sub'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-substitution>

	<livetickerevent-card *ngSwitchCase="'card'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-card>

	<livetickerevent-playerRecord *ngSwitchCase="'playerRecord'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-playerRecord>

	<livetickerevent-stats *ngSwitchCase="'stats'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-stats>

	<livetickerevent-progress *ngSwitchCase="'progress'" class="event" [liveEntry]="liveEntry" [clubData]="teams"></livetickerevent-progress>
</ng-container>
