import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { BundesmasterSeasonApiService } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'resolveSeasonId'
})
export class ResolveSeasonIdPipe implements PipeTransform {
	constructor(private readonly seasonService: BundesmasterSeasonApiService) {}

	transform(seasonId: string): Observable<string> {
		return this.seasonService.getSeasonById(seasonId).pipe(
			map((season) => (season ? season.season : seasonId)),
			startWith(seasonId)
		);
	}
}
