<div class="pie-chart">
	<span class="title">{{title}}</span>
	<div class="pie">
		<svg class="chart" viewBox="0 0 110 110">
			<!-- LEFT FAKE BORDER CIRCLE -->
			<circle class="left-circle-fakeborder" *ngIf="leftColor.toLowerCase() === '#ffffff'" cx="55" cy="55" r="50" stroke-width="9" stroke="#7f7f7f" [attr.stroke-dasharray]="leftFakeBorderCircleStrokeDasharray" [attr.stroke-dashoffset]="leftFakeBorderCircleStrokeDashoffset" />
			<!-- LEFT CIRCLE -->
			<circle class="left-circle" cx="55" cy="55" r="50" stroke-width="8" [attr.stroke]="leftColor" [attr.stroke-dasharray]="leftCircleStrokeDasharray" [attr.stroke-dashoffset]="leftCircleStrokeDashoffset" />
			<!-- RIGHT FAKE BORDER CIRCLE -->
			<circle class="right-circle-fakeborder" *ngIf="rightColor.toLowerCase() === '#ffffff'" cx="55" cy="55" r="50" stroke-width="9" stroke="#7f7f7f" [attr.stroke-dasharray]="rightFakeBorderCircleStrokeDasharray" [attr.stroke-dashoffset]="rightFakeBorderCircleStrokeDashoffset" />
			<!-- RIGHT CIRCLE -->
			<circle class="right-circle" cx="55" cy="55" r="50" stroke-width="8" [attr.stroke]="rightColor" [attr.stroke-dasharray]="rightCircleStrokeDasharray" [attr.stroke-dashoffset]="rightCircleStrokeDashoffset" />
			<line stroke="var(--type-body-soft)" stroke-width="0.5" x1="54.5" x2="54.5" y1="42" y2="62" />
		</svg>
		<div class="values">
			<span class="left">{{leftValue}}</span>
			<span class="right">{{rightValue}}</span>
		</div>
	</div>
</div>
