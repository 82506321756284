export interface BundesmasterClubHexColors {
	readonly primary: string;
	readonly primaryText: string;
}

export interface BundesmasterClub {
	readonly clubId: string;
	readonly displayName: string;
	readonly shortName: string;
	readonly threeLetterCode: string;
	readonly hexColors: BundesmasterClubHexColors;
	readonly logoUrl?: string;
	readonly allNames: readonly string[];
	readonly competitions: readonly string[];
	readonly seasons: {
		readonly [seasonId: string]: readonly string[];
	};
}
