import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { LivetickereventScoreComponent } from './livetickerevent-score.component';

@NgModule({
	declarations: [LivetickereventScoreComponent],
	exports: [LivetickereventScoreComponent],
	imports: [SharedModule]
})
export class LivetickerEventScoreModule {}
