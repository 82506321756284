import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	currentMatchdayNumberBL1: string;
	currentMatchdayNumberBL2: string;
	constructor(private config: ConfigService) {}

	ngOnInit() {
		this.currentMatchdayNumberBL1 = this.config.getSettings('DFL-COM-000001.matchday.matchdayNumber', '0');
		this.currentMatchdayNumberBL2 = this.config.getSettings('DFL-COM-000002.matchday.matchdayNumber', '0');
	}
}
