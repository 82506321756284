<div class="container">
	<div class="row">
		<div class="col-12" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<ng-container *ngIf="players.length > 0">
						<mat-form-field class="w-100">
							<mat-label>Player</mat-label>
							<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
								<mat-option>
									<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
								</mat-option>

								<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
									<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
									{{player.name.full}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<button *ngFor="let iterator of [].constructor(11); let i = index" (click)="setLineupPlayer(i)" class="button-player-setter" mat-raised-button>
							<mat-icon>play_for_work</mat-icon>
							Use selected Player for {{i + 1}}
						</button>
						<hr />
					</ng-container>

					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<mat-accordion>
									<mat-expansion-panel *ngFor="let lineupPerson of liveEntry?.detail?.lineup; let i = index;" (opened)="i">
										<mat-expansion-panel-header>
											<mat-panel-title> Lineup person {{i + 1}} </mat-panel-title>
										</mat-expansion-panel-header>

										<div class="container-fluid">
											<div class="row">
												<div class="col-12">
													<mat-form-field class="full-width">
														<mat-label>dflDatalibraryObjectId</mat-label>
														<input matInput [(ngModel)]="lineupPerson.dflDatalibraryObjectId" (ngModelChange)="update()" type="text" placeholder="dflDatalibraryObjectId" tabindex="1" />
													</mat-form-field>
												</div>
												<div class="col-12">
													<mat-form-field class="full-width">
														<mat-label>Player name</mat-label>
														<input matInput [(ngModel)]="lineupPerson.name" (ngModelChange)="update()" type="text" placeholder="Player name" tabindex="1" />
													</mat-form-field>
												</div>
												<div class="col-12">
													<mat-form-field class="full-width">
														<mat-label>imageUrl</mat-label>
														<input matInput [(ngModel)]="lineupPerson.imageUrl" (ngModelChange)="update()" type="text" placeholder="imageUrl" tabindex="1" />
													</mat-form-field>
												</div>
												<div class="col-12">
													<mat-form-field class="full-width">
														<mat-label>shirtNumber</mat-label>
														<input matInput [(ngModel)]="lineupPerson.shirtNumber" (ngModelChange)="update()" type="number" placeholder="shirtNumber" tabindex="1" />
													</mat-form-field>
												</div>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="row">
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Playtime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.minute" (ngModelChange)="updatePlaytime(); update();" placeholder="Playtime" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Injurytime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.injuryTime" (ngModelChange)="updatePlaytime(); update();" placeholder="Injurytime" type="number" tabindex="4" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>MatchStatus</mat-label>
				<mat-select [(ngModel)]="liveEntry.matchSection" (ngModelChange)="update()" tabindex="5">
					<mat-option value="PRE_MATCH">PRE_MATCH</mat-option>
					<mat-option value="FIRST_HALF">FIRST_HALF</mat-option>
					<mat-option value="HALF">HALF</mat-option>
					<mat-option value="SECOND_HALF">SECOND_HALF</mat-option>
					<ng-container *ngIf="customMatch === true || (competitionId !== 'DFL-COM-000001' && competitionId !== 'DFL-COM-000002')">
						<mat-option value="PRE_EXTRA">PRE_EXTRA</mat-option>
						<mat-option value="FIRST_HALF_EXTRA">FIRST_HALF_EXTRA</mat-option>
						<mat-option value="HALF_EXTRA">HALF_EXTRA</mat-option>
						<mat-option value="SECOND_HALF_EXTRA">SECOND_HALF_EXTRA</mat-option>
						<mat-option value="PRE_PENALTY">PRE_PENALTY</mat-option>
						<mat-option value="PENALTY">PENALTY</mat-option>
					</ng-container>
					<mat-option value="FINAL_WHISTLE">FINAL_WHISTLE</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>entryDate</mat-label>
				<input matInput [(ngModel)]="liveEntry.entryDate" (ngModelChange)="update()" type="input" placeholder="entryDate" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6 d-flex">
			<mat-form-field>
				<mat-label>Order</mat-label>
				<mat-select [(ngModel)]="orderType" (ngModelChange)="updateOrderType()">
					<mat-option value="auto">(auto) top of given minute</mat-option>
					<mat-option value="keep" *ngIf="editMode === true" [disabled]="liveEntry.playtime.minute !== minuteOfPlay.minute || liveEntry.playtime.injuryTime !== minuteOfPlay.injuryTime">(keep) maintain order on edited posts</mat-option>
					<mat-option value="parent">(parent) place above given postId</mat-option>
					<mat-option value="child">(child) place below given postId</mat-option>
					<mat-option value="number">(number) assign given order directly</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<span matPrefix *ngIf="orderType ==='parent'">parent&nbsp;</span>
				<span matPrefix *ngIf="orderType ==='child'">child&nbsp;</span>
				<input matInput [disabled]="orderType === 'auto' || orderType === 'keep'" [(ngModel)]="orderValue" (ngModelChange)="updateOrderValue()" type="input" placeholder="order" />
			</mat-form-field>
		</div>
	</div>
</div>

<div class="preview">
	<div class="liveticker-entryContainer">
		<!--<livetickerevent-card class="event side_{{side}}" [liveEntry]="previewEntry" [clubData]="clubData"></livetickerevent-card>-->
	</div>
</div>
