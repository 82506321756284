<div *ngIf="liveEntry && liveEntry.detail" [ngClass]="textColorClass" [style.backgroundColor]="backgroundColor" class="event playerRecordEvent side_{{side}}">
	<div class="head">
		<div class="headline-row">
			<div>
				<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
				<span>{{'LIVETICKER.EVENTS.speedAlert' | translate}}</span>
			</div>
			<livetickerevent-matchfacts-info *ngIf="liveEntry.detail?.matchFact === true" [liveEntry]="liveEntry" [matchFact]="liveEntry.detail?.title + '.RECORD'" [textColor]="textColorClass"></livetickerevent-matchfacts-info>
		</div>
		<div class="headline-row">
			<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [textColor]="textColorClass" [headline]="matchFactTitle"></livetickerevent-headline>
		</div>
	</div>

	<div class="body">
		<div class="record-container">
			<span class="record" *ngIf="liveEntry.detail.value">
				<span class="value">{{liveEntry.detail.value}}</span>
				<span class="unit" *ngIf="liveEntry.detail.unit">{{liveEntry.detail.unit}}</span>
			</span>
		</div>

		<h3 class="player">
			<a [ngClass]="textColorClass" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', liveEntry.detail.person.name | slugify] | translateRoute) : null">
				<span class="first">{{liveEntry.detail.person.name | livetickerEventPlayername:'first'}}</span>
				<span class="last">{{liveEntry.detail.person.name | livetickerEventPlayername:'last'}}</span>
			</a>
		</h3>
	</div>

	<a [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', liveEntry.detail.person.name | slugify] | translateRoute) : null">
		<img
			class="player-image"
			[dflLazyLoad]="liveEntry.detail.person.imageUrl | playerImage: 'PORTRAIT_S' | imageService:0:180"
			[alt]="liveEntry.detail.person.name"
			defaultImage="/assets/placeholder/resp_live_placeholder_player.png"
			errorImage="/assets/placeholder/resp_live_placeholder_player.png"
			src="/assets/placeholder/resp_live_placeholder_player.png"
			offset="200" />
	</a>
</div>

<div class="poweredByAWS" *ngIf="liveEntry?.detail?.matchFact === true">
	<livetickerevent-matchfacts-banner></livetickerevent-matchfacts-banner>
</div>
