import { Component, Input } from '@angular/core';
import { LiveBlogPerson } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-keeperefficiency-card',
	templateUrl: './stats-keeperefficiency-card.component.html',
	styleUrls: ['./stats-keeperefficiency-card.component.scss']
})
export class StatsKeeperefficiencyCardComponent {
	@Input() person: LiveBlogPerson;
	@Input() savedShots: number;
	@Input() xSaves: number;
	@Input() title = '';

	constructor() {}
}
