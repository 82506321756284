import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { StatsChartsModule, StatsClubprogressrankingCardModule, StatsPlayercomparisonCardModule, StatsPlayerprogressrankingCardModule } from '@nx-bundesliga/bundesliga-com/components/stats';
import { LivetickerEventMatchfactsBannerModule } from '../../../liveticker/components/parts/livetickerevent-matchfacts-banner/liveticker-event-matchfacts-banner.module';
import { StatsClubmetricprogressionListModule } from '../../../stats-clubmetricprogression-list/stats-clubmetricprogression-list.module';
import { MatchcenterStatsCollectionComponent } from './matchcenter-stats-collection.component';

@NgModule({
	imports: [CommonsModule, StatsChartsModule, StatsPlayercomparisonCardModule, StatsPlayerprogressrankingCardModule, StatsClubprogressrankingCardModule, StatsClubmetricprogressionListModule, LivetickerEventMatchfactsBannerModule, MatIconModule],
	exports: [MatchcenterStatsCollectionComponent],
	declarations: [MatchcenterStatsCollectionComponent],
	providers: []
})
export class MatchcenterStatsCollectionModule {}
