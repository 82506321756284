import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { BundesmasterSeason, BundesmasterSeasonApiService } from '@nx-bundesliga/bundesmaster/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-season-selector',
	standalone: true,
	imports: [CommonModule, CommonsLibModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, StoreModule],
	templateUrl: './bundesmaster-ui-season-selector.component.html',
	styleUrls: ['./bundesmaster-ui-season-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => BundesmasterUiSeasonSelectorComponent)
		}
	]
})
export class BundesmasterUiSeasonSelectorComponent implements OnDestroy, OnChanges, ControlValueAccessor {
	private readonly destroying = new Subject<void>();
	public readonly touched = new Subject<void>();

	public readonly formControl = new FormControl<string>('');

	@Input()
	public providedValue: keyof BundesmasterSeason = 'seasonId';

	public seasons: readonly BundesmasterSeason[] = [];

	private isLoading = false;
	private isDisabled = false;

	constructor(service: BundesmasterSeasonApiService) {
		service
			.getSeasonsCollection()
			.pipe(takeUntilDestroyed())
			.subscribe(({ items, status }) => {
				this.seasons = items;
				this.setLoadingState(status === 'loading');
			});
	}

	writeValue(value: string): void {
		this.formControl.setValue(value, { emitEvent: false });
	}

	registerOnChange(handler: (value: string) => void): void {
		this.formControl.valueChanges.pipe(takeUntil(this.destroying)).subscribe(handler);
	}

	registerOnTouched(handler: () => void): void {
		this.touched.subscribe(handler);
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.updateDisabled();
	}

	private updateDisabled(emitEvent = true): void {
		if (this.isLoading || this.isDisabled) {
			this.formControl.disable({ emitEvent });
		} else {
			this.formControl.enable({ emitEvent });
		}
	}

	private setLoadingState(isLoading?: boolean): void {
		this.isLoading = isLoading ?? this.isLoading;
		this.updateDisabled(false);
	}

	ngOnChanges(): void {
		this.setLoadingState();
	}

	ngOnDestroy(): void {
		this.destroying.next();
		this.destroying.complete();
	}
}
