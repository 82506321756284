import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DflJwPlayerModule } from '@nx-bundesliga/bundesliga-com/components/dfl-jw-player';
import { BlMatchHighlightDialogComponent } from './match-highlight-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlMatchHighlightDialogComponent],
	exports: [BlMatchHighlightDialogComponent],
	imports: [CommonsModule, MatIconModule, MatInputModule, MatButtonModule, MatDialogModule, DflJwPlayerModule, FormsModule, MatProgressSpinnerModule, MatFormFieldModule]
})
export class BlMatchHighlightDialogModule {}
