import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveBlogClubs, LiveBlogInfos, LiveBlogMatch, Playtime } from '@nx-bundesliga/models';
import * as _moment from 'moment';
import { environment } from '../../../../environments/environment';
import { BlLivetickereventDialogsCreateComponent } from '../bl-livetickerevent-dialogs/bl-livetickerevent-dialogs.component';

const moment = _moment;

@Component({
	selector: 'bl-twitter-feed',
	templateUrl: './twitter-feed.component.html',
	styleUrls: ['./twitter-feed.component.scss']
})
export class TwitterFeedComponent implements OnInit {
	@Input() language: string;
	@Input() liveBlogInfos: LiveBlogInfos;
	@Input() minuteOfPlay: Playtime;
	@Input() match: LiveBlogMatch;
	@Input() clubData: LiveBlogClubs;
	public query = 'bundesliga';
	public count = 100;
	public result_type = 'mixed';
	public tweets: any;
	public tweetsResult: any;

	constructor(public http: HttpClient, public dialog: MatDialog) {}

	ngOnInit(): void {
		if (this.match) {
			this.query = '#' + this.match.teams.home.threeLetterCode + this.match.teams.away.threeLetterCode;
		}
		if (this.match && this.match.hasOwnProperty('minuteOfPlay')) {
			this.minuteOfPlay = this.match.minuteOfPlay;
		}
	}

	getTweets() {
		this.http
			.post(`https://api.bundesstreaker.${environment.stageDomain}.com/feed/twitter/${this.language}/${encodeURIComponent(this.query)}`, {
				count: this.count,
				result_type: this.result_type
			})
			.subscribe(
				(res: any) => {
					this.tweets = res;
					this.tweetsResult = {
						count: this.tweets.length,
						query: this.query,
						error: null
					};
				},
				(err: any) => {
					this.tweets = [];
					this.tweetsResult = {
						count: 0,
						query: this.query,
						error: err.error.message
					};
				}
			);
	}

	public openCreateDialog(twitterId: string) {
		const localLiveEntry = {
			entryType: 'embed',
			detail: {
				headline: '',
				text: '',
				embedId: twitterId,
				embedPlatform: 'twitter'
			},
			eventId: '',
			entryDate: '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.match.matchStatus || 'PRE_MATCH',
			side: 'none',
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			}
		};
		const dialogRef = this.dialog.open(BlLivetickereventDialogsCreateComponent, {
			data: {
				match: this.match,
				liveEntry: localLiveEntry,
				language: this.language,
				clubData: this.clubData
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}

	public formatTime(time) {
		return moment(time).format('YYYY-MM-DDTHH:mm:ssZZ');
	}
}
