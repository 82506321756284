import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { BundesmasterSeason, BundesmasterSeasonApiService, TaggingDialogInput, TaggingDialogResult } from '@nx-bundesliga/bundesmaster/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-seasons',
	standalone: true,
	imports: [CommonModule, MatButtonModule, FormsModule, MatListModule, MatDialogModule],
	templateUrl: './bundesmaster-ui-dialog-select-seasons.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-seasons.component.scss']
})
export class BundesmasterUiDialogSelectSeasonsComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();

	public readonly seasons: Observable<readonly BundesmasterSeason[]>;
	public selected = new Set<string>();

	constructor(service: BundesmasterSeasonApiService, public readonly dialogRef: MatDialogRef<BundesmasterUiDialogSelectSeasonsComponent, TaggingDialogResult>, @Inject(MAT_DIALOG_DATA) public readonly data: TaggingDialogInput) {
		this.seasons = service.getSeasons().pipe(takeUntil(this.destroying$));
	}

	selectionChange(event: MatSelectionListChange): void {
		event.options.forEach((option) => (option.selected ? this.selected.add(option.value) : this.selected.delete(option.value)));
	}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit() {
		this.selected = new Set(this.data?.selected ?? []);
	}

	add() {
		this.dialogRef.close([...this.selected]);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
