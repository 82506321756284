import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Club, ClubPlayer, EditorialVideo, JWManifest, JWVideo } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';

@Injectable({
	providedIn: 'root'
})
export class JwContentDeliveryService {
	private endpointPlaylists = 'https://cdn.jwplayer.com/v2/playlists';

	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	public getPlaylist(playlistId: string, offset: number, limit: number, params?: any): Observable<JWManifest> {
		return this.http.get<JWManifest>(`${this.getPlaylistUrl(playlistId, params)}&page_limit=${limit}&page_offset=${offset}`);
	}

	private getPlaylistUrl(id, params?: any): string {
		let playlistUrl = `${this.endpointPlaylists}/${id}?`;
		if (params && Object.keys(params).length > 0) {
			playlistUrl += `&${Object.entries(params)
				.map(([key, val]) => `${key}=${val}`)
				.join('&')}`;
		}
		return playlistUrl;
	}

	public getGoals(DflDatalibraryCompetitionId: string, DflDatalibrarySeasonId: string, matchdayNumber: number | string, language = 'en', offset = 1, limit = 10): Observable<JWManifest> {
		const playlistId = language === 'de' ? this.environment?.jw?.playlist?.goals?.dach || '' : this.environment?.jw?.playlist?.goals?.nondach || '';
		const params = {
			format: 'json',
			poster_width: '720',
			media_filtering: `competitionId:${DflDatalibraryCompetitionId}${DflDatalibrarySeasonId ? ',seasonId:' + DflDatalibrarySeasonId : ''}${matchdayNumber ? ',matchDayName:' + matchdayNumber.toString() : ''}`,
			tags: ['Goal'],
			tagsMode: 'any'
		};
		return this.getPlaylist(playlistId, offset, limit, params);
	}

	public getPlayerGoals(DflDatalibraryCompetitionId: string, DflDatalibrarySeasonId: string, matchdayNumber: number | string, language = 'en', playerId: string | string[], offset = 1, limit = 10): Observable<JWManifest> {
		const playlistId = language === 'de' ? this.environment?.jw?.playlist?.goals?.dach || '' : this.environment?.jw?.playlist?.goals?.nondach || '';
		const params = Array.isArray(playerId)
			? {
					format: 'json',
					poster_width: '720',
					media_filtering: playerId.map((id) => `playerIds:${id}`),
					media_filtering_mode: 'any',
					tags: ['Goal'],
					tagsMode: 'any'
			  }
			: {
					format: 'json',
					poster_width: '720',
					media_filtering: `scorePlayerId:${playerId}${DflDatalibrarySeasonId ? ',seasonId:' + DflDatalibrarySeasonId : ''}${matchdayNumber ? ',matchDayName:' + matchdayNumber.toString() : ''}`,
					media_filtering_mode: 'all',
					tags: ['Goal'],
					tagsMode: 'any'
			  };
		return this.getPlaylist(playlistId, offset, limit, params);
	}

	public getClubGoals(DflDatalibraryCompetitionId: string, DflDatalibrarySeasonId: string, matchdayNumber: number | string, language = 'en', clubId: string | string[], offset = 1, limit = 10): Observable<JWManifest> {
		const playlistId = language === 'de' ? this.environment?.jw?.playlist?.goals?.dach || '' : this.environment?.jw?.playlist?.goals?.nondach || '';
		const params = {
			format: 'json',
			poster_width: '720',
			media_filtering: Array.isArray(clubId) ? clubId.map((id) => `scoreClubId:${id}`) : `scoreClubId:${clubId}` + `${DflDatalibrarySeasonId ? ',seasonId:' + DflDatalibrarySeasonId : ''}${matchdayNumber ? ',matchDayName:' + matchdayNumber.toString() : ''}`,
			media_filtering_mode: 'all',
			tags: ['Goal'],
			tagsMode: 'any'
		};
		return this.getPlaylist(playlistId, offset, limit, params);
	}

	public getPlayerVideos(player: ClubPlayer, club: Club, language = 'en', offset = 1, limit = 10): Observable<JWManifest> {
		const playlistId = this.environment?.jw?.playlist?.player || '';
		const params = {
			format: 'json',
			poster_width: '720',
			tags: [player.externalPersonIds.dflDatalibraryPersonId],
			tagsMode: 'all',
			media_filtering: `language:${language === 'de' ? language : 'en'}`,
			media_filtering_mode: 'all'
		};
		return this.getPlaylist(playlistId, offset, limit, params);
	}

	public getClubVideos(club: Club, language = 'en', offset = 1, limit = 10): Observable<JWManifest> {
		const playlistId = this.environment?.jw?.playlist?.player || '';
		const params = {
			format: 'json',
			poster_width: '720',
			tags: [club.externalClubIds.dflDatalibraryClubId],
			tagsMode: 'all',
			media_filtering: `language:${language === 'de' ? language : 'en'}`,
			media_filtering_mode: 'all'
		};
		return this.getPlaylist(playlistId, offset, limit, params);
	}

	public getVideoHubPlaylists(language = 'en'): Observable<JWManifest>[] {
		const allPlaylists = {
			'de': [
				'B6rwKoS4', // top videos
				'm1TEnJiw',
				'f3Wmb381',
				'1hHLocXR',
				'ESHzIgvB',
				'wPdXMIT3' // bottom videos
			],
			'en': [
				'3Le9PAsC', // top videos
				'T8IKc8TX',
				'ihHvDRzo',
				'UgD6JZbr',
				// 'w7aT4MQj',
				'UvISRQeH' // bottom videos
			]
		};

		const playlists = allPlaylists[language];
		return playlists.map((playlistsId) => {
			return this.getPlaylist(playlistsId, 1, 10, {
				format: 'json',
				poster_width: '720'
			});
		});
	}

	/**
	 * Queries the JW API for all metadata for a given mediaID.
	 *
	 * @param {string} id
	 * @returns {Promise<JWManifest>}
	 */
	public getVideoById(id: string): Observable<JWManifest> {
		const url = `https://cdn.jwplayer.com/v2/media/${id}?format=json`;
		return this.http.get<JWManifest>(url).pipe(share(), take(1));
	}

	/**
	 * transforms a JW video to a DFL editorial video
	 *
	 * @returns {EditorialVideo}
	 * @param jwVideo
	 */
	public mapJWVideoToEditorialVideo(jwVideo: JWVideo): EditorialVideo {
		return {
			type: 'video',
			videoId: jwVideo.mediaid,
			poster: jwVideo.image,
			headline: jwVideo.title,
			published: Number(jwVideo.pubdate)
		} as EditorialVideo;
	}
}
