import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, defaultIfEmpty, map, of, shareReplay, startWith, tap } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../../bundesmaster-environment';
import { BundesmasterDataCollection, buildBundesmasterDataCollection, emptyBundesmasterDataCollection } from '../bundesmaster-data-collection';
import { BundesmasterMatch, BundesmasterMatchesLambdaResponse } from './bundesmaster-match';

export type BundesmasterMatchCollection<TItem extends BundesmasterMatch = BundesmasterMatch> = BundesmasterDataCollection<TItem>;

export interface MatchFilter {
	readonly matchDayId?: string;
	readonly seasonId?: string;
	readonly competitionId?: string;
	readonly query?: string;
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterMatchApiService {
	private readonly baseUrl: string;

	private readonly resolvedMatches = new Map<string, Observable<BundesmasterMatch | null>>();

	constructor(private readonly http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987';
	}

	public getMatch(id: string): Observable<BundesmasterMatch | null> {
		if (typeof id !== 'string') {
			return of(null);
		}
		if (!this.resolvedMatches.has(id)) {
			const request = this.http.get<BundesmasterMatch>(`${this.baseUrl}/tags/matches/${id}`).pipe(
				catchError(() => EMPTY),
				defaultIfEmpty<BundesmasterMatch, null>(null),
				shareReplay(1)
			);

			this.resolvedMatches.set(id, request);
		}

		return this.resolvedMatches.get(id);
	}

	public getMatches(filter: MatchFilter = {}): Observable<BundesmasterMatchCollection> {
		let params = new HttpParams();

		(['query', 'seasonId', 'matchDayId', 'competitionId'] as const).forEach((paramName) => {
			const value = (filter[paramName] ?? '').trim();
			if (value.length > 0) {
				params = params.set(paramName, value);
			}
		});

		return this.http.get<BundesmasterMatchesLambdaResponse>(`${this.baseUrl}/tags/matches`, { params }).pipe(
			tap(({ matches }) => matches.forEach((match) => this.resolvedMatches.set(match.matchId, of(match)))),
			map(({ matches, partial }) => buildBundesmasterDataCollection(matches, partial)),
			startWith(emptyBundesmasterDataCollection<BundesmasterMatch>('loading')),
			shareReplay(1)
		);
	}
}
