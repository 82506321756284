import { Component, Input } from '@angular/core';
import { LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-ballrecoverytime-card',
	templateUrl: './stats-ballrecoverytime-card.component.html',
	styleUrls: ['./stats-ballrecoverytime-card.component.scss']
})
export class StatsBallrecoverytimeCardComponent {
	@Input() clubData: LiveBlogClubs;
	@Input() side: LivetickerSides;
	@Input() times: {
		label: LivetickerSides | string;
		value: number;
	}[] = [];

	constructor() {}
}
