import { Component, Input, OnChanges } from '@angular/core';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEvents, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'livetickerevent-headline',
	templateUrl: './livetickerevent-headline.component.html',
	styleUrls: ['./livetickerevent-headline.component.scss']
})
export class LivetickerEventHeadlineComponent implements OnChanges {
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() headline = '';
	@Input() textColor = 'text_black';
	public side: LivetickerSides;

	constructor() {}

	ngOnChanges() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
	}
}
