import { inject, Injectable } from '@angular/core';
import { Database, equalTo, limitToFirst, listVal, objectVal, orderByChild, query, ref } from '@angular/fire/database';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class IFirebaseService {
	public db = inject(Database);
	constructor() {}

	/**
	 *
	 * @param listPath
	 * @param orderBy
	 * @param filterFieldValue
	 * @param limit
	 * @private
	 */
	public _getDataFromFirebaseWithFilter(listPath: string, orderBy: string, filterFieldValue: any, limit: number | null): Observable<any[]> {
		let filteredData = [];
		if (orderBy && !filterFieldValue) {
			filteredData = [orderByChild(orderBy)];
		}
		if (orderBy && filterFieldValue) {
			filteredData = [orderByChild(orderBy), equalTo(filterFieldValue)];
		}

		if (limit && Number(limit) > 0) {
			filteredData.push(limitToFirst(Number(limit)));
		}

		const dbRef = ref(this.db, listPath);
		const listWithQuery = query(dbRef, ...filteredData);

		return listVal(listWithQuery);
	}

	/**
	 *
	 * @param listPath
	 * @private
	 */
	public _getDataFromFirebase(listPath: string): Observable<any> {
		return objectVal(ref(this.db, listPath));
	}

	public handleError(data) {
		return throwError(data);
	}
}
