<div class="container">
	<h1 *ngIf="!user">Please Login to use DFLDS Bundes Streaker</h1>
	<p *ngIf="!user"><a class="btn btn-primary" style="cursor: pointer" (click)="doLogin()" role="button">Login using your Active Directory credentials</a></p>
	<h1 *ngIf="!user && loginFailure">Your login attempt was unsuccessful:</h1>
	<h2 *ngIf="!user && loginFailure">{{loginFailure?.error}}</h2>
	<h2 *ngIf="!user && loginFailure">{{loginFailure?.errorDesc}}</h2>

	<h1 *ngIf="user">You have been successfully logged in as: {{user.name}}</h1>
	<h2>
		<a *ngIf="user" mat-flat-button color="primary" class="gotocurrentmatchday" [routerLink]="['/matches']"><h2>Start Liveticker - GO TO CURRENT MATCHDAY</h2></a>
	</h2>

	<hr class="mb-4" />

	<img src="/assets/memes/lookatme.png" />
</div>
