<ng-container *ngIf="user">
	<button mat-flat-button (click)="doLogout()">
		<img *ngIf="userImage" [src]="userImage" [alt]="user.name" title="{{user.name}} ({{user.username}})" />
		<span *ngIf="!userImage && userInitials" class="userInitials" title="{{user.name}} ({{user.username}})">{{userInitials}}</span>
		<span>Logout</span>
	</button>
	<a (click)="openUserInfo()" class="userDetails-info d-flex align-items-center justify-content-center"><mat-icon [inline]="true">info</mat-icon></a>
</ng-container>

<button mat-flat-button color="primary" *ngIf="!user" (click)="doLogin()">Login</button>

<ng-template #userInfo let-data>
	<h2 mat-dialog-title>Login Information</h2>
	<mat-dialog-content class="mat-typography">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<pre>
            {{data.user | json}}
          </pre>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close cdkFocusInitial>OK</button>
	</mat-dialog-actions>
</ng-template>
