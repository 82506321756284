import { LiveBlogAutoEventCard, LiveBlogAutoEventGoal, LiveBlogEntries } from '@nx-bundesliga/models';
import { HomeAway } from '../../../components/organisms/mat-match-header/mat-match-header.component';
/**
 * Returns the path to the stadium image for the given threeLetterCode. Wrapped in a url(path) expression to be used with ngStyle.
 *
 * @param tlc
 */
export function getStadiumImageFromTlc(tlc: string): string {
	return `url(/assets/stadiums/stadium-${tlc?.toLocaleLowerCase()}.jpg)`;
}
/**
 * Filters everything except autogoals from a liveBlogEntry stream and sorts them by association.
 *
 * @param events
 */
export function getAllGoalsFromEvents(events: LiveBlogEntries): HomeAway<LiveBlogAutoEventGoal> {
	const goals = {
		home: [],
		away: []
	};
	for (const event in events) {
		if (events.hasOwnProperty(event)) {
			if (events[event].entryType === 'goal') {
				goals[events[event].side].push(events[event]);
			} else if (events[event].entryType === 'ownGoal') {
				goals[events[event].side === 'home' ? 'away' : 'home'].push(events[event]);
			}
		}
	}
	return goals.home.length > 0 || goals.away.length > 0 ? goals : null;
}
export function getAllCardsFromEvents(events: LiveBlogEntries): HomeAway<LiveBlogAutoEventCard> {
	const cards = {
		home: [],
		away: []
	};
	for (const event in events) {
		if (events.hasOwnProperty(event)) {
			if (['redCard', 'yellowCard', 'yellowRedCard'].includes(events[event].entryType)) {
				cards[events[event].side].push(events[event]);
			}
		}
	}
	return cards.home.length > 0 || cards.away.length > 0 ? cards : null;
}
/**
 * Filters everything except autogoals from a liveBlogEntry stream and sorts them by association.
 *
 * @param events
 */
export function getAllBasicMatchEvents(events: LiveBlogEntries, eventFilter?: string[]): any {
	const basicMatchEvents = {
		home: [],
		away: []
	};
	let basicMatchEventFilter = ['goal', 'ownGoal', 'yellowCard', 'redCard', 'yellowRedCard', 'sub'];
	if (eventFilter && eventFilter.length > 0) {
		basicMatchEventFilter = eventFilter;
	}
	for (const event in events) {
		if (events.hasOwnProperty(event)) {
			if (basicMatchEventFilter.indexOf(events[event].entryType) > -1) {
				if (events[event].entryType === 'ownGoal') {
					basicMatchEvents[events[event].side === 'home' ? 'away' : 'home'].push(events[event]);
				} else {
					basicMatchEvents[events[event].side].push(events[event]);
				}
			}
		}
	}
	return basicMatchEvents.home.length > 0 || basicMatchEvents.away.length > 0 ? basicMatchEvents : null;
}
