import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LivetickerEventsModule } from '@nx-bundesliga/bundesliga-com/components/liveticker';
import { WelcomeComponent } from './welcome.component';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
	{
		path: 'tools/welcome',
		component: WelcomeComponent,
		// canActivate : [MsalGuardStreaker]
		canActivate: [MsalGuard]
	}
];

@NgModule({
	declarations: [WelcomeComponent],
	imports: [CommonsModule, FormsModule, MatFormFieldModule, MatButtonModule, RouterModule.forChild(routes), MatExpansionModule, LivetickerEventsModule, MatIconModule, MatProgressSpinnerModule, MatSelectModule, MatInputModule, MatCheckboxModule]
})
export class WelcomePageModule {}
