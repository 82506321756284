<div class="header">
	<h2>Slides</h2>
	<button mat-icon-button matTooltip="Add new slide" [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon></button>

	<mat-menu #menu="matMenu">
		<button mat-menu-item (click)="addNewSlide(slideTypes.TEXT)"> <mat-icon>short_text</mat-icon> Text </button>
		<button mat-menu-item (click)="addNewSlide(slideTypes.QUOTE)"> <mat-icon>format_quote</mat-icon> Quote </button>
		<button mat-menu-item (click)="addNewSlide(slideTypes.VIDEO)"> <mat-icon>video_library</mat-icon> Video </button>
		<button *ngIf="storyType === 'match_story'" mat-menu-item (click)="addNewSlide(slideTypes.GOAL)"> <mat-icon>view_carousel</mat-icon> Goal </button>
		<button *ngIf="storyType === 'match_story'" mat-menu-item (click)="addNewSlide(slideTypes.CARD)"> <mat-icon>view_carousel</mat-icon> Card </button>
		<button *ngIf="storyType === 'match_story'" mat-menu-item (click)="addNewSlide(slideTypes.SUB)"> <mat-icon>view_carousel</mat-icon> Substitution </button>
		<button *ngIf="storyType === 'match_story'" mat-menu-item (click)="addNewSlide(slideTypes.PROGRESS)"> <mat-icon>view_carousel</mat-icon> Progress </button>
		<button *ngIf="storyType === 'match_story'" mat-menu-item (click)="addNewSlide(slideTypes.LINEUP)"> <mat-icon>view_carousel</mat-icon> Lineup </button>
	</mat-menu>
</div>
<p class="info"><mat-icon>info</mat-icon> The slides are only a visual approximation and might look completely different in the app</p>

<mat-error *ngIf="slides.hasError('required')"><mat-icon color="warn">error</mat-icon>At least one slide is required</mat-error>
<mat-error *ngIf="slides.hasError('matchStorySortedSlides')"><mat-icon color="warn">error</mat-icon>Match_Story slides need to be sorted by Playtime!</mat-error>
<div class="slides row" *ngIf="slides; else noSlides">
	<div class="slide col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let slide of slides.controls; let index = index">
		<bundesmaster-ui-story-slide [index]="index" [slide]="slide" [storyType]="storyType">
			<ng-container actions>
				<button class="small" mat-icon-button matTooltip="Move to Front" (click)="moveToFront(index)"><mat-icon>skip_previous</mat-icon></button>
				<button class="small" mat-icon-button matTooltip="Move Back" (click)="moveBefore(index)"><mat-icon>navigate_before</mat-icon></button>
				<button class="small" mat-icon-button matTooltip="Clone slide" (click)="cloneSlide(index)"><mat-icon>scatter_plot</mat-icon></button>
				<button class="small" mat-icon-button matTooltip="Remove Slide" (click)="removeSlide(index)"><mat-icon>delete</mat-icon></button>
				<button class="small" mat-icon-button matTooltip="Move Forward" (click)="moveNext(index)"><mat-icon>navigate_next</mat-icon></button>
				<button class="small" mat-icon-button matTooltip="Move to End" (click)="moveToEnd(index)"><mat-icon>skip_next</mat-icon></button>
			</ng-container>

			<ng-container headerActions>
				<button mat-icon-button matTooltip="Edit slide content" (click)="editSlide(index)"><mat-icon>edit</mat-icon></button>
				<button mat-icon-button matTooltip="Edit slide CTA" (click)="editSlideCta(index)" [ngClass]="slide.value.cta.target | highlightEmpty"><mat-icon>star</mat-icon></button>
				<ng-container *ngIf="[slideTypes.TEXT, slideTypes.QUOTE, slideTypes.GOAL, slideTypes.SUB, slideTypes.CARD, slideTypes.LINEUP, slideTypes.PROGRESS].includes(slide?.value?.slideType)">
					<button mat-icon-button matTooltip="Replace slide image" (click)="editSlideImage(index)"><mat-icon>image</mat-icon></button>
					<button mat-icon-button matTooltip="Crop slide image" (click)="cropSlideImage(index)"><mat-icon>crop</mat-icon></button>
				</ng-container>
				<button *ngIf="[slideTypes.VIDEO].includes(slide?.value?.slideType)" mat-icon-button matTooltip="Replace slide video" (click)="editSlideVideo(index)"><mat-icon>video_library</mat-icon></button>
			</ng-container>
		</bundesmaster-ui-story-slide>
	</div>
</div>

<ng-template #noSlides> No slides have been added to this story 😟 </ng-template>
