import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
// import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { CreateCustomMatchComponent } from './create-custom-match.component';

const routes: Routes = [
	{
		path: 'create-custom-match/:language/:customMatchId',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		component: CreateCustomMatchComponent
	},
	{
		path: 'create-custom-match',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		component: CreateCustomMatchComponent
	}
];

@NgModule({
	declarations: [CreateCustomMatchComponent],
	imports: [
		CommonsModule,
		RouterModule.forChild(routes),
		FormsModule,
		MatCardModule,
		MatDatepickerModule,
		MatDateFnsModule,
		// MatMomentDateModule,
		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		NgxMatSelectSearchModule,
		MatOptionModule,
		MatButtonModule,
		MatIconModule,
		MatDividerModule,
		MatListModule,
		MatCheckboxModule,
		ReactiveFormsModule,
		TranslateModule
	]
})
export class CreateCustomMatchModule {}
