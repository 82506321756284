import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BundesligaMatchStates, LiveBlogClub, Playtime } from '@nx-bundesliga/models';
import { Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';

@Component({
	selector: 'stats-clubmetricprogression-card',
	templateUrl: './stats-clubmetricprogression-card.component.html',
	styleUrls: ['./stats-clubmetricprogression-card.component.scss']
})
export class StatsClubmetricprogressionCardComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() club: LiveBlogClub;
	@Input() isLiveticker = false;
	@Input() playtime: Playtime = { minute: 0 };
	@Input() progression: {
		value: number;
		unit: string;
		playtime?: Playtime;
		matchSection?: BundesligaMatchStates;
	}[];
	public language: string;
	public competition: string;
	private languageSubscription: Subscription;
	private competitionSubscription: Subscription;

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.languageSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate: Language) => {
			this.language = langstate.code;
		});
		this.competitionSubscription = this.cstore.pipe(select(getWorkingCompetition)).subscribe((compState) => {
			this.competition = compState.name;
		});
	}

	ngOnDestroy(): void {
		if (this.languageSubscription) {
			this.languageSubscription.unsubscribe();
		}
		if (this.competitionSubscription) {
			this.competitionSubscription.unsubscribe();
		}
	}
}
