import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveBlogInfos, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'bl-ticker-typing-controls',
	templateUrl: './bl-ticker-typing-controls.component.html',
	styleUrls: ['./bl-ticker-typing-controls.component.scss']
})
export class BlTickerTypingControlsComponent {
	@Input() typing: LiveBlogInfos;
	@Output() isTypingChange: EventEmitter<[LivetickerSides, boolean]> = new EventEmitter<[LivetickerSides, boolean]>();

	constructor() {}
	public setTyping(side: LivetickerSides, status: boolean): void {
		this.isTypingChange.emit([side, status]);
	}
}
