import { Component } from '@angular/core';
import { AVAILABLE_MODES, Modes, ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { Observable } from 'rxjs';

@Component({
	selector: 'theme-switcher',
	templateUrl: './theme-switcher.component.html',
	styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent {
	public themes = AVAILABLE_MODES;
	public currentThemingMode$: Observable<Modes>;
	constructor(private themingService: ThemingService) {
		this.currentThemingMode$ = this.themingService.mode$;
	}

	public changeTheme(e) {
		this.themingService.setMode(e.value);
	}
}
