import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LineupCollectionModule } from '../../organisms/stats-collection/component/lineup/lineup-collection.module';
import { LineupFeedComponent } from './lineup-feed.component';

@NgModule({
	imports: [CommonsModule, LineupCollectionModule],
	exports: [LineupFeedComponent],
	declarations: [LineupFeedComponent]
})
export class LineupFeedModule {}
