// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { environment as environmentDev } from './environment.dev';
export const environment = {
	...environmentDev,
	build: 'localhost',
	adConfig: {
		de: {
			'DFL-COM-000001': 'http://localhost:4000/assets/config/adconfigs/adslot-config-de-dfl-com-000001.json',
			'DFL-COM-000002': 'http://localhost:4000/assets/config/adconfigs/adslot-config-de-dfl-com-000002.json'
		},
		en: {
			'DFL-COM-000001': 'http://localhost:4000/assets/config/adconfigs/adslot-config-en-dfl-com-000001.json',
			'DFL-COM-000002': 'http://localhost:4000/assets/config/adconfigs/adslot-config-en-dfl-com-000002.json'
		},
		es: {
			'DFL-COM-000001': 'http://localhost:4000/assets/config/adconfigs/adslot-config-es-dfl-com-000001.json'
		},
		jp: {
			'DFL-COM-000001': 'http://localhost:4000/assets/config/adconfigs/adslot-config-jp-dfl-com-000001.json'
		}
	}
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
