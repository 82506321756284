import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubLogoComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { BundesmasterUiDialogSelectCompetitionsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-competitions';
import { BundesmasterUiDialogSelectSeasonsComponent } from '@nx-bundesliga/bundesmaster/ui/dialog-select-seasons';
import { BundesmasterUiLoadingBarComponent, BundesmasterUiLoadingBarTextComponent } from '@nx-bundesliga/bundesmaster/ui/loading-bar';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { BundesmasterUiArticleTagsComponent } from './bundesmaster-ui-article-tags.component';

@NgModule({
	declarations: [BundesmasterUiArticleTagsComponent],
	exports: [BundesmasterUiArticleTagsComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatCheckboxModule,
		FormsModule,
		BundesmasterUiLoadingBarComponent,
		BundesmasterUiLoadingBarTextComponent,
		MatProgressSpinnerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatSelectModule,
		PipesModule,
		CommonsLibModule,
		BundesmasterUiDialogSelectCompetitionsComponent,
		BundesmasterUiDialogSelectSeasonsComponent,
		DflNgxLazyLoadModule,
		MatTooltipModule,
		BundesmasterUiClubLogoComponent
	]
})
export class BundesmasterUiArticleTagsModule {}
