import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getNodgeColor, getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogClubs, LiveBlogEntryStats, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';
import { Router } from '@angular/router';

@Component({
	selector: 'livetickerevent-stats',
	templateUrl: './livetickerevent-stats.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-stats.component.scss'],
	animations: [liveblogPostAnimation('.head livetickerevent-playtime, .head livetickerevent-score, .head .headline, .head .detailText, .body .stats, .poweredByAWS')]
})
export class LivetickereventStatsComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogEntryStats;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() isWebview = false;
	@Input() enablePlayerLinking = true;
	public side: LivetickerSides;
	public nodgeColor: string;
	public currentLang: string;
	public currentCompetition: string;

	constructor(private translate: TranslateService, private router: Router) {}
	ngOnChanges() {
		this.currentLang = this.translate.currentLang;
		this.side = getSide(this.liveEntry) as LivetickerSides;
		this.nodgeColor = this.liveEntry.playtime && this.liveEntry.playtime.minute === 0 ? 'transparent' : getNodgeColor(this.side, this.clubData);
		this.getCurrentCompetition();
	}

	getCurrentCompetition() {
		this.currentCompetition = this.router.url.split('/')[2];
	}

	translateStatsString(translateString: string, prefix = '', suffix = '', interpolateParams = {}): Observable<string> {
		return this.translate.get(prefix + translateString + suffix, interpolateParams).pipe(
			map((translationResponse) => {
				if (translationResponse === prefix + translateString + suffix || translationResponse === '') {
					return translateString;
				}
				return translationResponse;
			})
		);
	}
}
