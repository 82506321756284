<div class="container" *ngIf="clubData && match">
	<matchcenter-stats-collection
		(createStatsPost)="openCreateDialog($event)"
		[competitionId]="match.dflDatalibraryCompetitionId"
		[seasonId]="match.dflDatalibrarySeasonId"
		[matchdayId]="match.dflDatalibraryMatchdayId"
		[matchId]="match.dflDatalibraryMatchId"
		[home]="clubData.home"
		[away]="clubData.away"
		[match]="match"></matchcenter-stats-collection>
</div>
