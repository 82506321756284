import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ProfileComponent } from './profile.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ProfileComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule, MatChipsModule],
	exports: [ProfileComponent]
})
export class ProfileModule {}
