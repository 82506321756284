import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { HomeComponent } from './home.component';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		// canActivate : [MsalGuardStreaker]
		canActivate: [MsalGuard]
	}
];

@NgModule({
	declarations: [HomeComponent],
	imports: [CommonsModule, MatButtonModule, RouterModule.forChild(routes)]
})
export class HomePageModule {}
