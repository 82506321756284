import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';

@Component({
	selector: 'nx-bundesliga-video-dialog-select-video',
	templateUrl: './dialog-select-video.component.html',
	styleUrls: ['./dialog-select-video.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSelectVideoComponent implements OnDestroy {
	constructor(public dialogRef: MatDialogRef<DialogSelectVideoComponent>, private router: Router, private route: ActivatedRoute) {}

	public videoSelected(selected: { video: jwp.Media; videoproperty: string }) {
		this.dialogRef.close({
			video: selected.video
		});
	}

	ngOnDestroy() {
		// remove added queryparams from url
		this.router.navigate([], {
			relativeTo: this.route
		});
	}
}
