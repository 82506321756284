import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BundesligaMatchStates, LiveBlogAutoEventCard, LiveBlogAutoEventLineup, LiveBlogClubs, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import idx from 'idx';
import { environment } from '../../../../../../environments/environment';
import * as _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'bl-lineup-event-input',
	templateUrl: './bl-lineup-event-input.component.html',
	styleUrls: ['./bl-lineup-event-input.component.scss', '../liveticker-events.common.scss']
})
export class BlLineupEventInputComponent implements OnInit, OnChanges {
	@Input() liveEntry: LiveBlogAutoEventLineup & { order?: any };
	@Input() clubData: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() side: LivetickerSides;
	@Input() orderType = 'auto';
	@Input() orderValue = 'auto';
	@Input() editMode = false;
	@Input() customMatch = false;

	public previewEntry: LiveBlogAutoEventLineup & { order?: any };
	@Input() tributeOptions: any;

	public players = [];
	public filteredPlayers = [];
	public playerSearch = '';
	public selectedPlayer: any;

	constructor() {}
	public update() {
		this.previewEntry = Object.assign({}, this.liveEntry);
	}
	public updatePlaytime() {
		if (this.editMode === true && (this.liveEntry.playtime.minute !== this.minuteOfPlay.minute || this.liveEntry.playtime.injuryTime !== this.minuteOfPlay.injuryTime)) {
			if (this.orderType === 'keep') {
				this.orderType = 'auto';
			}
			this.updateOrderType();
		}
	}

	public updateOrderType() {
		switch (this.orderType) {
			case 'auto':
				this.orderValue = 'auto';
				break;
			case 'keep':
				this.orderValue = 'keep';
				break;
			case 'parent':
				this.orderValue = '';
				break;
			case 'child':
				this.orderValue = '';
				break;
			case 'number':
				this.orderValue = '';
				break;
			default:
				this.orderValue = '';
		}
		this.updateOrderValue();
	}

	public updateOrderValue() {
		let prependedText = '';
		if (this.orderType === 'parent' || this.orderType === 'child') {
			prependedText = this.orderType;
		}
		this.liveEntry.order = prependedText + this.orderValue;
	}

	ngOnInit() {
		this.liveEntry = {
			...this.liveEntry,
			entryType: 'lineup',
			detail: {
				lineup: (idx(this.liveEntry, (_) => _.detail.lineup) || [].constructor(11).fill({})).map((item) => ({
					dflDatalibraryObjectId: idx(item, (_) => _.dflDatalibraryObjectId) || '',
					imageUrl: idx(item, (_) => _.imageUrl) || `https://www.${environment.stageDomain}.com/assets/placeholder/player-circle-default.png`,
					name: idx(item, (_) => _.name) || '',
					shirtNumber: idx(item, (_) => _.shirtNumber) || 0
				}))
			},
			order: 'auto',
			entryDate: idx(this.liveEntry, (_) => _.entryDate) || '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.matchStatus || 'PRE_MATCH',
			side: this.side,
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			}
		} as LiveBlogAutoEventLineup & { order?: any };
		this.initializeOrderDefault();
		this.update();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('tributeOptions') && this.tributeOptions && this.tributeOptions.hasOwnProperty('collection') && this.tributeOptions.collection.length >= 3) {
			this.players = this.tributeOptions.collection.find((col) => col?.trigger === (this.clubData[this.side]?.dflDatalibraryClubId ? '@' : '#'))?.values ?? [];
			this.filterPlayers();
		}
	}

	public filterPlayers() {
		if (!this.players) {
			return;
		}
		// get the search keyword
		if (!this.playerSearch || this.playerSearch === '') {
			this.filteredPlayers = this.players;
			return;
		} else {
			this.playerSearch = this.playerSearch.toLowerCase();
		}
		// filter the players
		this.filteredPlayers = this.players.filter((item) => (item.name.hasOwnProperty('alias') && item.name.alias ? item.name.alias : item.name.full).toLowerCase().trim().includes(this.playerSearch.toLowerCase().trim()));
	}
	public trackByPlayerId(index, item) {
		if (item.id) {
			return item.id;
		}
	}

	initializeOrderDefault() {
		if (this.editMode === true) {
			this.orderType = 'keep';
			this.updateOrderType();
		}
	}

	public getPayload() {
		return this.liveEntry;
	}

	public setLineupPlayer(index: number) {
		this.liveEntry.detail.lineup[index].dflDatalibraryObjectId = this.selectedPlayer?.externalPersonIds?.dflDatalibraryPersonId;
		this.liveEntry.detail.lineup[index].imageUrl = this.selectedPlayer?.playerImages?.FACE_CIRCLE.replace('-circle', '');
		this.liveEntry.detail.lineup[index].name = this.selectedPlayer?.name.hasOwnProperty('alias') && this.selectedPlayer?.name?.alias ? this.selectedPlayer?.name?.alias : this.selectedPlayer?.name?.last;
		this.liveEntry.detail.lineup[index].shirtNumber = this.selectedPlayer?.shirtNumber;
		this.update();
	}
}
