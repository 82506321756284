export interface BoxCoordinates {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}

export interface RekognitionBoundingBox {
	Width?: number;
	Height?: number;
	Left?: number;
	Top?: number;
}

export function cropImageToRatio(imageWidth: number, imageHeight: number, destinationRatio: number): BoxCoordinates | null {
	const assetRatio = imageWidth / imageHeight;
	if (Math.floor(destinationRatio * 100) === Math.floor(assetRatio * 100)) {
		// No cropping necessary
		return null;
	}
	// positions work like x1,y1 are starting points top left corner (0,0), x2,y2 is the width/height of the area beginning of the origin point
	// {  x1: margin-left, y1: margin-top, x2: width, y2: height }
	if (destinationRatio < assetRatio) {
		return { x1: (imageWidth - imageHeight * destinationRatio) / 2, y1: 0, x2: imageHeight * destinationRatio, y2: imageHeight };
	} else {
		return { x1: 0, y1: (imageHeight - imageWidth / destinationRatio) / 2, x2: imageWidth, y2: imageWidth / destinationRatio };
	}
}

export function centerAroundBoundingBox(imageWidth: number, imageHeight: number, boxToCenter: BoxCoordinates | null, centerAround?: RekognitionBoundingBox): BoxCoordinates | null {
	if (centerAround === undefined || boxToCenter === null) {
		return boxToCenter;
	}

	const x2 = Math.abs(boxToCenter.x2);
	const y2 = Math.abs(boxToCenter.y2);

	const shiftDimension = (center: number, boxDimension: number, imageDimension: number) => {
		let shifted = Math.round(center - boxDimension * 0.5);
		shifted = Math.min(imageDimension - boxDimension, shifted); // never exceed upper edge
		shifted = Math.max(0, shifted); // never exceed lower edge
		return shifted;
	};

	const centerPointX = ((centerAround.Left ?? 0.5) + (centerAround.Width ?? 0) * 0.5) * imageWidth;
	const x1 = shiftDimension(centerPointX, x2, imageWidth);

	const centerPointY = ((centerAround.Top ?? 0.5) + (centerAround.Height ?? 0) * 0.5) * imageHeight;
	const y1 = shiftDimension(centerPointY, y2, imageHeight);

	return { x1, y1, x2, y2 };
}

export function addCroppingParametersToImageUrl(imageUrl: string, croppingPosition: BoxCoordinates | null): string {
	const urlObj = new URL(imageUrl);
	if (croppingPosition !== null) {
		const { x1, x2, y1, y2 } = croppingPosition;
		urlObj.searchParams.set('crop', [x1, y1, x2, y2].map((pos) => Math.floor(pos).toString(10) + 'px').join(','));
	}
	return urlObj.toString();
}
