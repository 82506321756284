import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, defaultIfEmpty, map, of, shareReplay, startWith, tap } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../../bundesmaster-environment';
import { BundesmasterDataCollection, buildBundesmasterDataCollection, emptyBundesmasterDataCollection } from '../bundesmaster-data-collection';
import { BundesmasterMatchDaysLambdaResponse, BundesmasterMatchday } from './bundesmaster-matchday';

export type BundesmasterMatchdayCollection<TItem extends BundesmasterMatchday = BundesmasterMatchday> = BundesmasterDataCollection<TItem>;

export interface MatchdayFilter {
	readonly seasonId?: string;
	readonly query?: string;
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterMatchdayApiService {
	private readonly baseUrl: string;

	private readonly resolvedMatchdays = new Map<string, Observable<BundesmasterMatchday | null>>();

	constructor(private readonly http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987';
	}

	public getMatchday(id: string): Observable<BundesmasterMatchday | null> {
		if (typeof id !== 'string') {
			return of(null);
		}
		if (!this.resolvedMatchdays.has(id)) {
			const request = this.http.get<BundesmasterMatchday>(`${this.baseUrl}/tags/matchdays/${id}`).pipe(
				catchError(() => EMPTY),
				defaultIfEmpty<BundesmasterMatchday, null>(null),
				shareReplay(1)
			);

			this.resolvedMatchdays.set(id, request);
		}

		return this.resolvedMatchdays.get(id);
	}

	public getMatchdays(filter: MatchdayFilter = {}): Observable<BundesmasterMatchdayCollection> {
		let params = new HttpParams();

		(['query', 'seasonId'] as const).forEach((paramName) => {
			const value = (filter[paramName] ?? '').trim();
			if (value.length > 0) {
				params = params.set(paramName, value);
			}
		});

		return this.http.get<BundesmasterMatchDaysLambdaResponse>(`${this.baseUrl}/tags/matchdays`, { params }).pipe(
			tap(({ matchdays }) => matchdays.forEach((matchday) => this.resolvedMatchdays.set(matchday.matchdayId, of(matchday)))),
			map(({ matchdays, partial }) => buildBundesmasterDataCollection(matchdays, partial)),
			startWith(emptyBundesmasterDataCollection<BundesmasterMatchday>('loading')),
			shareReplay(1)
		);
	}
}
