import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getBackgroundColor, getNodgeColor, getSide, getTextColorClass } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEventGoal, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-goal',
	templateUrl: './livetickerevent-goal.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-goal.component.scss'],
	animations: [liveblogPostAnimation('.head livetickerevent-playtime, .head livetickerevent-score, .head livetickerevent-headline, .body .scorer, .body .score-container .goal, .body .score-container .score, .scorer-image, .advancedStats-xg')]
})
export class LivetickereventGoalComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogAutoEventGoal;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() xGoalsEnabled = true;
	@Input() shotSpeedEnabled = true;
	@Input() distanceToGoalEnabled = true;
	@Input() language = 'en';
	@Input() competition = 'bundesliga';
	@Input() enablePlayerLinking = true;
	@Input() enableCLubLinking = true;
	@Input() isWebview = false;
	@Input() isCustomMatch = false;

	public side: LivetickerSides;
	public textColorClass = 'text_black';
	public backgroundColor: string;
	public nodgeColor: any;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		this.side = getSide(this.liveEntry) as LivetickerSides;
		this.textColorClass = getTextColorClass(this.side, this.clubData);
		this.nodgeColor = getNodgeColor(this.side, this.clubData);
		this.backgroundColor = getBackgroundColor(this.side, this.clubData);
	}
}
