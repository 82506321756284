import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BlTickerStatusComponent } from './bl-ticker-status.component';

@NgModule({
	declarations: [BlTickerStatusComponent],
	exports: [BlTickerStatusComponent],
	imports: [CommonModule, MatProgressSpinnerModule, MatCardModule, MatIconModule, MatButtonModule]
})
export class BlRapidTickerStatusModule {}
