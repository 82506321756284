import { Injectable } from '@angular/core';
import { LiveBlogMatch } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { IFirebaseService } from '../firebase/IFirebase.service';

@Injectable({
	providedIn: 'root'
})
export class CustomMatchesService extends IFirebaseService {
	public language = 'en';

	constructor() {
		super();
	}

	/**
	 * Returns an observable for the entire firebase matchday/match node, including all clubData, liveEvents
	 *
	 * @param lang
	 * @param matchId
	 *
	 * @webviewOnly
	 */
	public getMatch(lang: string, matchId: string): Observable<LiveBlogMatch> {
		const path = ['', lang, 'custom', 'matches', matchId].join('/');
		return this._getDataFromFirebase(path);
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isLiveMatch(match: any): boolean {
		if (match['matchStatus'] !== 'PRE_MATCH' && match['matchStatus'] !== 'FINAL_WHISTLE') {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isPenalty(match: any): boolean {
		if (match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY') {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * checks if match has overtime state
	 * @param match
	 */
	public isOverTimeLiveMatch(match: any): boolean {
		if (match['matchStatus'] === 'PRE_EXTRA' || match['matchStatus'] === 'FIRST_HALF_EXTRA' || match['matchStatus'] === 'HALF_EXTRA' || match['matchStatus'] === 'SECOND_HALF_EXTRA' || match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY') {
			return true;
		} else {
			return false;
		}
	}
}
