import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {
	@Input() title: string;
	@Input() leftValue: number;
	@Input() rightValue: number;
	@Input() leftColor: string;
	@Input() rightColor: string;

	// set circle attributes
	public leftCircleStrokeDasharray: any;
	public leftCircleStrokeDashoffset: any;
	public rightCircleStrokeDasharray: any;
	public rightCircleStrokeDashoffset: any;
	// set fake border circle attributes
	public leftFakeBorderCircleStrokeDasharray: any;
	public leftFakeBorderCircleStrokeDashoffset: any;
	public rightFakeBorderCircleStrokeDasharray: any;
	public rightFakeBorderCircleStrokeDashoffset: any;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('leftValue') || changes.hasOwnProperty('rightValue') || changes.hasOwnProperty('leftColor') || changes.hasOwnProperty('rightColor')) {
			// inspired by : https://stackoverflow.com/a/59232930

			const radius = 50;
			const circumference = 2 * Math.PI * radius;
			const gutterAngle = 1.5;
			const gutterLength = (gutterAngle / 360) * circumference;

			// in case the gutter needs to be adjusted for the fake border to be visible
			const fakeBorderGutterAngle = 0.5;
			const fakeBorderGutterLength = (fakeBorderGutterAngle / 360) * circumference;

			const fakeBorderGutterLeft = this.leftColor.toLowerCase() === '#ffffff' ? fakeBorderGutterLength : 0;
			const fakeBorderGutterRight = this.rightColor.toLowerCase() === '#ffffff' ? fakeBorderGutterLength : 0;

			const angleRight = (this.rightValue * 360) / 100;
			const strokeOffsetRight = (1 / 4) * circumference;
			const strokeDasharrayRight = (angleRight / 360) * circumference;

			const angleLeft = (this.leftValue * 360) / 100;
			const strokeOffsetLeft = circumference - strokeDasharrayRight + (1 / 4) * circumference;
			const strokeDasharrayLeft = (angleLeft / 360) * circumference;

			this.leftCircleStrokeDasharray = [strokeDasharrayLeft - 2 * (gutterLength + fakeBorderGutterLeft), circumference - strokeDasharrayLeft + 2 * (gutterLength + fakeBorderGutterLeft)];
			this.leftCircleStrokeDashoffset = strokeOffsetLeft - gutterLength - fakeBorderGutterLeft;
			this.leftFakeBorderCircleStrokeDasharray = [strokeDasharrayLeft - 2 * gutterLength, circumference - strokeDasharrayLeft + 2 * gutterLength];
			this.leftFakeBorderCircleStrokeDashoffset = strokeOffsetLeft - gutterLength;

			this.rightCircleStrokeDasharray = [strokeDasharrayRight - 2 * (gutterLength + fakeBorderGutterRight), circumference - strokeDasharrayRight + 2 * (gutterLength + fakeBorderGutterRight)];
			this.rightCircleStrokeDashoffset = strokeOffsetRight - gutterLength - fakeBorderGutterRight;
			this.rightFakeBorderCircleStrokeDasharray = [strokeDasharrayRight - 2 * gutterLength, circumference - strokeDasharrayRight + 2 * gutterLength];
			this.rightFakeBorderCircleStrokeDashoffset = strokeOffsetRight - gutterLength;
		}
	}
}
