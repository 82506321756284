import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'bar-progress-chart',
	templateUrl: './bar-progress-chart.component.html',
	styleUrls: ['./bar-progress-chart.component.scss']
})
export class BarProgressChartComponent implements OnChanges {
	@Input() leftValue: number;
	@Input() rightValue: number;
	@Input() relativeValue: number;
	@Input() leftColor: string;
	@Input() rightColor: string;
	@Input() leftTextColor: string;
	@Input() rightTextColor: string;
	@Input() leftLabel: string;
	@Input() rightLabel: string;
	public leftWidth = 0;
	public rightWidth = 0;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('leftValue') || changes.hasOwnProperty('rightValue')) {
			this.leftWidth = Math.max(this.leftValue >= this.relativeValue ? 100 : (this.leftValue / this.relativeValue) * 100, 0);
			this.rightWidth = Math.max(this.rightValue >= this.relativeValue ? 100 : (this.rightValue / this.relativeValue) * 100, 0);
		}
	}
}
