import { animate, animateChild, AnimationTriggerMetadata, query, sequence, stagger, style, transition, trigger } from '@angular/animations';

export const liveblogPostAnimation = (staggeredElements = '*', animateChildren = true): AnimationTriggerMetadata => {
	const hideStaggeredElements = query(staggeredElements, [style({ opacity: 0 })], { optional: true });
	const expandMainEvent = query(':self', [style({ transform: 'scaleY(0)' }), animate('600ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({ transform: 'scaleY(1)' }))], { optional: true });
	const animateChildElements = query(staggeredElements, [style({ opacity: 0, transform: 'translateX(-10%)' }), stagger(200, [animate('250ms cubic-bezier(0.25, 1, 0.5, 1)', style({ opacity: 1, transform: 'translateX(0)' }))])], { optional: true });
	const animateAdditionalChildren = query('@liveblogPostImageAnimation', animateChild(), { optional: true });
	const sequenceAnimations = animateChildren ? [hideStaggeredElements, expandMainEvent, animateChildElements, animateAdditionalChildren] : [expandMainEvent];

	return trigger('liveblogPostAnimation', [transition(':enter', [sequence(sequenceAnimations)])]);
};
