import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BlCrosspostDialogModule } from '../bl-crosspost-dialog/bl-crosspost-dialog.module';
import { BlLivetickereventDialogsModule } from '../bl-livetickerevent-dialogs/bl-livetickerevent-dialogs.module';
import { BlStoryDialogsModule } from '../bl-story-dialogs/bl-story-dialogs.module';
import { BlLivetickereventWrapperComponent } from './bl-livetickerevent-wrapper.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlLivetickereventWrapperComponent],
	exports: [BlLivetickereventWrapperComponent],
	imports: [CommonsModule, MatIconModule, MatButtonModule, MatDialogModule, BlLivetickereventDialogsModule, BlCrosspostDialogModule, BlStoryDialogsModule, MatProgressSpinnerModule, NgxTippyModule]
})
export class BlLivetickereventWrapperModule {}
