import { Article } from './article.model';

export interface ArticleHistoryVersion extends Article {
	history_id: string;
}

export interface ArticleHistoryListVersion {
	approval: string;
	history_id: string;
	publish_date: string;
}

export interface ArticleHistoryList {
	page: number;
	size: number;
	total: number;
	items: ArticleHistoryListVersion[];
}
