import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BundesmasterEnvironment, CORE_ENVIRONMENT, PipesModule, SLIDE_TYPES, StoryTypes } from '@nx-bundesliga/bundesmaster/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';
import 'jwplayer-video-element';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';

@Component({
	selector: 'bundesmaster-ui-story-slide',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule, CommonsLibModule, MatTooltipModule, MatExpansionModule, MatTableModule, DflNgxLazyLoadModule, PipesModule],
	templateUrl: './bundesmaster-ui-story-slide.component.html',
	styleUrls: ['./bundesmaster-ui-story-slide.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BundesmasterUiStorySlideComponent {
	@Input() slide: AbstractControl;
	@Input() storyType: StoryTypes;
	@Input() index: number;
	public slideTypes = SLIDE_TYPES;
	public jwPlayerId = this.environment.videoLibrary.jwPlayerId;
	constructor(@Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}
}
