import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BundesmasterContentApiService, BundesmasterEnvironment, CORE_ENVIRONMENT } from '@nx-bundesliga/bundesmaster/core';
import { SearchQuery } from '@nx-bundesliga/bundesmaster/ui/search-bar';
import { format } from 'date-fns';
import { catchError, concatMap, Observable, of, ReplaySubject, Subject, take, takeUntil, tap } from 'rxjs';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';
import { getStageDomain } from '@nx-bundesliga/shared/util/functions';

@Component({
	selector: 'nx-bundesliga-video-library-overview',
	templateUrl: './video-library-overview.component.html',
	styleUrls: ['./video-library-overview.component.scss']
})
export class VideoLibraryOverviewComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();
	public videos$: Observable<jwp.ListMediaResponse> = new Observable<jwp.ListMediaResponse>();
	public navigation$: ReplaySubject<{ page: number; size: number }> = new ReplaySubject<{ page: number; size: number }>(1);
	public query$: Observable<any>;
	@Output() videoClicked: EventEmitter<{ video: jwp.Media; videoproperty: string }> = new EventEmitter<{ video: jwp.Media; videoproperty: string }>();

	public videoproperty = 'videos';
	public size = 24;
	public page = 1;

	public cdnPrefix: string;
	public loading = true;
	public initialLoad = true;
	public selectFilter = {
		videoproperty: [
			{ name: 'Videos (de)', value: 'videos' },
			{ name: 'Videos (international)', value: 'videos_int' },
			{ name: 'Goals (de)', value: 'goals_de' },
			{ name: 'Goals (international)', value: 'goals_int' }
		]
	};

	constructor(private store: Store, private videoService: BundesmasterContentApiService, private router: Router, private route: ActivatedRoute, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {
		this.cdnPrefix = `https://assets.${getStageDomain(this.environment.stage)}/video/jw/`;

		this.videos$ = this.route.queryParams.pipe(
			takeUntil(this.destroying$),
			tap(() => (this.loading = true)),
			concatMap(({ page, size, ...filter }) => {
				this.page = parseInt(page, 10) || 1;
				this.size = parseInt(size, 10) || 24;
				this.videoproperty = filter?.['videoproperty'] || 'videos';
				return this.videoService.getVideos(page, size, filter).pipe(
					catchError(() => of(null)),
					tap(() => {
						this.loading = false;
						this.initialLoad = false;
					})
				);
			}),
			tap((videos) => {
				this.loading = false;
				this.initialLoad = false;
				console.log('videos:', videos);
			})
		);
	}

	ngOnInit() {
		this.query$ = this.route.queryParams.pipe(take(1));
	}

	ngOnDestroy() {
		this.destroying$.next();
		this.destroying$.complete();
	}

	onSearchQueryChanged(event: SearchQuery) {
		const dateRangeStart = event.dateRange?.start ? new Date(event.dateRange?.start) : '';
		const dateRangeEnd = event.dateRange?.end ? new Date(event.dateRange?.end) : '';
		const rangeStart = dateRangeStart instanceof Date ? format(dateRangeStart, 'yyyy-MM-dd') : '';
		const rangeEnd = dateRangeEnd instanceof Date ? format(dateRangeEnd, 'yyyy-MM-dd') : '';

		const filters = {
			...(event.search && { search: event.search }),
			...((rangeStart || rangeEnd) && { dateRange: `${rangeStart}_${rangeEnd}` }),
			...(event.filter.clubs?.length > 0 && { clubs: event.filter.clubs.join(',') }),
			...(event.filter.players?.length > 0 && { players: event.filter.players.join(',') }),
			...(event.filter.seasons?.length > 0 && { seasons: event.filter.seasons.join(',') }),
			...(event.filter.competitions?.length > 0 && { competitions: event.filter.competitions.join(',') }),
			...(event.filter.matchdays?.length > 0 && { matchdays: event.filter.matchdays.join(',') }),
			...(event.filter.matches?.length > 0 && { matches: event.filter.matches.join(',') }),
			...(event.filter.tags?.length > 0 && { tags: event.filter.tags.join(',') }),
			...(event.selectFilter?.['videoproperty'] && { videoproperty: event.selectFilter?.['videoproperty'] })
		};

		if (this.initialLoad === false) {
			this.page = 0;
			// console.log('navigate filters', {...filters, size: this.size, page: this.page});
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: { ...filters, size: this.size, page: this.page }
			});
		}
	}

	onPageinationChanged(event: PageEvent) {
		this.size = event.pageSize;
		this.page = event.pageIndex + 1;
		// console.log('navigate pagination', { size: this.size, page: this.page });
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { size: this.size, page: this.page },
			queryParamsHandling: 'merge'
		});
	}
}
