<div class="cube-container" *ngIf="loading">
	<div [ngClass]="['scene', size]">
		<div class="cube">
			<div class="face front">
				<img src="/assets/loader/cube/face_1_front.svg" />
			</div>
			<div class="face right">
				<img src="/assets/loader/cube/face_2_right.svg" />
			</div>
			<div class="face back">
				<img src="/assets/loader/cube/face_3_back.svg" />
			</div>
			<div class="face left">
				<img src="/assets/loader/cube/face_4_left.svg" />
			</div>
		</div>
	</div>

	<p class="text"><ng-content></ng-content></p>
</div>
