import { NgModule } from '@angular/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { BarProgressChartComponent } from './bar-progress-chart.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule, CommonsLibModule],
	exports: [BarProgressChartComponent],
	declarations: [BarProgressChartComponent],
	providers: []
})
export class BarProgressChartModule {}
