<div class="clubprogressranking-card">
	<span class="title">{{title}}</span>
	<div class="columns">
		<div class="line home" [ngStyle]="{'height': homeRelativeSize}">
			<span class="value">{{homeRelativeValue}}</span>
		</div>
		<div class="column home" [ngStyle]="{'backgroundColor': homeColor, 'height': homeSize}" [ngClass]="{'surround-with-border': homeBorderNeeded}">
			<span class="value" [ngStyle]="{'color': homeTextColor}">{{homeValue | number : '1.1-2'}}</span>
		</div>
		<div class="column away" [ngStyle]="{'backgroundColor': awayColor, 'height': awaySize}" [ngClass]="{'surround-with-border': awayBorderNeeded}">
			<span class="value" [ngStyle]="{'color': awayTextColor}">{{awayValue | number : '1.1-2'}}</span>
		</div>
		<div class="line away" [ngStyle]="{'height': awayRelativeSize}">
			<span class="value">{{awayRelativeValue}}</span>
		</div>
	</div>
	<div class="legend" *ngIf="metric">
		<span class="metric">{{metric}}</span>
	</div>
</div>
