import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const appRoutes: Route[] = [
	{
		path: 'videos',
		canActivate: [MsalGuard],
		loadChildren: () => import('./components/pages/video-library/video-library.module').then((mod) => mod.VideoLibraryModule)
	},
	{
		path: 'media',
		canActivate: [MsalGuard],
		loadChildren: () => import('./components/pages/media-library/media-library.module').then((mod) => mod.MediaLibraryModule)
	},
	{ path: '**', loadChildren: () => import('./components/pages/NotFound/NotFound.module').then((mod) => mod.NotFoundModule) }
];
