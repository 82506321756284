import { Pipe, PipeTransform } from '@angular/core';

/**
 * Reformat event.countdown string, because Safari can't handle our dateformat, since it's lacking a colon between the timezone values
 * i.e. "2018-12-14T20:30:00+0100" to "2018-12-14T20:30:00+01:00"
 *
 * @param date
 */
export function insertColonToTimezone(date: string): string {
	// some requests send a datetime with Z to indicate zero hour offset, however most scripts are expecting +0000
	if (date.endsWith('Z')) {
		return date.substr(0, date.length - 1) + '+00:00';
	}

	// @todo only apply if last 4000 chars are numerical
	return date.substr(-3, 1) === ':' ? date : date.substr(0, date.length - 2) + ':' + date.substr(date.length - 2);
}

@Pipe({ name: 'timeAgo' })
/**
 * Takes a properly formed date format as string and transforms it into a "time-ago-style" string (i.e. "a few seconds ago").
 */
export class TimeAgoPipe implements PipeTransform {
	transform(input: string): string {
		const now = new Date();
		const date = new Date(insertColonToTimezone(input));

		const seconds = Math.round(Math.abs((now.getTime() - date.getTime()) / 1000));
		if (Number.isNaN(seconds)) {
			// return early since something went completely wrong. sad panda.
			return input;
		}

		const minutes = Math.round(seconds / 60);
		if (minutes === 1) {
			return 'a minute ago';
		} else if (minutes <= 59) {
			return `${minutes} minutes ago`;
		}

		const hours = Math.round(minutes / 60);
		if (hours === 1) {
			return 'one hour ago';
		} else if (hours <= 23) {
			return `${hours} hours ago`;
		}

		const days = Math.round(hours / 24);
		if (days === 1) {
			return 'a day ago';
		} else if (days <= 6) {
			return `${days} days ago`;
		}

		const weeks = Math.round(days / 7);
		if (weeks === 1) {
			return 'a week ago';
		} else if (weeks <= 51) {
			return `${weeks} weeks ago`;
		}

		const years = Math.round(days / 365);
		if (years > 1) {
			return `${years} years ago`;
		} else {
			return 'a year ago';
		}
	}
}
