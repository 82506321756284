import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorialVideo, JWManifest, LiveBlogMatch, Match, MatchHighlightVideo } from '@nx-bundesliga/models';
import idx from 'idx';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { debounce, map, mapTo, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';
import { MatchesService } from '../../../services/matches/matches.service';

@Component({
	selector: 'bl-match-highlight-dialog',
	templateUrl: 'match-highlight-dialog.component.html',
	styleUrls: ['./match-highlight-dialog.component.scss']
})
export class BlMatchHighlightDialogComponent {
	@Input() match: LiveBlogMatch;
	@Input() video: MatchHighlightVideo;
	@Input() language: string;
	public previewEnabled$: any;
	public previewEntry: EditorialVideo;
	@ViewChild('composer') composer: Component;
	public processing = false;
	public loadingManifest = false;
	constructor(private toastr: ToastrService, public http: HttpClient, private matchService: MatchesService, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlMatchHighlightDialogComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
		}
		this.matchService
			.getBasicMatchinfosByMatchday(this.match.matchday, this.match.dflDatalibraryCompetitionId, this.match.dflDatalibrarySeasonId)
			.pipe(
				map((matches: Match[]) => matches.filter((match: Match) => match.dflDatalibraryMatchId === this.match.dflDatalibraryMatchId)),
				map((matches) => (matches.length === 1 ? matches[0] : {})),
				take(1)
			)
			.subscribe((matchInfo: Match | any) => {
				this.video = {
					videoId: idx(matchInfo, (_) => _[this.language].highlightVideo.videoId) || '',
					headline: idx(matchInfo, (_) => _[this.language].highlightVideo.headline) || '',
					text: idx(matchInfo, (_) => _[this.language].highlightVideo.text) || '',
					duration: idx(matchInfo, (_) => _[this.language].highlightVideo.duration) || ''
				};
				this.update();
				this.previewEnabled$ = new BehaviorSubject<boolean>(false);
				this.previewEnabled$ = this.previewEnabled$.pipe(debounce((val) => (val === false ? of(false) : timer(3000).pipe(mapTo(true)))));
				if (idx(this.previewEntry, (_) => _.videoId) || false) {
					this.reloadEmbed();
				}
			});
	}

	public update() {
		this.previewEntry = { type: 'video', ...Object.assign({}, this.video) };
	}

	public reloadEmbed() {
		if (this.video.videoId) {
			this.loadingManifest = true;
			this.getManifest(this.video.videoId).subscribe(
				(duration) => {
					this.video.duration = duration;
					this.loadingManifest = false;
				},
				() => {
					this.loadingManifest = false;
				}
			);
		}
		this.previewEnabled$.next(false);
		this.previewEnabled$.next(true);
	}

	private getManifest(id: string): Observable<number> {
		const url = `https://cdn.jwplayer.com/v2/media/${id}?format=json`;
		return this.http.get<JWManifest>(url).pipe(
			take(1),
			map((manifest) => {
				const playlistVideo = manifest.playlist ? manifest.playlist.filter((playlistItem) => playlistItem.mediaid === id) : [];
				if (playlistVideo.length > 0) {
					return playlistVideo[0]['duration'] || '';
				}
				return '';
			})
		);
	}

	public onSubmit(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/matchhighlight`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + this.match.dflDatalibraryMatchId;

		const url = urlBase + '/' + urlSuffix;
		const payload = this.video;

		console.log(`Sending highlight put to API: `, url);
		console.log('matchhighlight editEvent', payload);

		this.http.put(url, payload).subscribe(
			(success) => {
				this.toastr.success('Video highlight saved successfully!', 'Published');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		);
	}

	public onDelete(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/matchhighlight`;

		const urlSuffix = this.language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + this.match.dflDatalibraryMatchId;

		const url = urlBase + '/' + urlSuffix;

		console.log(`Sending highlight delete to API: `, url);

		this.http.delete(url).subscribe(
			(success) => {
				this.toastr.success('Video highlight deleted successfully!', 'Deleted');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		);
	}
}
