<mat-card>
	<mat-progress-bar [mode]="progressMode" [value]="progressValue"></mat-progress-bar>
	<mat-card-header>
		<mat-card-title>{{ uploadResult?.filename || file?.name }}</mat-card-title>
		<mat-card-subtitle [ngSwitch]="status">
			<ng-container *ngSwitchDefault>🔵</ng-container>
			<ng-container *ngSwitchCase="'DONE'">🟢</ng-container>
			<ng-container *ngSwitchCase="'FAILED'">🤬</ng-container>
			{{ status }}
		</mat-card-subtitle>
	</mat-card-header>

	<bundesmaster-ui-bounding-box-overlay [persons]="uploadResult?.persons || []" [noFadeIn]="true">
		<img class="image" [ngClass]="{'d-none': imageLoading}" mat-card-image [src]="imageSrc" [alt]="key" *ngIf="!closeable && uploadResult?.path" (load)="imageLoading = false" (error)="imageLoading = true; imageSrc = getImageSrc()" />
	</bundesmaster-ui-bounding-box-overlay>
	<mat-card-content>
		<mat-progress-spinner class="align-self-center" mode="indeterminate" color="primary" diameter="65" *ngIf="!closeable && imageLoading"></mat-progress-spinner>
		<div>
			<mat-chip-listbox aria-label="Matched Players" class="playerlist">
				<mat-chip color="primary" *ngFor="let match of uploadResult?.persons">{{ match.user.User!.UserId! | resolvePersonId | async }} ({{ match.user.Similarity | number:'1.1-2' }})</mat-chip>
				<mat-chip *ngFor="let competition of uploadResult?.tags?.competitions">{{ competition | resolveCompetitionId | async }}</mat-chip>
				<mat-chip *ngFor="let season of uploadResult?.tags?.seasons">{{ season | resolveSeasonId | async }}</mat-chip>
				<mat-chip *ngFor="let matchday of uploadResult?.tags?.matchdays">{{ matchday | resolveMatchDayId | async }}</mat-chip>
				<mat-chip *ngFor="let match of uploadResult?.tags?.matches">{{ match | resolveMatchId | async }}</mat-chip>
				<mat-chip *ngFor="let club of uploadResult?.tags?.clubs">{{ club | resolveClubId | async }}</mat-chip>
			</mat-chip-listbox>

			<p *ngIf="status !== 'DONE'">
				<strong>{{ (progress || 0) | fileSize }}</strong>
				of
				<strong>{{ file?.size | fileSize }}</strong>
				uploaded ({{ percentage }}%)
			</p>
			<p>
				REQUEST_ID:
				<strong *ngIf="ingestId">{{ ingestId }}</strong>
			</p>
			<p>
				CONTENT_ID:
				<strong *ngIf="uploadResult?.contentId">{{ uploadResult?.contentId }}</strong>
			</p>
			<p>
				Url:
				<a [href]="imageBaseUrl + '/' + uploadResult?.path" *ngIf="uploadResult?.path">{{ imageBaseUrl + '/' + uploadResult?.path }}</a>
			</p>
		</div>

		<mat-chip-listbox aria-label="Tags" class="taglist">
			<mat-chip *ngFor="let tag of uploadResult?.tags?.tags">{{ tag }}</mat-chip>
		</mat-chip-listbox>
	</mat-card-content>
	<mat-card-actions *ngIf="!closeable && status === 'DONE' && uploadResult?.contentId">
		<a mat-flat-button target="_blank" color="primary" [routerLink]="['/media/asset/', uploadResult?.contentId]">Edit Metadata</a>
	</mat-card-actions>
</mat-card>
