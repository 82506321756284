import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

interface ConfirmDialogOptions {
	question?: string;
	yes?: string;
	no?: string;
}

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-confirm',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatDialogModule],
	templateUrl: './bundesmaster-ui-dialog-confirm.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-confirm.component.scss']
})
export class BundesmasterUiDialogConfirmComponent {
	public dialogData: ConfirmDialogOptions = {
		question: 'Are you sure?',
		yes: 'yes',
		no: 'no'
	};
	constructor(public dialogRef: MatDialogRef<BundesmasterUiDialogConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions) {
		this.dialogData = {
			question: this.data?.question || this.dialogData.question,
			yes: this.data?.yes || this.dialogData.yes,
			no: this.data?.no || this.dialogData.no
		};
	}

	reject() {
		this.dialogRef.close(false);
	}

	confirm() {
		this.dialogRef.close(true);
	}
}

interface ConfirmableDialogOptions {
	message?: string;
	confirm?: string;
	reject?: string;
}

export function Confirmable(options?: ConfirmableDialogOptions) {
	return (target: object, propertyKey: string, descriptor: PropertyDescriptor) => {
		const originalMethod = descriptor.value;

		descriptor.value = async function (...args) {
			if (!this.dialog) {
				console.warn(`Using "@Confirmable" but MatDialog is not defined!`);
				return originalMethod.apply(this, args);
			}

			this.dialog
				.open(BundesmasterUiDialogConfirmComponent, {
					data: {
						yes: options?.confirm ?? 'yes',
						no: options?.reject ?? 'no',
						question: options?.message ?? 'confirm'
					}
				})
				.afterClosed()
				.subscribe((confirm) => confirm && originalMethod.apply(this, args));
		};

		return descriptor;
	};
}
