<div class="container">
	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width">
				<mat-label>Headline</mat-label>
				<input matInput *ngIf="tributeOptions" [(ngModel)]="liveEntry.detail.headline" [ngxTribute]="tributeOptions" (ngModelChange)="update()" placeholder="Headline" tabindex="1" />
				<input matInput *ngIf="!tributeOptions" [(ngModel)]="liveEntry.detail.headline" (ngModelChange)="update()" placeholder="Headline" tabindex="1" />
			</mat-form-field>
		</div>
		<div class="col-12">
			<mat-form-field class="full-width">
				<mat-label>Body</mat-label>
				<textarea matInput *ngIf="tributeOptions" [(ngModel)]="liveEntry.detail.text" [ngxTribute]="tributeOptions" (ngModelChange)="update()" placeholder="Body" rows="3" tabindex="2"></textarea>
				<textarea matInput *ngIf="!tributeOptions" [(ngModel)]="liveEntry.detail.text" (ngModelChange)="update()" placeholder="Body" rows="3" tabindex="2"></textarea>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-12 d-flex justify-content-start align-items-center">
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.conference" [ngModelOptions]="{standalone: true}" *ngIf="!customMatch">Conference</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.enableHtml" [ngModelOptions]="{standalone: true}">Enable HTML</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.hidden" [ngModelOptions]="{standalone: true}">Hide Post</mat-checkbox>
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.disableCrosspost" [ngModelOptions]="{standalone: true}" *ngIf="!customMatch">Do not Translate/Crosspost</mat-checkbox>
		</div>
	</div>

	<div class="row">
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Playtime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.minute" (ngModelChange)="updatePlaytime(); update();" placeholder="Playtime" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Injurytime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.injuryTime" (ngModelChange)="updatePlaytime(); update();" placeholder="Injurytime" type="number" tabindex="4" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>MatchStatus</mat-label>
				<mat-select [(ngModel)]="liveEntry.matchSection" (ngModelChange)="update()" tabindex="5">
					<mat-option value="PRE_MATCH">PRE_MATCH</mat-option>
					<mat-option value="FIRST_HALF">FIRST_HALF</mat-option>
					<mat-option value="HALF">HALF</mat-option>
					<mat-option value="SECOND_HALF">SECOND_HALF</mat-option>
					<ng-container *ngIf="customMatch === true || (competitionId !== 'DFL-COM-000001' && competitionId !== 'DFL-COM-000002')">
						<mat-option value="PRE_EXTRA">PRE_EXTRA</mat-option>
						<mat-option value="FIRST_HALF_EXTRA">FIRST_HALF_EXTRA</mat-option>
						<mat-option value="HALF_EXTRA">HALF_EXTRA</mat-option>
						<mat-option value="SECOND_HALF_EXTRA">SECOND_HALF_EXTRA</mat-option>
						<mat-option value="PRE_PENALTY">PRE_PENALTY</mat-option>
						<mat-option value="PENALTY">PENALTY</mat-option>
					</ng-container>
					<mat-option value="FINAL_WHISTLE">FINAL_WHISTLE</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>entryDate</mat-label>
				<input matInput [(ngModel)]="liveEntry.entryDate" (ngModelChange)="update()" type="input" placeholder="entryDate" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6 d-flex">
			<mat-form-field>
				<mat-label>Order</mat-label>
				<mat-select [(ngModel)]="orderType" (ngModelChange)="updateOrderType()">
					<mat-option value="auto">(auto) top of given minute</mat-option>
					<mat-option value="keep" *ngIf="editMode === true" [disabled]="liveEntry.playtime.minute !== minuteOfPlay.minute || liveEntry.playtime.injuryTime !== minuteOfPlay.injuryTime">(keep) maintain order on edited posts</mat-option>
					<mat-option value="parent">(parent) place above given postId</mat-option>
					<mat-option value="child">(child) place below given postId</mat-option>
					<mat-option value="number">(number) assign given order directly</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<span matPrefix *ngIf="orderType ==='parent'">parent&nbsp;</span>
				<span matPrefix *ngIf="orderType ==='child'">child&nbsp;</span>
				<input matInput [disabled]="orderType === 'auto' || orderType === 'keep'" [(ngModel)]="orderValue" (ngModelChange)="updateOrderValue()" type="input" placeholder="order" />
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<hr />
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-md-6">
			<mat-form-field class="w-100">
				<mat-label>Chart Type</mat-label>
				<mat-select [(ngModel)]="liveEntry.detail.type" (ngModelChange)="changeChartType(); update();" tabindex="5">
					<mat-option value="player">Player Comparison</mat-option>
					<mat-option value="bar">Bar Chart</mat-option>
					<mat-option value="pie">Pie Chart</mat-option>
					<mat-option value="stackedcolumn">StackedColumn Chart</mat-option>
					<mat-option value="playerRanking">PlayerRanking List</mat-option>
					<mat-option value="playerProgressRanking">PlayerProgressRanking List (xPasses)</mat-option>
					<mat-option value="clubProgressRanking">ClubProgressRanking List (xGoals)</mat-option>
					<mat-option value="clubMetricProgression" *ngIf="side !== 'none'">ClubMetricProgression (winProbability)</mat-option>
					<mat-option value="pressureHandling" *ngIf="side !== 'none'">PressureHandling (escapeRate)</mat-option>
					<mat-option value="keeperEfficiency" *ngIf="side !== 'none'">KeeperEfficiency (xSaves)</mat-option>
					<mat-option value="ballRecoveryTime">Ball Recovery Time</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
			<mat-checkbox color="primary" [(ngModel)]="liveEntry.detail.matchFact" [ngModelOptions]="{standalone: true}" (ngModelChange)="update()">MatchFact</mat-checkbox>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'bar' || liveEntry.detail.type === 'pie'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6">
			<mat-form-field>
				<mat-label>Home Value</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.home.value" (ngModelChange)="update()" placeholder="Home Value" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6">
			<mat-form-field>
				<mat-label>Away Value</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.away.value" (ngModelChange)="update()" placeholder="Away Value" type="number" tabindex="4" />
			</mat-form-field>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'stackedcolumn'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Home Data (left)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>primaryValue</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.primaryValue" (ngModelChange)="update()" placeholder="primaryValue" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>secondaryValue</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.secondaryValue" (ngModelChange)="update()" placeholder="secondaryValue" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>primaryLabel</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.primaryLabel" (ngModelChange)="update()" placeholder="primaryLabel" tabindex="4" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>secondaryLabel</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.secondaryLabel" (ngModelChange)="update()" placeholder="secondaryLabel" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Away Data (left)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label></mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.primaryValue" (ngModelChange)="update()" placeholder="primaryValue" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>secondaryValue</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.secondaryValue" (ngModelChange)="update()" placeholder="secondaryValue" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>primaryLabel</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.primaryLabel" (ngModelChange)="update()" placeholder="primaryLabel" tabindex="4" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>secondaryLabel</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.secondaryLabel" (ngModelChange)="update()" placeholder="secondaryLabel" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'player'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12" *ngIf="players.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-label>Player</mat-label>
						<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{player.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setPlayerCardPlayer('home')" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home
					</button>
					<button (click)="setPlayerCardPlayer('away')" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away
					</button>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Home Player (left)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Home Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.value" (ngModelChange)="update()" placeholder="Home Value" type="number" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Away Player (right)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Away Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.value" (ngModelChange)="update()" placeholder="Away Value" type="number" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'playerRanking'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12" *ngIf="players.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-label>Player</mat-label>
						<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{player.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setPlayerRankingsPlayer('home', 0)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 1
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 0)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 1
					</button>
					<button (click)="setPlayerRankingsPlayer('home', 1)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 2
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 1)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 2
					</button>
					<button (click)="setPlayerRankingsPlayer('home', 2)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 3
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 2)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 3
					</button>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Player (Ranked 1)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[0].person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[0].person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[0].person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Player Side</mat-label>
						<mat-select [(ngModel)]="liveEntry.detail.ranking[0].side" (ngModelChange)="update()" tabindex="5">
							<mat-option value="home">home</mat-option>
							<mat-option value="away">away</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[0].value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="4" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Unit</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[0].unit" (ngModelChange)="update()" placeholder="Unit" type="text" tabindex="4" />
					</mat-form-field>

					<input matInput [(ngModel)]="liveEntry.detail.ranking[0].rank" (ngModelChange)="update()" [hidden]="true" type="number" tabindex="4" />
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Player (Ranked 2)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[1].person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[1].person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[1].person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Player Side</mat-label>
						<mat-select [(ngModel)]="liveEntry.detail.ranking[1].side" (ngModelChange)="update()" tabindex="5">
							<mat-option value="home">home</mat-option>
							<mat-option value="away">away</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[1].value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="4" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Unit</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[1].unit" (ngModelChange)="update()" placeholder="Unit" type="text" tabindex="4" />
					</mat-form-field>

					<input matInput [(ngModel)]="liveEntry.detail.ranking[1].rank" (ngModelChange)="update()" [hidden]="true" type="number" tabindex="4" />
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Player (Ranked 3)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[2].person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[2].person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[2].person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Player Side</mat-label>
						<mat-select [(ngModel)]="liveEntry.detail.ranking[2].side" (ngModelChange)="update()" tabindex="5">
							<mat-option value="home">home</mat-option>
							<mat-option value="away">away</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[2].value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="4" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Unit</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[2].unit" (ngModelChange)="update()" placeholder="Unit" type="text" tabindex="4" />
					</mat-form-field>

					<input matInput [(ngModel)]="liveEntry.detail.ranking[2].rank" (ngModelChange)="update()" [hidden]="true" type="number" tabindex="4" />
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'playerProgressRanking'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12" *ngIf="players.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-label>Player</mat-label>
						<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{player.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setPlayerRankingsPlayer('home', 0, 1)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 1
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 3, 1)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 1
					</button>
					<button (click)="setPlayerRankingsPlayer('home', 1, 2)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 2
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 4, 2)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 2
					</button>
					<button (click)="setPlayerRankingsPlayer('home', 2, 3)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home Position 3
					</button>
					<button (click)="setPlayerRankingsPlayer('away', 5, 3)" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away Position 3
					</button>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="col-12 col-lg-6" *ngFor="let arrayNo of [0, 1, 2, 3, 4, 5]">
			<mat-card appearance="outlined">
				<mat-card-title>Player {{arrayNo < 3 ? 'Home' : 'Away'}} (Ranked {{(arrayNo%3)+1}})</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<input matInput [(ngModel)]="liveEntry.detail.ranking[arrayNo].person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerName</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[arrayNo].person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>playerImageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[arrayNo].person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Player Side</mat-label>
						<mat-select [(ngModel)]="liveEntry.detail.ranking[arrayNo].side" (ngModelChange)="update()" tabindex="5">
							<mat-option value="home">home</mat-option>
							<mat-option value="away">away</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[arrayNo].value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="4" />
					</mat-form-field>

					<mat-form-field>
						<input matInput [(ngModel)]="liveEntry.detail.ranking[arrayNo].rank" (ngModelChange)="update()" placeholder="Rank" type="number" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'clubProgressRanking'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Metric</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.metric" (ngModelChange)="update()" placeholder="Metric" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12 col-lg-6">
			<mat-card appearance="outlined">
				<mat-card-title>Home</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>RelativeValue</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.home.relativeValue" (ngModelChange)="update()" placeholder="RelativeValue" type="number" tabindex="3" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12 col-lg-6">
			<mat-card appearance="outlined">
				<mat-card-title>Away</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>Value</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>RelativeValue</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.away.relativeValue" (ngModelChange)="update()" placeholder="RelativeValue" type="number" tabindex="3" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'clubMetricProgression'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-12 full-width">
			<button (click)="addClubMetricProgression();update();" mat-raised-button>
				<mat-icon>play_for_work</mat-icon>
				Add progression entry
			</button>
		</div>
		<div class="col-12 col-lg-6" *ngFor="let metricProgression of liveEntry.detail.progression;let mIndex=index">
			<mat-card appearance="outlined">
				<mat-card-title>{{mIndex + 1}}</mat-card-title>
				<mat-card-content>
					<div class="full-width">
						<mat-form-field>
							<mat-label>Value</mat-label>
							<input matInput [(ngModel)]="metricProgression.value" (ngModelChange)="update()" placeholder="Value" type="number" tabindex="3" />
						</mat-form-field>

						<mat-form-field>
							<mat-label>Unit</mat-label>
							<input matInput [(ngModel)]="metricProgression.unit" (ngModelChange)="update()" placeholder="Unit" type="text" tabindex="3" />
						</mat-form-field>
					</div>
					<div class="full-width">
						<mat-form-field>
							<mat-label>Playtime</mat-label>
							<input matInput [(ngModel)]="metricProgression.playtime.minute" (ngModelChange)="update();" placeholder="Playtime" type="number" tabindex="3" />
						</mat-form-field>
						<mat-form-field>
							<mat-label>InjuryTime</mat-label>
							<input matInput [(ngModel)]="metricProgression.playtime.injuryTime" (ngModelChange)="update();" placeholder="Injurytime" type="number" tabindex="3" />
						</mat-form-field>
					</div>
					<div class="full-width">
						<mat-form-field>
							<mat-label>MatchSection</mat-label>
							<mat-select [(ngModel)]="metricProgression.matchSection" (ngModelChange)="update()" tabindex="3">
								<mat-option value="">use entered playtime</mat-option>
								<mat-option value="start_firstHalf">start_firstHalf</mat-option>
								<mat-option value="end_firstHalf">end_firstHalf</mat-option>
								<mat-option value="start_secondHalf">start_secondHalf</mat-option>
								<mat-option value="end_secondHalf">end_secondHalf</mat-option>
								<mat-option value="start_firstHalfExtra">start_firstHalfExtra</mat-option>
								<mat-option value="end_firstHalfExtra">end_firstHalfExtra</mat-option>
								<mat-option value="start_secondHalfExtra">start_secondHalfExtra</mat-option>
								<mat-option value="end_secondHalfExtra">end_secondHalfExtra (use start_penalty or finalWhistle instead)</mat-option>
								<mat-option value="start_penalty">start_penalty</mat-option>
								<mat-option value="end_penalty">end_penalty (use finalWhistle instead)</mat-option>
								<mat-option value="finalWhistle">finalWhistle</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="full-width">
						<button (click)="removeClubMetricProgression(mIndex);update();" mat-raised-button>
							<mat-icon>delete</mat-icon>
							remove
						</button>
						<button *ngIf="mIndex > 0" (click)="moveClubMetricProgression(mIndex, mIndex - 1);update();" mat-raised-button>
							<mat-icon>arrow_back</mat-icon>
							move left
						</button>
						<button *ngIf="mIndex < liveEntry.detail.progression.length - 1" (click)="moveClubMetricProgression(mIndex, mIndex + 1);update();" mat-raised-button>
							<mat-icon>arrow_forward</mat-icon>
							move right
						</button>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'pressureHandling'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="Title" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>escapeRate</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.escapeRate" (ngModelChange)="update()" placeholder="escapeRate" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6">
			<mat-form-field>
				<mat-label>pressureCount</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.pressureCount" (ngModelChange)="update()" placeholder="pressureCount" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12" *ngIf="players.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-label>Player</mat-label>
						<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{player.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setPlayerOnPersonProp()" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Use Player data
					</button>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Player ({{side}})</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Player name</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>imageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'keeperEfficiency'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="keeperEfficiency" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6">
			<mat-form-field class="full-width">
				<mat-label>savedShots</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.savedShots" (ngModelChange)="update()" placeholder="savedShots" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6">
			<mat-form-field class="full-width">
				<mat-label>xSaves</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.xSaves" (ngModelChange)="update()" placeholder="xSaves" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-12" *ngIf="players.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-label>Player</mat-label>
						<mat-select placeholder="Player" [(ngModel)]="selectedPlayer">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="playerSearch" (ngModelChange)="filterPlayers()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let player of filteredPlayers;trackBy: trackByPlayerId" [value]="player">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{player.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setPlayerOnPersonProp()" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Use Player data
					</button>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Player ({{side}})</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryObjectId</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.dflDatalibraryObjectId" (ngModelChange)="update()" placeholder="dflDatalibraryObjectId" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Player name</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.name" (ngModelChange)="update()" placeholder="playerName" tabindex="3" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>imageUrl</mat-label>
						<input matInput [(ngModel)]="liveEntry.detail.person.imageUrl" (ngModelChange)="update()" placeholder="playerImageUrl" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row" *ngIf="liveEntry.detail.type === 'ballRecoveryTime'">
		<div class="col-12 col-md-12">
			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="liveEntry.detail.title" (ngModelChange)="update()" placeholder="ballRecoveryTime" tabindex="3" />
			</mat-form-field>
		</div>

		<div class="col-6" *ngFor="let brtIndex of [0, 1]">
			<mat-card appearance="outlined">
				<mat-card-title>ballRecoveryTime ({{brtIndex}})</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>Label</mat-label>
						<mat-select [(ngModel)]="liveEntry.detail.times[brtIndex].label" (ngModelChange)="update()" tabindex="3">
							<ng-container *ngIf="side === 'none'">
								<mat-option value="">Select Team Side</mat-option>
								<mat-option value="home">home</mat-option>
								<mat-option value="away">away</mat-option>
							</ng-container>
							<ng-container *ngIf="side !== 'none'">
								<mat-option value="">Select Comparison Timeframe</mat-option>
								<mat-option value="first_half">first_half</mat-option>
								<mat-option value="second_half">second_half</mat-option>
								<mat-option value="season">season</mat-option>
								<mat-option value="today">today</mat-option>
							</ng-container>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Value</mat-label>
						<input type="number" matInput [(ngModel)]="liveEntry.detail.times[brtIndex].value" (ngModelChange)="update()" placeholder="value" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>

<div class="preview">
	<div class="liveticker-entryContainer">
		<livetickerevent-stats class="event side_{{side}}" [liveEntry]="previewEntry" [clubData]="clubData"></livetickerevent-stats>
	</div>
</div>
