import { NgModule } from '@angular/core';
import { BarChartModule } from './bar-chart/bar-chart.module';
import { BarProgressChartModule } from './bar-progress-chart/bar-progress-chart.module';
import { PieChartModule } from './pie-chart/pie-chart.module';
import { StackedcolumnChartModule } from './stackedcolumn-chart/stackedcolumn-chart.module';
import { TextChartModule } from './text-chart/text-chart.module';

@NgModule({
	imports: [BarChartModule, PieChartModule, StackedcolumnChartModule, TextChartModule, BarProgressChartModule],
	exports: [BarChartModule, PieChartModule, StackedcolumnChartModule, TextChartModule, BarProgressChartModule],
	declarations: [],
	providers: []
})
export class StatsChartsModule {}
