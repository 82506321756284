<div class="sidenav-container-wrapper">
	<button class="toggle-menu" [class.is-flipped]="drawer.opened" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
		<mat-icon aria-label="Side nav toggle icon">chevron_right</mat-icon>
	</button>
	<mat-sidenav-container class="sidenav-container">
		<mat-sidenav #drawer class="sidenav" fixedInViewPort [fixedTopGap]="0" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="'over'">
			<overlay-scrollbars [defer]="true" [options]="{ scrollbars: { autoHide: 'leave', theme: 'os-theme-light' } }">
				<div class="item logo">
					<a routerLink="/">
						<img src="/assets/logo/bl_streaker.png" alt="Back to BundesStreaker home" title="Back to BundesStreaker home" />
					</a>
				</div>
				<div class="item profile">
					<app-profile></app-profile>
				</div>

				<ul class="navigation" *ngIf="loggedIn === true">
					<li>
						<a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" matRipple>
							<mat-icon>home</mat-icon>
							<span class="label">Homepage</span>
						</a>
					</li>
					<li>
						<a routerLink="/matches/de" routerLinkActive="active" matRipple>
							<mat-icon>sports_soccer</mat-icon>
							<span class="label">Matches DE</span>
						</a>
						<div class="submenu">
							<a routerLink="/matches/de/DFL-COM-000001" routerLinkActive="active" matRipple>Bundesliga</a>
							<a routerLink="/matches/de/DFL-COM-000002" routerLinkActive="active" matRipple>2 Bundesliga</a>
						</div>
					</li>
					<li>
						<a routerLink="/matches/en" routerLinkActive="active" matRipple>
							<mat-icon>sports_soccer</mat-icon>
							<span class="label">Matches EN</span>
						</a>
						<div class="submenu">
							<a routerLink="/matches/en/DFL-COM-000001" routerLinkActive="active" matRipple>Bundesliga</a>
							<a routerLink="/matches/en/DFL-COM-000002" routerLinkActive="active" matRipple>2 Bundesliga</a>
						</div>
					</li>

					<li>
						<a routerLink="/custom-matches" routerLinkActive="active" matRipple>
							<mat-icon>description</mat-icon>
							<span class="label">Custom Matches</span>
						</a>
					</li>

					<li>
						<a routerLink="/tools" routerLinkActive="active" matRipple>
							<mat-icon>liquor</mat-icon>
							<span class="label">Tools</span>
						</a>

						<div class="submenu">
							<a routerLink="/tools/livestreams" routerLinkActive="active" matRipple>Livestream manager</a>
						</div>
						<div class="submenu">
							<a routerLink="/tools/welcome" routerLinkActive="active" matRipple>Welcome text generator</a>
						</div>
					</li>

					<li>
						<a routerLink="/media" routerLinkActive="active" matRipple>
							<mat-icon>collections_outlined</mat-icon>
							<span class="label">Media Library</span>
						</a>

						<div class="submenu">
							<a routerLink="/media/upload" routerLinkActive="active" matRipple>Upload</a>
						</div>
					</li>
					<li>
						<a routerLink="/videos" routerLinkActive="active" matRipple>
							<mat-icon>movie</mat-icon>
							<span class="label">Videos Library</span>
						</a>

						<!--<div class="submenu">
            <a routerLink="/videos/upload" routerLinkActive="active" matRipple data-test-id="videosUpload">Upload</a>
          </div>-->
					</li>

					<li class="divider"></li>
					<li class="spacer"></li>
				</ul>
				<div class="item stage">
					<div class="stage_text stage_{{stage}}">THIS. IS. {{ stage.toUpperCase() }}.</div>
				</div>
				<div class="item theme">
					<theme-switcher></theme-switcher>
				</div>
			</overlay-scrollbars>
		</mat-sidenav>

		<mat-sidenav-content>
			<div class="content">
				<mat-progress-bar *ngIf="isLoading" [ngClass]="{'d-none': (isLoading | async) === false}" mode="query"></mat-progress-bar>
				<router-outlet></router-outlet>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
