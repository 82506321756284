import { NgModule } from '@angular/core';
import { LivetickerEventModule } from '../../organisms/liveticker/components/liveticker-event/liveticker-event.module';
import { BlRapidTickerComposerModule } from '../bl-liveticker-rapid-composer/composer/bl-rapid-ticker-composer.module';
import { BlLivetickereventDialogsEditComponent, BlLivetickereventDialogsDeleteComponent, BlLivetickereventDialogsCreateComponent } from './bl-livetickerevent-dialogs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlLivetickereventDialogsEditComponent, BlLivetickereventDialogsDeleteComponent, BlLivetickereventDialogsCreateComponent],
	exports: [BlLivetickereventDialogsEditComponent, BlLivetickereventDialogsDeleteComponent, BlLivetickereventDialogsCreateComponent],
	imports: [CommonsModule, MatIconModule, MatButtonModule, MatDialogModule, BlRapidTickerComposerModule, LivetickerEventModule]
})
export class BlLivetickereventDialogsModule {}
