import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Asset, assetDimensions, AssetDimensions, AssetServicePipe, BundesmasterContentApiService, BundesmasterStoriesApiService, OpenSearchMatchStory, OpenSearchMatchStorySlides, OpenSearchStorySlides, SLIDE_TYPES } from '@nx-bundesliga/bundesmaster/core';
import { DialogSelectMediaComponent, DialogSelectMediaData } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { DialogSelectVideoComponent } from '@nx-bundesliga/bundesmaster/feature-video-library';
import { DialogCtaEditComponent } from '@nx-bundesliga/bundesmaster/stories/ui-story-slide-deck';
import { BundesmasterUiImageCropperDialogComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper-dialog';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogEntryEditorialImage, LiveBlogEntryEditorialVideo, LiveBlogMatch } from '@nx-bundesliga/models';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';
import { addCroppingParametersToImageUrl, cropImageToRatio } from '@nx-bundesliga/shared/util/functions';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { getSide } from '../../../../framework/common/functions/liveticker.functions';
import { LoadingService } from '../../../../services/loading/loading.service';

interface MatchStoryFormText {
	slideType: FormControl<SLIDE_TYPES>;
	slideId: FormControl<string>;
	headline: FormControl<string>;
	text: FormControl<string>;
	image: FormGroup<{
		url: FormControl<string>;
		copyright: FormControl<string>;
	}>;
	playtime: FormGroup<{
		minute: FormControl<number>;
		injuryTime: FormControl<number>;
	}>;
	cta: FormGroup<any>;
}
interface MatchStoryFormVideo {
	slideType: FormControl<SLIDE_TYPES>;
	slideId: FormControl<string>;
	headline: FormControl<string>;
	text: FormControl<string>;
	video: FormGroup<{
		vid: FormControl<string>;
		duration: FormControl<number>;
	}>;
	playtime: FormGroup<{
		minute: FormControl<number>;
		injuryTime: FormControl<number>;
	}>;
	cta: FormGroup<any>;
}
interface MatchStoryFormQuote {
	slideType: FormControl<SLIDE_TYPES>;
	slideId: FormControl<string>;
	author: FormControl<string>;
	quote: FormControl<string>;
	image: FormGroup<{
		url: FormControl<string>;
		copyright: FormControl<string>;
	}>;
	playtime: FormGroup<{
		minute: FormControl<number>;
		injuryTime: FormControl<number>;
	}>;
	cta: FormGroup<any>;
}

@Component({
	selector: 'story-slide-create-basic-slide',
	templateUrl: 'bl-story-dialog-create-basic-slide.component.html',
	styleUrls: ['./bl-story-dialog-create-basic-slide.component.scss']
})
export class CreateBasicSlideDialogComponent {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	@Input() language: string;
	@Input() postId: string;
	@Input() clubData: LiveBlogClubs;
	@Input() customMatch = false;
	public processing = true;
	public storyLinkContender = '';
	public slideTypes = SLIDE_TYPES;
	public storyId: string;
	public story$: Observable<OpenSearchMatchStory>;
	public storySlide$: Observable<OpenSearchMatchStorySlides | string>;

	//public slide = this.formBuilder.group<FormGroup<{ type: FormControl<string>; author: FormControl<string>; quote: FormControl<string> }> | FormGroup<{ type: FormControl<string>; headline: FormControl<string>; text: FormControl<string> }>>;
	public slide: FormGroup<MatchStoryFormText> | FormGroup<MatchStoryFormQuote> | FormGroup<MatchStoryFormVideo>; // = this.formBuilder.group<MatchStoryFormText | MatchStoryFormVideo | MatchStoryFormQuote>(null);
	constructor(
		private readonly dialog: MatDialog,
		private readonly assetPipe: AssetServicePipe,
		private toastr: ToastrService,
		public http: HttpClient,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public loading: LoadingService,
		public dialogRef: MatDialogRef<CreateBasicSlideDialogComponent>,
		private readonly storyApi: BundesmasterStoriesApiService,
		private readonly contentApi: BundesmasterContentApiService,
		private readonly formBuilder: FormBuilder
	) {
		if (this.data) {
			this.processing = true;

			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.postId = this.data.hasOwnProperty('postId') ? this.data.postId : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
			this.customMatch = this.data.hasOwnProperty('customMatch') ? this.data.customMatch : null;
			this.storyId = `${this.match?.dflDatalibraryMatchId?.toLowerCase()?.replace('dfl-mat-', '') ?? null}-${this.language}`;

			const postId = this.liveEntry?.key;
			const liveEntry = this.liveEntry?.value;
			const entryType = liveEntry?.entryType ?? 'freetext';

			this.storyLinkContender = `https://bundesmaster.${environment.stageDomain}.com/story/edit/${this.storyId}`;

			this.story$ = this.storyApi.getStory<OpenSearchMatchStory>(this.storyId).pipe(share());

			this.storySlide$ = this.story$.pipe(
				map((s: OpenSearchMatchStory) => (s?.slides as OpenSearchMatchStorySlides[])?.filter((s) => s.slideId === postId)?.[0] ?? 'noslide'),
				catchError(() => of('nostory'))
			);

			switch (entryType) {
				case 'freetext':
				case 'stats':
				case 'embed':
				case 'image': {
					const imageUrl = (liveEntry as LiveBlogEntryEditorialImage)?.detail?.url ?? '';
					if (imageUrl) {
						this.contentApi.getAssetByUrl(imageUrl).subscribe(
							(imageInfo) => {
								this.patchForm({
									slideType: SLIDE_TYPES.TEXT,
									slideId: postId,
									headline: liveEntry?.detail?.headline ?? '',
									text: liveEntry?.detail?.text ?? '',
									image: {
										url: addCroppingParametersToImageUrl(this.assetPipe.transform(imageInfo.file), cropImageToRatio(imageInfo.image.width, imageInfo.image.height, assetDimensions.portrait.ratio)),
										copyright: (liveEntry as LiveBlogEntryEditorialImage)?.detail?.copyright ?? ''
									},
									playtime: {
										minute: liveEntry?.playtime?.minute ?? 0,
										injuryTime: liveEntry?.playtime?.injuryTime ?? 0
									}
								});
							},
							() => {
								console.error('Could not get thumbnail imageinfo');
								this.toastr.error('Story Slide image could not be processed correctly. Please use the cropping screen for the slide image to properly select a 9:16 section!', 'Manual intervention required');
								this.patchForm({
									slideType: SLIDE_TYPES.TEXT,
									slideId: postId,
									headline: liveEntry?.detail?.headline ?? '',
									text: liveEntry?.detail?.text ?? '',
									image: {
										url: imageUrl ?? '',
										copyright: (liveEntry as LiveBlogEntryEditorialImage)?.detail?.copyright ?? ''
									},
									playtime: {
										minute: liveEntry?.playtime?.minute ?? 0,
										injuryTime: liveEntry?.playtime?.injuryTime ?? 0
									}
								});
							}
						);
					} else {
						this.patchForm({
							slideType: SLIDE_TYPES.TEXT,
							slideId: postId,
							headline: liveEntry?.detail?.headline ?? '',
							text: liveEntry?.detail?.text ?? '',
							image: {
								url: '',
								copyright: (liveEntry as LiveBlogEntryEditorialImage)?.detail?.copyright ?? ''
							},
							playtime: {
								minute: liveEntry?.playtime?.minute ?? 0,
								injuryTime: liveEntry?.playtime?.injuryTime ?? 0
							}
						});
					}
					break;
				}
				case 'video': {
					this.patchForm({
						slideType: SLIDE_TYPES.VIDEO,
						slideId: postId,
						headline: liveEntry?.detail?.headline ?? '',
						text: liveEntry?.detail?.text ?? '',
						video: {
							vid: (liveEntry as LiveBlogEntryEditorialVideo)?.detail?.videoId ?? '',
							duration: (liveEntry as LiveBlogEntryEditorialVideo)?.detail?.duration ?? 0
						},
						playtime: {
							minute: liveEntry?.playtime?.minute,
							injuryTime: liveEntry?.playtime?.injuryTime
						}
					});
					break;
				}
			}
		}
	}

	getUnifiedSide(entry: LiveBlogAutoEvents, prefix = 'side_') {
		return getSide(entry, prefix);
	}

	public createSlideForm(slide: Partial<OpenSearchStorySlides>): FormGroup<MatchStoryFormText> | FormGroup<MatchStoryFormQuote> | FormGroup<MatchStoryFormVideo> {
		if (slide?.slideType === SLIDE_TYPES.TEXT) {
			return this.formBuilder.group<MatchStoryFormText>({
				slideType: this.formBuilder.control<SLIDE_TYPES>(slide?.slideType, [Validators.required]),
				slideId: this.formBuilder.control<string>({ value: slide?.slideId, disabled: true }, [Validators.required]),
				headline: this.formBuilder.control<string>(slide?.headline, [Validators.required]),
				text: this.formBuilder.control<string>(slide?.text, [Validators.required]),
				image: this.formBuilder.group({
					url: this.formBuilder.control<string>(slide?.image?.url, [Validators.required]),
					copyright: this.formBuilder.control<string>(slide?.image?.copyright, [Validators.required])
				}),
				playtime: this.formBuilder.group({
					minute: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.minute ?? 0, []),
					injuryTime: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.injuryTime ?? 0, [])
				}),
				cta: this.storyApi.createSlideCtaFormGroup(slide?.cta)
			});
		} else if (slide?.slideType === SLIDE_TYPES.QUOTE) {
			return this.formBuilder.group<MatchStoryFormQuote>({
				slideType: this.formBuilder.control<SLIDE_TYPES>(slide?.slideType, [Validators.required]),
				slideId: this.formBuilder.control<string>({ value: slide?.slideId, disabled: true }, [Validators.required]),
				author: this.formBuilder.control<string>(slide?.author, [Validators.required]),
				quote: this.formBuilder.control<string>(slide?.quote, [Validators.required]),
				image: this.formBuilder.group({
					url: this.formBuilder.control<string>(slide?.image?.url, [Validators.required]),
					copyright: this.formBuilder.control<string>(slide?.image?.copyright, [Validators.required])
				}),
				playtime: this.formBuilder.group({
					minute: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.minute ?? 0, []),
					injuryTime: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.injuryTime ?? 0, [])
				}),
				cta: this.storyApi.createSlideCtaFormGroup(slide?.cta)
			});
		} else if (slide?.slideType === SLIDE_TYPES.VIDEO) {
			return this.formBuilder.group<MatchStoryFormVideo>({
				slideType: this.formBuilder.control<SLIDE_TYPES>(slide?.slideType, [Validators.required]),
				slideId: this.formBuilder.control<string>({ value: slide?.slideId, disabled: true }, [Validators.required]),
				headline: this.formBuilder.control<string>(slide?.headline, []),
				text: this.formBuilder.control<string>(slide?.text, []),
				video: this.formBuilder.group({
					vid: this.formBuilder.control<string>(slide?.video?.vid, [Validators.required]),
					duration: this.formBuilder.control<number>(slide?.video?.duration, [])
				}),
				playtime: this.formBuilder.group({
					minute: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.minute ?? 0, []),
					injuryTime: this.formBuilder.control<number>((slide as OpenSearchMatchStorySlides)?.playtime?.injuryTime ?? 0, [])
				}),
				cta: this.storyApi.createSlideCtaFormGroup(slide?.cta)
			});
		}
	}

	public patchForm(slide: Partial<OpenSearchStorySlides>) {
		this.slide = this.createSlideForm(slide);
		this.processing = false;
		this.slide.updateValueAndValidity();
		this.slide.markAsTouched();
	}

	editSlideCta() {
		console.log('editSlideCta', this.slide.value);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogCtaEditComponent, {
					data: {
						cta: this.slide.value?.cta ?? null,
						storyType: 'match_story'
					},
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((cta) => {
					console.log('editSlideCta.afterClosed', cta);
					if (cta || cta === null) {
						const slideForm = this.slide as FormGroup;
						const ctaForm = this.storyApi.createSlideCtaFormGroup(cta);
						slideForm.removeControl('cta');
						slideForm.addControl('cta', ctaForm);
						this.slide?.markAllAsTouched();
						this.slide?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(true);
					}
					resolve(false);
				});
		});
	}

	selectVideo() {
		this.dialog
			.open(DialogSelectVideoComponent, {
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '90%'
			})
			.afterClosed()
			.subscribe((response: { video: jwp.Media }) => {
				if (response && response.video) {
					this.slide.patchValue({
						video: {
							vid: response.video?.id ?? '',
							duration: Math.round(response?.video?.duration ?? 0)
						}
					});
					this.slide.markAsTouched();
				}
				console.log('video selected', response);
			});
	}

	editSlideImage() {
		console.log('editSlideImage', this.slide.value);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogSelectMediaComponent, {
					data: { dimensions: ['portrait'], selectedDimensions: ['portrait'] } as DialogSelectMediaData,
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((response: { asset: Asset; dimensions: AssetDimensions[] }) => {
					if (response && response.asset && response?.dimensions?.length > 0) {
						const imageUrl = addCroppingParametersToImageUrl(this.assetPipe.transform(response.asset.file), cropImageToRatio(response.asset.image.width, response.asset.image.height, assetDimensions.portrait.ratio));
						console.log('selected image', imageUrl);
						this.slide.patchValue({
							image: {
								url: imageUrl,
								copyright: response.asset.copyright
							}
						});
						this.slide.markAsTouched();
						resolve(true);
					}
				});
		});
	}

	cropSlideImage() {
		console.log('editSlideImage', this.slide.value['image']['url']);
		return new Promise((resolve) => {
			this.dialog
				.open(BundesmasterUiImageCropperDialogComponent, {
					data: { imageUrl: this.slide.value['image']['url'], dimension: 'portrait', targetAspectRatio: assetDimensions.portrait.ratio },
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((imageUrl) => {
					if (imageUrl) {
						this.slide.patchValue({
							image: {
								url: imageUrl
							}
						});
						this.slide.markAsTouched();
						resolve(true);
					}
				});
		});
	}

	public onEditClick(): void {
		this.slide.markAllAsTouched();
		if (this.slide.valid) {
			this.loading.setLoading(true);
			this.processing = true;
			const url = `https://stories.api.bundesmaster.${environment.stageDomain}.com/bundesstreaker/matchstory/${this.storyId}/addSlides`;

			const payload = {
				slides: [this.slide.getRawValue()],
				competitionId: this.match.dflDatalibraryCompetitionId,
				matchId: this.match.dflDatalibraryMatchId,
				matchdayId: this.match.dflDatalibraryMatchdayId,
				seasonId: this.match.dflDatalibrarySeasonId,
				matchday: this.match.matchday,
				language: this.language,
				homeClubId: this.clubData.home.dflDatalibraryClubId,
				homeClubName: this.clubData.home.nameFull,
				awayClubId: this.clubData.away.dflDatalibraryClubId,
				awayClubName: this.clubData.away.nameFull
			};

			console.log(`Sending StorySlide to API: `, url);
			console.log('StorySlideEvent', payload);

			this.http.put(url, payload, { headers: { 'Accept-Language': this.language } }).subscribe(
				(success) => {
					this.toastr.success('Story Slide has successfully been submitted!', 'Published');
					this.loading.setLoading(false);
					this.dialogRef.close();
				},
				(error) => {
					const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
					this.loading.setLoading(false);
					this.toastr.error(message, 'Error');
					this.processing = false;
				}
			);
		}
	}

	public onDeleteClick(): void {
		if (confirm(`Delete this slide from story?`)) {
			this.loading.setLoading(true);
			this.processing = true;
			const url = `https://stories.api.bundesmaster.${environment.stageDomain}.com/bundesstreaker/matchstory/${this.storyId}/deleteSlides/${this.postId}`;

			console.log(`Deleting slide from Story to API: `, url);

			this.http.delete(url).subscribe(
				(success) => {
					this.toastr.success('Story Slide has successfully been deleted!', 'Deleted');
					this.loading.setLoading(false);
					this.dialogRef.close();
				},
				(error) => {
					const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
					this.loading.setLoading(false);
					this.toastr.error(message, 'Error');
					this.processing = false;
					// this.dialogRef.close();
				}
			);
		}
	}
}
