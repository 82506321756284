import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
	@Input() title: string;
	@Input() leftValue: number;
	@Input() rightValue: number;
	@Input() leftColor: string;
	@Input() leftUnit: string;
	@Input() rightColor: string;
	@Input() leftTextColor: string;
	@Input() rightTextColor: string;
	@Input() rightUnit: string;
	@Input() horizontal = true;
	@Input() chips = false;
	@Input() single = false;
	public leftWidth = '0px';
	public rightWidth = '0px';

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('leftValue') || changes.hasOwnProperty('rightValue')) {
			if (this.chips === true) {
				// chips have fixed widths
				this.leftWidth = `34px`;
				this.rightWidth = `34px`;
				if (this.single) {
					// single chips have different fixed widths
					this.leftWidth = `116px`;
					this.rightWidth = `116px`;
				}
			} else {
				if (this.horizontal === true) {
					// horizontal mode has min width of 48px. Added gutters of 2x2px will result in 50px gutter for 0 value bars
					const totalValue = this.leftValue + this.rightValue;
					const leftPercentageNumber = totalValue === 0 ? 50 : (this.leftValue / totalValue) * 100;
					const rightPercentageNumber = totalValue === 0 ? 50 : (this.rightValue / totalValue) * 100;
					const leftGutterValue = (leftPercentageNumber > 0 ? 2 : 0) + (leftPercentageNumber === 100 ? 50 : 0);
					const rightGutterValue = (rightPercentageNumber > 0 ? 2 : 0) + (rightPercentageNumber === 100 ? 50 : 0);
					this.leftWidth = `calc(${leftPercentageNumber}% - ${leftGutterValue}px)`;
					this.rightWidth = `calc(${rightPercentageNumber}% - ${rightGutterValue}px)`;
				}
				if (this.horizontal === false) {
					// horizontal mode will display the highest value in full width while the other value is displayed relatively
					const leftPercentageNumber = this.rightValue === 0 ? 100 : Math.min((this.leftValue / this.rightValue) * 100, 100);
					const rightPercentageNumber = this.leftValue === 0 ? 100 : Math.min((this.rightValue / this.leftValue) * 100, 100);
					this.leftWidth = `${leftPercentageNumber}%`;
					this.rightWidth = `${rightPercentageNumber}%`;
				}
			}
		}
	}
}
