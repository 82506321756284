import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSyncReducer, reducers } from './+state/core.reducers';
import { BundesmasterEnvironment } from './bundesmaster-environment';
import { PipesModule } from './pipes/pipes.module';
import { BundesligaHttpClientService } from './services/bundesliga-http-client/bundesliga-http-client.service';
import { BundesligaWappApiService } from './services/bundesliga-wapp-api/bundesliga-wapp-api.service';
import { BundesmasterEpgApiService } from './services/bundesmaster-epg-api';
import { BundesmasterStoriesApiService } from './services/bundesmaster-stories-api/bundesmaster-stories-api.service';
export const CORE_ENVIRONMENT = new InjectionToken<BundesmasterEnvironment>('CORE_ENVIRONMENT');

@NgModule({
	declarations: [],
	imports: [CommonModule, HttpClientModule, PipesModule, HttpClientJsonpModule, StoreModule.forRoot(reducers, { metaReducers: [localStorageSyncReducer] }), EffectsModule.forRoot([]), StoreDevtoolsModule.instrument({ connectInZone: true })],
	exports: [HttpClientModule]
})
export class BundesmasterCoreModule {
	static forRoot(environment: BundesmasterEnvironment) {
		return {
			ngModule: BundesmasterCoreModule,
			providers: [
				BundesligaHttpClientService,
				BundesligaWappApiService,
				BundesmasterEpgApiService,
				BundesmasterStoriesApiService,
				{
					provide: CORE_ENVIRONMENT,
					useValue: environment
				}
			]
		};
	}
}
