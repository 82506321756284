import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { StatsPlayercomparisonCardComponent } from './component/stats-playercomparison-card/stats-playercomparison-card.component';

@NgModule({
	imports: [SharedModule, CommonsLibModule],
	exports: [StatsPlayercomparisonCardComponent],
	declarations: [StatsPlayercomparisonCardComponent],
	providers: []
})
export class StatsPlayercomparisonCardModule {}
