import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { BlStsFeedControlsComponent } from './bl-sts-feed-controls.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [BlStsFeedControlsComponent],
	exports: [BlStsFeedControlsComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, MatDialogModule, CommonsLibModule, MatFormFieldModule, MatInputModule]
})
export class BlStsFeedControlsModule {}
