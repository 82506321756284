import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivetickerSides } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoadingService } from '../loading/loading.service';

@Injectable({
	providedIn: 'root'
})
export class StsFeedService {
	constructor(private http: HttpClient, private toastr: ToastrService, private loading: LoadingService) {}

	public customIngest(payload: string) {
		this.loading.setLoading(true);
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/matchevents/custom`;

		console.log(`Sending custom matchevents XML to API: `, url, payload);
		return this.http
			.post(url, payload)
			.pipe(
				tap(() => {
					this.loading.setLoading(false);
				})
			)
			.subscribe(
				(resp: any) => {
					const method = resp.message === 'success' ? 'success' : 'warn';
					this.toastr[method](resp.message, `XML successfully submitted`);
				},
				(err) => {
					this.toastr.error('Error', `Failed to sumbit XML: ${JSON.stringify(err)}`);
					this.loading.setLoading(false);
					console.error('Error submitting XML: ', err);
				},
				() => {
					this.loading.setLoading(false);
				}
			);
	}

	public ingestMatch(matchId: string) {
		this.loading.setLoading(true);
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/matchevents/ingest/${matchId}`;

		console.log(`Sending matchevents XML to API: `, url, matchId);
		return this.http
			.get(url)
			.pipe(
				tap(() => {
					this.loading.setLoading(false);
				})
			)
			.subscribe(
				(resp: any) => {
					const method = resp.message === 'success' ? 'success' : 'warn';
					this.toastr[method](resp.message, `Match XML successfully submitted`);
				},
				(err) => {
					this.toastr.error('Error', `Failed to sumbit Match XML: ${JSON.stringify(err)}`);
					this.loading.setLoading(false);
					console.error('Error submitting XML: ', err);
				},
				() => {
					this.loading.setLoading(false);
				}
			);
	}
}
