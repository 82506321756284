<h2 mat-dialog-title>Crosspost Liveticker entry</h2>
<div mat-dialog-content class="mat-typography action-crosspost">
	<h3>Id: {{postId}}</h3>

	<div class="container">
		<div class="row">
			<div class="col-12 liveticker-event">
				<liveticker-event [liveEntry]="liveEntry.value" [teams]="clubData" [match]="match" [class]="liveEntry.value.entryType"></liveticker-event>
			</div>
		</div>
		<div class="row" *ngIf="liveEntry?.value?.detail?.headline || liveEntry?.value?.detail?.text">
			<div class="col-12">
				<button mat-raised-button color="warn">
					Headline and/or Description are available for this entry.
					<br />
					Crossposted entries in foreign languages will be stripped of these texts!
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-5 offset-2 font-weight-bold">Language</div>
			<div class="col-1 font-weight-bold">exists?</div>
			<div class="col-4 font-weight-bold">Description</div>
		</div>
		<div class="row" *ngFor="let lang of languages">
			<div class="col-5 offset-2">
				<mat-checkbox color="primary" [disabled]="lang === language || !possibleLanguages.includes(lang)" [(ngModel)]="selectedLanguages[lang]" [ngModelOptions]="{standalone: true}">{{lang}}</mat-checkbox>
			</div>
			<div class="col-1 d-flex align-items-center">
				<ng-container *ngIf="lang !== language">
					<mat-icon *ngIf="!loadingCrosspost && crossPosts[lang] === true" style="color: limegreen">check</mat-icon>
					<mat-icon *ngIf="!loadingCrosspost && crossPosts[lang] === false" style="color: red">warning</mat-icon>
					<mat-spinner *ngIf="loadingCrosspost" diameter="24"></mat-spinner>
				</ng-container>
			</div>
			<div class="col-4 d-flex align-items-center">
				<ng-container *ngIf="lang !== language">
					<ng-container *ngIf="!possibleLanguages.includes(lang)"> <mat-icon style="color: red">warning</mat-icon> <small>Auto translate active</small> </ng-container>
				</ng-container>
				<ng-container *ngIf="lang === language"> <mat-icon style="color: red">warning</mat-icon> <small>Current language</small> </ng-container>
			</div>
		</div>
	</div>
</div>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
	<button mat-button [disabled]="processing" (click)="onCrosspostClick()">Crosspost</button>
</mat-dialog-actions>
