import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { LivetickerSides } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LIVESTREAM_MODAL_DATA, LIVESTREAM_MODALS, LivestreamData, LivestreamModalName } from './livestream.tokens';
import { LivestreamHlsComponent } from './livestream-hls/livestream-hls.component';

@Injectable()
export class LivestreamService {
	private styleSheetElement;

	constructor(private http: HttpClient, private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver, private applicationRef: ApplicationRef) {}

	public setLivestream(matchId: string, hls: string): Observable<any> {
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/livestream/${matchId.toUpperCase()}`;
		const body = { hls: hls };
		return this.http.put(url, body);
	}

	public getLivestream(matchId: string): Observable<{ hls?: string; MatchId?: string }> {
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/livestream/${matchId.toUpperCase()}`;
		return this.http.get(url);
	}

	public deleteLivestream(matchId: string): Observable<any> {
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/livestream/${matchId.toUpperCase()}`;
		return this.http.delete(url);
	}

	public triggerHLSLivestream(livestreamData: LivestreamData) {
		const livestreamPopoutDetails = LIVESTREAM_MODALS[LivestreamModalName.livestreamHLS];

		if (!this.isPopoutWindowOpen()) {
			this.openPopoutModal(livestreamData);
		} else {
			const livestreamOpen = LIVESTREAM_MODALS['componentInstance'].name === name;
			// When popout modal is open and there is no change in data, focus on popout modal
			if (livestreamOpen) {
				this.focusPopoutWindow();
			} else {
				LIVESTREAM_MODALS['outlet'].detach();
				const injector = this.createInjector(livestreamData);
				const componentInstance = this.attachLivestreamHls(LIVESTREAM_MODALS['outlet'], injector);
				LIVESTREAM_MODALS['componentInstance'] = componentInstance;
				this.focusPopoutWindow();
			}
		}
	}

	openPopoutModal(data) {
		const windowInstance = this.openOnce('assets/modal/livestream.html', 'MODAL_POPOUT');

		// Wait for window instance to be created
		setTimeout(() => {
			this.createCDKPortal(data, windowInstance);
		}, 1000);
	}

	openOnce(url, target) {
		// Open a blank "target" window
		// or get the reference to the existing "target" window
		const winRef = window.open('', target, 'popup');
		// If the "target" window was just opened, change its url
		if (winRef.location.href === 'about:blank') {
			winRef.location.href = url;
		}
		return winRef;
	}

	createCDKPortal(data, windowInstance) {
		if (windowInstance) {
			// Create a PortalOutlet with the body of the new window document
			const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
			// Copy styles from parent window
			document.querySelectorAll('style').forEach((htmlElement) => {
				windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
			});
			// Copy stylesheet link from parent window
			this.styleSheetElement = this.getStyleSheetElement();
			windowInstance.document.head.appendChild(this.styleSheetElement);

			this.styleSheetElement.onload = () => {
				// Clear popout modal content
				windowInstance.document.body.innerText = '';

				// Create an injector with modal data
				const injector = this.createInjector(data);

				// Attach the portal
				let componentInstance;
				if (data.modalName === LivestreamModalName.livestreamHLS) {
					windowInstance.document.title = `${data?.matchName} Livestream HTS`;
					componentInstance = this.attachLivestreamHls(outlet, injector);
				}

				LIVESTREAM_MODALS['windowInstance'] = windowInstance;
				LIVESTREAM_MODALS['outlet'] = outlet;
				LIVESTREAM_MODALS['componentInstance'] = componentInstance;
			};
		}
	}

	isPopoutWindowOpen() {
		return LIVESTREAM_MODALS['windowInstance'] && !LIVESTREAM_MODALS['windowInstance'].closed;
	}

	focusPopoutWindow() {
		LIVESTREAM_MODALS['windowInstance'].focus();
	}

	closePopoutModal() {
		Object.keys(LIVESTREAM_MODALS).forEach((modalName) => {
			if (LIVESTREAM_MODALS['windowInstance']) {
				LIVESTREAM_MODALS['windowInstance'].close();
			}
		});
	}

	attachLivestreamHls(outlet, injector) {
		const containerPortal = new ComponentPortal(LivestreamHlsComponent, null, injector);
		const containerRef: ComponentRef<LivestreamHlsComponent> = outlet.attach(containerPortal);
		return containerRef.instance;
	}

	createInjector(data): PortalInjector {
		const injectionTokens = new WeakMap();
		injectionTokens.set(LIVESTREAM_MODAL_DATA, data);
		return new PortalInjector(this.injector, injectionTokens);
	}

	getStyleSheetElement() {
		const styleSheetElement = document.createElement('link');
		document.querySelectorAll('link').forEach((htmlElement) => {
			if (htmlElement.rel === 'stylesheet') {
				const absoluteUrl = new URL(htmlElement.href).href;
				styleSheetElement.rel = 'stylesheet';
				styleSheetElement.href = absoluteUrl;
			}
		});
		console.log(styleSheetElement.sheet);
		return styleSheetElement;
	}
}
