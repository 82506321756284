import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { getBackgroundColor, getNodgeColor, getSide, getTextColorClass } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogClubs, LiveBlogEntryPlayerRecord, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-playerRecord',
	templateUrl: './livetickerevent-playerRecord.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-playerRecord.component.scss'],
	animations: [liveblogPostAnimation('.head livetickerevent-playtime, .head livetickerevent-score, .head livetickerevent-headline, .body .scorer, .body .score-container .goal, .body .score-container .score, .scorer-image, .advancedStats-xg')]
})
export class LivetickereventPlayerRecordComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogEntryPlayerRecord;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() language = 'en';
	@Input() competition = 'bundesliga';
	@Input() enablePlayerLinking = true;

	public side: LivetickerSides;
	public textColorClass = 'text_black';
	public backgroundColor: string;
	public nodgeColor: any;
	public matchFactTitle = '';

	constructor(private translate: TranslateService) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.side = getSide(this.liveEntry) as LivetickerSides;
		this.textColorClass = getTextColorClass(this.side, this.clubData);
		this.nodgeColor = getNodgeColor(this.side, this.clubData);
		this.backgroundColor = getBackgroundColor(this.side, this.clubData);
		if (this.liveEntry && this.liveEntry.detail && this.liveEntry.detail.title && this.liveEntry.detail.matchFact === true) {
			this.translate
				.get('LIVETICKER.MATCHFACTS.' + this.liveEntry.detail.title + '.RECORD.TITLE')
				.pipe(
					take(1),
					map((translationResponse) => {
						if (translationResponse === 'LIVETICKER.MATCHFACTS.' + this.liveEntry.detail.title + '.RECORD.TITLE' || translationResponse === '') {
							return this.liveEntry.detail.title;
						}
						return translationResponse;
					})
				)
				.subscribe((translation) => {
					this.matchFactTitle = translation;
				});
		}
	}
}
