import { MiddlewareObj } from '@middy/core';
import { APIGatewayProxyEvent, APIGatewayProxyResult, Context } from 'aws-lambda';
import { BundesmasterLanguageRole } from './language-role';
import { BundesmasterLanguageRoleValidator } from './language-validator';
import { BundesmasterUserRole } from './user-role';

export interface UserRoleMiddleWareData extends Context {
	role: BundesmasterUserRole;
	languageRoles: BundesmasterLanguageRole[];
	languageRoleValidator: BundesmasterLanguageRoleValidator;
}

export function injectUserRolesAndLanguages(): MiddlewareObj<APIGatewayProxyEvent & UserRoleMiddleWareData, APIGatewayProxyResult> {
	return {
		before: (handler): Promise<void> => {
			const rolesAsStrings: readonly string[] = (handler.event?.requestContext?.authorizer?.['roles'] ?? '').split(',');
			const parsedRoles = BundesmasterUserRole.getFromNames(rolesAsStrings);
			const mostPowerfulRole = BundesmasterUserRole.getMostPowerfulRole(parsedRoles);
			const languageRoles = BundesmasterLanguageRole.getFromRoles(rolesAsStrings);

			handler.event.role = mostPowerfulRole;
			handler.event.languageRoles = languageRoles;
			handler.event.languageRoleValidator = new BundesmasterLanguageRoleValidator(mostPowerfulRole, languageRoles);

			return Promise.resolve();
		}
	};
}

export const validationErrorJSONFormatter = (): MiddlewareObj<APIGatewayProxyEvent, APIGatewayProxyResult, any, any> => ({
	onError: (request: any) => {
		const error = request.error;
		if (error.expose && error.statusCode === 400) {
			request.response = {
				statusCode: 400,
				body: JSON.stringify({
					message: error.message,
					errorMessage: error?.cause?.data?.map((d: any) => d?.message).join('. '),
					validationErrors: error?.cause?.data
				}),
				headers: { 'Content-Type': 'application/json' }
			};
		}
	}
});
