import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveBlogClubs, LiveBlogInfos, LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'livetickerevent-isTyping',
	templateUrl: './livetickerevent-isTyping.html',
	styleUrls: ['./livetickerevent-isTyping.component.scss'],
	animations: [trigger('isTypingState', [transition(':enter', [style({ transform: 'scale(0)' }), animate('100ms ease-in', style({ transform: 'scale(1)' }))]), transition(':leave', [style({ transform: 'scale(1)' }), animate('100ms ease-out', style({ transform: 'scale(0)' }))])])]
})
export class LivetickereventIsTypingComponent {
	@Output() animationStarted: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();
	@Output() animationDone: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();
	@Input() typingData: LiveBlogInfos;
	@Input() clubData: LiveBlogClubs;
	constructor() {}

	public getTypingColor(side: LivetickerSides): string {
		if (side === 'home' && this.clubData[side] && this.clubData[side].gradientStartColor) {
			return this.clubData[side].gradientStartColor;
		}

		if (side === 'away' && this.clubData[side] && this.clubData[side].gradientEndColor) {
			return this.clubData[side].gradientStartColor;
		}

		return '#c9cccf';
	}
}
