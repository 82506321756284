import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { languages } from '@nx-bundesliga/bundesmaster/core';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogMatch } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'bl-crosspost-dialog',
	templateUrl: './crosspost-dialog.component.html',
	styleUrls: ['./crosspost-dialog.component.scss']
})
export class BlCrosspostDialogComponent {
	@Input() match: LiveBlogMatch;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents & { order?: any }; style: any; class: any };
	@Input() language: string;
	@Input() postId: string;
	@Input() clubData: LiveBlogClubs;
	public languages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];
	public possibleLanguages = [];
	public selectedLanguages = { de: true, en: true, es: true, fr: true, ja: true, pt: true, ar: true };
	public crossPosts = { de: false, en: false, es: false, fr: false, ja: false, pt: false, ar: false };
	public processing = false;
	public loadingCrosspost = false;
	constructor(private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlCrosspostDialogComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.postId = this.data.hasOwnProperty('postId') ? this.data.postId : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
			this.getCrossposts();
			// deactivate current language to avoid cunfusion
			this.selectedLanguages[this.language] = false;
		}
	}

	private getCrossposts(): void {
		this.loadingCrosspost = true;
		combineLatest([
			this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? of({}) : this.http.get<any>(`https://api.bundesstreaker.${environment.stageDomain}.com/translationConfig/${this.match.dflDatalibraryMatchId}`),
			of(this.languages.filter((lang) => lang !== this.language)).pipe(
				switchMap((lang) =>
					forkJoin(
						lang.reduce((acc, curlang) => {
							const matchUrl = [environment.endpoints.config.base, curlang, this.match.dflDatalibraryCompetitionId, 'seasons', this.match.dflDatalibrarySeasonId, 'matchdays', this.match.dflDatalibraryMatchdayId, this.match.dflDatalibraryMatchId, 'liveBlogEntries', this.postId + '.json'].join('/');
							const customMatchUrl = [environment.endpoints.config.base, curlang, 'custom', 'matches', this.match.matchId || '', 'liveBlogEntries', this.postId + '.json'].join('/');
							const url = this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? customMatchUrl : matchUrl;
							acc[curlang] = this.http.get<boolean>(url).pipe(
								take(1),
								map((resp) => {
									return resp !== null;
								}),
								catchError(() => of(false))
							);
							return acc;
						}, {})
					)
				),
				take(1)
			)
		]).subscribe({
			next: ([translationConfig, val]: [any, any]) => {
				this.crossPosts = val;
				// only assign languages which are neither origin or target language in auto translations
				this.possibleLanguages = this.languages.filter((l) => Object.entries(translationConfig).findIndex(([target, source]) => source !== null && (target === l || source === l)) === -1);
				this.languages.forEach((l) => {
					this.selectedLanguages[l] = this.possibleLanguages.includes(l);
				});
				console.log('this.crossPosts', this.crossPosts);
				this.loadingCrosspost = false;
			},
			error: (err) => {
				this.possibleLanguages = [];
				this.languages.forEach((l) => {
					if (l !== this.language) {
						this.possibleLanguages.push(l);
					}
					this.selectedLanguages[l] = !this.possibleLanguages.includes(this.language);
				});
				this.toastr.warning(`Unable to determine list of autotranslated languages. Make sure to not crosspost to a language which in itself is either fed by autotranslations from other languages, or will act as a source for autotranslations to other languages!`, 'Warning');
				this.loadingCrosspost = false;
			}
		});
	}

	public onCrosspostClick(): void {
		this.loading.setLoading(true);
		this.processing = true;
		// clone and delete language dependent props from liveEntry
		const liveEntry = { ...this.liveEntry.value };
		if (liveEntry.hasOwnProperty('detail') && liveEntry.detail.hasOwnProperty('headline')) {
			delete liveEntry.detail.headline;
		}
		if (liveEntry.hasOwnProperty('detail') && liveEntry.detail.hasOwnProperty('text')) {
			delete liveEntry.detail.text;
		}
		// set order auto for the calculation of ordering to take place in backend again
		liveEntry.order = 'auto';
		const languages = this.languages.filter((lang) => lang !== this.language && this.selectedLanguages[lang] === true);
		forkJoin(
			languages.map((language) => {
				const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;

				const urlSuffix = language + '/' + this.match.dflDatalibraryCompetitionId + '/' + this.match.dflDatalibrarySeasonId + '/' + this.match.dflDatalibraryMatchdayId + '/' + (this.match.dflDatalibraryMatchId === 'DFL-MAT-CUSTOM' ? this.match.matchId : this.match.dflDatalibraryMatchId) + '/' + this.postId;

				const url = urlBase + '/' + urlSuffix;
				const payload = liveEntry;

				console.log(`Sending crosspost put to API: `, url);
				console.log('Crosspost editEvent', payload);

				return this.http
					.put(url, payload)
					.pipe(
						tap((success) => {
							if (!success.hasOwnProperty('error')) {
								this.toastr.success('Crosspost saved successfully! (ID: ' + this.postId + ')', language + ': Published');
							}
						}),
						catchError((error) => {
							const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
							this.toastr.error(message, 'Error');
							return of(error);
						})
					)
					.subscribe(
						(success) => {
							this.loading.setLoading(false);
							this.processing = false;
							this.dialogRef.close();
						},

						(error) => {
							this.loading.setLoading(false);
							this.processing = false;
							this.dialogRef.close();
						}
					);
			})
		);
	}
}
