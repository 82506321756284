import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonsModule, TimeAgoPipe } from '@nx-bundesliga/bundesliga-com/components/commons';
import { TwitterFeedComponent } from './twitter-feed.component';

@NgModule({
	imports: [CommonsModule, FormsModule, MatIconModule, MatCardModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatInputModule],
	providers: [TimeAgoPipe],
	exports: [TwitterFeedComponent],
	declarations: [TwitterFeedComponent]
})
export class TwitterFeedModule {}
