import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiveBlogMatch } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'bl-translation-config-dialog',
	templateUrl: 'translation-config-dialog.component.html',
	styleUrls: ['./translation-config-dialog.component.scss']
})
export class BlTranslationConfigDialogComponent implements OnInit {
	@Input() matchId: string;
	@Input() translationConfig: { [key: string]: string };
	public processing = true;
	public availableLanguages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];
	public translationConfigDefaultDE = {
		'de': null,
		'en': 'de',
		'es': 'de',
		'fr': 'de',
		'ja': 'de',
		'pt': 'de',
		'ar': 'de'
	};
	public form = this.formBuilder.group({
		de: this.formBuilder.control<string>(null, []),
		en: this.formBuilder.control<string>(null, []),
		es: this.formBuilder.control<string>(null, []),
		fr: this.formBuilder.control<string>(null, []),
		ja: this.formBuilder.control<string>(null, []),
		pt: this.formBuilder.control<string>(null, []),
		ar: this.formBuilder.control<string>(null, [])
	});

	constructor(private formBuilder: FormBuilder, private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlTranslationConfigDialogComponent>) {}

	ngOnInit() {
		if (this.data) {
			this.matchId = this.data?.matchId ?? null;
			this.form.patchValue(this.translationConfig ?? this.data?.translationConfig);
		}
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/translationConfig/${this.matchId}`;
		this.http.get(url).subscribe({
			next: (resp: any) => {
				this.form.patchValue(resp);
				this.processing = false;
			},
			error: (error) => {
				this.form.patchValue({});
				this.processing = false;
			}
		});
	}

	public onSubmit(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/translationConfig/${this.matchId}`;
		const payload = this.form.getRawValue();

		console.log(`Sending translationConfig put to API: `, url);
		console.log('translationConfig editEvent', payload);

		this.http.put(url, payload).subscribe({
			next: (success) => {
				this.toastr.success('TranslationConfig saved successfully!', 'SAVED');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			error: (error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		});
	}

	public onDelete(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/translationConfig/${this.matchId}`;

		console.log(`Sending translationConfig delete to API: `, url);

		this.http.delete(url).subscribe({
			next: (success) => {
				this.toastr.success('TranslationConfig deleted successfully!', 'Deleted');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			error: (error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
				this.dialogRef.close();
			}
		});
	}
}
