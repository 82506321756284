import { Injectable } from '@angular/core';
import { format, parse } from 'date-fns';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterDateService {
	public parseDate(date: string): Date {
		return parse(date, "yyyy-MM-dd'T'HH:mm:ssXX", new Date());
	}

	public formatDate(date: Date | string): string {
		if (!date) {
			return null;
		}
		try {
			if (typeof date === 'string') {
				return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssXX");
			}
			return format(date, "yyyy-MM-dd'T'HH:mm:ssXX");
		} catch (err) {
			if (err instanceof RangeError) {
				return null;
			}
			throw err;
		}
	}
}
