import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LoginComponent } from './login.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'login/error',
		component: LoginComponent
	}
];

@NgModule({
	imports: [CommonsModule, RouterModule.forChild(routes)],
	exports: [LoginComponent],
	declarations: [LoginComponent]
})
export class LoginModule {}
