import { NgModule } from '@angular/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { MatchcenterStatsCollectionModule } from '../../organisms/stats-collection/component/matchcenter/matchcenter-stats-collection.module';
import { StatsFeedComponent } from './stats-feed.component';

@NgModule({
	imports: [CommonsModule, MatchcenterStatsCollectionModule],
	exports: [StatsFeedComponent],
	declarations: [StatsFeedComponent]
})
export class StatsFeedModule {}
