import { AfterViewInit, Component, Input } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { BundesligaEmbedPlaceholder } from '@nx-bundesliga/models';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';

@Component({
	selector: 'embed-facebook',
	templateUrl: './embed-facebook.component.html',
	styles: [':host { margin: 20px auto; }']
})
export class EmbedFacebookComponent implements AfterViewInit {
	private appId: string;
	@Input() id: string;
	@Input() placeholder: BundesligaEmbedPlaceholder;
	@Input() forceLoad = false;

	constructor(private loader: ScriptLoaderService, private configService: ConfigService) {
		this.appId = configService.getSettings('facebook.appId');
	}

	ngAfterViewInit() {
		this.loader.load('facebook', this.forceLoad)[0].subscribe(
			() => {
				if (typeof (window as any).FB === 'object') {
					(window as any).FB.init({
						appId: this.appId,
						status: true,
						xfbml: true,
						version: 'v2.7' // or v2.6, v2.5, v2.4, v2.3
					});
				}
			},
			(error) => {
				console.warn(`facebook.loader.error: ${error}`);
			}
		);
	}
}
