import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'liveticker'
})
export class LivetickerPipe implements PipeTransform {
	/**
	 * Returns an object with all necessary classes and styles for this event.
	 *
	 * @param input
	 */
	transform(input: any): any {
		return input.filter((item) => item.value.hasOwnProperty('order')).sort((a, b) => (a.value.order < b.value.order ? 1 : b.value.order < a.value.order ? -1 : 0));
	}
}

@Pipe({
	name: 'conferenceLiveticker'
})
export class ConferenceLivetickerPipe implements PipeTransform {
	/**
	 * Returns an object with all necessary classes and styles for this event.
	 *
	 * @param input
	 */
	transform(input: any): any {
		return input.sort((a, b) => (a.value.entryDate < b.value.entryDate ? 1 : b.value.entryDate < a.value.entryDate ? -1 : 0));
		// .sort( (a, b) => (a.value.order < b.value.order) ? 1 : (b.value.order < a.value.order) ? -1 : 0 )
	}
}
