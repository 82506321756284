<div class="playerprogressranking-card">
	<span class="title">{{title}}</span>
	<div class="bar" *ngFor="let interation of [].constructor(showRankings);let barindex=index">
		<bar-progress-chart
			[leftValue]="homeValue[barindex]"
			[leftColor]="homeColor"
			[leftTextColor]="homeTextColor"
			[rightValue]="awayValue[barindex]"
			[rightColor]="awayColor"
			[rightTextColor]="awayTextColor"
			[leftLabel]="homeName[barindex]"
			[rightLabel]="awayName[barindex]"
			[relativeValue]="maxValue"></bar-progress-chart>
	</div>
</div>
