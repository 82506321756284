import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { LivetickerSides } from '@nx-bundesliga/models';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'bl-rapid-ticker-side-chooser',
	templateUrl: './bl-rapid-ticker-side-chooser.component.html',
	styleUrls: ['./bl-rapid-ticker-side-chooser.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlRapidTickerSideChooserComponent implements OnInit, OnDestroy {
	@Output() sideSelected: EventEmitter<[LivetickerSides, boolean]> = new EventEmitter<[LivetickerSides, boolean]>();
	public sendTypingStatus = true;
	private sub: Subscription;
	constructor() {}

	ngOnInit(): void {
		console.log('BlRapidTickerSideChooserComponent.ngOnInit');
		this.sub = fromEvent<KeyboardEvent>(document, 'keyup')
			.pipe(map((event) => event.key))
			.subscribe((key) => {
				if (key === 'ArrowLeft') {
					this.emitSelection('home');
				} else if (key === 'ArrowRight') {
					this.emitSelection('away');
				} else if (key === 'ArrowDown') {
					this.emitSelection(null);
				} else if (key === 'ArrowUp') {
					this.emitSelection('none');
				}
			});
	}

	ngOnDestroy(): void {
		console.log('BlRapidTickerSideChooserComponent.ngOnDestroy');
		this.sub.unsubscribe();
	}

	public emitSelection(side: LivetickerSides) {
		this.sideSelected.emit([side, this.sendTypingStatus]);
	}
}
