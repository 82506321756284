import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { getNodgeColor, getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEventSubstitution, LiveBlogClubs, LivetickerSides, NON_BUNDESLIGA_COMPETITIONS } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-substitution',
	templateUrl: './livetickerevent-substitution.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-substitution.component.scss'],
	animations: [liveblogPostAnimation('.head .playtime, .head .headline livetickerevent-score, .head .headline livetickerevent-headline, .body .player-in, .body .player-out')]
})
export class LivetickereventSubstitutionComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogAutoEventSubstitution;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() language = 'en';
	@Input() competition = 'bundesliga';
	@Input() enablePlayerLinking = true;

	public side: LivetickerSides;
	public nodgeColor: string;
	public isBundesliga = true;

	constructor() {}

	ngOnChanges() {
		if (NON_BUNDESLIGA_COMPETITIONS[this.competition.toUpperCase()]) {
			this.isBundesliga = false;
		}
		this.side = getSide(this.liveEntry) as LivetickerSides;
		this.nodgeColor = getNodgeColor(this.side, this.clubData);
	}
}
