import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Club } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { ClubService } from '../../../services/club/club.service';

@Component({
	selector: 'app-overview-page',
	templateUrl: './match-overview-page.component.html',
	styleUrls: ['./match-overview-page.component.scss']
})
export class MatchOverviewPageComponent implements OnInit {
	public season: string = null;
	public matchdays: Array<number>;
	public clubName = '';
	public numberOfMatchdays = {
		'DFL-COM-000001': 34,
		'DFL-COM-000002': 34,
		'DFL-COM-000003': 1,
		'DFL-COM-000004': 2,
		'DFL-COM-000005': 2,
		'DFL-COM-J00028': 3,
		'DFL-COM-J0002E': 3
	};
	public clubs: Club[];
	public seasons: string[];

	public defaultClubName = '';
	public defaultSeasonId = 'DFL-SEA-0001K7';
	public defaultMatchdayNumber = 1;
	public defaultCompetitionId = 'DFL-COM-000001';
	public defaultLanguage = 'en';

	public matchdayNumber = 1;
	public language = 'en';
	public competitionId = 'DFL-COM-000001';
	public seasonId = 'DFL-SEA-0001K7';

	public availableLanguages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];

	public seasonsIds = {
		'DFL-SEA-0001K8': '2024/2025',
		'DFL-SEA-0001K7': '2023/2024',
		'DFL-SEA-0001K6': '2022/2023',
		'DFL-SEA-0001K5': '2021/2022',
		'DFL-SEA-0001K4': '2020/2021',
		'DFL-SEA-0001K3': '2019/2020',
		'DFL-SEA-0001K2': '2018/2019',
		'DFL-SEA-0001K1': '2017/2018',
		'DFL-SEA-0001K0': '2016/2017'
	};

	public competitionIds = {
		'DFL-COM-000001': 'Bundesliga',
		'DFL-COM-000002': '2. Bundesliga',
		'DFL-COM-000003': 'Supercup',
		'DFL-COM-000004': 'Relegation BL',
		'DFL-COM-000005': 'Relegation 2BL',
		'DFL-COM-J00028': 'World Cup 2022',
		'DFL-COM-J0002E': 'Euro 2024'
	};

	public singleSeasonCompetition = {
		'DFL-COM-J00028': 'DFL-SEA-0001K6',
		'DFL-COM-J0002E': 'DFL-SEA-0001K8'
	};

	constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private configService: ConfigService, private clubService: ClubService) {}

	/**
	 *
	 */
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.language = params['language'] && this.availableLanguages.includes(params['language']) ? params['language'] : this.defaultLanguage;
			this.competitionId = params['competitionId'] && params['competitionId'].startsWith('DFL-COM-') ? params['competitionId'] : this.defaultCompetitionId;
			this.seasonId = params['seasonId'] && params['seasonId'].startsWith('DFL-SEA-') ? params['seasonId'] : this.configService.getSettings([this.competitionId, 'season', 'dflDatalibrarySeasonId'], this.defaultSeasonId);
			this.defaultMatchdayNumber = this.configService.getSettings([this.competitionId, 'matchday', 'matchdayNumber'], 1);
			this.matchdayNumber = params['matchdayNumber'] ? parseInt(params['matchdayNumber'], 10) : this.configService.getSettings([this.competitionId, 'matchday', 'matchdayNumber'], this.defaultMatchdayNumber);
			this.clubName = params['clubName'] || this.defaultClubName;
			if (params['language'] && this.availableLanguages.includes(params['language']) && params['competitionId'] && params['competitionId'].startsWith('DFL-COM-') && params['seasonId'] && params['seasonId'].startsWith('DFL-SEA-') && params['matchdayNumber']) {
				this._fetchClubs();
				this._fetchMatchdays();
			} else {
				this.router.navigate([this.language, this.competitionId, this.seasonId, this.matchdayNumber], { relativeTo: this.route.parent });
			}
		});
	}

	/**
	 *
	 */
	public prevMatchday(): void {
		if (Number(this.matchdayNumber) > 1) {
			this.redirectToFixtures(null, null, null, Number(this.matchdayNumber) - 1);
		}
	}

	/**
	 *
	 * @private
	 */
	private _fetchMatchdays() {
		const numberOfMatchdays = this.numberOfMatchdays?.[this.competitionId] ?? 34;
		this.matchdays = Array.from(Array(numberOfMatchdays)).map((x, i) => i + 1);
		// redirect all wrong matchdays to the default matchday
		if (this.matchdayNumber > numberOfMatchdays) {
			this.redirectToFixtures(null, null, null, this.defaultMatchdayNumber);
		}
	}

	/**
	 *
	 * @private
	 */
	private _fetchClubs() {
		const competitionIds = {
			'DFL-COM-000001': 'bundesliga',
			'DFL-COM-000002': '2bundesliga',
			'DFL-COM-000003': 'bundesliga',
			'DFL-COM-000004': 'bundesliga',
			'DFL-COM-000005': '2bundesliga'
		};
		this.clubService.getAllClubsByCompetitionAndSeason(competitionIds[this.competitionId], 'editorialorder').subscribe(
			(data: Club[]) => {
				this.clubs = data;
			},
			(error) => {
				this.clubs = [];
			}
		);
	}

	public filterSeasonIds(competitionId: string): { [p: string]: string } {
		if (Object.keys(this.singleSeasonCompetition).includes(competitionId)) {
			return { [this.singleSeasonCompetition[competitionId]]: this.seasonsIds[this.singleSeasonCompetition[competitionId]] };
		}
		return this.seasonsIds;
	}

	/**
	 *
	 */
	public nextMatchday(): void {
		const numberOfMatchdays = this.numberOfMatchdays?.[this.competitionId] ?? 34;
		if (Number(this.matchdayNumber) < Number(numberOfMatchdays)) {
			this.redirectToFixtures(null, null, null, Number(this.matchdayNumber) + 1);
		}
	}

	/**
	 *
	 * @param language
	 * @param competitionId
	 * @param seasonId
	 * @param matchday
	 * @param clubname
	 */
	public redirectToFixtures(language: string, competitionId: string, seasonId?: string, matchday?: any, clubname?: string) {
		this.router.navigate([language || this.language, competitionId || this.competitionId, seasonId || this.seasonId, matchday || this.matchdayNumber, clubname || this.clubName], {
			relativeTo: this.route.parent,
			replaceUrl: true
		});
	}

	/**
	 * onChangeSeason
	 * @param season
	 */
	public onChangeSeason(season: string): void {
		this.redirectToFixtures(null, null, season, null);
	}

	/**
	 * onChangeCompetition
	 * @param competition
	 */
	public onChangeCompetition(competition: string): void {
		if (this.singleSeasonCompetition?.[competition]) {
			this.redirectToFixtures(null, competition, this.singleSeasonCompetition?.[competition], null);
		} else {
			this.redirectToFixtures(null, competition, null, null);
		}
	}

	/**
	 * onChangeCompetition
	 * @param language
	 */
	public onChangeLanguage(language: string): void {
		this.redirectToFixtures(language, null, null, null);
	}

	/**
	 * onChangeSeason
	 * @param season
	 */
	public onChangeMatchday(matchdayNumber: any): void {
		this.clubName = '';
		this.redirectToFixtures(null, null, null, matchdayNumber);
	}

	/**
	 * onChangeClub
	 * @param season
	 */
	public onChangeClub(clubName: string): void {
		this.matchdayNumber = 0;
		this.redirectToFixtures(null, null, null, 0, clubName);
	}
}
