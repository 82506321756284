import { NgModule } from '@angular/core';
import { PieChartComponent } from './pie-chart.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule],
	exports: [PieChartComponent],
	declarations: [PieChartComponent],
	providers: []
})
export class PieChartModule {}
