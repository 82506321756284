import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogClubs, LiveBlogEntryEditorialImage, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-image',
	templateUrl: './livetickerevent-image.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-image.component.scss'],
	animations: [liveblogPostAnimation('.body .playtime, .body .headline livetickerevent-score, .body .headline livetickerevent-headline, .body .headline .text, .copyright'), trigger('liveblogPostImageAnimation', [transition(':enter', [style({ opacity: 0 }), animate('250ms ease-in', style({ opacity: 1 }))])])]
})
export class LivetickereventImageComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogEntryEditorialImage;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() isWebview = false;
	public side: LivetickerSides;

	// lazyloading
	public readonly defaultImage = '/assets/placeholder/resp_live_placeholder_image_16_9.png';

	constructor() {}

	ngOnChanges() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
	}
}
