import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Club, Match } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'custom-match-overview',
	templateUrl: './custom-match-overview-page.component.html',
	styleUrls: ['./custom-match-overview-page.component.scss']
})
export class CustomMatchOverviewPageComponent implements OnInit {
	public season: string = null;
	public matchdays: Array<number>;
	public clubs: Club[];
	public seasons: string[];
	public matches: Match[];

	public defaultCompetitionId = 'DFL-COM-CUSTOM';
	public defaultLanguage = 'en';

	public matchdayNumber = 1;
	public language = 'en';
	public competitionId = 'DFL-COM-CUSTOM';
	public seasonId = 'DFL-SEA-CUSTOM';

	public availableLanguages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];
	private _matchesServiceSubscription: Subscription;
	loading = true;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	dataSource: MatTableDataSource<any>;

	constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private loadingService: LoadingService, private toastr: ToastrService) {}

	/**
	 *
	 */
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.language = params['language'] && this.availableLanguages.includes(params['language']) ? params['language'] : this.defaultLanguage;
			if (params['language'] && this.availableLanguages.includes(params['language'])) {
				this._fetchMatchData();
			} else {
				this.router.navigate([this.language], { relativeTo: this.route.parent });
			}
		});
	}

	applyFilter(event: Event) {
		this.dataSource.filterPredicate = (data: any, filter: string) => {
			return data?.teams?.home?.nameFull?.toLocaleLowerCase()?.includes(filter) || data?.teams?.away?.nameFull?.toLocaleLowerCase()?.includes(filter);
		};

		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	private _fetchMatchData(): void {
		// unsibscribe first
		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/custom-match`;

		const urlSuffix = this.language;

		const url = urlBase + '/' + urlSuffix;

		console.log(`Getting custom matches from API: `, url);

		this.loadingService.setLoading(true);
		this.loading = true;

		this._matchesServiceSubscription = this.http.get(url).subscribe(
			(success) => {
				this.matches = success as any[];
				// Assign the data to the data source for the table to render
				this.dataSource = new MatTableDataSource(this.matches);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
				const sortState: Sort = { active: 'plannedKickOff', direction: 'desc' };
				this.sort.active = sortState.active;
				this.sort.direction = sortState.direction;
				this.sort.sortChange.emit(sortState);

				this.loadingService.setLoading(false);
				this.loading = false;
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.matches = [];
				this.dataSource = new MatTableDataSource(this.matches);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;

				this.loadingService.setLoading(false);
				this.toastr.error(message, 'Error');
				this.loading = false;
			}
		);
	}

	/**
	 *
	 * @param language
	 * @param competitionId
	 * @param seasonId
	 * @param matchday
	 * @param clubname
	 */
	public redirectToFixtures(language: string) {
		this.router.navigate([language || this.language], {
			relativeTo: this.route.parent,
			replaceUrl: true
		});
	}

	/**
	 * onChangeCompetition
	 * @param language
	 */
	public onChangeLanguage(language: string): void {
		this.redirectToFixtures(language);
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isLiveMatch(match: any): boolean {
		if (match['matchStatus'] !== 'PRE_MATCH' && match['matchStatus'] !== 'FINAL_WHISTLE') {
			return true;
		} else {
			return false;
		}
	}
}
