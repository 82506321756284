<div class="container">
	<div class="row">
		<div class="col col-12">
			<page-title>Create Custom Game {{editMode ? '(EDITING)' : ''}}</page-title>
		</div>
	</div>
	<div class="row" *ngIf="editMode && !editLoading && !editLoadingFailed">
		<div class="col-12">
			<button mat-raised-button [color]="'primary'" [disabled]="processing" (click)="onDelete()">Delete Custom Match</button>
		</div>
		<div class="col-12" style="height: 32px"></div>
	</div>
	<div class="row" *ngIf="editLoading">
		<div class="col col-12 justify-content-center align-items-center">
			<dfl-loader [loading]="editLoading" surrounding="large"></dfl-loader>
		</div>
	</div>
	<div class="row" *ngIf="!editLoading && editLoadingFailed">
		<div class="col col-12 justify-content-center align-items-center">
			<page-title>Loading of {{language}} : {{matchId}} failed.</page-title>
			<button mat-raised-button [color]="'primary'" class="full-width" (click)="fetchCustomMatch(language, matchId)">Try Again</button>
		</div>
	</div>
	<div class="row" *ngIf="!editLoading && !editLoadingFailed">
		<div class="col col-12">
			<mat-form-field class="full-width">
				<mat-label>Custom Match ID (unique)</mat-label>
				<input matInput [(ngModel)]="matchId" [disabled]="editMode" placeholder="Custom Match ID (unique)" type="text" tabindex="0" />
			</mat-form-field>
		</div>
		<div class="col col-12">
			<mat-form-field class="full-width">
				<mat-label>MatchName</mat-label>
				<input matInput [(ngModel)]="customMatch.matchName" placeholder="MatchName" type="text" tabindex="0" />
			</mat-form-field>
		</div>

		<div class="col col-12 col-md-6">
			<mat-form-field>
				<mat-label>Language</mat-label>
				<mat-select [(ngModel)]="language" [disabled]="editMode" tabindex="5">
					<mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{lang}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="col col-12 col-md-6">
			<mat-form-field>
				<mat-label>Competition Name</mat-label>
				<mat-select [(ngModel)]="customMatch.competitionName" tabindex="5">
					<mat-option *ngFor="let competitionName of competitionNames" [value]="competitionName">{{competitionName}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="col col-12" *ngIf="clubs.length > 0" style="margin-bottom: 12px">
			<mat-card appearance="outlined">
				<mat-card-content>
					<mat-form-field>
						<mat-select placeholder="Club" [(ngModel)]="selectedClub">
							<mat-option>
								<ngx-mat-select-search [(ngModel)]="clubSearch" (ngModelChange)="filterClubs()"></ngx-mat-select-search>
							</mat-option>

							<mat-option *ngFor="let club of filteredClubs;trackBy: trackByClubId" [value]="club">
								<!--<img [src]="player.playerImages.FACE_CIRCLE" height="60" width="60" alt="" style="vertical-align:middle;" />-->
								{{club.name.full}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button (click)="setClub('home')" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Home
					</button>
					<button (click)="setClub('away')" mat-raised-button>
						<mat-icon>play_for_work</mat-icon>
						Away
					</button>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="col col-12">
			<mat-card appearance="outlined">
				<mat-card-title>General</mat-card-title>
				<mat-card-content>
					<mat-checkbox [(ngModel)]="customMatch.hideHeader">
						<span>Hide Header (e.g.: for non-match Custom games)</span>
					</mat-checkbox>
					<mat-form-field class="full-width">
						<input matInput [(ngModel)]="customMatch.matchdayLabel" placeholder="matchdayLabel" type="text" tabindex="0" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<input matInput [(ngModel)]="customMatch.plannedKickOff" placeholder="plannedKickOff" type="text" tabindex="0" />
					</mat-form-field>

					<mat-form-field class="full-width">
						<input matInput [(ngModel)]="customMatch.stadiumName" placeholder="stadiumName" tabindex="4" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="col col-12 col-md-6">
			<mat-card appearance="outlined">
				<mat-card-title>Home Team (left)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryClubId (empty for non-Bundeslig teams)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.dflDatalibraryClubId" placeholder="dflDatalibraryClubId (empty for non-Bundeslig teams)" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>nameFull</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.nameFull" placeholder="nameFull" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>nameShort</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.nameShort" placeholder="nameShort" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>threeLetterCode</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.threeLetterCode" placeholder="threeLetterCode" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>logoUrl</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.logoUrl" placeholder="logoUrl" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>color (#hex)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.color" (ngModelChange)="setTeamColor('home', customMatch.teams.home.color)" placeholder="color (#hex)" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>textColor (#hex)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.home.textColor" placeholder="textColor (#hex)" type="text" tabindex="0" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="col col-12 col-md-6">
			<mat-card appearance="outlined">
				<mat-card-title>Away Team (right)</mat-card-title>
				<mat-card-content>
					<mat-form-field class="full-width">
						<mat-label>dflDatalibraryClubId (empty for non-Bundeslig teams)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.dflDatalibraryClubId" placeholder="dflDatalibraryClubId (empty for non-Bundeslig teams)" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>nameFull</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.nameFull" placeholder="nameFull" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>nameShort</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.nameShort" placeholder="nameShort" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>threeLetterCode</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.threeLetterCode" placeholder="threeLetterCode" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>logoUrl</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.logoUrl" placeholder="logoUrl" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>color (#hex)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.color" (ngModelChange)="setTeamColor('away', customMatch.teams.away.color)" placeholder="color (#hex)" type="text" tabindex="0" />
					</mat-form-field>
					<mat-form-field class="full-width">
						<mat-label>textColor (#hex)</mat-label>
						<input matInput [(ngModel)]="customMatch.teams.away.textColor" placeholder="textColor (#hex)" type="text" tabindex="0" />
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="row" *ngIf="!editLoading && !editLoadingFailed">
		<div class="col-12">
			<button mat-raised-button [color]="'primary'" [disabled]="processing" class="full-width" (click)="onSubmit()">Submit</button>
		</div>
		<div class="col-12" style="height: 32px"></div>
	</div>
</div>
