import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { LiveBlogClub, LiveBlogMatch, LivetickerSides, MatchStats, StatsPlayerProgressRanking, StatsPlayerProgressRankingEntry } from '@nx-bundesliga/models';
import { Subscription } from 'rxjs';
import { StatsMatchService } from '../../../../../services/stats-match/stats-match.service';

@Component({
	selector: 'matchcenter-stats-collection',
	templateUrl: './matchcenter-stats-collection.component.html',
	styleUrls: ['./matchcenter-stats-collection.component.scss']
})
export class MatchcenterStatsCollectionComponent implements OnChanges, OnDestroy {
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() match: LiveBlogMatch;
	@Input() home: LiveBlogClub;
	@Input() away: LiveBlogClub;
	@Output() createStatsPost: EventEmitter<{ type: string; title: string; data: any; side: string }> = new EventEmitter<{ type: string; title: string; data: any; side: string }>();
	public statsData: MatchStats;
	private statsSubscription: Subscription;

	constructor(private statsMatchService: StatsMatchService) {}

	ngOnChanges(changes: SimpleChanges) {
		this.statsSubscription = this.statsMatchService.getMatchStats(this.competitionId, this.seasonId, this.matchdayId, this.matchId).subscribe(
			(success: MatchStats) => {
				this.statsData = success;
			},
			(error) => {
				console.log('Error subscribing to match stats:', error);
			}
		);
	}

	ngOnDestroy(): void {
		if (this.statsSubscription) {
			this.statsSubscription.unsubscribe();
		}
	}

	public openCreateDialog(type: string, title: string, data: any, side = 'none') {
		this.createStatsPost.emit({ type, title, data, side });
	}

	public transformPlayerProgressRanking(players: StatsPlayerProgressRanking) {
		return [...(players.home || [{}, {}, {}]), ...(players.away || [{}, {}, {}])].map((player: StatsPlayerProgressRankingEntry, index) => {
			return {
				person: {
					dflDatalibraryObjectId: player.dflDatalibraryObjectId,
					imageUrl: player.playerImageUrl,
					name: player.playerName
				},
				side: (index < 3 ? 'home' : 'away') as LivetickerSides,
				rank: index < 3 ? index + 1 : index - 2,
				value: player.value
			};
		});
	}
}
