import { Asset } from './asset.model';
import { OpenSearchMatch, OpenSearchPerson } from './opensearch';
import { Article, ArticleContentBlocks } from './article.model';

export type MatchReportSuggestionLanguages = 'en' | 'de';
export const MatchReportSuggestionLanguagesOptions: MatchReportSuggestionLanguages[] = ['en', 'de'];

export interface MatchReportSuggestionResponse {
	page: number;
	size: number;
	total: number;
	items: MatchReportSuggestion[];
}

export type MatchReportSuggestionStatus = 'QUEUED' | 'PROCESSING' | 'SUCCESS' | 'FAILED';

export interface MatchReportSuggestionPromptParams {
	language: MatchReportSuggestionLanguages;
	matchId: string;
	persona?: MatchReportSuggestionPromptPersona;
	additionalInstructions?: string;
	personId_mvp?: string;
	allowEmbeds?: boolean;
}

export type MatchReportSuggestionPromptPersona = 'NONE' | 'ENTHUSIAST' | 'AGENCY' | 'DERBY' | 'UNINTERESTED';
export const MatchReportSuggestionPersonaOptions: { [persona: string | MatchReportSuggestionPromptPersona]: string } = {
	'NONE': 'NONE: No persona and no tone of writing will be provided',
	'ENTHUSIAST': 'ENTHUSIAST: Describes the game in a enthusiastic way',
	'AGENCY': 'AGENCY: Describes the game in an agency style',
	'DERBY': 'DERBY: Tries to focus on the importance of the derby match and both its teams',
	'UNINTERESTED': 'UNINTERESTED: Describes the way easy readable for users not familiar with soccer'
};

export type MatchReportContentBlocks = ArticleContentBlocks | ArticleContentBlockPlaceholder;

export interface ArticleContentBlockPlaceholder {
	blockType: 'placeholder';
}

export interface MatchReportSuggestion {
	id: string;
	requestId: string;
	matchId: string;
	/** @nullable */
	match?: OpenSearchMatch | null;
	/** @nullable */
	mvp?: OpenSearchPerson | null;
	/** @nullable */
	images?: Asset[] | null;
	/** @nullable */
	language?: MatchReportSuggestionLanguages | null;
	/** @nullable */
	article?: Article | null;
	/** @nullable */
	promptParams?: MatchReportSuggestionPromptParams | null;
	/** @nullable */
	modified_date?: string | null;
	/** @nullable */
	created_date?: string | null;
	/** @nullable */
	errorMessage?: any | null;
	/** @nullable */
	status?: MatchReportSuggestionStatus;
	/** @nullable */
	prompts?: {
		matchreport?: {
			prompt?: string | null;
			response?: any | null;
		};
	};
}
