<div class="container">
	<div class="row">
		<div class="col-12">
			<mat-card appearance="outlined">
				<mat-card-title>Search Tweets</mat-card-title>
				<mat-card-content>
					<mat-form-field>
						<input matInput [(ngModel)]="query" placeholder="Query (e.g. Hastag)" tabindex="1" autocomplete="off" />
					</mat-form-field>

					<mat-form-field>
						<input matInput [(ngModel)]="count" placeholder="Number of results" type="number" [min]="1" [max]="100" tabindex="2" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>Result Type</mat-label>
						<mat-select [(ngModel)]="result_type" tabindex="3">
							<mat-option value="mixed">mixed</mat-option>
							<mat-option value="recent">recent</mat-option>
							<mat-option value="popular">popular</mat-option>
						</mat-select>
					</mat-form-field>
				</mat-card-content>

				<mat-card-actions>
					<button mat-flat-button color="primary" type="submit" (click)="getTweets()">Search</button>
				</mat-card-actions>
			</mat-card>
		</div>

		<div class="col-12">
			<mat-card appearance="outlined" *ngIf="tweetsResult" style="margin-top: 5px">
				<mat-card-content *ngIf="tweetsResult">
					Query: {{tweetsResult.query}} - Result: {{tweetsResult.count}}
					<button mat-button color="warn" *ngIf="tweetsResult.error">{{tweetsResult.error}}</button>
				</mat-card-content>
			</mat-card>
		</div>
	</div>

	<div class="row justify-content-center">
		<div *ngIf="tweets" class="tweets">
			<article class="tweet" *ngFor="let tweet of tweets">
				<img class="avatar" [src]="tweet.user.profile_image_url" alt="Name" />
				<div class="content">
					<div class="author-meta">
						<span class="name">{{tweet.user.name}}</span>
						<span class="handle"><a href="https://twitter.com/{{tweet.user.screen_name}}" target="_blank">&nbsp;&#64;{{tweet.user.screen_name}}</a></span>
						<span class="time">{{formatTime(tweet.created_at) | timeAgo}}</span>
					</div>
					<p class="post">
						{{tweet.text}}
						<img *ngIf="tweet.entities.media?.length > 0" src="{{tweet.entities.media[0].media_url_https}}:small" alt="" />
					</p>

					<ul class="actions">
						<li>
							<a href="https://twitter.com/{{tweet.user.screen_name}}/status/{{tweet.id_str}}" target="_blank">
								<mat-icon>reply</mat-icon>
							</a>
						</li>
						<li>
							<mat-icon>compare_arrows</mat-icon>
							({{tweet.retweet_count}})
						</li>
						<li>
							<mat-icon>favorite</mat-icon>
							({{tweet.favorite_count}})
						</li>
						<li>
							<mat-icon (click)="openCreateDialog(tweet.id_str)">attach_file</mat-icon>
						</li>
					</ul>
				</div>
			</article>
		</div>
	</div>
</div>
