import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, Optional, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Asset, AssetDimensions, AssetServicePipe, BundesmasterStoriesApiService, OpenSearchStorySlides, SLIDE_TYPES, StoryTypes } from '@nx-bundesliga/bundesmaster/core';
import { DialogSelectMediaComponent, DialogSelectMediaData } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { DialogSelectVideoComponent } from '@nx-bundesliga/bundesmaster/feature-video-library';
import { BundesmasterUiStorySlideComponent } from '@nx-bundesliga/bundesmaster/stories/ui-story-slide';
import { Confirmable } from '@nx-bundesliga/bundesmaster/ui/dialog-confirm';
import { BundesmasterUiImageCropperDialogComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper-dialog';
import * as jwp from '@nx-bundesliga/shared/data-access/jwp-platform-api';
import { addCroppingParametersToImageUrl, cropImageToRatio } from '@nx-bundesliga/shared/util/functions';
import { DialogCtaEditComponent } from '../dialog-cta-edit/dialog-cta-edit.component';
import { DialogSlideEditComponent } from '../dialog-slide-edit/dialog-slide-edit.component';

@Pipe({
	name: 'highlightEmpty',
	standalone: true
})
export class HighlightEmptyPipe implements PipeTransform {
	transform(str: string): any {
		return str === '' ? 'default' : 'highlight';
	}
}

@Component({
	selector: 'bundesmaster-ui-story-slide-deck',
	standalone: true,
	imports: [HighlightEmptyPipe, CommonModule, FormsModule, ReactiveFormsModule, BundesmasterUiStorySlideComponent, MatButtonModule, MatIconModule, MatTooltipModule, MatMenuModule, MatFormFieldModule],
	templateUrl: './bundesmaster-ui-story-slide-deck.component.html',
	styleUrls: ['./bundesmaster-ui-story-slide-deck.component.scss']
})
export class BundesmasterUiStorySlideDeckComponent implements OnInit, OnChanges {
	@Input() slides: FormArray;
	@Input() storyType: StoryTypes = 'article_story';
	public slideTypes = SLIDE_TYPES;
	public storyForm: AbstractControl;

	constructor(private readonly dialog: MatDialog, private readonly assetPipe: AssetServicePipe, private readonly storiesApiService: BundesmasterStoriesApiService, @Optional() private controlContainer: ControlContainer) {}

	ngOnInit() {
		this.storyForm = this.controlContainer?.control;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.['storyType']?.currentValue) {
			this.slides.clearValidators();
			this.slides.addValidators(BundesmasterStoriesApiService.matchStorySlidesSortedValidator(this.storyType));
		}
	}

	moveToFront(index: number) {
		console.log('moveToFront', index);
		if (index > 0) {
			this.#moveArrayItem(index, 0);
		}
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	moveBefore(index: number) {
		console.log('moveBefore', index);
		if (index > 0) {
			this.#moveArrayItem(index, index - 1);
		}
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	moveNext(index: number) {
		console.log('moveNext', index);
		if (index < this.slides.value.length) {
			this.#moveArrayItem(index, index + 1);
		}
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	moveToEnd(index: number) {
		if (index < this.slides.value.length) {
			this.#moveArrayItem(index, this.slides.value.length);
		}
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	cloneSlide(index: number) {
		console.log('cloneSlide', index);
		const slide = this.slides.at(index);
		this.slides.insert(index, this.#getSlideForm({ ...slide.value, slideId: null }));
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	@Confirmable({ message: 'Do you really want to DELETE this slide?' })
	removeSlide(index: number) {
		console.log('removeSlide', index);
		this.slides.removeAt(index);
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	editSlide(index: number): Promise<number> {
		console.log('editSlide', index, this.storyType);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogSlideEditComponent, {
					data: {
						slide: this.slides.at(index).value,
						storyType: this.storyType
					},
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((slide) => {
					console.log('editSlide.afterClosed', slide);
					if (slide) {
						this.slides.at(index).patchValue(slide);
						this.storyForm?.markAllAsTouched();
						this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(index);
					}
				});
		});
	}

	editSlideCta(index: number): Promise<number> {
		console.log('editSlideCta', index, this.storyType);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogCtaEditComponent, {
					data: {
						cta: this.slides.at(index)?.value?.cta ?? null,
						storyType: this.storyType
					},
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((cta) => {
					console.log('editSlideCta.afterClosed', cta);
					if (cta || cta === null) {
						const slideForm = this.slides.at(index) as FormGroup;
						const ctaForm = this.storiesApiService.createSlideCtaFormGroup(cta);
						slideForm.removeControl('cta');
						slideForm.addControl('cta', ctaForm);
						this.storyForm?.markAllAsTouched();
						this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(index);
					}
				});
		});
	}

	editSlideImage(index: number): Promise<number> {
		console.log('editSlideImage', index, this.slides.at(index).value);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogSelectMediaComponent, {
					data: { dimensions: ['portrait'], selectedDimensions: ['portrait'] } as DialogSelectMediaData,
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((response: { asset: Asset; dimensions: AssetDimensions[] }) => {
					if (response && response.asset && response?.dimensions?.length > 0) {
						const imageUrl = addCroppingParametersToImageUrl(this.assetPipe.transform(response.asset.file), cropImageToRatio(response.asset.image.width, response.asset.image.height, 9 / 16));
						console.log('selected image', imageUrl);
						this.slides.at(index).patchValue({
							image: {
								url: imageUrl,
								copyright: response.asset.copyright
							}
						});
						this.storyForm?.markAllAsTouched();
						this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(index);
					}
				});
		});
	}

	editSlideVideo(index: number): Promise<number> {
		console.log('editSlideVideo', index, this.slides.at(index).value);
		return new Promise((resolve) => {
			this.dialog
				.open(DialogSelectVideoComponent, {
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((response: { video: jwp.Media }) => {
					if (response && response.video) {
						this.slides.at(index).patchValue({
							video: {
								vid: response.video.id,
								duration: Math.round(<number>response.video.duration)
							}
						});
						this.storyForm?.markAllAsTouched();
						this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(index);
					}
				});
		});
	}

	cropSlideImage(index: number): Promise<number> {
		console.log('editSlideImage', index, this.slides.at(index).value['image']['url']);
		return new Promise((resolve) => {
			this.dialog
				.open(BundesmasterUiImageCropperDialogComponent, {
					data: { imageUrl: this.slides.at(index).value['image']['url'], dimension: 'portrait', targetAspectRatio: 9 / 16 },
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((imageUrl) => {
					if (imageUrl) {
						this.slides.at(index).patchValue({
							image: {
								url: imageUrl
							}
						});
						this.storyForm?.markAllAsTouched();
						this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
						resolve(index);
					}
				});
		});
	}

	addNewSlide(type: SLIDE_TYPES, index = 0) {
		console.log('addNewSlide', type);
		this.slides.insert(index, this.#getSlideForm({ slideType: type } as Partial<OpenSearchStorySlides>));
		this.storyForm?.markAllAsTouched();
		this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });

		const moveMatchStorySlide = (index: number): number => {
			if (this.storyType === 'match_story') {
				const newPosition = this.storiesApiService.findMatchStorySlidePositionByPlaytime(this.slides?.value?.slice(1) ?? [], this.slides.at(index).value);
				if (index >= 0 && index !== newPosition) {
					this.#moveArrayItem(index, newPosition);
					return newPosition;
				}
				return index;
			}
			return index;
		};

		if ([SLIDE_TYPES.TEXT, SLIDE_TYPES.QUOTE, SLIDE_TYPES.GOAL, SLIDE_TYPES.SUB, SLIDE_TYPES.CARD, SLIDE_TYPES.LINEUP, SLIDE_TYPES.PROGRESS].includes(type)) {
			this.editSlide(index)
				.then((newIndex) => moveMatchStorySlide(newIndex))
				.then((newIndex) => this.editSlideImage(newIndex))
				.then((newIndex) => this.cropSlideImage(newIndex));
		}
		if (type === SLIDE_TYPES.VIDEO) {
			this.editSlide(index)
				.then((newIndex) => moveMatchStorySlide(newIndex))
				.then((newIndex) => this.editSlideVideo(newIndex));
		}
	}

	#getSlideForm(slide: Partial<OpenSearchStorySlides>) {
		console.log('getSlideForm', slide, this.storiesApiService.createSlideFormGroup(slide, this.storyType));
		return this.storiesApiService.createSlideFormGroup(slide, this.storyType);
	}

	#moveArrayItem(from: number, to: number): void {
		if (from !== to) {
			const item = this.slides.at(from);
			this.slides.removeAt(from);
			this.slides.insert(to, item);
			this.storyForm?.markAllAsTouched();
			this.storyForm?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
		}
	}

	protected readonly SLIDE_TYPES = SLIDE_TYPES;
}
