<mat-form-field>
	<mat-label>Match</mat-label>
	<mat-hint *ngIf="hasValue && formControl.value | resolveMatchId | async as resolvedMatch">
		<ng-container *ngIf="resolvedMatch !== formControl.value"> {{resolvedMatch}} </ng-container>
	</mat-hint>
	<mat-spinner matSuffix *ngIf="matches.status === 'loading'" [diameter]="15"></mat-spinner>
	<input matInput type="text" [formControl]="formControl" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
		<mat-option *ngFor="let match of matches.items" [value]="match.matchId">
			<div class="d-flex flex-column option-padding">
				<div>MD{{match.matchday}}: {{ match.home.threeLetterCode }} vs. {{ match.guest.threeLetterCode }}</div>
				<small>{{match.season}} | {{match.competitionName}} | {{match.matchId}}</small>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
