<h2 mat-dialog-title>Select LLM Tag</h2>

<mat-dialog-content class="mat-typography">
	<mat-selection-list (selectionChange)="selectionChange($event)" [ngModel]="selectedLlmTags">
		<mat-list-option *ngFor="let tag of possibleLlmTags" [value]="tag">{{ tag }}</mat-list-option>
	</mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
