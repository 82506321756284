import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlLineupsComponent } from './bl-lineups.component';

@NgModule({
	declarations: [BlLineupsComponent],
	exports: [BlLineupsComponent],
	imports: [CommonModule]
})
export class BlLineupsModule {}
