import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LogoutComponent } from './logout.component';

const routes: Routes = [
	{
		path: 'logout',
		component: LogoutComponent
	}
];

@NgModule({
	imports: [CommonsModule, RouterModule.forChild(routes)],
	exports: [LogoutComponent],
	declarations: [LogoutComponent]
})
export class LogoutModule {}
