<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div class="event substitutionEvent side_{{side}}">
	<div class="head">
		<div class="playtime">
			<livetickerevent-playtime [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
			<livetickerevent-headline [headline]="'LIVETICKER.EVENTS.' + liveEntry.entryType | translate" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
		</div>
	</div>
	<div class="body" [ngClass]="{'no-playerImage': !(clubData && clubData[side]?.dflDatalibraryClubId)}">
		<div class="player player-in">
			<div class="substitution">
				<img [alt]="liveEntry.entryType" src="/assets/liveticker/substitution_in.svg" />
			</div>
			<div class="image" *ngIf="clubData && clubData[side]?.dflDatalibraryClubId">
				<player-image
					class="scorer-image"
					[playerImage]="liveEntry.detail.in.imageUrl | playerImage: 'FACE_CIRCLE' | imageService:0:64"
					[dimension]="64"
					placeHolderImg="/assets/placeholder/player-circle-default.png"
					[language]="language"
					[competition]="competition"
					[playerName]="liveEntry?.detail?.in?.name"
					[enabledLinking]="enablePlayerLinking"></player-image>
				<span class="clublogo" [title]="clubData[side]?.nameFull">
					<clublogo *ngIf="isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
					<clublogo *ngIf="!isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [width]="27" [height]="18"></clublogo>
				</span>
			</div>
			<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking && liveEntry?.detail?.in?.name ? (['/', language, competition, 'route-player', liveEntry.detail.in.name | slugify] | translateRoute) : null">
				<span class="first">{{liveEntry.detail.in.name | livetickerEventPlayername:'first'}}</span>
				<span class="last">{{liveEntry.detail.in.name | livetickerEventPlayername:'last'}}</span>
			</a>
		</div>
		<div class="player player-out">
			<div class="substitution">
				<img [alt]="liveEntry.entryType" src="/assets/liveticker/substitution_out.svg" />
			</div>
			<div class="image" *ngIf="clubData && clubData[side]?.dflDatalibraryClubId">
				<player-image
					class="scorer-image"
					[playerImage]="liveEntry.detail.out.imageUrl | playerImage: 'FACE_CIRCLE' | imageService:0:64"
					[dimension]="64"
					placeHolderImg="/assets/placeholder/player-circle-default.png"
					[language]="language"
					[competition]="competition"
					[playerName]="liveEntry?.detail?.out?.name"
					[enabledLinking]="enablePlayerLinking"></player-image>
				<span class="clublogo" [title]="clubData[side]?.nameFull">
					<clublogo *ngIf="isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
					<clublogo *ngIf="!isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [width]="27" [height]="18"></clublogo>
				</span>
			</div>
			<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking && liveEntry?.detail?.out?.name ? (['/', language, competition, 'route-player', liveEntry.detail.out.name | slugify] | translateRoute) : null">
				<span class="first">{{liveEntry.detail.out.name | livetickerEventPlayername:'first'}}</span>
				<span class="last">{{liveEntry.detail.out.name | livetickerEventPlayername:'last'}}</span>
			</a>
		</div>
	</div>
</div>
