<div class="clubmetricprogression-card">
	<div class="metric-box" *ngFor="let progress of progression; let pindex=index">
		<div class="playtime" *ngIf="progress?.playtime && !progress?.matchSection">
			<ng-container *ngIf="(playtime?.minute === progress?.playtime?.minute && playtime?.injuryTime === progress?.playtime?.injuryTime) else displayPlaytime">{{ 'NOW' | translate }}</ng-container>
			<ng-template #displayPlaytime>
				<div *ngIf="progress?.playtime?.minute >= 0" class="minute">{{progress?.playtime?.minute}}'</div>
				<div *ngIf="progress?.playtime?.minute && progress?.playtime?.injuryTime" class="injuryTime">+ {{progress?.playtime?.injuryTime}}</div>
			</ng-template>
		</div>
		<div class="matchsection" *ngIf="progress?.matchSection">{{"LIVETICKER." + progress?.matchSection | translate}}</div>
		<div class="metric">
			<span class="triangle" *ngIf="pindex > 0" [ngClass]="{'down': progress?.value < progression[pindex - 1]?.value}"></span>
			<span>
				{{progress?.value | number:"1.0-1"}}
				<ng-container *ngIf="progress?.unit">{{progress?.unit}}</ng-container>
			</span>
		</div>
	</div>
	<clublogo [clubId]="club?.dflDatalibraryClubId" [clubLogoUrl]="club?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
</div>
