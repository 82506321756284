import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';

export interface ImageInfoResponse {
	original: {
		width: number;
		height: number;
	};
	shrinked: {
		width: number;
		height: number;
		base64: string;
	};
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterImageinfoApiService {
	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	public imageInfo(imageUrl: string): Observable<ImageInfoResponse> {
		return this.http.post<ImageInfoResponse>(`${this.environment.apis.content.url}/imageinfo`, imageUrl);
	}
}
