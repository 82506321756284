import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Club, ClubStateData, LivetickerSides } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import idx from 'idx';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { ClubService } from '../../../services/club/club.service';
import { LoadingService } from '../../../services/loading/loading.service';
import * as _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'create-custom-match',
	templateUrl: './create-custom-match.component.html',
	styleUrls: ['./create-custom-match.component.scss']
})
export class CreateCustomMatchComponent implements OnInit {
	public processing = false;
	public editMode = false;
	public editLoading = false;
	public editLoadingFailed = false;
	public clubs: Club[] = [];
	public filteredClubs = [];
	public clubSearch = '';
	public selectedClub: Club;
	public defaultCompetitionName = 'DFB-POKAL';
	public defaultLanguage = 'en';
	public language = 'en';
	public availableLanguages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];
	public competitionNames = ['FRIENDLY', 'CHAMPIONS-LEAGUE', 'DFB-POKAL', 'EUROPA-LEAGUE', 'NATIONS-LEAGUE', 'CONFERENCE-LEAGUE', 'CLUB-WORLD-CUP', 'WORLD-CUP', 'EUROPA-CUP', 'OLYMPIC-GAMES'];
	public matchId = '';
	public customMatch = {
		competitionName: 'FRIENDLY',
		dflDatalibraryCompetitionId: 'DFL-COM-CUSTOM',
		dflDatalibraryMatchId: 'DFL-MAT-CUSTOM',
		dflDatalibraryMatchdayId: 'DFL-DAY-CUSTOM',
		dflDatalibrarySeasonId: 'DFL-SEA-CUSTOM',
		matchStatus: 'PRE_MATCH',
		plannedKickOff: '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
		stadiumName: '',
		matchdayLabel: '',
		matchName: '',
		hideHeader: true,
		teams: {
			away: {
				dflDatalibraryClubId: '',
				gradientStartColor: '',
				gradientEndColor: '',
				color: '',
				logoUrl: '',
				nameFull: '',
				nameShort: '',
				textColor: '#FFFFFF',
				threeLetterCode: ''
			},
			home: {
				dflDatalibraryClubId: '',
				gradientStartColor: '',
				gradientEndColor: '',
				color: '',
				logoUrl: '',
				nameFull: '',
				nameShort: '',
				textColor: '#FFFFFF',
				threeLetterCode: ''
			}
		}
	};

	constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private toastr: ToastrService, private loading: LoadingService, private configService: ConfigService, private clubService: ClubService) {}

	/**
	 *
	 */
	ngOnInit() {
		this._fetchClubs();
		this.route.params.subscribe((param) => {
			this.language = param['language'] ? param['language'] : this.defaultLanguage;
			this.matchId = param['customMatchId'] ? param['customMatchId'] : '';
			this.editMode = !!(param['language'] && param['customMatchId']);
			console.log('params', this.editMode, param);
			if (this.editMode) {
				this.fetchCustomMatch(this.language, this.matchId);
			}
		});
	}

	private _fetchClubs() {
		this.clubService
			.getAllClubs('alphabetical')
			.pipe(map((clubResp: ClubStateData) => clubResp.bundesliga.concat(clubResp['2bundesliga'])))
			.subscribe(
				(data: Club[]) => {
					this.clubs = data;
					this.filteredClubs = this.clubs;
				},
				(error) => {
					this.clubs = [];
					this.filteredClubs = [];
				}
			);
	}

	public filterClubs() {
		if (!this.clubs) {
			return;
		}
		// get the search keyword
		if (!this.clubSearch || this.clubSearch === '') {
			this.filteredClubs = this.clubs;
			return;
		} else {
			this.clubSearch = this.clubSearch.toLowerCase();
		}
		// filter the players
		this.filteredClubs = this.clubs.filter((item) => item.name.full.toLowerCase().trim().includes(this.clubSearch.toLowerCase()) || item.name.small.toLowerCase().trim().includes(this.clubSearch.toLowerCase()));
	}
	public trackByClubId(index, item) {
		if (item.id) {
			return item.id;
		}
	}

	public setTeamColor(side: LivetickerSides, color: string) {
		this.customMatch.teams[side].color = color;
		this.customMatch.teams[side].gradientStartColor = color;
		this.customMatch.teams[side].gradientEndColor = color;
	}

	public setClub(side: LivetickerSides) {
		this.customMatch.teams[side] = {
			...this.customMatch.teams[side],
			dflDatalibraryClubId: this.selectedClub.externalClubIds.dflDatalibraryClubId,
			color: this.selectedClub.colors.club.primary.hex,
			gradientStartColor: this.selectedClub.colors.club.primary.hex,
			gradientEndColor: this.selectedClub.colors.club.primary.hex,
			logoUrl: idx(this.selectedClub, (_) => _.logos[0].uri) || '',
			nameFull: this.selectedClub.name.full,
			nameShort: this.selectedClub.name.small,
			textColor: this.selectedClub.colors.club.primaryText.hex,
			threeLetterCode: this.selectedClub.threeLetterCode
		};
	}

	public fetchCustomMatch(language, matchId): void {
		this.loading.setLoading(true);
		this.editLoading = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/custom-match`;

		const urlSuffix = language + '/' + matchId;

		const url = urlBase + '/' + urlSuffix;

		console.log(`Getting custom match from API: `, url);

		this.http.request('get', url).subscribe(
			(success) => {
				this.toastr.success('Custom match retrieved successfully!', '' + language + ' ' + matchId);
				this.customMatch = success as any;
				this.setTeamColor('home', this.customMatch.teams.home.gradientStartColor);
				this.setTeamColor('away', this.customMatch.teams.away.gradientStartColor);
				this.loading.setLoading(false);
				this.editLoading = false;
				this.editLoadingFailed = false;
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error: ' + language + ' ' + matchId);
				this.editLoading = false;
				this.editLoadingFailed = true;
			}
		);
	}
	public onSubmit(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/custom-match`;

		const urlSuffix = this.editMode ? '' + this.language + '/' + this.matchId : '';

		const url = urlBase + '/' + urlSuffix;
		const matchIdentifiers = this.editMode ? {} : { language: this.language, matchId: this.matchId };
		const payload = { ...this.customMatch, ...matchIdentifiers };
		const method = this.editMode ? 'put' : 'post';

		console.log(`Sending custom match to API: `, url);
		console.log('custom match', payload);

		this.http.request(method, url, { body: payload }).subscribe(
			(success) => {
				this.toastr.success('Custom match saved successfully!', 'Published');
				this.loading.setLoading(false);
				this.processing = false;
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
			}
		);
	}
	public onDelete(): void {
		if (window.confirm('Do you really want to delete the match and all liveticker entries posted to it? This action cannot be undone.')) {
			this.loading.setLoading(true);
			this.processing = true;
			const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/custom-match`;

			const urlSuffix = this.language + '/' + this.matchId;

			const url = urlBase + '/' + urlSuffix;

			console.log(`Sending delete match to API: `, url);

			this.http.request('delete', url).subscribe(
				(success) => {
					this.toastr.success('Custom match deleted successfully!', 'Deleted');
					this.loading.setLoading(false);
					this.processing = false;
				},

				(error) => {
					const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
					this.loading.setLoading(false);
					this.toastr.error(message, 'Error deleting match');
					this.processing = false;
				}
			);
		} else {
			this.toastr.warning('Custom match has NOT been deleted!', 'Deletion aborted!');
		}
	}
}
