import { NgModule } from '@angular/core';
import { StackedcolumnChartComponent } from './stackedcolumn-chart.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule],
	exports: [StackedcolumnChartComponent],
	declarations: [StackedcolumnChartComponent],
	providers: []
})
export class StackedcolumnChartModule {}
