import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClubPersons, ClubPlayer, ClubPlayers } from '@nx-bundesliga/models';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BundesligaRestService } from '../common/bundesliga-rest.service';
import { map, share, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PersonService extends BundesligaRestService {
	public language = 'en';
	public personCache = { 'bundesliga': {}, '2bundesliga': {} };
	static getPlayerAliasFirstName(names: any): string {
		if (names.alias) {
			const indexOf = names.alias.indexOf(' ');
			if (indexOf === -1) {
				return '';
			}

			return names.alias.slice(0, indexOf);
		}

		return names.first;
	}

	static getPlayerAliasLastName(names: any): string {
		if (names.alias) {
			const indexOf = names.alias.indexOf(' ');
			if (indexOf === -1) {
				return names.alias;
			}

			return names.alias.slice(names.alias.indexOf(' '));
		}

		return names.last;
	}

	static getPlayerAge(birthdate: string): number {
		const birthday = +new Date(birthdate);
		return Math.floor((Date.now() - birthday) / 31557600000);
	}

	constructor(private http: HttpClient) {
		super(http);
	}

	public getPersonLastNameByFullname(playerFullname: string): string {
		const playerName = {
			'alias': playerFullname
		};
		return PersonService.getPlayerAliasLastName(playerName);
	}

	public getPersonFirstNameByFullname(playerFullname: string): string {
		const playerName = {
			'alias': playerFullname
		};
		return PersonService.getPlayerAliasFirstName(playerName);
	}

	public getPersonAliasTruncatedByFullname(playerFullname: string, maxLength: number, delimiter: string): string {
		let playerName = playerFullname;
		if (!delimiter) {
			delimiter = '...';
		}
		if (!maxLength || maxLength <= 0) {
			maxLength = 255;
		}
		if (playerName.length > maxLength) {
			playerName = playerName.slice(0, maxLength) + delimiter;
		}
		return playerName;
	}

	public getPersonBySlug(slugifiedNameSmall: string): Observable<ClubPlayer> {
		this.settings = environment.endpoints.person;
		return this.get<ClubPlayer>(`personbyslug/${slugifiedNameSmall}`, false);
	}

	public getPersonsByClub(clubId: string): Observable<ClubPersons> {
		this.settings = environment.endpoints.person;
		return this.get<ClubPersons>(`personsbyclub/${clubId}`, false);
	}

	public getPersonsByClubAndSeason(clubId: string, seasonId: string): Observable<ClubPersons> {
		this.settings = ''; //environment.endpoints.person;
		return this.get<ClubPersons>(`seasons/${seasonId?.toString()}/person/personsbyclub/${clubId?.toString()}`, false);
	}

	public getAllPersonsForCompetition(competitionName = 'bundesliga'): Observable<ClubPlayers> {
		this.settings = environment.endpoints.player;
		const competitionMapped = competitionName === '2bundesliga' ? 'bundesliga2' : competitionName;
		const apiCall = this.get<{ [competition: string]: ClubPlayers }>(competitionName).pipe(
			tap((data) => {
				this.personCache[competitionName] = data[competitionMapped];
			})
		);
		return (Object.keys(this.personCache?.[competitionName] ?? {})?.length ?? 0 > 0 ? of({ [competitionMapped]: this.personCache[competitionName] }) : apiCall).pipe(
			map((data) => {
				return data.hasOwnProperty(competitionMapped) ? data[competitionMapped] : {};
			}),
			share()
		);
	}

	public getAllPersons(competitionId = 'DFL-COM-000001'): Observable<ClubPlayers & { club: string }> {
		this.settings = environment.endpoints.player;

		switch (competitionId?.toUpperCase()) {
			case 'DFL-COM-J0002E': {
				//return this.getPersonsByClubAndSeason('DFL-CLU-J00001', 'DFL-SEA-0001K8').pipe(
				return this.getAllPersonsForCompetition('euro_championship').pipe(
					map((data: ClubPlayers) => Object.entries(data).map((item: [string, any]) => ({ ...item[1], club: item[0] }))),
					map((data: any) =>
						data.reduce((acc: any[], cur: any) => {
							const club = cur.club;
							const players = Object.entries(cur)
								.filter(([pos, person]) => pos !== 'club')
								.map(([pos, person]: [any, any]) => person.map((per: any) => ({ ...per, club })));
							return acc.concat(players);
						}, [])
					)
				);
			}
			case 'DFL-COM-000001':
			case 'DFL-COM-000002':
			case 'DFL-COM-000003':
			case 'DFL-COM-000004':
			case 'DFL-COM-000005':
			default: {
				return forkJoin([this.getAllPersonsForCompetition('bundesliga'), this.getAllPersonsForCompetition('2bundesliga')]).pipe(
					map(([pbundesliga, pbundesliga2]) => ({ bundesliga: pbundesliga, '2bundesliga': pbundesliga2 })),
					map((data: { bundesliga: ClubPlayers; '2bundesliga': ClubPlayers }) => ({ ...data['bundesliga'], ...data['2bundesliga'] })),
					map((data: ClubPlayers) => Object.entries(data).map((item: [string, any]) => ({ ...item[1], club: item[0] }))),
					map((data: any) =>
						data.reduce((acc: any[], cur: any) => {
							const club = cur.club;
							const players = Object.entries(cur)
								.filter(([pos, person]) => pos !== 'club')
								.map(([pos, person]: [any, any]) => person.map((per: any) => ({ ...per, club })));
							return acc.concat(players);
						}, [])
					),
					map((data: any) => data.reduce((acc: any[], cur: any) => acc.concat(Object.values(cur)), []))
				);
			}
		}
	}
}
