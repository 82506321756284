import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEventVar, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-var',
	templateUrl: './livetickerevent-var.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-var.component.scss'],
	animations: [liveblogPostAnimation('.head .playtime, .head .headline, .head .logo-var, .body .detailText')]
})
export class LivetickereventVarComponent implements OnInit {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogAutoEventVar;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	public side: LivetickerSides = 'none';

	constructor() {}

	ngOnInit() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
	}
}
