<div class="container fixtures">
	<div class="row">
		<div class="col-12">
			<page-title>Livestream Manager</page-title>
			<page-title>Matchday {{matchdayNumber}} | Competition {{competitionId}} | Season {{seasonId}}</page-title>
		</div>
	</div>
	<div class="row">
		<div class="col-12 d-flex flex-column flex-md-row justify-content-center">
			<div class="fixtures-dropdown-wrapper" *ngIf="clubs && clubs.length > 0">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="clubName" (selectionChange)="onChangeClub(clubName)" [disableOptionCentering]="true">
						<mat-option value="">All Clubs</mat-option>
						<mat-option *ngFor="let club of clubs" [value]="club.threeLetterCode">{{club.name.small}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="competitionId" (selectionChange)="onChangeCompetition(competitionId)" [disableOptionCentering]="true">
						<mat-option value="" disabled>Competitions</mat-option>
						<mat-option *ngFor="let comp of competitionIds | keyvalue" [value]="comp.key">{{comp.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="matchdays && matchdays.length > 0">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="matchdayNumber" (selectionChange)="onChangeMatchday(matchdayNumber)">
						<mat-option [value]="0" disabled>All Matchdays</mat-option>
						<mat-option *ngFor="let matchdayOption of matchdays" [value]="matchdayOption" [ngClass]="{'font-weight-bold': matchdayOption === defaultMatchdayNumber}">{{ "Matchday" }} {{matchdayOption}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="seasonsIds">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="seasonId" (selectionChange)="onChangeSeason(seasonId)" [disableOptionCentering]="true">
						<mat-option *ngFor="let seasonOption of seasonsIds | keyvalue" [value]="seasonOption.key">{{seasonOption.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="fixturesWrapper" *ngIf="matches && matches.length > 0">
		<div class="container-fluid matchInfos" *ngFor="let match of matches; trackBy: trackByMatchId; let i=index">
			<div class="row">
				<div class="col-12 col-md-8">
					<div class="matchDataRow" id="fixtures_{{match.dflDatalibraryMatchId}}">
						<div class="teamHome clubHome">
							<a class="matchcenterLink">
								<div class="clubName">{{match.teams.home.nameFull}}</div>
								<div class="clubNameShort">{{match.teams.home.nameShort}}</div>
								<div class="clubLogo">
									<clublogo [dimension]="40" [title]="match.teams.home.nameFull" [clubId]="match.teams.home.dflDatalibraryClubId" [clubLogoUrl]="match.teams.home.logoUrl"></clublogo>
								</div>
							</a>
						</div>

						<div class="matchResults text-center">
							<a class="matchcenterLink">
								<div *ngIf="match.score; then tplScore else tplNoScore"></div>
								<ng-template #tplScore>
									<div class="score">
										<div *ngIf="matchesLiveState[i] === true" class="isLiveWrapper">
											<live-animation *ngIf="match.minuteOfPlay && match.minuteOfPlay.minute && match.minuteOfPlay.minute >= 0" [playtime]="match.minuteOfPlay" [matchStatus]="match.matchStatus" layout="regular"></live-animation>
										</div>
										<div *ngIf="matchesLiveState[i] === false" class="isNotLiveWrapper"></div>
										<span class="scoreLive">
											<span>{{ match.score.home.live }}</span>
											:
											<span>{{ match.score.away.live }}</span>
										</span>
										<span *ngIf="matchesLiveState[i] === false" class="scoreHt">
											(
											<span>{{ match.score.home.halftime }}</span>
											:
											<span>{{ match.score.away.halftime }}</span>
											)
										</span>
									</div>
								</ng-template>
								<ng-template #tplNoScore>
									<div class="score">
										<div class="scoreDefault">
											<span>-</span>
											:
											<span>-</span>
										</div>
									</div>
								</ng-template>
							</a>
						</div>

						<div class="teamAway clubAway">
							<a class="matchcenterLink">
								<div class="clubLogo">
									<clublogo [dimension]="40" [title]="match.teams.away.nameFull" [clubId]="match.teams.away.dflDatalibraryClubId" [clubLogoUrl]="match.teams.away.logoUrl"></clublogo>
								</div>
								<div class="clubName">{{match.teams.away.nameFull}}</div>
								<div class="clubNameShort">{{match.teams.away.nameShort}}</div>
							</a>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="matchDataRow">
						<ng-template #livestreamLoading>
							<dfl-loader [loading]="true"></dfl-loader>
						</ng-template>
						<ng-container *ngIf="livestreams[match?.dflDatalibraryMatchId]?.value && (livestreams[match?.dflDatalibraryMatchId]?.value | async) as livestream else livestreamLoading">
							<mat-form-field>
								<mat-label>{{match.dflDatalibraryMatchId}}</mat-label>
								<input matInput placeholder="https://" [tabindex]="i" [value]="livestream?.hls || ''" autocomplete="off" #hlsInput />
							</mat-form-field>
							<button mat-icon-button color="accent" [disabled]="livestreams[match?.dflDatalibraryMatchId]?.loading" (click)="setLivestream(match?.dflDatalibraryMatchId, hlsInput.value)" [matBadge]="livestreams[match?.dflDatalibraryMatchId]?.saveSuccess ? '✓' : ''" matBadgeColor="accent">
								<mat-icon>save</mat-icon>
							</button>
							<button mat-icon-button color="warn" [disabled]="livestreams[match?.dflDatalibraryMatchId]?.loading" (click)="deleteLivestream(match?.dflDatalibraryMatchId)" [matBadge]="livestreams[match?.dflDatalibraryMatchId]?.deleteSuccess ? '✓' : ''" matBadgeColor="warn">
								<mat-icon>delete</mat-icon>
							</button>
						</ng-container>
					</div>
				</div>
			</div>

			<!--/matchData-->
		</div>
		<!--/ngFor-->

		<div *ngIf="!matches[0].plannedKickOff" class="container kickoffDatetime">
			<div class="row">
				<div class="col-12">
					<span>{{ "FIXTURES.matchdayNotFixed" }}</span>
				</div>
			</div>
		</div>
	</div>
	<dfl-loader [loading]="loading"></dfl-loader>

	<div class="container-fluid" *ngIf="clubName === ''">
		<div class="row fixturesNavigation">
			<div class="col-6">
				<button *ngIf="matchdayNumber > 1" mat-raised-button color="primary" (click)="prevMatchday()" class="float-right">Previous Matchday</button>
			</div>
			<div class="col-6">
				<button *ngIf="matchdayNumber < 34" mat-raised-button color="primary" (click)="nextMatchday()" class="float-left">Next Matchday</button>
			</div>
		</div>
	</div>
</div>
