import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { BundesmasterMatchdayApiService } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'resolveMatchDayId'
})
export class ResolveMatchDayIdPipe implements PipeTransform {
	constructor(private readonly service: BundesmasterMatchdayApiService) {}

	transform(matchdayId: string): Observable<string> {
		return this.service.getMatchday(matchdayId).pipe(
			map((matchday) => (matchday ? `Matchday ${matchday.matchday} (${matchday.season})` : matchdayId)),
			startWith(matchdayId)
		);
	}
}
