import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { BarProgressChartModule } from '../stats-charts/bar-progress-chart/bar-progress-chart.module';
import { StatsPlayerprogressrankingCardComponent } from './component/stats-playerprogressranking-card/stats-playerprogressranking-card.component';

@NgModule({
	imports: [SharedModule, BarProgressChartModule],
	exports: [StatsPlayerprogressrankingCardComponent],
	declarations: [StatsPlayerprogressrankingCardComponent],
	providers: []
})
export class StatsPlayerprogressrankingCardModule {}
