import { Article } from './article.model';

export type StorySuggestionLanguages = 'en' | 'de';
export const storySuggestionLanguagesOptions: StorySuggestionLanguages[] = ['en', 'de'];

export interface StorySuggestionResponse {
	page: number;
	size: number;
	total: number;
	items: StorySuggestion[];
}

export type StorySuggestionStatus = 'PROCESSING' | 'SUCCESS' | 'FAILED';

export type SlideScene = 'interview' | 'player' | 'goal' | 'celebration' | 'tackling' | 'action' | 'stadium' | 'unknown';

export interface StorySuggestionPromptParams {
	minSlides?: string | number;
	maxSlides?: string | number;
	maxContentSlides?: string | number;
	maxQuoteSlides?: string | number;
	persona?: StorySuggestionPromptPersona;
}

export type StorySuggestionPromptPersona = 'ENTHUSIAST' | 'FAN' | 'NEUTRAL' | 'UNINTERESTED';
export const StorySuggestionPersonaOptions: { [persona: string | StorySuggestionPromptPersona]: string } = {
	'ENTHUSIAST': 'ENTHUSIAST: An avid soccer and bundesliga enthusiast',
	'FAN': 'FAN: A fan of an particular club',
	'NEUTRAL': 'NEUTRAL: neutral style',
	'UNINTERESTED': 'UNINTERESTED: A reader rather uninterested in soccer'
};

export interface StorySlideSuggestionBase {
	readonly actors?: readonly string[];
	readonly scene?: SlideScene;
	readonly title?: string;
	readonly image?: {
		url?: string;
		copyright?: string;
	};
}

export interface StoryQuoteSlideSuggestion extends StorySlideSuggestionBase {
	readonly type: 'quote';
	readonly quote: string;
}

export interface StoryTextSlideSuggestion extends StorySlideSuggestionBase {
	readonly type: 'text';
	readonly description: string;
}

export type StorySlideSuggestion = StoryQuoteSlideSuggestion | StoryTextSlideSuggestion;

export interface StorySuggestion {
	id: string;
	requestId: string;
	/** @nullable */
	articleId?: string | null;
	/** @nullable */
	language?: StorySuggestionLanguages | null;
	/** @nullable */
	article?: Article | null;
	/** @nullable */
	story?: StorySlideSuggestion[] | null;
	/** @nullable */
	promptParams?: StorySuggestionPromptParams | null;
	/** @nullable */
	modified_date?: string | null;
	/** @nullable */
	created_date?: string | null;
	/** @nullable */
	errorMessage?: any | null;
	/** @nullable */
	status?: StorySuggestionStatus;
	/** @nullable */
	prompts?: {
		story?: {
			prompt?: string | null;
			response?: any | null;
		};
		assignImages?: {
			prompt?: string | null;
			response?: any | null;
		};
		assignAdditionalImages?: {
			[slide: string]: {
				prompt?: string | null;
				response?: any | null;
			};
		};
	};
}
