import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PersonService } from '../person/person.service';

@Injectable({
	providedIn: 'root'
})
export class TributeOptionsService {
	constructor(private personService: PersonService) {}

	private normalizeName(input?: string): string {
		return (input?.toLowerCase() ?? '')
			.trim()
			.normalize('NFD')
			.replace(/\p{Diacritic}/gu, '');
	}

	public getTributeOptions(homeClub: string, awayClub: string, competitionId = 'DFL-COM-000001', seasonId = 'DFL-SEA-0001K8'): Observable<any> {
		const clubs: string[] = [homeClub, awayClub];
		return this.personService.getAllPersons(competitionId).pipe(
			catchError(() => {
				return combineLatest(
					[homeClub, awayClub].map((clubId) =>
						this.personService.getPersonsByClubAndSeason(clubId, seasonId).pipe(
							map((data) => data.players),
							map((data: any) =>
								Object.entries(data).reduce((acc: any[], cur: any) => {
									const club = homeClub;
									const players = cur[1].map((person: any) => ({ ...person, club }));
									return acc.concat(players);
								}, [])
							)
						)
					)
				).pipe(map((playersArr) => [].concat(...playersArr)));
			}),
			map((data: any) => data.map((item: any) => ({ ...item, value: item.name.hasOwnProperty('alias') && item.name.alias ? item.name.alias : item.name.full, valueLastName: item.name.hasOwnProperty('alias') && item.name.alias ? item.name.alias : item.name.last }))),
			map((data: any) => ({
				allplayers: data,
				matchplayers: data.filter((player) => clubs.includes(player.club))
			})),
			map((persons: any) => {
				return {
					collection: [
						{
							trigger: '#',
							allowSpaces: true,
							requireLeadingSpace: false,
							values: persons.allplayers,
							lookup: (item) => (item.name?.alias ?? item.name?.full ?? '') + '|' + this.normalizeName(item.name?.alias ?? item.name?.full ?? ''),
							selectTemplate: (item) => {
								return item.original?.value;
							},
							menuItemTemplate: (item) => {
								return '<img src="' + item.original?.playerImages?.FACE_CIRCLE + '" height="60" width="60" alt="" style="vertical-align:middle;" />&nbsp;' + `${item.string?.split('|')?.[0] ?? ''}`;
							},
							searchOpts: {
								pre: '<span>',
								post: '</span>'
							}
						},
						{
							trigger: '@',
							allowSpaces: true,
							requireLeadingSpace: false,
							values: persons.matchplayers,
							lookup: (item) => (item.name?.alias ?? item.name?.full ?? '') + '|' + this.normalizeName(item.name?.alias ?? item.name?.full ?? ''),
							selectTemplate: (item) => {
								return item.original?.valueLastName;
							},
							menuItemTemplate: (item) => {
								return '<img src="' + item.original?.playerImages?.FACE_CIRCLE + '" height="60" width="60" alt="" style="vertical-align:middle;" />&nbsp;' + `${item.string?.split('|')?.[0] ?? ''}`;
							},
							searchOpts: {
								pre: '<span>',
								post: '</span>'
							}
						},
						{
							trigger: '<<',
							allowSpaces: false,
							values: persons.matchplayers.filter((item) => item.club === homeClub),
							lookup: (item) => item.shirtNumber,
							selectTemplate: (item) => {
								return item.original?.value;
							},
							menuItemTemplate: (item) => {
								return '<img src="' + item.original?.playerImages?.FACE_CIRCLE + '" height="60" width="60" alt="" style="vertical-align:middle;" />&nbsp;' + item.string + ' - ' + item.original.value;
							},
							searchOpts: {
								pre: '<span>',
								post: '</span>'
							}
						},
						{
							trigger: '>>',
							allowSpaces: false,
							values: persons.matchplayers.filter((item) => item.club === awayClub),
							lookup: (item) => item.shirtNumber,
							selectTemplate: (item) => {
								return item.original?.value;
							},
							menuItemTemplate: (item) => {
								return '<img src="' + item.original?.playerImages?.FACE_CIRCLE + '" height="60" width="60" alt="" style="vertical-align:middle;" />&nbsp;' + item.string + ' - ' + item.original.value;
							},
							searchOpts: {
								pre: '<span>',
								post: '</span>'
							}
						}
					],
					allowSpaces: true
				};
			})
		);
	}
}
