import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Club, Match } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { finalize, Observable, of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ClubService } from '../../../services/club/club.service';
import { DflDatalibraryMatchdayService } from '../../../services/dfldatalibrary/dflDatalibraryMatchday.service';
import { DflDatalibrarySeasonService } from '../../../services/dfldatalibrary/dflDatalibrarySeason.service';
import { MatchesService } from '../../../services/matches/matches.service';
import { LivestreamService } from '../../molecules/livestream/livestream.service';

@Component({
	selector: 'livestream-manager-page',
	templateUrl: './livestream-manager.component.html',
	styleUrls: ['./livestream-manager.component.scss']
})
export class LivestreamManagerComponent implements OnInit, OnDestroy {
	public season: string = null;
	public matchdays: Array<number>;
	public clubName = '';
	public numberOfMatchdays = {
		'DFL-COM-000001': 34,
		'DFL-COM-000002': 34,
		'DFL-COM-000003': 1,
		'DFL-COM-000004': 2,
		'DFL-COM-000005': 2
	};
	public clubs: Club[];
	public seasons: string[];

	public defaultClubName = '';
	public defaultSeasonId = 'DFL-SEA-0001K7';
	public defaultMatchdayNumber = 1;
	public defaultCompetitionId = 'DFL-COM-000001';

	public matchdayNumber = 1;
	public language = 'en';
	public competitionId = 'DFL-COM-000001';
	public seasonId = 'DFL-SEA-0001K7';

	public seasonsIds = {
		'DFL-SEA-0001K8': '2024/2025',
		'DFL-SEA-0001K7': '2023/2024',
		'DFL-SEA-0001K6': '2022/2023',
		'DFL-SEA-0001K5': '2021/2022',
		'DFL-SEA-0001K4': '2020/2021',
		'DFL-SEA-0001K3': '2019/2020',
		'DFL-SEA-0001K2': '2018/2019',
		'DFL-SEA-0001K1': '2017/2018',
		'DFL-SEA-0001K0': '2016/2017'
	};

	public competitionIds = {
		'DFL-COM-000001': 'Bundesliga',
		'DFL-COM-000002': '2. Bundesliga',
		'DFL-COM-000003': 'Supercup',
		'DFL-COM-000004': 'Relegation BL',
		'DFL-COM-000005': 'Relegation 2BL'
	};
	loading = true;
	data: any;
	public matches: Match[];
	public matchesShowKickoffDate: any;
	public matchesLiveState: any;
	public matchesOverTimeLiveState: any;
	public matchesPenaltyState: any;
	public matchcenterUrls: any;
	public currentMatchdayNumber: string;
	public currentSeasonId: string;
	public clubsObject: any = {};
	public livestreams: { [key: string]: { value?: Observable<{ hls?: string; MatchId?: string }>; loading?: boolean; saveSuccess?: boolean; deleteSuccess?: boolean } } = {};

	private _clubServiceSubscription: Subscription;
	private _matchesServiceSubscription: Subscription;

	constructor(
		private livestreamService: LivestreamService,
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient,
		private configService: ConfigService,
		private clubService: ClubService,
		private matchesService: MatchesService,
		private matchdayService: DflDatalibraryMatchdayService,
		private seasonService: DflDatalibrarySeasonService
	) {}

	/**
	 *
	 */
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.competitionId = params['competitionId'] && params['competitionId'].startsWith('DFL-COM-') ? params['competitionId'] : this.defaultCompetitionId;
			this.seasonId = params['seasonId'] && params['seasonId'].startsWith('DFL-SEA-') ? params['seasonId'] : this.configService.getSettings([this.competitionId, 'season', 'dflDatalibrarySeasonId'], this.defaultSeasonId);
			this.defaultMatchdayNumber = this.configService.getSettings([this.competitionId, 'matchday', 'matchdayNumber'], 1);
			this.matchdayNumber = params['matchdayNumber'] ? parseInt(params['matchdayNumber'], 10) : this.configService.getSettings([this.competitionId, 'matchday', 'matchdayNumber'], this.defaultMatchdayNumber);
			this.clubName = params['clubName'] || this.defaultClubName;
			if (params['competitionId'] && params['competitionId'].startsWith('DFL-COM-') && params['seasonId'] && params['seasonId'].startsWith('DFL-SEA-') && params['matchdayNumber']) {
				this._fetchClubs();
				this._fetchMatchdays();
				this._fetchMatchData();
			} else {
				this.router.navigate([this.competitionId, this.seasonId, this.matchdayNumber], { relativeTo: this.route.parent });
			}
		});
	}

	ngOnDestroy() {
		if (this._clubServiceSubscription) {
			this._clubServiceSubscription.unsubscribe();
		}

		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}
	}

	/**
	 *
	 */
	public prevMatchday(): void {
		if (Number(this.matchdayNumber) > 1) {
			this.redirectToFixtures(null, null, Number(this.matchdayNumber) - 1);
		}
	}

	/**
	 *
	 * @private
	 */
	private _fetchMatchdays() {
		this.matchdays = Array.from(Array(this.numberOfMatchdays[this.competitionId])).map((x, i) => i + 1);
		// redirect all wrong matchdays to the default matchday
		if (this.matchdayNumber > this.numberOfMatchdays[this.competitionId]) {
			this.redirectToFixtures(null, null, this.defaultMatchdayNumber);
		}
	}

	/**
	 *
	 * @private
	 */
	private _fetchClubs() {
		const competitionIds = {
			'DFL-COM-000001': 'bundesliga',
			'DFL-COM-000002': '2bundesliga',
			'DFL-COM-000003': 'bundesliga',
			'DFL-COM-000004': 'bundesliga',
			'DFL-COM-000005': '2bundesliga'
		};
		this.clubService.getAllClubsByCompetitionAndSeason(competitionIds[this.competitionId], 'editorialorder').subscribe(
			(data: Club[]) => {
				this.clubs = data;
			},
			(error) => {
				this.clubs = [];
			}
		);
	}

	/**
	 *
	 */
	public nextMatchday(): void {
		if (Number(this.matchdayNumber) < this.numberOfMatchdays[this.competitionId]) {
			this.redirectToFixtures(null, null, Number(this.matchdayNumber) + 1);
		}
	}

	/**
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchday
	 * @param clubname
	 */
	public redirectToFixtures(competitionId: string, seasonId?: string, matchday?: any, clubname?: string) {
		this.router.navigate([competitionId || this.competitionId, seasonId || this.seasonId, matchday || this.matchdayNumber, clubname || this.clubName], {
			relativeTo: this.route.parent,
			replaceUrl: true
		});
	}

	/**
	 * onChangeSeason
	 * @param season
	 */
	public onChangeSeason(season: string): void {
		this.redirectToFixtures(null, season, null);
	}

	/**
	 * onChangeCompetition
	 * @param competition
	 */
	public onChangeCompetition(competition: string): void {
		this.redirectToFixtures(competition, null, null);
	}

	/**
	 * onChangeSeason
	 * @param season
	 */
	public onChangeMatchday(matchdayNumber: any): void {
		this.clubName = '';
		this.redirectToFixtures(null, null, matchdayNumber);
	}

	/**
	 * onChangeClub
	 * @param clubName
	 */
	public onChangeClub(clubName: string): void {
		this.matchdayNumber = 0;
		this.redirectToFixtures(null, null, 0, clubName);
	}

	private _fetchMatchData(): void {
		let subscription = null;
		const isFilteredByClub = !!(this.clubName && this.clubName !== '');
		if (isFilteredByClub) {
			subscription = this.matchesService.getAllMatchesByClubname(this.clubName, this.competitionId, this.seasonId);
		} else {
			subscription = this.matchesService.getBasicMatchinfosByMatchday(this.matchdayNumber, this.competitionId, this.seasonId);
		}

		// unsibscribe first
		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}

		// get matches
		this._matchesServiceSubscription = subscription.pipe(take(1)).subscribe(
			(success: Match[]) => {
				this.matches = success;
				this._setAndFilterMatchData(isFilteredByClub ? true : false);
			},
			(error) => {
				this.data = error;
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	private _setAndFilterMatchData(isFilteredByClub = false): void {
		/* sort the values by kickofftime or matchday range*/
		if (isFilteredByClub === true) {
			this.matches.sort((a, b) => (a.matchdayRange.start > b.matchdayRange.start ? 1 : b.matchdayRange.start > a.matchdayRange.start ? -1 : 0));
		} else {
			this.matches.sort((a, b) => (a.plannedKickOff > b.plannedKickOff ? 1 : b.plannedKickOff > a.plannedKickOff ? -1 : 0));
		}
		let lastPlannedKickoff = null;
		this.matchesShowKickoffDate = [];
		this.matchesLiveState = [];
		this.matchesOverTimeLiveState = [];
		this.matchcenterUrls = [];
		this.matchesPenaltyState = [];

		Object.keys(this.matches).forEach((key) => {
			const match = this.matches[key] as Match;
			if (!match['plannedKickOff']) {
				this.matchesShowKickoffDate[key] = true;
			} else if (lastPlannedKickoff !== match['plannedKickOff']) {
				lastPlannedKickoff = match['plannedKickOff'];
				this.matchesShowKickoffDate[key] = true;
			} else {
				this.matchesShowKickoffDate[key] = false;
			}
			this.matchesLiveState[key] = this.matchesService.isLiveMatch(match);
			this.matchesPenaltyState[key] = this.matchesService.isPenalty(match);
			this.matchesOverTimeLiveState[key] = this.matchesService.isOverTimeLiveMatch(match);
			this.livestreams[match.dflDatalibraryMatchId] = {
				value: this.getLivestream(match.dflDatalibraryMatchId),
				loading: false,
				deleteSuccess: false,
				saveSuccess: false
			};
		});
		this.loading = false;
	}

	public trackByMatchId(index, item) {
		if (item.dflDatalibraryMatchId) {
			return item.dflDatalibraryMatchId;
		}
	}

	public getLivestream(matchId: string): Observable<{ hls?: string; MatchId?: string }> {
		//this.livestreams[matchId]['loading'] = true;
		return this.livestreamService.getLivestream(matchId).pipe(
			tap(() => {
				this.livestreams[matchId]['loading'] = true;
			}),
			catchError(() => of({})),
			finalize(() => {
				this.livestreams[matchId]['loading'] = false;
			})
		);
	}
	public setLivestream(matchId: string, hls: string): void {
		this.livestreams[matchId]['loading'] = true;
		this.livestreamService
			.setLivestream(matchId, hls)
			.pipe(
				tap(() => {
					this.livestreams[matchId]['saveSuccess'] = true;
				}),
				catchError(() => {
					this.livestreams[matchId]['saveSuccess'] = false;
					return of(false);
				}),
				finalize(() => {
					this.livestreams[matchId]['loading'] = false;
				})
			)
			.subscribe();
	}
	public deleteLivestream(matchId: string): void {
		this.livestreams[matchId]['loading'] = true;
		this.livestreamService
			.deleteLivestream(matchId)
			.pipe(
				tap(() => {
					this.livestreams[matchId]['deleteSuccess'] = true;
				}),
				catchError(() => {
					this.livestreams[matchId]['deleteSuccess'] = false;
					return of(false);
				}),
				finalize(() => {
					this.livestreams[matchId]['loading'] = false;
				})
			)
			.subscribe();
	}
}
