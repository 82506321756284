import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { ToolsComponent } from './tools.component';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
	{
		path: 'tools',
		component: ToolsComponent,
		// canActivate : [MsalGuardStreaker]
		canActivate: [MsalGuard]
	}
];

@NgModule({
	declarations: [ToolsComponent],
	imports: [CommonsModule, MatButtonModule, RouterModule.forChild(routes)]
})
export class ToolsPageModule {}
