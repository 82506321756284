import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BundesligaMatchStates, LiveBlogMatch, Playtime } from '@nx-bundesliga/models';
import { Subscription } from 'rxjs';

interface ProgressionEntry {
	value: number;
	unit: string;
	playtime?: Playtime;
	matchSection?: BundesligaMatchStates;
}

@Component({
	selector: 'stats-clubmetricprogression-list',
	templateUrl: './stats-clubmetricprogression-list.component.html',
	styleUrls: ['./stats-clubmetricprogression-list.component.scss']
})
export class StatsClubmetricprogressionListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() title: string;
	@Input() match: LiveBlogMatch;
	@Output() progressionHome: EventEmitter<ProgressionEntry[]> = new EventEmitter<ProgressionEntry[]>();
	@Output() progressionAway: EventEmitter<ProgressionEntry[]> = new EventEmitter<ProgressionEntry[]>();
	public matchSections = ['start_firstHalf', 'end_firstHalf', 'start_secondHalf', 'end_secondHalf', 'finalWhistle', 'start_firstHalfExtra', 'end_firstHalfExtra', 'start_secondHalfExtra', 'end_secondHalfExtra', 'start_penalty', 'end_penalty'];
	@Input() public winProbability = [];
	checkboxGroup: UntypedFormGroup;
	subscription: Subscription;
	constructor(private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		// generate random winprobs
		const getRandomInt = (min, max) => {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		};
		const itFirst = getRandomInt(0, 5);
		const itSecond = getRandomInt(0, 5);
		// autogenerate mockdata
		/*this.winProbability = Array.apply(null, Array(91 + itFirst + itSecond)).map((item, index) => {
      const homeProb = getRandomInt(0, 100);
      return {
        home: homeProb,
        away: getRandomInt(0, 100 - homeProb),
        minuteOfPlay: {
          minute: index <= 45 ? index : (index > 45 && index < 45 + itFirst ? 45 : (index <= 90 + itFirst ? index - itFirst : 90)),
          injuryTime: index >= 45 && index <= 45 + itFirst ? index - 45 : (index > 90 + itFirst && index <= 90 + itFirst + itSecond ? index - 90 - itFirst : 0)
        }
      };
    });*/

		this.checkboxGroup = this.fb.group({
			checkboxesHome: this.fb.array(this.winProbability.map((x) => false)),
			checkboxesAway: this.fb.array(this.winProbability.map((x) => false))
		});

		const checkboxControlHome = this.checkboxGroup.controls['checkboxesHome'] as UntypedFormArray;
		const checkboxControlAway = this.checkboxGroup.controls['checkboxesAway'] as UntypedFormArray;
		this.progressionHome.emit(checkboxControlHome.value.filter((value) => !!value));
		this.progressionAway.emit(checkboxControlAway.value.filter((value) => !!value));

		this.subscription = this.checkboxGroup.valueChanges.subscribe((checkbox) => {
			checkboxControlHome.setValue(
				checkboxControlHome.value.map((value, i) => (value ? this.transformToPregressionEntry(this.winProbability[i], 'home') : false)),
				{ emitEvent: false }
			);
			checkboxControlAway.setValue(
				checkboxControlAway.value.map((value, i) => (value ? this.transformToPregressionEntry(this.winProbability[i], 'away') : false)),
				{ emitEvent: false }
			);
			this.progressionHome.emit(checkboxControlHome.value.filter((value) => !!value));
			this.progressionAway.emit(checkboxControlAway.value.filter((value) => !!value));
		});
	}

	private transformToPregressionEntry(item: any, side: 'home' | 'away'): ProgressionEntry {
		return {
			value: item[side === 'home' ? 'homeTeamWinProbability' : 'guestTeamWinProbability'],
			unit: '%',
			playtime: {
				minute: item.minuteOfPlay.minute,
				injuryTime: item.minuteOfPlay.injuryTime
			}
		};
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {}
}
