<dfl-loader *ngIf="isLoading" layout="matchcenter"></dfl-loader>
<bl-mat-match-header *ngIf="match" [match]="match" [language]="language" [customMatch]="true" (layoutChange)="layoutChange($event)"></bl-mat-match-header>

<!-- LIVETICKER -->
<div class="container-fluid container-match">
	<as-split unit="percent" [direction]="dragDirection" [gutterSize]="50" [useTransition]="false" (dragEnd)="dragEnd($event)" [disabled]="dragDirection === 'vertical'" #split="asSplit">
		<ng-container *ngFor="let dragArea of dragAreas; let areaIndex = index">
			<as-split-area *ngIf="dragArea.component === 'composer'" [size]="dragArea.size" [order]="dragArea.order" #area1="asSplitArea">
				<ng-container *ngTemplateOutlet="dragAreaControl; context: {length: dragAreas.length, dragArea: dragArea}"></ng-container>
				<overlay-scrollbars [defer]="true" [options]="{ scrollbars: { autoHide: 'leave', theme: 'os-theme-' + ((theme.theme$ | async) === 'dark' ? 'light' : 'dark') } }">
					<bl-rapid-ticker-creator
						*ngIf="liveBlogInfos && teams && matchStatus && minuteOfPlay"
						[liveBlogInfos]="liveBlogInfos"
						[liveBlogTeams]="teams"
						[matchStatus]="matchStatus"
						[minuteOfPlay]="minuteOfPlay"
						[language]="language"
						[competitionId]="competitionId"
						[seasonId]="seasonId"
						[matchdayId]="matchdayId"
						[matchId]="matchId"
						[customMatch]="true"></bl-rapid-ticker-creator>
				</overlay-scrollbars>
			</as-split-area>

			<as-split-area *ngIf="dragArea.component !== 'composer'" [size]="dragArea.size" [order]="dragArea.order" #area2="asSplitArea">
				<ng-container *ngTemplateOutlet="dragAreaControl; context: {length: dragAreas.length, dragArea: dragArea}"></ng-container>
				<overlay-scrollbars [defer]="true" [options]="{ scrollbars: { autoHide: 'leave', theme: 'os-theme-' + ((theme.theme$ | async) === 'dark' ? 'light' : 'dark') } }">
					<nav mat-tab-nav-bar [tabPanel]="tabPanel">
						<a mat-tab-link [active]="dragArea.component === 'liveticker'" (click)="setDragAreaTab(dragArea.order, 'liveticker')">
							<mat-icon>sms</mat-icon>
							Ticker
						</a>
						<a mat-tab-link [active]="dragArea.component === 'twitter'" (click)="setDragAreaTab(dragArea.order, 'twitter')">
							<mat-icon>attach_file</mat-icon>
							Twitter
						</a>
					</nav>

					<mat-tab-nav-panel #tabPanel>
						<div *ngIf="dragArea.component === 'empty'">
							<p>Please choose a Tab above</p>
						</div>
						<div *ngIf="dragArea.component === 'liveticker'">
							<liveticker *ngIf="liveBlogEntries && liveBlogInfos; else liveBlogNoEntries" [liveBlogEntries]="liveBlogEntries" [liveBlogInfos]="liveBlogInfos" [teams]="teams" [language]="language" [customMatch]="true" [match]="match"></liveticker>
						</div>
						<div *ngIf="dragArea.component === 'twitter'">
							<bl-twitter-feed [language]="language" [liveBlogInfos]="liveBlogInfos" [match]="match" [clubData]="teams" [minuteOfPlay]="minuteOfPlay"></bl-twitter-feed>
						</div>
					</mat-tab-nav-panel>
				</overlay-scrollbars>
			</as-split-area>
		</ng-container>
	</as-split>
</div>

<ng-template #liveBlogNoEntries>
	<p class="text-center load-more-error" *ngIf="language && competitionId && seasonId && matchdayId && matchId && liveBlogInfos">Match information available. Ready for Liveticker. No tickered Event yet.</p>
	<p class="text-center load-more-error" *ngIf="language && competitionId && seasonId && matchdayId && matchId && !liveBlogInfos">Match information available. Ready for Liveticker. No tickered Event yet.</p>
	<p class="text-center load-more-error" *ngIf="!(language && competitionId && seasonId && matchdayId && matchId)">Some parameters are missing from the Url. Make sure it includes the following: language && competitionId && seasonId && matchdayId && matchId</p>
</ng-template>

<ng-template #dragAreaControl let-length="length" let-dragArea="dragArea">
	<div class="dragAreaControl container-fluid" [ngClass]="{'visible': areaControlsVisible}">
		<div class="row">
			<div class="col-4 text-left"><mat-icon *ngIf="dragArea.order !== 0" (click)="moveDragArea(dragArea.order, -1)">skip_previous</mat-icon></div>
			<div class="col-4 text-center"><mat-icon *ngIf="dragArea.component !== 'composer'" (click)="removeDragArea(dragArea.order)">clear</mat-icon></div>
			<div class="col-4 text-right"><mat-icon *ngIf="dragArea.order !== length - 1" (click)="moveDragArea(dragArea.order, 1)">skip_next</mat-icon></div>
		</div>
	</div>
</ng-template>
