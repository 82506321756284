<div class="event varEvent side_{{ side }}">
	<div class="head">
		<div class="playtime">
			<livetickerevent-playtime [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
		</div>
		<div class="logo-var">
			<dfl-image light="/assets/liveticker/var.png" height="42" dark="/assets/liveticker/var_negative.png" class="logoVAR" alt="Video Assistant Referee"></dfl-image>
		</div>
	</div>
	<div class="body" *ngIf="liveEntry.detail">
		<div class="detailText">
			<span>{{ 'LIVETICKER.EVENTS.VideoAssistEvent.SITUATION' | translate }}</span>
			<span *ngIf="liveEntry.detail.situation" class="result">{{liveEntry.detail.situation}}</span>
		</div>
		<div class="detailText">
			<span>{{ 'LIVETICKER.EVENTS.VideoAssistEvent.REVIEW' | translate }}</span>
			<span *ngIf="liveEntry.detail.review" class="result">{{liveEntry.detail.review}}</span>
		</div>
		<div class="detailText">
			<span>{{ 'LIVETICKER.EVENTS.VideoAssistEvent.DECISION' | translate }}</span>
			<strong *ngIf="liveEntry.detail.decision" class="result">{{liveEntry.detail.decision}}</strong>
		</div>
	</div>
</div>
