import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DflJwPlayerModule } from '@nx-bundesliga/bundesliga-com/components/dfl-jw-player';
import { LivetickerEventModule } from '../../organisms/liveticker/components/liveticker-event/liveticker-event.module';
import { BlCrosspostDialogComponent } from './crosspost-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlCrosspostDialogComponent],
	exports: [BlCrosspostDialogComponent],
	imports: [CommonsModule, MatDialogModule, MatIconModule, MatButtonModule, DflJwPlayerModule, FormsModule, MatProgressSpinnerModule, MatCheckboxModule, LivetickerEventModule]
})
export class BlCrosspostDialogModule {}
