<div class="metric-info" *ngIf="matchFactsInfo" (click)="showMatchFactsInfo()">
	<dfl-simple-icon icon="{{ infoIcon }}"></dfl-simple-icon>
</div>
<div class="overlay" (click)="overlayActive = false" [ngClass]="{'active': overlayActive === true}"></div>

<div class="overlay-inner" [ngClass]="{'active': overlayActive === true}">
	<div class="container-fluid">
		<div class="row title">
			<div class="col-8">
				<span class="matchfact-title">{{ matchFactTitle }}</span>
			</div>
			<div class="col-4">
				<button type="button" class="close" (click)="overlayActive = false">
					<dfl-simple-icon icon="close"></dfl-simple-icon>
				</button>
			</div>
		</div>

		<div class="row description">
			<span>{{ matchFactDescription }}</span>
		</div>

		<div class="row matchfacts">
			<livetickerevent-matchfacts-banner></livetickerevent-matchfacts-banner>
		</div>
	</div>
</div>
