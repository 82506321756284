import { Component, Input, OnChanges } from '@angular/core';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEvents, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';
import { TextColors } from '../livetickerevent-playtime/livetickerevent-playtime.component';

@Component({
	selector: 'livetickerevent-score',
	templateUrl: './livetickerevent-score.component.html',
	styleUrls: ['./livetickerevent-score.component.scss']
})
export class LivetickereventScoreComponent implements OnChanges {
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	public side: LivetickerSides;
	@Input() textColor: TextColors = 'text_default';

	constructor() {}

	ngOnChanges() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
	}
}
