<mat-card appearance="outlined">
	<mat-card-header>
		<div *ngIf="['home', 'away'].includes(this.side)" mat-card-avatar class="header-image" [style.background-image]="'url(/assets/clublogo/' + (liveBlogTeams[side].threeLetterCode | lowercase) + '.png)'"></div>
		<mat-card-title *ngIf="['home', 'away'].includes(this.side)">{{ liveBlogTeams[side].nameFull }}</mat-card-title>
	</mat-card-header>

	<mat-card-content>
		<div class="status" *ngIf="!error">
			<div class="text">
				<div class="spinner">
					<mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
					<span class="icon rocket">🚀</span>
				</div>

				<div>
					<span>Publishing your</span>
					<span>awesome content</span>
					<strong>sit tight</strong>
				</div>
			</div>
			<video width="480" height="323" autoplay muted loop controls>
				<source [src]="postIsPublishingVideoSource" />
			</video>
		</div>

		<ng-container *ngIf="error">
			<div class="status error">
				<div class="text">
					<div>
						<div class="spinner">
							<span class="icon">⚾</span>
							<strong>Something is wrong</strong>
						</div>
						<span>Please read the error message below</span>
						<span>Find common problems in the faq or contact Nordcloud or the Devs</span>
					</div>
				</div>
			</div>

			<div class="status error p-2">
				<div class="status-choice">
					<button (click)="back.emit()" mat-flat-button color="primary" class="m-2">
						<mat-icon>undo</mat-icon>
						BACK
					</button>
					<button (click)="cancel.emit()" mat-flat-button class="m-2">
						<mat-icon>refresh</mat-icon>
						START OVER
					</button>
				</div>
			</div>

			<div class="status error">
				<div class="text">
					<div class="spinner">
						<span class="icon"><mat-icon>error</mat-icon></span>
					</div>

					<div class="text-left">
						<span>
							<strong>Errorcode</strong>
							: {{errorStatus}}
						</span>
						<span>
							<strong>ErrorCategory</strong>
							: {{errorName}}
						</span>
						<span>
							<strong>ErrorMessage</strong>
							: {{errorMessage}}
						</span>
					</div>
				</div>
			</div>

			<div class="status error">
				<div class="text">
					<div class="spinner">
						<span class="icon"><mat-icon>healing</mat-icon></span>
					</div>

					<div>
						<h2>FAQ</h2>
						<ul class="text-left">
							<li>400: Business Logic error. Please read the message carefully. Go back and check your inputs or start fresh.</li>
							<li>0: Cors issue or DNS problem</li>
							<li>403: Unazthorized. Please make sure you are logged in. Login again if nothing helps.</li>
							<li>404: Endpoint does not exist. Please contact Devs</li>
							<li>500: Service crashed unrecoverable. Please contact Devs</li>
						</ul>
					</div>
				</div>
			</div>
		</ng-container>
	</mat-card-content>
</mat-card>
