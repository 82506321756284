import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

interface BundesligaRestServiceHttpOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?: HttpParams | { [param: string]: string | string[] };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export abstract class BundesligaRestService {
	public defaults: any;
	public settings: any;

	/**
	 *
	 * @param data
	 */
	static handleError(data) {
		return throwError(data);
	}

	protected constructor(private httpClient: HttpClient) {}

	/**
	 * Returns a HttpHeaders object with the default headers required for Bundesliga.com API access, i.e.:
	 * - Content-Type: application/json
	 * - accept-language: current language in store
	 * - x-dflds-api-key (or whatever is defined in settings[header]): settings[key]
	 */
	protected getDefaultHeaders(overrides: any = {}): HttpHeaders {
		const headers = new HttpHeaders()
			.append('Content-Type', 'application/json')
			.append('accept-language', overrides.hasOwnProperty('accept-language') ? overrides['accept-language'] : 'en-EN')
			.append(this.settings.header || this.defaults.header, this.settings.key || this.defaults.key);

		return headers;
	}

	/**
	 * Builds the final URL to query against
	 *
	 * @param relativePath
	 */
	protected buildRequestUrl(relativePath: string) {
		if (this.settings.uri) {
			return this.settings.uri;
		}

		const base = this.settings.base || this.defaults.base;
		const path = typeof this.settings === 'string' ? this.settings : this.settings.path;

		return `${base}/${path}/${relativePath}`;
	}

	/**
	 * Wraps the defaultHeaders in an object, ready to use for the Angular HttpClient.
	 */
	protected buildRequestOptions(options: any, headers?: any): BundesligaRestServiceHttpOptions {
		return {
			headers: this.getDefaultHeaders(headers) as HttpHeaders,
			responseType: 'json',
			observe: 'body',
			...options
		} as BundesligaRestServiceHttpOptions;
	}

	/**
	 * Queries the BundesligaAPI
	 *
	 * @param path
	 * @param pathIsAbsolute
	 * @param options the options for the http GET request used in Angular HttpClient
	 * @param headers
	 */
	protected get<T>(path: string, pathIsAbsolute = false, options?: any, headers?: any): Observable<T> {
		this.defaults = environment.endpoints.defaults;
		const url = pathIsAbsolute ? path : this.buildRequestUrl(path);
		return this.httpClient.get<T>(url, this.buildRequestOptions(options, headers)).pipe(catchError((data) => BundesligaRestService.handleError(data)));
	}
}
