import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user/user.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
	constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private userService: UserService, private authService: MsalService, public msalService: MsalService, private http: HttpClient, private sanitizer: DomSanitizer, public dialog: MatDialog) {}
	private readonly destroying$ = new Subject<void>();
	public user: AccountInfo | null;
	public userImage: any;
	public userInitials = '';
	public loggedIn = false;
	@ViewChild('userInfo') userInfo: TemplateRef<any>;
	ngOnInit() {
		this.userService.isLoggedIn$.pipe(takeUntil(this.destroying$), distinctUntilChanged()).subscribe((isLoggedIn) => {
			if (this.loggedIn === false && isLoggedIn === true) {
				this.loggedIn = isLoggedIn;
				this.fetchAccountInfo();
			} else {
				this.loggedIn = isLoggedIn;
			}
		});
		this.userService.user$.pipe(takeUntil(this.destroying$)).subscribe((user: AccountInfo | null) => {
			this.user = user;
			if (this.user) {
				this.userInitials =
					this.user.name
						.replace(' ', '')
						.split(',')
						.reverse()
						.map((n) => n[0].toUpperCase())
						.join('') || null;
			}
		});
	}
	ngOnDestroy(): void {
		this.destroying$.next(undefined);
		this.destroying$.complete();
	}

	private fetchAccountInfo() {
		if (this.loggedIn && !this.userImage) {
			this.http.get('https://graph.microsoft.com/beta/me/photos/48x48/$value', { responseType: 'blob' }).subscribe(
				(blob) => {
					const objectURL = URL.createObjectURL(blob);
					const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
					this.userImage = image;
				},
				(error) => {
					this.userImage = null;
					console.log('error fetching userimage', error);
				}
			);
		} else {
			this.userImage = null;
		}
	}

	public openUserInfo() {
		this.dialog.open(this.userInfo, {
			data: {
				user: this.user
			}
		});
	}

	public doLogout() {
		this.msalService.logout();
	}
	public doLogin() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...(this.msalGuardConfig.authRequest as PopupRequest) })
					.pipe(takeUntil(this.destroying$))
					.subscribe(() => {});
			} else {
				this.authService
					.loginPopup()
					.pipe(takeUntil(this.destroying$))
					.subscribe(() => {});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({ ...(this.msalGuardConfig.authRequest as PopupRequest) });
			} else {
				this.authService.loginRedirect();
			}
		}
	}
}
