import { InjectionToken } from '@angular/core';

export interface LivestreamData {
	modalName: string;
	competitionId?: string;
	seasonId: string;
	matchdayId?: string;
	matchId?: string;
	matchName?: string;
}

export const LIVESTREAM_MODAL_DATA = new InjectionToken<LivestreamData>('LIVESTREAM_MODAL_DATA');

export enum LivestreamModalName {
	'livestreamHLS' = 'LIVESTREAM_HLS'
}

export const LIVESTREAM_MODALS = {};
