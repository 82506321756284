import { NgModule } from '@angular/core';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { ThemeSwitcherComponent } from './theme-switcher.component';

@NgModule({
	declarations: [ThemeSwitcherComponent],
	exports: [ThemeSwitcherComponent],
	imports: [SharedModule]
})
export class ThemeSwitcherModule {}
