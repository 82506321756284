import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { BundesmasterMatchday, BundesmasterMatchdayApiService, emptyBundesmasterDataCollection, PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubLogoComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { combineLatest, debounceTime, distinctUntilChanged, startWith, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-matchday-selector',
	standalone: true,
	imports: [CommonModule, CommonsLibModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, StoreModule, PipesModule, MatIconModule, MatInputModule, MatAutocompleteModule, BundesmasterUiClubLogoComponent, MatProgressSpinnerModule],
	templateUrl: './bundesmaster-ui-matchday-selector.component.html',
	styleUrls: ['./bundesmaster-ui-matchday-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => BundesmasterUiMatchdaySelectorComponent)
		}
	]
})
export class BundesmasterUiMatchdaySelectorComponent implements OnChanges, ControlValueAccessor {
	private readonly destroying = new Subject<void>();
	public readonly touched = new Subject<void>();

	private seasonIdFilterSubject = new Subject<string | undefined>();

	public readonly formControl = new FormControl<string>('');

	public matchdays = emptyBundesmasterDataCollection<BundesmasterMatchday>('loading');

	public isDisabled = false;

	public get hasValue(): boolean {
		return (this.formControl.value ?? '').trim().length > 0;
	}

	@Input() public seasonIdFilter?: string;

	constructor(private service: BundesmasterMatchdayApiService) {
		combineLatest([this.formControl.valueChanges.pipe(takeUntilDestroyed(), startWith(''), distinctUntilChanged(), debounceTime(500, undefined)), this.seasonIdFilterSubject.pipe(takeUntilDestroyed(), startWith(undefined), distinctUntilChanged())])
			.pipe(
				takeUntilDestroyed(),
				switchMap(([query, seasonId]) => this.service.getMatchdays({ query, seasonId }))
				//map((clubs) => ({ ...clubs, clubs: [...clubs.items].sort((c0, c1) => c0.shortName.localeCompare(c1.shortName)) })),
				//tap((clubs) => this.sanitizeSelection(clubs))
			)
			.subscribe((matchdays) => {
				this.matchdays = matchdays;
				this.updateDisabled(true);
			});
	}

	optionSelected(event: MatAutocompleteSelectedEvent) {
		const selectedMatchday: string = event.option.value;
		this.formControl.setValue(selectedMatchday, { emitEvent: true });
	}

	ngOnChanges(): void {
		this.seasonIdFilterSubject.next(this.seasonIdFilter);
	}

	writeValue(value: string): void {
		this.formControl.setValue(value, { emitEvent: false });
	}

	registerOnChange(handler: (value: string) => void): void {
		this.formControl.valueChanges.pipe(takeUntil(this.destroying)).subscribe(handler);
	}

	registerOnTouched(handler: () => void): void {
		this.touched.subscribe(handler);
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.updateDisabled();
	}

	private updateDisabled(emitEvent = true): void {
		if (this.matchdays.status === 'loading' || this.isDisabled) {
			this.formControl.disable({ emitEvent });
		} else {
			this.formControl.enable({ emitEvent });
		}
	}
}
