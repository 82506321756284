import { FaceDetail, FaceMatch, Label, UserMatch } from '@aws-sdk/client-rekognition';
import exif from 'exifreader';
import { LlmImageTag } from './llm-image-tags.model';
import { Tags } from './tags.model';

export type AssetDimensions = 'desktop' | 'mobile' | 'portrait' | 'liveticker';
export interface AssetDimensionsProperties {
	ratio: number;
	width: number;
	height: number;
	minWidth: number;
	minHeight: number;
}
export const assetDimensions: { [dimension in AssetDimensions]?: AssetDimensionsProperties } = {
	desktop: {
		ratio: 16 / 9,
		width: 16,
		height: 9,
		minWidth: 1920,
		minHeight: 1080
	},
	mobile: {
		ratio: 5 / 4,
		width: 5,
		height: 4,
		minWidth: 720,
		minHeight: 501
	},
	portrait: {
		ratio: 9 / 16,
		width: 9,
		height: 16,
		minWidth: 1080,
		minHeight: 1920
	},
	liveticker: {
		ratio: 3 / 2,
		width: 2,
		height: 2,
		minWidth: 720,
		minHeight: 480
	}
};

export interface FaceDetectionUser {
	user: UserMatch;
	face: FaceDetail;
}
export interface AssetResponse {
	page: number;
	size: number;
	total: number;
	items: Asset[];
}

export type AssetMotifModelV1Classes =
	| 'action_general'
	| 'action_shot'
	| 'action_tackling'
	| 'celebration'
	| 'no_label'
	| 'label_error'
	| 'batch_label_error'
	| 'celebration_close'
	| 'celebration_far'
	| 'goal'
	| 'goal_behind'
	| 'goal_front'
	| 'goal_side'
	| 'person_close'
	| 'player_full_body'
	| 'player_half_body'
	| 'stadium';

export interface AssetMotifLabel<TLabel> {
	readonly label: TLabel;
	readonly confidence: number;
}

export interface AssetMotif {
	readonly modelV1: readonly AssetMotifLabel<AssetMotifModelV1Classes>[];
}

export interface Asset {
	id: string;
	created_date: string;
	modified_date?: string;
	author: string[];
	media_type: string;
	mime_type: string;
	file: string;
	file_size: number;
	image?: {
		width: number;
		height: number;
	};
	title?: string;
	caption?: string;
	alt_text?: string;
	copyright?: string;
	exif?: exif.Tags; // @deprecated: see exifString
	exifString?: string;
	faces?: FaceMatch[]; // @deprecated: see users
	labels?: Label[];
	tags?: Tags;
	motif?: AssetMotif;
	llmTags?: LlmImageTag[];
	persons?: FaceDetectionUser[];
	photoDbAssetId?: string;
}

export interface AssetDeleteResponse {
	message: string;
}

export interface AssetSaveResponse {
	message: string;
	item?: Asset;
}
