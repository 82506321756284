import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { LiveBlogClubs, LiveBlogEntryEditorialEmbed, LivetickerSides } from '@nx-bundesliga/models';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-embed',
	templateUrl: './livetickerevent-embed.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-embed.component.scss'],
	animations: [liveblogPostAnimation('', false)]
})
export class LivetickereventEmbedComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogEntryEditorialEmbed;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() isWebview = false;
	public side: LivetickerSides = 'none';
	constructor() {}

	ngOnChanges(): void {
		this.side = getSide(this.liveEntry) as LivetickerSides;
	}
}
