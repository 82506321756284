<h2 mat-dialog-title>Select Clubs</h2>

<ng-template #loading>
	<mat-progress-spinner class="flex-grow-1 align-self-center d-flex justify-content-center" diameter="48" color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>

<mat-dialog-content class="mat-typography">
	<div class="d-flex flex-row gap" [formGroup]="searchInputFormGroup">
		<mat-form-field class="flex-grow-1">
			<mat-label>Search for club name, three letter code or clubId ...</mat-label>
			<input formControlName="name" #searchInput matInput />
		</mat-form-field>

		<nx-bundesliga-bundesmaster-ui-competition-selector formControlName="competitionId"></nx-bundesliga-bundesmaster-ui-competition-selector>
		<nx-bundesliga-bundesmaster-ui-season-selector formControlName="seasonId"></nx-bundesliga-bundesmaster-ui-season-selector>
	</div>

	<p *ngIf="(clubs$ | async).status === 'empty'" class="d-flex flex-column align-items-center justify-content-center flex-grow-1">No clubs found</p>

	<div class="items" *ngIf="(clubs$ | async).status !== 'loading' else loading">
		<div *ngFor="let club of (clubs$ | async).items; trackBy: trackByClubId" (click)="toggleClub(club)" [style.backgroundColor]="club.hexColors.primary" [style.color]="club.hexColors.primaryText">
			<nx-bundesliga-bundesmaster-ui-teaser-preview class="club-card" [class.selected]="isSelected(club)">
				<nx-bundesliga-bundesmaster-ui-club-card [clubLinkVisibility]="'hidden'" content [club]="club"></nx-bundesliga-bundesmaster-ui-club-card>
			</nx-bundesliga-bundesmaster-ui-teaser-preview>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button [disabled]="!hasSelection" mat-flat-button color="primary" (click)="clearSelection()">
		<span>Clear selection</span>
	</button>
	<button [disabled]="(clubs$ | async).status === 'empty'" mat-flat-button color="primary" (click)="selectAll()">
		<span>Select visible clubs</span>
	</button>
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
