<div class="container matchcenter-lineup" [ngClass]="{'homeVisible': isVisibleHome, 'awayVisible': isVisibleAway}" *ngIf="lineupData">
	<div class="row sides" *ngIf="lineupData.home && lineupData.away">
		<div class="col selectHome col-6 d-flex align-items-center justify-content-center" [ngStyle]="{'color': home.textColor, 'background-color': home.gradientStartColor}" (click)="setVisibleSide('home')">
			<clublogo [clubId]="home.dflDatalibraryClubId" [dimension]="40"></clublogo>
			<div class="tactical-formation">
				<ng-container *ngIf="lineupData.home && lineupData.home.startingEleven && lineupData.home.startingEleven.tacticalFormationName">{{lineupData.home.startingEleven.tacticalFormationName}}</ng-container>
			</div>
		</div>
		<div class="col selectAway col-6 d-flex align-items-center justify-content-center" [ngStyle]="{'color': away.textColor, 'background-color': away.gradientStartColor}" (click)="setVisibleSide('away')">
			<div class="tactical-formation">
				<ng-container *ngIf="lineupData.away && lineupData.away.startingEleven && lineupData.away.startingEleven.tacticalFormationName">{{lineupData.away.startingEleven.tacticalFormationName}}</ng-container>
			</div>
			<clublogo [clubId]="away.dflDatalibraryClubId" [dimension]="40"></clublogo>
		</div>
	</div>

	<ng-container *ngIf="!lineupData.home?.startingEleven || !lineupData.away?.startingEleven">
		<div class="row lineup-empty">
			<div class="col col-12">
				<span class="title d-block">{{ "MATCHCENTER.LINEUP.EmptyTitle" | translate }}</span>
				<span class="message d-block">{{ "MATCHCENTER.LINEUP.EmptyMessage" | translate }}</span>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="lineupData.home && lineupData.away && lineupData.home.startingEleven && lineupData.away.startingEleven">
		<div class="row tactical-lineup" *ngIf="lineupData.home.startingEleven.isTactical && lineupData.away.startingEleven.isTactical">
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="row home lineup-clubname" [ngStyle]="{'color': home.textColor, 'background-color': home.gradientStartColor}">
					<div class="col col-12">
						<clublogo [clubId]="home.dflDatalibraryClubId" [dimension]="40"></clublogo>
						<span class="clubname">{{ home.nameFull }}</span>
					</div>
				</div>
				<div class="row home lineup-image">
					<div class="col col-12 d-flex justify-content-center">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 360 484" class="lineup-svg home">
							<g fill="none" fill-rule="evenodd">
								<path fill="var(--background-card-hard)" d="M0 0h360v484H0z" />
								<!-- fill whole canvas white -->
								<path fill="var(--divider-dark-reduced)" d="M74.88 386h211.2v-2H74.88z" />
								<!-- horizontal line at top of goal area -->
								<g transform="matrix(1 0 0 -1 120.96 384)">
									<!-- rounding on top of goal area -->
									<path stroke="var(--divider-dark-reduced)" stroke-width="2" d="M111.36 22c0-29.823-23.21-54-51.84-54C30.89-32 7.68-7.823 7.68 22" transform="matrix(1 0 0 -1 0 -10)" />
									<!-- half a circle positioned down -->
									<path d="M0 -51.84 h124.8 v50H 0 z" fill="var(--background-card-hard)" />
									<!-- white filler to cover excess circle at the bottom -->
								</g>
								<path fill="var(--divider-dark-reduced)" d="M122.88 444h115.2v-2h-115.2zM74.88 484h1.92V384h-1.92zM284.16 484h1.92V384h-1.92zM236.16 484h1.92v-40h-1.92zM122.88 484h1.92v-40h-1.92z" />
								<!-- square areas at goal area -->
							</g>
							<ng-container *ngFor="let player of lineupData.home.startingEleven.persons; trackBy: trackByPlayerId;">
								<a [attr.href]="publicWebsite + '/' + language + '/' + competition + '/' + 'route-player/' + player.slugifiedShortName | translateRoute" [attr.pointer-events]="'all'" target="_blank">
									<image width="46" height="46" [attr.xlink:href]="(player.imageUrl ? player.imageUrl : this.defaultPlayerImage) | imageService : 52 " [attr.x]="(player.position.x * svgOffsetFactorX) - 23" [attr.y]="(player.position.y * svgOffsetFactorY) - 32" (error)="errorHandlerSVGImage($event)"></image>
									<text class="playerName" [attr.x]="(player.position.x * svgOffsetFactorX) - 2" [attr.y]="(player.position.y * svgOffsetFactorY) + 27" dominant-baseline="middle" text-anchor="middle" fill="var(--type-body-dark)" width="60px">
										{{ getPlayerAliasTruncated(player.tacticalName ? player.tacticalName : player.name)}}
									</text>
									<!-- playerIcons -->
									<ng-container *ngIf="playerBasicMatchEvents.home && playerBasicMatchEvents.home[player.dflDatalibraryObjectId]">
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].sub.out === true">
											<image
												class="eventIcon icon-substitution-of"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-of.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].sub.in === true">
											<image
												class="eventIcon icon-substitution-on"
												width="20"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-on.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].sub.in === true && playerBasicMatchEvents.home[player.dflDatalibraryObjectId].sub.out === true">
											<image
												class="eventIcon icon-substitution-on"
												width="20"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-inout.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals === 1 && !playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals">
											<image
												class="eventIcon icon-goal"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goal.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals > 1 && !playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals">
											<image
												class="eventIcon icon-goals-empty"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goals-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
											<text class="goalsShot" [attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals']) + 10" [attr.y]="(player.position.y * svgOffsetFactorY) + 43" dominant-baseline="middle" text-anchor="middle">
												{{ playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['goals']['goals'] }}
											</text>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals === 1 && !playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals">
											<image
												class="eventIcon icon-goal-own"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goal-own.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals === 1 && playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals === 1 ">
											<image
												class="eventIcon icon-goal-owngoal"
												width="24"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/goal-owngoal.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals > 1 && !playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals">
											<image
												class="eventIcon icon-owngoals-empty"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/owngoals-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
											<text class="goalsShot" [attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals']) + 10" [attr.y]="(player.position.y * svgOffsetFactorY) + 43" dominant-baseline="middle" text-anchor="middle">
												{{ playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['goals']['ownGoals'] }}
											</text>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.ownGoals > 0 && playerBasicMatchEvents.home[player.dflDatalibraryObjectId].goals.goals > 1 ">
											<image
												class="eventIcon icon-goals-owngoal-empty"
												width="24"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goals-owngoal-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].cards.yellow === true">
											<image
												class="eventIcon icon-yellow-card"
												width="21"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/yellow-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 33"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].cards.yellowRed === true">
											<image
												class="eventIcon icon-yellow-red-card"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/yellow-red-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 33"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.home[player.dflDatalibraryObjectId].cards.red === true">
											<image
												class="eventIcon icon-red-card"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/red-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.home[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 33"></image>
										</ng-container>
									</ng-container>
									<!-- /playerIcons -->
								</a>
							</ng-container>
						</svg>
					</div>
					<div class="col col-12 tactical-formation">
						<ng-container *ngIf="lineupData.home.startingEleven.tacticalFormationName">{{lineupData.home.startingEleven.tacticalFormationName}}</ng-container>
					</div>
				</div>
			</div>

			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="row away lineup-clubname" [ngStyle]="{'color': away.textColor, 'background-color': away.gradientStartColor}">
					<div class="col col-12">
						<clublogo [clubId]="away.dflDatalibraryClubId" [dimension]="40"></clublogo>
						<span class="clubname">{{ away.nameFull }}</span>
					</div>
				</div>
				<div class="row away lineup-image">
					<div class="col col-12 d-flex justify-content-center">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 360 484" class="lineup-svg away">
							<g fill="none" fill-rule="evenodd">
								<path fill="var(--background-card-hard)" d="M0 0h360v484H0z" />
								<!-- fill whole canvas white -->
								<path fill="var(--divider-dark-reduced)" d="M74.88 386h211.2v-2H74.88z" />
								<!-- horizontal line at top of goal area -->
								<g transform="matrix(1 0 0 -1 120.96 384)">
									<!-- rounding on top of goal area -->
									<path stroke="var(--divider-dark-reduced)" stroke-width="2" d="M111.36 22c0-29.823-23.21-54-51.84-54C30.89-32 7.68-7.823 7.68 22" transform="matrix(1 0 0 -1 0 -10)" />
									<!-- half a circle positioned down -->
									<path d="M0 -51.84 h124.8 v50H 0 z" fill="var(--background-card-hard)" />
									<!-- white filler to cover excess circle at the bottom -->
								</g>
								<path fill="var(--divider-dark-reduced)" d="M122.88 444h115.2v-2h-115.2zM74.88 484h1.92V384h-1.92zM284.16 484h1.92V384h-1.92zM236.16 484h1.92v-40h-1.92zM122.88 484h1.92v-40h-1.92z" />
								<!-- square areas at goal area -->
							</g>
							<ng-container *ngFor="let player of lineupData.away.startingEleven.persons; trackBy: trackByPlayerId;">
								<a [attr.href]="publicWebsite + '/' + language + '/' + competition + '/' + 'route-player/' + player.slugifiedShortName | translateRoute" [attr.pointer-events]="'all'" target="_blank">
									<image width="46" height="46" [attr.xlink:href]="(player.imageUrl ? player.imageUrl : this.defaultPlayerImage) | imageService : 52 " [attr.x]="(player.position.x * svgOffsetFactorX) - 23" [attr.y]="(player.position.y * svgOffsetFactorY) - 32" (error)="errorHandlerSVGImage($event)"></image>
									<text class="playerName" [attr.x]="(player.position.x * svgOffsetFactorX) - 2" fill="var(--type-body-dark)" [attr.y]="(player.position.y * svgOffsetFactorY) + 27" dominant-baseline="middle" text-anchor="middle" width="60px">
										{{ getPlayerAliasTruncated(player.tacticalName ? player.tacticalName : player.name)}}
									</text>
									<!-- playerIcons -->
									<ng-container *ngIf="playerBasicMatchEvents.away && playerBasicMatchEvents.away[player.dflDatalibraryObjectId]">
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].sub.out === true">
											<image
												class="eventIcon icon-substitution-of"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-of.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].sub.in === true">
											<image
												class="eventIcon icon-substitution-on"
												width="20"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-on.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].sub.in === true && playerBasicMatchEvents.away[player.dflDatalibraryObjectId].sub.out === true">
											<image
												class="eventIcon icon-substitution-on"
												width="20"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/substitution-inout.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['sub'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals === 1 && !playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals">
											<image
												class="eventIcon icon-goal"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goal.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals > 1 && !playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals">
											<image
												class="eventIcon icon-goals-empty"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goals-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
											<text class="goalsShot" [attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals']) + 10" [attr.y]="(player.position.y * svgOffsetFactorY) + 43" dominant-baseline="middle" text-anchor="middle">
												{{ playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['goals']['goals'] }}
											</text>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals === 1 && !playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals">
											<image
												class="eventIcon icon-goal-own"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goal-own.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals === 1 && playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals === 1 ">
											<image
												class="eventIcon icon-goal-owngoal"
												width="24"
												height="16"
												[attr.xlink:href]="'/assets/icons/lineup/goal-owngoal.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals > 1 && !playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals">
											<image
												class="eventIcon icon-owngoals-empty"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/owngoals-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
											<text class="goalsShot" [attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals']) + 10" [attr.y]="(player.position.y * svgOffsetFactorY) + 43" dominant-baseline="middle" text-anchor="middle">
												{{ playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['goals']['ownGoals'] }}
											</text>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.ownGoals > 0 && playerBasicMatchEvents.away[player.dflDatalibraryObjectId].goals.goals > 1 ">
											<image
												class="eventIcon icon-goals-owngoal-empty"
												width="24"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/goals-owngoal-empty.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['goals'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].cards.yellow === true">
											<image
												class="eventIcon icon-yellow-card"
												width="21"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/yellow-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].cards.yellowRed === true">
											<image
												class="eventIcon icon-yellow-red-card"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/yellow-red-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
										<ng-container *ngIf="playerBasicMatchEvents.away[player.dflDatalibraryObjectId].cards.red === true">
											<image
												class="eventIcon icon-red-card"
												width="20"
												height="17"
												[attr.xlink:href]="'/assets/icons/lineup/red-card.svg'"
												[attr.x]="(player.position.x * svgOffsetFactorX) - (playerBasicMatchEvents.away[player.dflDatalibraryObjectId]['svgImageOffsetsX']['cards'])"
												[attr.y]="(player.position.y * svgOffsetFactorY) + 34"></image>
										</ng-container>
									</ng-container>
									<!-- /playerIcons -->
								</a>
							</ng-container>
						</svg>
					</div>
					<div class="col col-12 tactical-formation">
						<ng-container *ngIf="lineupData.away.startingEleven.tacticalFormationName">{{lineupData.away.startingEleven.tacticalFormationName}}</ng-container>
					</div>
				</div>
			</div>
		</div>

		<ng-template #personListTemplate let-personList="personList" let-isCoach="isCoach" let-playerBasicMatchEvents="playerBasicMatchEvents">
			<a
				class="player d-flex justify-content-between align-items-center"
				[ngClass]="{'linkActive': (!isCoach)}"
				*ngFor="let player of personList; trackBy: trackByPlayerId;"
				[attr.href]="(isCoach) ? null :  [publicWebsite, language, competition, 'route-player', player.slugifiedShortName].join('/') | translateRoute"
				target="_blank">
				<div class="playerImage">
					<img *ngIf="lazyload && player.imageUrl" [dflLazyLoad]="player.imageUrl | imageService : 52 " [errorImage]="defaultPlayerImage" [src]="defaultPlayerImage" [alt]="player.name" (error)="errorHandlerHTMLImg($event)" />
				</div>
				<div class="playerShirt">
					<ng-container *ngIf="isCoach">{{ "MATCHCENTER.LINEUP.HC" | translate }}</ng-container>
					<ng-container *ngIf="!isCoach">
						<div class="playerShirt">{{player.shirtNumber}}</div>
					</ng-container>
				</div>
				<div class="playerName">
					{{ getPlayerAliasFirstName(player.name) }}
					<span class="playerLastname">{{ getPlayerAliasLastName(player.name) }}</span>
				</div>
				<div class="playerIcons">
					<!-- playerIcons -->
					<ng-container *ngIf="playerBasicMatchEvents && playerBasicMatchEvents[player.dflDatalibraryObjectId]">
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].sub.out === true">
							<img class="eventIcon icon-substitution-of" width="20" height="17" [src]="'/assets/icons/lineup/substitution-of.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].sub.in === true">
							<img class="eventIcon icon-substitution-on" width="20" height="16" [src]="'/assets/icons/lineup/substitution-on.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].sub.in === true && playerBasicMatchEvents[player.dflDatalibraryObjectId].sub.out === true">
							<img class="eventIcon icon-substitution-onof" width="20" height="16" [src]="'/assets/icons/lineup/substitution-inout.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals === 1 && !playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals">
							<img class="eventIcon icon-goal" width="21" height="17" [src]="'/assets/icons/lineup/goal.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals > 1 && !playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals">
							<img class="eventIcon icon-goals-empty" width="21" height="17" [src]="'/assets/icons/lineup/goals-empty.svg'" />
							<span class="goalsShot">{{ playerBasicMatchEvents[player.dflDatalibraryObjectId]['goals']['goals'] }}</span>
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals === 1 && !playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals">
							<img class="eventIcon icon-goal-own" width="21" height="17" [src]="'/assets/icons/lineup/goal-own.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals === 1 && playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals === 1 ">
							<img class="eventIcon icon-owngoal" width="21" height="17" [src]="'/assets/icons/lineup/goal-owngoal.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals > 1 && !playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals">
							<img class="eventIcon icon-owngoals-empty" width="21" height="17" [src]="'/assets/icons/lineup/owngoals-empty.svg'" />
							<span class="goalsShot">{{ playerBasicMatchEvents[player.dflDatalibraryObjectId]['goals']['ownGoals'] }}</span>
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.ownGoals > 0 && playerBasicMatchEvents[player.dflDatalibraryObjectId].goals.goals > 1 ">
							<img class="eventIcon icon-owngoal" width="21" height="17" [attr.xlink:href]="'/assets/icons/lineup/goals-owngoal-empty.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].cards.yellow === true">
							<img class="eventIcon icon-yellow-card" width="21" height="17" [src]="'/assets/icons/lineup/yellow-card.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].cards.yellowRed === true">
							<img class="eventIcon icon-yellow-red-card" width="20" height="17" [src]="'/assets/icons/lineup/yellow-red-card.svg'" />
						</ng-container>
						<ng-container *ngIf="playerBasicMatchEvents[player.dflDatalibraryObjectId].cards.red === true">
							<img class="eventIcon icon-red-card" width="20" height="17" [src]="'/assets/icons/lineup/red-card.svg'" />
						</ng-container>
					</ng-container>
					<!-- /playerIcons -->
				</div>
			</a>
		</ng-template>

		<div class="row tactical-lineup" *ngIf="!lineupData.home.startingEleven.isTactical || !lineupData.away.startingEleven.isTactical">
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="home headline">{{ "TACTICAL_LINEUP" }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="away headline">{{ "TACTICAL_LINEUP" }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="row home lineup-clubname" [ngStyle]="{'color': home.textColor, 'background-color': home.gradientStartColor}">
					<div class="col col-12">
						<clublogo [clubId]="home.dflDatalibraryClubId" [dimension]="40"></clublogo>
						<span class="clubname">{{ home.nameFull }}</span>
					</div>
				</div>
				<div class="row home">
					<div class="col col-12">
						<ng-container
							*ngIf="lineupData && lineupData.home && lineupData.home.startingEleven && lineupData.home.startingEleven.persons"
							[ngTemplateOutlet]="personListTemplate"
							[ngTemplateOutletContext]="{personList: lineupData.home.startingEleven.persons, isCoach: false, playerBasicMatchEvents: playerBasicMatchEvents.home}"></ng-container>
					</div>
				</div>
			</div>

			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="row away lineup-clubname" [ngStyle]="{'color': away.textColor, 'background-color': away.gradientStartColor}">
					<div class="col col-12">
						<clublogo [clubId]="away.dflDatalibraryClubId" [dimension]="40"></clublogo>
						<span class="clubname">{{ away.nameFull }}</span>
					</div>
				</div>
				<div class="row away">
					<div class="col col-12">
						<ng-container
							*ngIf="lineupData && lineupData.away && lineupData.away.startingEleven && lineupData.away.startingEleven.persons"
							[ngTemplateOutlet]="personListTemplate"
							[ngTemplateOutletContext]="{personList: lineupData.away.startingEleven.persons, isCoach: false, playerBasicMatchEvents: playerBasicMatchEvents.away}"></ng-container>
					</div>
				</div>
			</div>
		</div>

		<div class="row substitutes">
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome)}">
				<div class="home headline">{{ "MATCHCENTER.LINEUP.SUBSTITUTES" | translate }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleAway)}">
				<div class="away headline">{{ "MATCHCENTER.LINEUP.SUBSTITUTES" | translate }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="home">
					<ng-container *ngIf="lineupData && lineupData.home && lineupData.home.bench && lineupData.home.bench.persons" [ngTemplateOutlet]="personListTemplate" [ngTemplateOutletContext]="{personList: lineupData.home.bench.persons, isCoach: false, playerBasicMatchEvents: playerBasicMatchEvents.home}"></ng-container>
				</div>
			</div>

			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="away">
					<ng-container *ngIf="lineupData && lineupData.away && lineupData.away.bench && lineupData.away.bench.persons" [ngTemplateOutlet]="personListTemplate" [ngTemplateOutletContext]="{personList: lineupData.away.bench.persons, isCoach: false, playerBasicMatchEvents: playerBasicMatchEvents.away}"></ng-container>
				</div>
			</div>
		</div>

		<div class="row coaches">
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome)}">
				<div class="home headline">{{ "MATCHCENTER.LINEUP.TRAINER" | translate }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleAway)}">
				<div class="away headline">{{ "MATCHCENTER.LINEUP.TRAINER" | translate }}</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="home">
					<ng-container *ngIf="lineupData && lineupData.home && lineupData.home.coaches && lineupData.home.coaches.persons" [ngTemplateOutlet]="personListTemplate" [ngTemplateOutletContext]="{personList: lineupData.home.coaches.persons, isCoach: true}"></ng-container>
				</div>
			</div>
			<div class="col col-12" [ngClass]="{'col-md-6': (isVisibleHome && isVisibleAway)}">
				<div class="away">
					<ng-container *ngIf="lineupData && lineupData.away && lineupData.away.coaches && lineupData.away.coaches.persons" [ngTemplateOutlet]="personListTemplate" [ngTemplateOutletContext]="{personList: lineupData.away.coaches.persons, isCoach: true}"></ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>
