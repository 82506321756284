import { Inject, Injectable } from '@angular/core';
import { AnalyticsExceptionTrack, AnalyticsPageTrack, AnalyticsTrack, AnalyticsWebviewTrack, IAnalytics } from '@nx-bundesliga/models';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AnalyticsServiceStub implements IAnalytics {
	private dataLayer: any;
	private userId = null;
	private isBrowser: boolean;

	pageTrack = new ReplaySubject<Partial<AnalyticsPageTrack>>(10);
	eventTrack = new ReplaySubject<Partial<AnalyticsTrack>>(10);
	exceptionTrack = new ReplaySubject<any>(10);
	username = new ReplaySubject<{ userId: string | number } | string>(10);
	setUserProperties = new ReplaySubject<any>(10);

	constructor() {}

	trackPage(path: string, custom = {}) {}
	track(properties: AnalyticsTrack) {}

	addWebviewEventToDatalayer(properties: AnalyticsWebviewTrack) {}
	public trackWebviewScreenEvent(screen_name, event, category, product = 'web') {}
	private mapProductNameForTracking(productName: string): string {
		return 'Web BL';
	}
	trackException(properties: AnalyticsExceptionTrack) {}
	setUsername(userId: string) {}
}

export class Analytics implements IAnalytics {
	constructor(@Inject(AnalyticsService) public analytics: AnalyticsService) {}
	track(event: AnalyticsTrack): void {}
}
