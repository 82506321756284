import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BlTickerMatchsettingsControlsComponent } from './bl-ticker-matchsettings-controls.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [BlTickerMatchsettingsControlsComponent],
	exports: [BlTickerMatchsettingsControlsComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, NgxTippyModule]
})
export class BlTickerMatchsettingsControlsModule {}
