export const llmImageTagQuestions = [
	{ tag: 'Throw-In' as const, question: 'Does the image show a player standing at the sideline, preparing or performing a throw-in?' },
	{ tag: 'Free Kick' as const, question: 'Does the image show a player preparing or taking a free kick (often the player is standing next to the ball and the opposing team is forming a defensive wall in the distance)?' },
	{ tag: 'Penalty' as const, question: 'Does the image show a player taking a penalty kick?' },
	{ tag: 'Bicycle Kick' as const, question: 'Does the image show a player executing a bicycle kick where the player is airborne, performing an overhead kick with their back towards the ground?' },
	{ tag: 'Celebrate' as const, question: 'Does the image show player or fans celebraing a goal or victory (celebrations can include hugging, jumping, running with arms raised, or any other display of jubilation)?' },
	{ tag: 'Laughing' as const, question: 'Does the image prominently focus on players, coaches, or fans visibly laughing (the subjects are usually in a relaxed or joyful setting, not in the heat of competitive play)?' },
	{ tag: 'Disappointed' as const, question: 'Does the image show players, coaches, or fans show signs of disappointment or frustration (common expressions include downcast eyes, hands on head, or a slumped posture)?' },
	{ tag: 'Referee' as const, question: 'Does the image focus or prominently show a referee on the field (the referee is typically identifiable by their uniform and whistle, and may be making calls, running, or observing play)?' },
	{ tag: 'Linesman' as const, question: 'Does the image focus or prominently show a linesman at the sideline with a flag (the linesman is often signaling for offsides or throw-ins and usually wears a uniform to assist the referee)?' },
	{ tag: 'Goal' as const, question: 'Does this image focus or prominently feature goalposts and the net in a very prominent way?' },
	{ tag: 'Goalkeeper' as const, question: 'Does this image show the goalkeeper (the goalkeeper is typically in action saving a shot, distributing the ball, or standing in the goal area ready to defend)?' },
	{ tag: 'Coach' as const, question: 'Does the image show the coach on the sidelines (the coach is often giving instructions, reacting to the game, or during a team talk with players)?' },
	{ tag: 'Yellow Card' as const, question: 'Does the image show a referee showing a yellow card during the game?' },
	{ tag: 'Red Card' as const, question: 'Does the image show a referee showing a red card during the game?' },
	{ tag: 'Flag' as const, question: 'Does this image focus or prominently show a flag (can be a corner flag, a linemans flag or fans waving a flag)?' },
	{ tag: 'Wall' as const, question: 'Does this image show focus or prominently show the defensive wall formed by players during a free kick (the wall is typically made up of several players standing side by side to block the shot)?' },
	{ tag: 'Shoe' as const, question: 'Does this image focus on soccer shoes or cleats (the shoes can be worn or displayed as standalone items)?' },
	{ tag: 'Ball' as const, question: 'Does this image focus or prominently show the soccer ball (the ball can be in action during play or shown as a static object on the field - but it needs to occupy a significant portion of the image)?' },
	{ tag: 'Corner' as const, question: 'Does the image show the corner area of the field, the corne flag or a player taking a corner kick during the game?' },
	{ tag: 'Fans' as const, question: 'Does the image focus or prominently show fans inside or outside the stadium (fans may be celebrating, cheering, or reacting to the events of the game)?' },
	{ tag: 'Mascot' as const, question: 'Does the image show one or more team mascots?' },
	{ tag: 'Wheelchair' as const, question: 'Does the image show wheelchairs (this is typically in the context of accessibility at the stadium or showing fans using wheelchairs to enjoy the game?)' },
	{ tag: 'Scarf' as const, question: 'Does the image show scarves worn or displayed by fans as part of their fan gear?' },
	{ tag: 'Badge' as const, question: "Does this image focus or prominently show a close up of team badges (these can be on jerseys, merchandise, or displayed as standalone items representing the team's identity)?" },
	{ tag: 'General View' as const, question: 'Is this image a wide shots of the stadium or field, providing an overview of the game or venue, showing large sections of the field and sometimes the crowd?' },
	{ tag: 'Tunnel' as const, question: "Does this image showing the players' tunnel where players enter and exit the field, often seen before the start of the game or at halftime?" },
	{ tag: 'Bench' as const, question: 'Does this image show the team benches where substitutes and coaching staff sit during the game, often seen along the sidelines?' },
	{ tag: 'Dressing Room' as const, question: 'Does this show the inside of the dressing room where players prepare before the match (the image may include uniforms, equipment, and players getting ready)?' },
	{ tag: 'Seats' as const, question: 'Does this image focus or prominently feature stadium seating (this can include seats occupied by fans or empty seats in the stadium)?' },
	{ tag: 'Goal Net' as const, question: 'Does the image focus or prominently show specifically on the net of the goal (often, these images show the ball hitting the net after a goal is scored)?' },
	{ tag: 'Tribune' as const, question: 'Does the images prominently show tribunes or stands in the stadium (typically sections of the audience, including seated fans are shown - but the stand can also be empty)?' },
	{ tag: 'Floodlight' as const, question: 'Does this image focus or feature stadium floodlights in a prominent way (these can be in use during a night game or shown as static objects that illuminate the field)?' },
	{ tag: 'Pitch' as const, question: 'Does this image prominently show the playing surface itself (this includes the grass or artificial turf, showing the condition and markings of the field)? Only answer with yes if the field is clearly the focus of the image and occupies a signifcant part of the image.' },
	{ tag: 'Reporter' as const, question: 'Does the image show reporters covering the game (reporters are often seen with a microphone or notepad, conducting interviews or providing commentary)?' },
	{ tag: 'Interview' as const, question: 'Is this image a post-match or pre-match interview (these feature players, coaches, or officials speaking to the media)?' },
	{ tag: 'Cameraman' as const, question: 'Does the image show one or more camerapersons filming the game (camerapersons often have large cameras and are positioned on the sidelines to capture the action?)' },
	{ tag: 'Press Conference' as const, question: 'Does the image show a press conference (these typically feature the coach or players speaking to the media, often after the game)?' },
	{ tag: 'Media' as const, question: 'Does the image feature various media personnel (this includes photographers, reporters, and broadcasters covering the game)?' },
	{ tag: 'Mixed Zone' as const, question: 'Does the image show the "mixed zone" where players and media interact post-match, often for quick interviews or comments?' },
	{ tag: 'Press' as const, question: 'Does the image show press members (this includes journalists and photographers working to cover the event)?' },
	{ tag: 'Microphone' as const, question: 'Does the image focus on microphones (these are often used in the context of interviews or press conferences, capturing sound)?' },
	{ tag: 'Spidercam' as const, question: 'Does the image focus on the Spidercam (this is an aerial camera used to capture dynamic shots of the game from above the field)?' },
	{ tag: 'Camera' as const, question: 'Does the image feature cameras (this includes handheld cameras or those mounted for filming the match, capturing the game from various angles)?' },
	{ tag: 'Photographer' as const, question: 'The the image images show photographers (photographers are seen taking pictures of the game or players, often from the sidelines)?' },
	{ tag: 'Tackle' as const, question: 'Does the image show two or more players fighting over the ball during the match?' },
	{ tag: 'Header' as const, question: 'Does the image show a player doing a header during the match?' },
	{ tag: 'Bundesliga Logo' as const, question: 'Does the image show a large version of the official bundesliga logo (a white soccer player on red foreground)?' },
	{ tag: '2. Bundesliga Logo' as const, question: 'Does the image show a large version of the official second bundesliga logo (a white soccer player on red foreground with a gray triangle on the bottom right)?' },
	{ tag: 'Meisterschale' as const, question: 'Does the image show the "Meisterschale" (the trophy awarded to the champions of a Bundesliga season)?' },
	{ tag: 'Sportcast' as const, question: 'Does the image show the official logo of "Sportcast" (a bold red square with a white symbol in the center resembling a camera viewfinder, with a plus sign in the middle)?' },
	{ tag: 'ESPN' as const, question: 'Does the image show the logo of ESPN (a major sports television network)?' },
	{ tag: 'Derbystar' as const, question: 'Does the image show the logo of Derbystar (a sports article manufacturer)?' },
	{ tag: 'DAZN' as const, question: 'Does the image show the logo of ESPN (a major sports television network)?' },
	{ tag: 'Sky' as const, question: 'Does the image show the logo of Sky (a major sports television network)?' }
];

export type LlmImageTagEnum = (typeof llmImageTagQuestions)[number]['tag'];
