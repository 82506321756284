<h2 mat-dialog-title>Select Matchdays</h2>

<ng-template #loading>
	<div class="d-flex align-items-center justify-content-center flex-grow-1">
		<mat-progress-spinner diameter="60" mode="indeterminate"></mat-progress-spinner>
	</div>
</ng-template>

<mat-dialog-content class="mat-typography">
	<nx-bundesliga-bundesmaster-ui-season-selector [formControl]="seasonSelectorFormControl"></nx-bundesliga-bundesmaster-ui-season-selector>

	<div class="matchday-grid" *ngIf="matchdays.status !== 'loading'; else loading">
		<mat-card *ngFor="let matchday of matchdays.items" class="matchday-card" [class.selected]="isSelected(matchday)" (click)="toggleSelectMatchday(matchday)">
			<div class="small-gap d-flex flex-column align-items-center">
				<small>Matchday</small>
				<div class="matchday-label">{{matchday.matchday}}</div>
				<small>{{matchday.matchdayId}}</small>
			</div>
		</mat-card>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
