import { Injectable } from '@angular/core';
import { BUNDESLIGA_SEASONS } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { DflDatalibraryService } from './dflDatalibrary.service';

@Injectable()
export class DflDatalibrarySeasonService extends DflDatalibraryService {
	public seasonId: string;
	public seasonName: string;

	private seasonNames = {
		'DFL-SEA-0001K0': '2016/2017',
		'DFL-SEA-0001K1': '2017/2018',
		'DFL-SEA-0001K2': '2018/2019',
		'DFL-SEA-0001K3': '2019/2020',
		'DFL-SEA-0001K4': '2020/2021',
		'DFL-SEA-0001K5': '2021/2022',
		'DFL-SEA-0001K6': '2022/2023',
		'DFL-SEA-0001K7': '2023/2024',
		'DFL-SEA-0001K8': '2024/2025'
	};

	constructor(public override configService: ConfigService) {
		super(configService);
		this.seasonId = this.getSeasonByCompetitionId(this.competitionId) || 'DFL-SEA-0001K7'; /* just a default value*/
		this.seasonName = this.getSeasonNameById(this.seasonId) || '2023/2024';
	}

	public getSeasonByCompetitionId(competitonId: string): string {
		return this.configService.getSettings(`${competitonId}.season.dflDatalibrarySeasonId`);
	}

	/**
	 * Returns the URL friendly name for a given DFL DataLibrary Season ID (i.e. DFL-SEA-0001K2 --> 2018/2019).
	 * If no valid ID is given the friendly name for the current season is returned.
	 *
	 * @param id
	 * @param friendly boolean if true replaces the dash with a hyphen to be url friendly.
	 */
	getSeasonNameById(id: string, friendly = false): string {
		if (this.seasonNames.hasOwnProperty(id)) {
			return friendly ? this.replaceSeasonNameSeperator(this.seasonNames[id]) : this.seasonNames[id];
		}
		return friendly ? this.replaceSeasonNameSeperator(this.seasonName) : this.seasonName;
	}

	/**
	 * Returns the URL friendly name for a given DFL DataLibrary Season ID (i.e.  2018/2019 --> 2018-2019).
	 */
	replaceSeasonNameSeperator(seasonName: string): string {
		return seasonName.replace('/', '-');
	}

	/**
	 * Returns the DFL DataLibrary Season ID (i.e. DFL-SEA-0001K2).
	 * @param seasonName 2018-2019 or 2018/2019
	 */
	getSeasonIdBySeasonName(seasonName: string): string {
		for (const seasonId in this.seasonNames) {
			if (this.seasonNames.hasOwnProperty(seasonId) && this.replaceSeasonNameSeperator(this.seasonNames[seasonId]) === seasonName) {
				return seasonId;
			}
		}
		return BUNDESLIGA_SEASONS.CURRENT;
	}

	get SeasonId(): string {
		return this.seasonId;
	}

	set SeasonId(value: string) {
		this.seasonId = value;
	}
}
