import { Injectable } from '@angular/core';
import { Competition, Language, PagedApiResponse } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { BundesligaHttpClientService } from '../bundesliga-http-client/bundesliga-http-client.service';

const CIS_NETWORK_IDS: { [key: number]: string } = {
	1: 'en-EN',
	3: 'de-DE',
	4: 'es-ES',
	5: 'jp-JP',
	9: 'fr-FR'
};

@Injectable({
	providedIn: 'root'
})
export class BundesligaWappApiService {
	constructor(private http: BundesligaHttpClientService) {}
	// public getArticles(competition = 'bundesliga', language = 'de-DE', pageNumber = 0): Observable<PagedApiResponse<IHomeControlArticle>> {
	// 	return this.http.get<PagedApiResponse<IHomeControlArticle>>(`editorial?category=${competition}&pageNumber=${pageNumber}`, language);
	// }

	// public getHomeConfig(competition: Competition, language: Language): Observable<IHomeControlResponse> {
	// 	return this.http.get<IHomeControlResponse>('home/' + this.competitionToNumber(competition) + `?${+new Date()}`, language.culture);
	// }
	public getArticles(competition = 'bundesliga', language = 'de-DE', pageNumber = 0): Observable<PagedApiResponse<any>> {
		return this.http.get<PagedApiResponse<any>>(`editorial?category=${competition}&pageNumber=${pageNumber}`, language);
	}

	public getHomeConfig(competition: Competition, language: Language): Observable<any> {
		return this.http.get<any>('home/' + this.competitionToNumber(competition) + `?${+new Date()}`, language.culture);
	}

	public getByCisId(cisId: string) {
		return this.http.get<any>(`editorial/cisid/${cisId}`, this.getLanguageByNetworkId(parseInt(cisId[0])));
	}

	// public getBars(country = 'us'): Observable<BundesligaPlace[]> {
	// 	return this.http.get<BundesligaPlace[]>(`barfinder/bars/country/${country}`, 'en');
	// }

	// public getBar(id: string): Observable<BundesligaPlace> {
	// 	return this.http.get<BundesligaPlace>(`barfinder/bars/${id}`, 'en');
	// }

	// public getCities(country = 'us'): Observable<BundesligaPlacesCity[]> {
	// 	return this.http.get<BundesligaPlacesCity[]>(`barfinder/cities/country/${country}`, 'en');
	// }
	public getBars(country = 'us', refresh = true): Observable<any[]> {
		return this.http.get<any[]>(`barfinder/bars/country/${country}?${refresh ? Math.random() : ''}`, 'en');
	}

	public getBar(id: string, refresh = true): Observable<any> {
		return this.http.get<any>(`barfinder/bars/${id}?${refresh ? Math.random() : ''}`, 'en');
	}

	public getCities(country = 'us', refresh = true): Observable<any[]> {
		return this.http.get<any[]>(`barfinder/cities/country/${country}?${refresh ? Math.random() : ''}`, 'en');
	}

	private competitionToNumber(competition: Competition): number {
		if (competition.name === '2bundesliga') {
			return 2;
		}

		return 1;
	}

	private getLanguageByNetworkId(networkId: number) {
		return CIS_NETWORK_IDS[networkId] || 'en';
	}
}
