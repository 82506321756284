export const languages: Languages = {
	de: {
		code: 'de',
		name: 'German',
		localName: 'Deutsch',
		culture: 'de-DE',
		ietf: 'de_DE',
		order: 1
	},
	en: {
		code: 'en',
		name: 'English',
		localName: 'English',
		culture: 'en-EN',
		ietf: 'en_US',
		order: 2
	},
	es: {
		code: 'es',
		name: 'Spanish',
		localName: 'Español',
		culture: 'es-ES',
		ietf: 'es_ES',
		order: 3
	},
	jp: {
		code: 'jp',
		name: 'Japanese',
		localName: '日本語',
		culture: 'ja-JP',
		ietf: 'ja_JP',
		order: 3
	},
	fr: {
		code: 'fr',
		name: 'French',
		localName: 'Français',
		culture: 'fr-FR',
		ietf: 'fr_FR',
		order: 3
	},
	pt: {
		code: 'pt',
		name: 'Portuguese',
		localName: 'Português',
		culture: 'pt-BR',
		ietf: 'pt_BR',
		order: 3
	},
	ar: {
		code: 'ar',
		name: 'Arabic',
		localName: 'عربي',
		culture: 'ar-EG',
		ietf: 'ar_EG',
		order: 3
	}
};

export interface Language {
	code: string;
	name: string;
	localName: string;
	culture: string;
	ietf: string;
	order: number;
}

export interface Languages {
	[key: string]: Language;
}
