import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetDimensions } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiImageCropperComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper';

interface CropperDialogData {
	imageUrl: string;
	targetAspectRatio: number;
	minWidth?: number;
	minHeight?: number;
}

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-image-cropper-dialog',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatDialogModule, BundesmasterUiImageCropperComponent],
	templateUrl: './bundesmaster-ui-image-cropper-dialog.component.html',
	styleUrls: ['./bundesmaster-ui-image-cropper-dialog.component.scss']
})
export class BundesmasterUiImageCropperDialogComponent {
	public imageUrl: string = null;
	public croppedImage: string;
	public dimension: AssetDimensions = 'desktop';

	constructor(@Inject(MAT_DIALOG_DATA) public data: CropperDialogData, public dialogRef: MatDialogRef<BundesmasterUiImageCropperDialogComponent>) {}

	public cropImage(croppingData: any) {
		this.croppedImage = croppingData.url;
	}

	public confirm() {
		this.dialogRef.close(this.croppedImage);
	}
	public cancel() {
		this.dialogRef.close(null);
	}
}
