import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { LivetickerSides } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-playerprogressranking-card',
	templateUrl: './stats-playerprogressranking-card.component.html',
	styleUrls: ['./stats-playerprogressranking-card.component.scss']
})
export class StatsPlayerprogressrankingCardComponent implements OnInit, OnChanges, OnDestroy {
	@Input() title: string;
	@Input() homeColor: string;
	@Input() awayColor: string;
	@Input() homeTextColor: string;
	@Input() awayTextColor: string;
	@Input() isLiveticker = false;
	@Input() ranking: {
		rank: number;
		value: number;
		side: LivetickerSides;
		person: {
			dflDatalibraryObjectId: string;
			name: string;
			imageUrl: string;
		};
	}[];
	public maxValue = 0;
	public language: string;
	public competition: string;
	private languageSubscription: Subscription;
	private competitionSubscription: Subscription;
	public homeValue: number[] = [0, 0, 0];
	public awayValue: number[] = [0, 0, 0];
	public homeName: string[] = ['', '', ''];
	public awayName: string[] = ['', '', ''];
	public showRankings = 3;

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.languageSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate: Language) => {
			this.language = langstate.code;
		});
		this.competitionSubscription = this.cstore.pipe(select(getWorkingCompetition)).subscribe((compState) => {
			this.competition = compState.name;
		});
	}

	ngOnDestroy(): void {
		if (this.languageSubscription) {
			this.languageSubscription.unsubscribe();
		}
		if (this.competitionSubscription) {
			this.competitionSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.ranking?.length > 0) {
			this.maxValue = Math.max(...this.ranking.map((item) => item.value));

			[...Array(this.showRankings).keys()].forEach((rankingNumber) => {
				const homePlayer = this.ranking.filter((player) => player.rank === rankingNumber + 1 && player.side === 'home');
				this.homeValue[rankingNumber] = homePlayer.length > 0 ? homePlayer[0].value : 0;
				this.homeName[rankingNumber] = homePlayer.length > 0 ? homePlayer[0].person.name : '';

				const awayPlayer = this.ranking.filter((player) => player.rank === rankingNumber + 1 && player.side === 'away');
				this.awayValue[rankingNumber] = awayPlayer.length > 0 ? awayPlayer[0].value : 0;
				this.awayName[rankingNumber] = awayPlayer.length > 0 ? awayPlayer[0].person.name : '';
			});
		}
	}
}
