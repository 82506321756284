<div *ngIf="liveEntry && liveEntry.detail" [ngClass]="textColorClass" [style.backgroundColor]="backgroundColor" class="event goalEvent side_{{side}}">
	<div class="head">
		<livetickerevent-playtime *ngIf="liveEntry.matchSection !== 'PENALTY'" [liveEntry]="liveEntry" [clubData]="clubData" [textColor]="textColorClass"></livetickerevent-playtime>
		<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [textColor]="textColorClass" [conference]="conference" *ngIf="conference"></livetickerevent-score>
	</div>

	<div class="body">
		<h3 class="scorer">
			<a *ngIf="liveEntry?.detail?.scorer?.name" class="name" [ngClass]="textColorClass" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking === true ? (['/', language, competition, 'route-player', liveEntry.detail.scorer.name | slugify] | translateRoute) : null">
				<span class="first">{{liveEntry.detail.scorer.name | livetickerEventPlayername:'first'}}</span>
				<span class="last">{{liveEntry.detail.scorer.name | livetickerEventPlayername:'last'}}</span>
			</a>
		</h3>

		<div class="score-container" [ngClass]="{'own': liveEntry.entryType === 'ownGoal'}">
			<h2 class="goal">{{ "LIVETICKER.EVENTS." + liveEntry.entryType | translate }}</h2>
			<span class="score" *ngIf="liveEntry.detail.score">
				<span [ngClass]="{highlight: side === 'away'}">{{liveEntry.detail.score.home}}</span>
				<span class="highlight spacer">:</span>
				<span [ngClass]="{highlight: side === 'home'}">{{liveEntry.detail.score.away}}</span>
			</span>
		</div>

		<div class="by-penalty" *ngIf="liveEntry.detail?.penalty">
			<span>{{ "LIVETICKER.by_penalty" | translate}}</span>
		</div>
	</div>

	<player-image
		*ngIf="liveEntry?.detail?.scorer && liveEntry?.detail?.scorer?.dflDatalibraryObjectId !== '' "
		class="scorer-image"
		[playerImage]="liveEntry.detail.scorer.imageUrl | playerImage: 'PORTRAIT_S' | imageService:0:180"
		placeHolderImg="/assets/placeholder/player-portrait-default.svg"
		[dimension]="180"
		[language]="language"
		[competition]="competition"
		[playerName]="liveEntry?.detail?.scorer?.name"
		[enabledLinking]="enablePlayerLinking"></player-image>

	<img *ngIf="isCustomMatch && !(liveEntry?.detail?.scorer && liveEntry?.detail?.scorer?.dflDatalibraryObjectId !== '') " class="scorer-image no-playerImage" [ngSrc]="clubData[side]?.logoUrl | imageService:0:180" [alt]="" ngSrc="/assets/placeholder/player-portrait-default.svg" offset="200" fill />
</div>

<div class="advancedStats">
	<div class="metric" *ngIf="xGoalsEnabled && !!liveEntry?.detail?.xG">
		<span class="metric-name">
			{{ "LIVETICKER.MATCHFACTS.xGoals.TITLE" | translate }}
			<livetickerevent-matchfacts-info matchFact="xGoals"></livetickerevent-matchfacts-info>
		</span>
		<span class="metric-value">{{liveEntry.detail?.xG * 100 | number:"1.0-0" }} %</span>
	</div>
	<div class="metric" *ngIf="shotSpeedEnabled && !!liveEntry?.detail?.shotSpeed">
		<span class="metric-name">
			{{ "LIVETICKER.MATCHFACTS.shotSpeed.TITLE" | translate }}
			<livetickerevent-matchfacts-info matchFact="shotSpeed"></livetickerevent-matchfacts-info>
		</span>
		<span class="metric-value">{{liveEntry.detail?.shotSpeed | number:"1.0-2" }} km/h</span>
	</div>
	<div class="metric" *ngIf="distanceToGoalEnabled && !!liveEntry?.detail?.distanceToGoal">
		<span class="metric-name">
			{{ "LIVETICKER.MATCHFACTS.distanceToGoal.TITLE" | translate }}
			<livetickerevent-matchfacts-info matchFact="distanceToGoal"></livetickerevent-matchfacts-info>
		</span>
		<span class="metric-value">{{liveEntry.detail?.distanceToGoal | number:"1.0-2" }} m</span>
	</div>

	<livetickerevent-matchfacts-banner *ngIf="!!liveEntry?.detail?.xG || !!liveEntry?.detail?.shotSpeed || !!liveEntry?.detail?.distanceToGoal" [isWebview]="isWebview"></livetickerevent-matchfacts-banner>
</div>
