import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LivetickerEventMatchfactsBannerModule } from '../livetickerevent-matchfacts-banner/liveticker-event-matchfacts-banner.module';
import { LivetickerEventMatchfactsInfoComponent } from './liveticker-event-matchfacts-info.component';
import { DflSimpleIconModule } from '@nx-bundesliga/bundesliga-com/components/dfl-simple-icon';

@NgModule({
	declarations: [LivetickerEventMatchfactsInfoComponent],
	exports: [LivetickerEventMatchfactsInfoComponent],
	imports: [CommonModule, TranslateModule, LivetickerEventMatchfactsBannerModule, DflSimpleIconModule]
})
export class LivetickerEventMatchfactsInfoModule {}
