import { BundesmasterBroadcaster } from './bundesmaster-broadcaster';

export interface BundesmasterBroadcastAdditionRequest {
	readonly matchId: string;
	readonly broadcasterId: number;
	readonly countries: readonly string[];
}

export interface BundesmasterBroadcastAddition extends BundesmasterBroadcastAdditionRequest {
	readonly matchLabel: string;
	readonly broadcaster?: BundesmasterBroadcaster;
}

export interface BundesmasterBroadcastAdditionGroup {
	readonly matchDayLabel: string;
	readonly matchDayId: string;
	readonly additions: BundesmasterBroadcastAddition[];
	readonly seasonStartYear: number;
	readonly matchDay: number;
}

export interface BundesmasterBroadcastAdditionList {
	readonly groups: readonly BundesmasterBroadcastAdditionGroup[];
}
