import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BundesmasterUiStorySlideComponent } from '@nx-bundesliga/bundesmaster/stories/ui-story-slide';
import { BundesmasterUiStorySlideDeckComponent } from '@nx-bundesliga/bundesmaster/stories/ui-story-slide-deck';
import { LivetickerEventModule } from '../../organisms/liveticker/components/liveticker-event/liveticker-event.module';
import { AttachStorySlideImageDialogComponent } from './attach-slide-image/bl-story-dialog-attach-slide-image.component';
import { CreateBasicSlideDialogComponent } from './create-basic-slide/bl-story-dialog-create-basic-slide.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [CreateBasicSlideDialogComponent, AttachStorySlideImageDialogComponent],
	exports: [CreateBasicSlideDialogComponent, AttachStorySlideImageDialogComponent],
	imports: [
		CommonsModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		LivetickerEventModule,
		BundesmasterUiStorySlideDeckComponent,
		MatExpansionModule,
		BundesmasterUiStorySlideComponent,
		MatTooltipModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatMenuModule,
		MatOptionModule,
		MatSelectModule,
		MatCardModule,
		MatTabsModule,
		MatCheckboxModule,
		MatProgressSpinnerModule
	]
})
export class BlStoryDialogsModule {}
