import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { StatsPlayerRanking } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-playercomparison-card',
	templateUrl: './stats-playercomparison-card.component.html',
	styleUrls: ['./stats-playercomparison-card.component.scss']
})
export class StatsPlayercomparisonCardComponent implements OnInit, OnChanges, OnDestroy {
	@Input() title: string;
	@Input() homeColor: string;
	@Input() awayColor: string;
	@Input() homeTextColor: string;
	@Input() awayTextColor: string;
	@Input() horizontal = false;
	@Input() ranking: StatsPlayerRanking;
	@Input() maxValue = 0;
	@Input() isLiveticker = false;
	@Input() enablePlayerLinking = false;

	public language: string;
	public competition: string;
	private languageSubscription: Subscription;
	private competitionSubscription: Subscription;
	public homeBorderNeeded: boolean;
	public awayBorderNeeded: boolean;
	public homeSize = '0%';
	public awaySize = '0%';

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.languageSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate: Language) => {
			this.language = langstate.code;
		});
		this.competitionSubscription = this.cstore.pipe(select(getWorkingCompetition)).subscribe((compState) => {
			this.competition = compState.name;
		});
	}

	ngOnDestroy(): void {
		if (this.languageSubscription) {
			this.languageSubscription.unsubscribe();
		}
		if (this.competitionSubscription) {
			this.competitionSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.homeBorderNeeded = this.homeColor.toLowerCase() === '#ffffff';
		this.awayBorderNeeded = this.awayColor.toLowerCase() === '#ffffff';

		// the highest value in full width while the other value is displayed relatively
		let leftPercentageNumber = Math.min((this.ranking.home.value / this.ranking.away.value) * 100, 100);
		let rightPercentageNumber = Math.min((this.ranking.away.value / this.ranking.home.value) * 100, 100);
		// if maxValue is set to value other than 0 the scale needs to be adjusted
		if (this.maxValue > 0) {
			leftPercentageNumber = Math.min((this.ranking.home.value * 50) / this.maxValue, 50);
			rightPercentageNumber = Math.min((this.ranking.away.value * 50) / this.maxValue, 50);
		}
		this.homeSize = `${leftPercentageNumber}%`;
		this.awaySize = `${rightPercentageNumber}%`;

		const homePlayer = this.ranking.home.playerName;
		const awayPlayer = this.ranking.away.playerName;
	}
}
