<div class="item stsFeedLink">
	<div class="btn-group-container">
		<div class="btn-group" ngxTippy="Open STS XML-Feed of this match">
			<a mat-flat-button target="_blank" [href]="'https://httpget.distribution.production.datahub-sts.de/DeliveryPlatform/REST/PullOnce/dflds-67wn-wxpk-b2uu/DFL-03.05-Ereignisdaten-Spiel-Basic-Erweitert/' + matchId">STS-Feed</a>
		</div>
		<div [matMenuTriggerFor]="stsIngestMenu" class="btn-group">
			<a mat-flat-button class="menutrigger"><mat-icon>keyboard_arrow_down</mat-icon></a>
		</div>
	</div>
	<mat-menu #stsIngestMenu="matMenu">
		<button (click)="ingestMatch()" mat-menu-item>
			<mat-icon>textsms</mat-icon>
			Submit this feed for reingestion
		</button>
		<button (click)="openCustomIngestModal()" mat-menu-item>
			<mat-icon>textsms</mat-icon>
			Modify feed before reingestion
		</button>
	</mat-menu>
</div>

<ng-template #customIngestModal>
	<h2 mat-dialog-title>STS Feed for you to modify</h2>
	<mat-dialog-content class="mat-typography">
		<div class="container">
			<div class="row">
				<ng-container *ngIf="(stsFeed$ | async) as feedData else loading">
					<div class="col-12">
						<mat-form-field class="w-100">
							<mat-label>XML Feed</mat-label>
							<textarea matInput rows="10" class="w-100" #customFeedInput>{{feedData}}</textarea>
						</mat-form-field>
					</div>
					<div class="col-12">
						<button mat-button (click)="customIngest(customFeedInput.value)">Submit</button>
					</div>
				</ng-container>
				<ng-template #loading>
					<div class="col-12">
						<dfl-loader [loading]="true" surrounding="large"></dfl-loader>
					</div>
				</ng-template>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close cdkFocusInitial>Close</button>
	</mat-dialog-actions>
</ng-template>
