import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { ArticleContentBlockEmbed } from '../../models/article.model';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterOembedApiService {
	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	public getEmbedForPlatform(embedPlatform: string, embedUrl: string): Observable<ArticleContentBlockEmbed> {
		return this.http.post<ArticleContentBlockEmbed>(`${this.environment.apis.content.url}/oembed/${embedPlatform}`, { embedUrl });
	}

	public getEmbed(embedUrl: string): Observable<ArticleContentBlockEmbed> {
		return this.http.post<ArticleContentBlockEmbed>(`${this.environment.apis.content.url}/oembed`, { embedUrl });
	}
}
