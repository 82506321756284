<div [class.large]="!small" [class.full-height]="fullHeight" class="dropzone" bundesmasterDropzone (dropped)="fileDragHandler($event)">
	<input type="file" #fileDropRef id="fileDropRef" [accept]="accept" [multiple]="multiple" (change)="fileBrowseHandler($event)" />
	<button mat-flat-button color="primary" (click)="clickHandler()" [disabled]="disabled">
		<mat-icon>upload</mat-icon>
		{{ buttonText }}
	</button>

	<p class="info">
		<mat-icon>wallpaper</mat-icon>
		{{ dropZoneText }}
	</p>
	<p class="small">
		<span>Allowed filetypes are: </span>
		<ng-container *ngFor="let fileType of fileTypeHint; index as i; count as c">
			<strong>{{ fileType }}</strong>
			<span *ngIf="i < c - 2">, </span>
			<span *ngIf="i === c - 2"> and </span>
		</ng-container>
		<span>!</span>
	</p>
</div>
