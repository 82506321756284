<h2 mat-dialog-title>Create Liveticker entry</h2>
<mat-dialog-content class="mat-typography action-create">
	<bl-rapid-ticker-composer
		#composer
		[language]="language"
		[matchStatus]="newLiveEntry.matchSection"
		[minuteOfPlay]="newLiveEntry.playtime"
		[liveBlogInfos]=""
		[liveBlogTeams]="clubData"
		[matchId]="match.dflDatalibraryMatchId"
		[seasonId]="match.dflDatalibrarySeasonId"
		[competitionId]="match.dflDatalibraryCompetitionId"
		[matchdayId]="match.dflDatalibraryMatchdayId"
		[side]="newLiveEntry.side || 'none'"
		[liveEntry]="newLiveEntry"
		[embedId]="newLiveEntry.detail.embedId"
		[hideOtherEntryTypes]="true"
		[editMode]="false"
		[standalone]="true"
		[customMatch]="customMatch"></bl-rapid-ticker-composer>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
	<button mat-button [disabled]="processing" (click)="onCreateClick()">Publish</button>
</mat-dialog-actions>
