import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConferenceLivetickerPipe, LivetickerPipe } from './liveticker.pipe';
import { VideoDurationPipe } from './videoduration.pipe';

@NgModule({
	declarations: [LivetickerPipe, ConferenceLivetickerPipe, VideoDurationPipe],
	exports: [LivetickerPipe, ConferenceLivetickerPipe, VideoDurationPipe],
	providers: [LivetickerPipe, ConferenceLivetickerPipe, VideoDurationPipe],
	imports: [CommonModule]
})
export class LivetickerEventsPipeModule {}
