import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { DflJwPlayerModule } from '@nx-bundesliga/bundesliga-com/components/dfl-jw-player';
import { BlTranslationConfigDialogComponent } from './translation-config-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlTranslationConfigDialogComponent],
	exports: [BlTranslationConfigDialogComponent],
	imports: [CommonsModule, MatIconModule, MatInputModule, MatButtonModule, MatDialogModule, DflJwPlayerModule, FormsModule, MatProgressSpinnerModule, MatFormFieldModule, ReactiveFormsModule, MatOptionModule, MatSelectModule]
})
export class BlTranslationConfigDialogModule {}
