import { HttpClient } from '@angular/common/http';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { data } from 'aws-cdk/lib/logging';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StsFeedService } from '../../../services/sts-feed/sts-feed.service';

@Component({
	selector: 'bl-sts-feed-controls',
	templateUrl: './bl-sts-feed-controls.component.html',
	styleUrls: ['./bl-sts-feed-controls.component.scss']
})
export class BlStsFeedControlsComponent {
	@Input() matchId: string;
	@ViewChild('customIngestModal') customIngestModal: TemplateRef<any>;
	public stsFeed$: Observable<string>;
	constructor(private stsFeedService: StsFeedService, public dialog: MatDialog, private http: HttpClient) {}

	public openCustomIngestModal() {
		this.stsFeed$ = this.http.get<string>(`https://httpget.distribution.production.datahub-sts.de/DeliveryPlatform/REST/PullOnce/dflds-67wn-wxpk-b2uu/DFL-03.05-Ereignisdaten-Spiel-Basic-Erweitert/${this.matchId}`).pipe(catchError(() => of('Put your feed XML here and click submit')));
		this.dialog.open(this.customIngestModal, {
			minWidth: '840px'
		});
	}

	public customIngest(feed: string) {
		this.stsFeedService.customIngest(feed);
	}

	public ingestMatch() {
		if (confirm('Re-ingest match data?')) {
			this.stsFeedService.ingestMatch(this.matchId);
		}
	}
}
