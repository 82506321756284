import { Component, Input, OnInit } from '@angular/core';
import { BundesligaMatchStates, LiveBlogAutoEventWhistle, LiveBlogClubs, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import idx from 'idx';
import * as _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'bl-progress-event-input',
	templateUrl: './bl-progress-event-input.component.html',
	styleUrls: ['./bl-progress-event-input.component.scss', '../liveticker-events.common.scss']
})
export class BlProgressEventInputComponent implements OnInit {
	@Input() liveEntry: LiveBlogAutoEventWhistle & { order?: any };
	@Input() clubData: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() side: LivetickerSides;
	@Input() orderType = 'auto';
	@Input() orderValue = 'auto';
	@Input() editMode = false;
	@Input() customMatch = false;

	public previewEntry: LiveBlogAutoEventWhistle & { order?: any };
	@Input() tributeOptions: any;
	constructor() {}
	public update() {
		this.previewEntry = Object.assign({}, this.liveEntry);
	}
	public updatePlaytime() {
		if (this.editMode === true && (this.liveEntry.playtime.minute !== this.minuteOfPlay.minute || this.liveEntry.playtime.injuryTime !== this.minuteOfPlay.injuryTime)) {
			if (this.orderType === 'keep') {
				this.orderType = 'auto';
			}
			this.updateOrderType();
		}
	}

	public updateOrderType() {
		switch (this.orderType) {
			case 'auto':
				this.orderValue = 'auto';
				break;
			case 'keep':
				this.orderValue = 'keep';
				break;
			case 'parent':
				this.orderValue = '';
				break;
			case 'child':
				this.orderValue = '';
				break;
			case 'number':
				this.orderValue = '';
				break;
			default:
				this.orderValue = '';
		}
		this.updateOrderValue();
	}

	public updateOrderValue() {
		let prependedText = '';
		if (this.orderType === 'parent' || this.orderType === 'child') {
			prependedText = this.orderType;
		}
		this.liveEntry.order = prependedText + this.orderValue;
	}

	ngOnInit() {
		this.liveEntry = {
			...this.liveEntry,
			entryType: idx(this.liveEntry, (_) => _.entryType) || 'start_firstHalf',
			detail: {
				score: {
					away: idx(this.liveEntry, (_) => _.detail.score.away) || 0,
					home: idx(this.liveEntry, (_) => _.detail.score.home) || 0
				}
			},
			order: 'auto',
			entryDate: idx(this.liveEntry, (_) => _.entryDate) || '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.matchStatus || 'PRE_MATCH',
			side: this.side,
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			}
		} as LiveBlogAutoEventWhistle & { order?: any };
		this.initializeOrderDefault();
		this.update();
	}

	initializeOrderDefault() {
		if (this.editMode === true) {
			this.orderType = 'keep';
			this.updateOrderType();
		}
	}

	public getPayload() {
		return this.liveEntry;
	}
}
