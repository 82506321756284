<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div class="event basicEvent side_{{ side }}">
	<div class="head">
		<livetickerevent-playtime [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
	</div>
	<div class="body" *ngIf="liveEntry.detail && liveEntry.detail.text">
		<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
		<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
		<div class="detailText detailHtml" *ngIf="liveEntry?.enableHtml === true && liveEntry?.detail?.text" [innerHTML]="isWebview ? (liveEntry.detail.text | striphtml:true:'external' | safeHtml) : (liveEntry.detail.text | safeHtml)"></div>
		<div class="detailText" *ngIf="!(liveEntry?.enableHtml === true) && liveEntry?.detail?.text">{{ liveEntry.detail.text }}</div>
	</div>
</div>
