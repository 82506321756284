import { NgModule } from '@angular/core';
import { EmbedApesterComponent } from './components/embed-apester/embed-apester.component';
import { EmbedFacebookComponent } from './components/embed-facebook/embed-facebook.component';
import { EmbedInstagramComponent } from './components/embed-instagram/embed-instagram.component';
import { EmbedTweetComponent } from './components/embed-tweet/embed-tweet.component';
import { EmbedWrapperComponent } from './components/embed-wrapper/embed-wrapper.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	imports: [SharedModule, CommonsModule],
	providers: [],
	exports: [EmbedWrapperComponent, EmbedApesterComponent],
	declarations: [EmbedTweetComponent, EmbedWrapperComponent, EmbedInstagramComponent, EmbedApesterComponent, EmbedFacebookComponent]
})
export class DflNgxEmbedsModule {}
