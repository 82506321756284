import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Asset, assetDimensions, AssetDimensions, AssetServicePipe, BundesmasterStoriesApiService, OpenSearchMatchStory, OpenSearchMatchStorySlides, OpenSearchStorySlides, SLIDE_TYPES } from '@nx-bundesliga/bundesmaster/core';
import { DialogSelectMediaComponent } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { BundesmasterUiImageCropperDialogComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper-dialog';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogMatch } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { LoadingService } from '../../../../services/loading/loading.service';

interface GoalThumbnailResponse {
	eventId?: string;
	chosenThumbnailURL?: string;
	thumbnailURLs: string[];
}

@Component({
	selector: 'story-slide-attach-slide-image',
	templateUrl: 'bl-story-dialog-attach-slide-image.component.html',
	styleUrls: ['./bl-story-dialog-attach-slide-image.component.scss']
})
export class AttachStorySlideImageDialogComponent {
	@Input() match: LiveBlogMatch;
	@Input() language: string;
	@Input() postId: string;
	@Input() liveEntry: { key: string; value: LiveBlogAutoEvents; style: any; class: any };
	@Input() clubData: LiveBlogClubs;
	@Input() customMatch = false;
	public processing = false;
	public storyLinkContender = '';
	public customImage: { url: string; copyright: string } = {
		url: null,
		copyright: null
	};
	public storyId: string;
	public story$: Observable<OpenSearchMatchStory>;
	public storySlide$: Observable<OpenSearchMatchStorySlides | string>;
	public goalThumbnails$: Observable<GoalThumbnailResponse>;

	public languages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];
	public selectedLanguages = { de: true, en: true, es: true, fr: true, ja: true, pt: true, ar: true };

	constructor(
		private readonly dialog: MatDialog,
		private readonly assetPipe: AssetServicePipe,
		private toastr: ToastrService,
		public http: HttpClient,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public loading: LoadingService,
		public dialogRef: MatDialogRef<AttachStorySlideImageDialogComponent>,
		private readonly storyApi: BundesmasterStoriesApiService
	) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.liveEntry = this.data.hasOwnProperty('liveEntry') ? this.data.liveEntry : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
			this.postId = this.data.hasOwnProperty('postId') ? this.data.postId : null;
			this.clubData = this.data.hasOwnProperty('clubData') ? this.data.clubData : null;
			this.customMatch = this.data.hasOwnProperty('customMatch') ? this.data.customMatch : null;
			this.storyId = `${this.match?.dflDatalibraryMatchId?.toLowerCase()?.replace('dfl-mat-', '') ?? null}-${this.language}`;

			this.storyLinkContender = `https://bundesmaster.${environment.stageDomain}.com/story/edit/${this.storyId}`;

			this.story$ = this.storyApi.getStory<OpenSearchMatchStory>(this.storyId).pipe(share());

			this.storySlide$ = this.story$.pipe(
				share(),
				map((s: OpenSearchMatchStory) => (s?.slides as OpenSearchMatchStorySlides[])?.filter((s) => s.slideId === this.postId)?.[0] ?? 'noslide'),
				tap((storySlide: OpenSearchMatchStorySlides) => {
					if ('image' in storySlide && !storySlide?.image?.url?.startsWith(`https://assets.${environment.stageDomain}.com/goalclips/`)) {
						this.customImage = storySlide?.image;
					}
				}),
				catchError(() => of('nostory'))
			);

			this.goalThumbnails$ = this.http.get<GoalThumbnailResponse>(`https://video.bundesmaster.${environment.stageDomain}.com/event/${this.postId}`).pipe(
				share(),
				catchError((err) => of({ thumbnailURLs: [], chosenThumbnailURL: '' }))
			);
		}
	}

	public createSlideForm(slide: Partial<OpenSearchStorySlides>) {
		return this.storyApi.createSlideFormGroup(slide, 'match_story');
	}

	public selectImage() {
		this.dialog
			.open(DialogSelectMediaComponent, {
				data: { hideDimensions: true },
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '90%'
			})
			.afterClosed()
			.pipe(
				switchMap((imageSelectionResponse: { asset: Asset; dimensions: AssetDimensions[] }) => {
					if (imageSelectionResponse) {
						return this.dialog
							.open(BundesmasterUiImageCropperDialogComponent, {
								data: {
									imageUrl: this.assetPipe.transform(imageSelectionResponse?.asset?.file),
									targetAspectRatio: assetDimensions['portrait'].ratio
								},
								maxWidth: '90vw',
								maxHeight: '90vh',
								width: '100%',
								height: 'auto'
							})
							.afterClosed()
							.pipe(
								map((croppedUrl: string) => {
									if (croppedUrl) {
										return { url: croppedUrl, copyright: imageSelectionResponse?.asset?.copyright !== '' ? imageSelectionResponse?.asset?.copyright : 'DFL' };
									}
									return null;
								})
							);
					}
					return of(null);
				})
			)
			.subscribe((response: { url: string; copyright: string }) => {
				if (response) {
					this.customImage.url = this.assetPipe.transform(response.url);
					this.customImage.copyright = response.copyright;
				}
				console.log('image selected and cropped', response);
			});
	}

	public assignImage(image: string, copyright?: string): void {
		if (this.processing === false) {
			this.loading.setLoading(true);
			this.processing = true;
			const entryType = this.liveEntry?.value?.entryType ?? '';
			let selectThumbnail$: Observable<any> = of(null);
			// add future preview thumnails here
			switch (entryType) {
				case 'goal':
				case 'ownGoal': {
					selectThumbnail$ = this.http.post(`https://video.bundesmaster.${environment.stageDomain}.com/event/${this.postId}`, {
						chosenThumbnailURL: image
					});
					break;
				}
			}

			const attachImageToSlide$ = forkJoin(
				Object.entries(this.selectedLanguages)
					.filter(([lang, enabled]) => enabled === true)
					.map(([lang, enabled]) => {
						const storyId = `${this.match?.dflDatalibraryMatchId?.toLowerCase()?.replace('dfl-mat-', '') ?? null}-${lang}`;
						return this.http
							.put(`https://stories.api.bundesmaster.${environment.stageDomain}.com/bundesstreaker/matchstory/${storyId}/attachImage/${this.postId}`, {
								url: image,
								copyright: copyright ?? 'DFL'
							})
							.pipe(
								map((val) => `${lang} Successful`),
								catchError((val) => of(`${lang} Unsuccessful`))
							);
					})
			);

			selectThumbnail$
				.pipe(
					catchError(() => of(null)),
					switchMap(() => attachImageToSlide$)
				)
				.subscribe(
					(messages: string[]) => {
						const message = messages.map((m) => `<li>${m}</li>`).join('');
						this.toastr.warning(`Slide Image attachment results:<ul>${message}</ul>`, 'Published', { enableHtml: true });
						this.loading.setLoading(false);
						this.dialogRef.close();
					},
					(error) => {
						const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
						this.loading.setLoading(false);
						this.toastr.error(message, 'Error');
						this.processing = false;
					}
				);
		}
	}

	cropSlideImage() {
		console.log('editSlideImage', this.customImage);
		return new Promise((resolve) => {
			this.dialog
				.open(BundesmasterUiImageCropperDialogComponent, {
					data: { imageUrl: this.customImage?.url, dimension: 'portrait', targetAspectRatio: assetDimensions.portrait.ratio },
					maxWidth: '90vw',
					maxHeight: '90vh',
					width: '90%'
				})
				.afterClosed()
				.subscribe((imageUrl) => {
					if (imageUrl) {
						this.customImage.url = imageUrl;
						resolve(true);
					}
				});
		});
	}

	public deleteSlideImage(): void {
		if (confirm(`Delete this image from slide?`)) {
			this.loading.setLoading(true);
			this.processing = true;
			const url = `https://stories.api.bundesmaster.${environment.stageDomain}.com/bundesstreaker/matchstory/${this.storyId}/attachImage/${this.postId}`;

			console.log(`Deleting slide image from Story to API: `, url);

			this.http.delete(url).subscribe(
				(success) => {
					this.toastr.success('Slide image has successfully been deleted!', 'Deleted');
					this.loading.setLoading(false);
					this.dialogRef.close();
				},
				(error) => {
					const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
					this.loading.setLoading(false);
					this.toastr.error(message, 'Error');
					this.processing = false;
					this.dialogRef.close();
				}
			);
		}
	}

	protected readonly SLIDE_TYPES = SLIDE_TYPES;
}
