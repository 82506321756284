import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DflJwPlayerModule } from '@nx-bundesliga/bundesliga-com/components/dfl-jw-player';
import { DflNgxEmbedsModule } from '@nx-bundesliga/bundesliga-com/components/dfl-ngx-embeds';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { DfpAdsModule } from '@nx-bundesliga/bundesliga-com/components/dfp-ads';
import {
	StatsBallrecoverytimeCardModule,
	StatsChartsModule,
	StatsClubmetricprogressionCardModule,
	StatsClubprogressrankingCardModule,
	StatsKeeperefficiencyCardModule,
	StatsPlayerprogressrankingCardModule,
	StatsPlayercomparisonCardModule,
	StatsPlayerrankingCardModule,
	StatsPressurehandlingCardModule
} from '@nx-bundesliga/bundesliga-com/components/stats';
import { LivetickerEventsPipeModule } from '../../pipes/liveticker-events-pipe.module';
import { LivetickerEventHeadlineModule } from '../parts/livetickerevent-headline/liveticker-event-headline.module';
import { LivetickerEventMatchfactsBannerModule } from '../parts/livetickerevent-matchfacts-banner/liveticker-event-matchfacts-banner.module';
import { LivetickerEventMatchfactsInfoModule } from '../parts/livetickerevent-matchfacts-info/liveticker-event-matchfacts-info.module';
import { LivetickerEventPlaytimeModule } from '../parts/livetickerevent-playtime/liveticker-event-playtime.module';
import { LivetickerEventScoreModule } from '../parts/livetickerevent-score/liveticker-event-score.module';
import { LivetickereventBasicComponent } from './livetickerevent-basic/livetickerevent-basic.component';
import { LivetickereventCardComponent } from './livetickerevent-card/livetickerevent-card.component';
import { LivetickereventEmbedComponent } from './livetickerevent-embed/livetickerevent-embed.component';
import { LivetickereventGoalComponent } from './livetickerevent-goal/livetickerevent-goal.component';
import { LivetickereventImageComponent } from './livetickerevent-image/livetickerevent-image.component';
import { LivetickereventIsTypingComponent } from './livetickerevent-isTyping/livetickerevent-isTyping.component';
import { LivetickereventLineupComponent } from './livetickerevent-lineup/livetickerevent-lineup.component';
import { LivetickereventPenaltyComponent } from './livetickerevent-penalty/livetickerevent-penalty.component';
import { LivetickereventPlayerRecordComponent } from './livetickerevent-playerRecord/livetickerevent-playerRecord.component';
import { LivetickereventProgressComponent } from './livetickerevent-progress/livetickerevent-progress.component';
import { LivetickereventStatsComponent } from './livetickerevent-stats/livetickerevent-stats.component';
import { LivetickereventSubstitutionComponent } from './livetickerevent-substitution/livetickerevent-substitution.component';
import { LivetickereventVarComponent } from './livetickerevent-var/livetickerevent-var.component';
import { LivetickereventVideoComponent } from './livetickerevent-video/livetickerevent-video.component';
import { DflSimpleIconModule } from '@nx-bundesliga/bundesliga-com/components/dfl-simple-icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	declarations: [
		LivetickereventBasicComponent,
		LivetickereventCardComponent,
		LivetickereventEmbedComponent,
		LivetickereventGoalComponent,
		LivetickereventPenaltyComponent,
		LivetickereventImageComponent,
		LivetickereventIsTypingComponent,
		LivetickereventProgressComponent,
		LivetickereventSubstitutionComponent,
		LivetickereventVarComponent,
		LivetickereventVideoComponent,
		LivetickereventStatsComponent,
		LivetickereventLineupComponent,
		LivetickereventPlayerRecordComponent
	],
	exports: [
		LivetickereventBasicComponent,
		LivetickereventCardComponent,
		LivetickereventEmbedComponent,
		LivetickereventGoalComponent,
		LivetickereventPenaltyComponent,
		LivetickereventImageComponent,
		LivetickereventIsTypingComponent,
		LivetickereventProgressComponent,
		LivetickereventSubstitutionComponent,
		LivetickereventVarComponent,
		LivetickereventVideoComponent,
		LivetickereventStatsComponent,
		LivetickereventLineupComponent,
		LivetickereventPlayerRecordComponent
	],
	imports: [
		CommonModule,
		CommonsModule,
		LivetickerEventsPipeModule,
		LivetickerEventHeadlineModule,
		LivetickerEventPlaytimeModule,
		LivetickerEventScoreModule,
		LivetickerEventMatchfactsBannerModule,
		LivetickerEventMatchfactsInfoModule,
		DflNgxEmbedsModule,
		DflNgxLazyLoadModule,
		SharedModule,
		TranslateModule,
		DflJwPlayerModule,
		DfpAdsModule,
		StatsChartsModule,
		StatsPlayercomparisonCardModule,
		StatsPlayerprogressrankingCardModule,
		StatsPlayerrankingCardModule,
		StatsClubprogressrankingCardModule,
		StatsClubmetricprogressionCardModule,
		DflSimpleIconModule,
		StatsPressurehandlingCardModule,
		StatsKeeperefficiencyCardModule,
		StatsBallrecoverytimeCardModule,
		CommonsLibModule,
		NgOptimizedImage
	]
})
export class LivetickerEventsModule {}
