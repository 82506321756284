export interface Tags {
	competitions: string[];
	clubs: string[];
	seasons: string[];
	matchdays: string[];
	matches: string[];
	players: string[];
	tags: string[];
}

export type TagsTypes = 'competitions' | 'clubs' | 'seasons' | 'matchdays' | 'matches' | 'players' | 'tags';
