import { Component, Input } from '@angular/core';

@Component({
	selector: 'text-chart',
	templateUrl: './text-chart.component.html',
	styleUrls: ['./text-chart.component.scss']
})
export class TextChartComponent {
	@Input() title: string;
	@Input() leftValue: number;
	@Input() rightValue: number;
	@Input() leftLabel = '%';
	@Input() rightLabel = '%';

	constructor() {}
}
