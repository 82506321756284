<div mat-dialog-title>
	<span>
		Attach Image for Slide Id: {{postId}} - {{liveEntry?.value?.entryType?.toUpperCase()}}
		<ng-container *ngIf="['goal', 'ownGoal'].includes(liveEntry?.value?.entryType)"> from {{liveEntry?.value?.detail?.scorer?.name}} and score {{liveEntry?.value?.detail?.score?.home}}:{{liveEntry?.value?.detail?.score?.away}}</ng-container>
		<a [href]="storyLinkContender" target="_blank">
			<button type="button" color="primary" aria-label="To story" title="To story" mat-flat-button>
				Story in Contender
				<mat-icon>open_in_new</mat-icon>
			</button>
		</a>
	</span>
</div>
<mat-dialog-content class="mat-typography">
	<div class="container-fluid slide-container">
		<div class="row">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<mat-checkbox *ngFor="let lang of languages" color="primary" [disabled]="processing" [(ngModel)]="selectedLanguages[lang]" [ngModelOptions]="{standalone: true}">{{lang}}</mat-checkbox>
			</div>
		</div>
		<div class="row">
			<div class="col col-12">
				<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" dynamicHeight="true">
					<mat-tab label="Goal Video Thumbnails" *ngIf="['goal', 'ownGoal'].includes(liveEntry?.value?.entryType)">
						<div class="container-fluid">
							<div class="row">
								<ng-template #goalsLoading>
									<div class="goal col-6 col-xl-9">
										<span class="text-center"><dfl-loader [loading]="true" surrounding="large"></dfl-loader></span>
									</div>
								</ng-template>

								<ng-container *ngIf="(goalThumbnails$ | async) as goalThumbs else goalsLoading">
									<div class="goal col-6 col-xl-3" *ngFor="let goalThumb of goalThumbs?.thumbnailURLs; let i = index">
										<button mat-raised-button color="primary" [disabled]="processing" (click)="assignImage(goalThumb)">Assign image {{i + 1}} to Slide</button>
										<h3><strong>Image {{i + 1}}</strong></h3>
										<div class="goal-thumbnail" [ngClass]="{selected: goalThumb === goalThumbs?.chosenThumbnailURL}" [style.background-image]="'url(' + (goalThumb | imageService:null: null: {fit: '380,0'}) + ')'"> </div>
									</div>
									<div class="goal col-6 col-xl-9" *ngIf="goalThumbs?.thumbnailURLs?.length === 0">
										<h1 class="text-center">Goal images are not available yet</h1>
									</div>
								</ng-container>

								<div class="previous col-6 col-xl-3">
									<ng-container [ngTemplateOutlet]="currentSlide"></ng-container>
								</div>
							</div>
						</div>
					</mat-tab>

					<mat-tab label="Custom Thumbnail (Media library)">
						<div class="container-fluid custom-image">
							<div class="row">
								<div class="goal col-6 col-xl-9">
									<button class="text-center" type="button" color="primary" aria-label="Select image" title="Select image" mat-flat-button (click)="selectImage()">
										Select image from Media library instead
										<mat-icon aria-label="select image">video_library</mat-icon>
									</button>
									<button [disabled]="processing || (!customImage.url || !customImage.copyright)" class="text-center" type="button" color="primary" aria-label="Crop custom image" title="Crop custom image" mat-flat-button (click)="cropSlideImage()">
										Crop custom image
										<mat-icon>crop</mat-icon>
									</button>
									<button mat-raised-button color="primary" [disabled]="processing || (!customImage.url || !customImage.copyright)" (click)="assignImage(customImage.url, customImage.copyright)">Assign custom image to Slide</button>
									<h3><strong>Custom Image</strong></h3>
									<div class="goal-thumbnail" [style.background-color]="customImage.url ? 'transparent' : 'black'" [style.background-image]="customImage.url ? 'url(' + (customImage.url | imageService:null: null: {fit: '380,0'}) + ')' : null"> </div>
								</div>
								<div class="previous col-6 col-xl-3">
									<ng-container [ngTemplateOutlet]="currentSlide"></ng-container>
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<mat-expansion-panel [expanded]="false" *ngIf="story$ | async as storyInfo">
					<mat-expansion-panel-header>
						<mat-panel-title>Additional Information</mat-panel-title>
						<mat-panel-description>Most likely only needed for debugging 🧙‍♂️</mat-panel-description>
					</mat-expansion-panel-header>

					<div class="additional-infos" *ngIf="storyInfo">
						<p>storyId: {{ storyInfo.storyId || "-" }}</p>
						<p>last_publish_date: {{ storyInfo.last_publish_date || "-" }}</p>
						<p>modified_date: {{ storyInfo.modified_date || "-" }}</p>
						<p>created_date: {{ storyInfo.created_date || "-" }}</p>
						<p>published_by: {{ storyInfo.published_by || "-" }}</p>
						<p>scheduled_by: {{ storyInfo.scheduled_by || "-" }}</p>
						<p>scheduled_date: {{ storyInfo.scheduled_date || "-" }}</p>
						<p>approved_by: {{ storyInfo.approved_by || "-" }}</p>
					</div>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [disabled]="processing" (click)="deleteSlideImage()">Remove Image from Slide</button>
	<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
</mat-dialog-actions>

<ng-template #currentSlide>
	<h3><strong>Current Slide</strong></h3>
	<dfl-loader [loading]="processing" surrounding="large"></dfl-loader>

	<ng-template #storyLoading>
		<dfl-loader [loading]="true" surrounding="large"></dfl-loader>
	</ng-template>
	<ng-template #storyUnavailable>
		<mat-icon>error</mat-icon>
		<span>Story does not yet exist. Attaching an image to slide will most likely fail.</span>
	</ng-template>
	<ng-template #storySlideUnavailable>
		<mat-icon>error</mat-icon>
		<span>Story exists but this Event is not part of the story. Attaching an image to slide will most likely fail.</span>
	</ng-template>

	<ng-container *ngIf="!processing">
		<ng-container *ngIf="(storySlide$ | async) as slideData else storyLoading">
			<ng-container *ngIf="slideData === 'nostory'" [ngTemplateOutlet]="storyUnavailable"></ng-container>
			<ng-container *ngIf="slideData === 'noslide'" [ngTemplateOutlet]="storySlideUnavailable"></ng-container>
			<ng-container *ngIf="slideData !== 'nostory' && slideData !== 'noslide'">
				<bundesmaster-ui-story-slide [index]="0" [slide]="createSlideForm(slideData)" [storyType]="'match_story'" *ngIf="slideData">
					<ng-container headerActions></ng-container>
				</bundesmaster-ui-story-slide>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>
