import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { FixturesModule } from '../../organisms/fixtures/fixtures.module';
import { LivestreamManagerComponent } from './livestream-manager.component';

const routes: Routes = [
	{
		path: 'tools/livestreams',
		// canActivate : [MsalGuardStreaker],
		canActivate: [MsalGuard],
		children: [
			{
				path: '',
				redirectTo: 'DFL-COM-000001',
				pathMatch: 'full'
			},
			{
				path: ':competitionId/:seasonId/:matchdayNumber/:clubName',
				component: LivestreamManagerComponent
			},
			{
				path: ':competitionId/:seasonId/:matchdayNumber',
				component: LivestreamManagerComponent
			},
			{
				path: ':competitionId/:seasonId',
				component: LivestreamManagerComponent
			},
			{
				path: ':competitionId',
				component: LivestreamManagerComponent
			}
		]
	}
];

@NgModule({
	declarations: [LivestreamManagerComponent],
	imports: [CommonsModule, RouterModule.forChild(routes), FixturesModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatInputModule, SharedModule, MatIconModule, MatBadgeModule]
})
export class LivestreamManagerModule {}
