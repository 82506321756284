<mat-horizontal-stepper [linear]="true" #stepper>
	<mat-step label="Side Selection" [editable]="false" [completed]="step1completed">
		<!-- Set isTyping Indicator -->
		<bl-rapid-ticker-side-chooser *ngIf="!step1completed" (sideSelected)="selectSide($event)"></bl-rapid-ticker-side-chooser>
	</mat-step>

	<mat-step label="Compose" [editable]="step1completed || publishingError" [completed]="step2completed">
		<!-- Compose Event -->
		<bl-rapid-ticker-composer
			[liveBlogInfos]="liveBlogInfos"
			[liveBlogTeams]="liveBlogTeams"
			[minuteOfPlay]="minuteOfPlay"
			[matchStatus]="matchStatus"
			[language]="language"
			[competitionId]="competitionId"
			[seasonId]="seasonId"
			[matchdayId]="matchdayId"
			[matchId]="matchId"
			[side]="selectedSide"
			[liveEntry]=""
			[customMatch]="customMatch"
			(canceled)="resetStepper()"
			(composed)="publishEvent($event)"></bl-rapid-ticker-composer>
	</mat-step>

	<mat-step label="Publish" [editable]="step2completed" [completed]="false">
		<bl-rapid-ticker-status [side]="selectedSide" [liveBlogTeams]="liveBlogTeams" [error]="publishingError" (back)="handleStatusBack($event)" (cancel)="handleStatusCancel($event)"></bl-rapid-ticker-status>
	</mat-step>
</mat-horizontal-stepper>
