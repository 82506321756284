import { Component, Input } from '@angular/core';
import { LiveBlogAutoEvents, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

export type TextColors = 'text_black' | 'text_white' | 'text_default';

@Component({
	selector: 'livetickerevent-playtime',
	templateUrl: './livetickerevent-playtime.component.html',
	styleUrls: ['./livetickerevent-playtime.component.scss']
})
export class LivetickerEventPlaytimeComponent {
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() clubData: LiveBlogClubs;
	@Input() textColor: TextColors = 'text_default';
	public side: LivetickerSides;

	constructor() {}
}
