<div class="event imageEvent side_{{ side }}">
	<img class="liveEntryImage" [dflLazyLoad]="liveEntry.detail.url | imageService:720" [defaultImage]="defaultImage" [@liveblogPostImageAnimation] />
	<div class="body">
		<div class="playtime">
			<dfl-simple-icon icon="camera-photo"></dfl-simple-icon>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
			<livetickerevent-headline *ngIf="liveEntry?.detail?.headline" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
			<span class="text" *ngIf="liveEntry?.enableHtml === true && liveEntry.detail?.text" [ngClass]="{'no-headline': !liveEntry?.detail?.headline}" [innerHTML]="isWebview ? (liveEntry.detail.text | striphtml:true:'external' | safeHtml) : (liveEntry.detail.text | safeHtml)"></span>
			<span class="text" *ngIf="!(liveEntry?.enableHtml === true) && liveEntry.detail?.text">{{ liveEntry.detail.text }}</span>
		</div>
	</div>
	<div class="copyright" *ngIf="liveEntry.detail?.copyright">© {{liveEntry.detail.copyright}}</div>
</div>
