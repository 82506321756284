import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LivetickerEventMatchfactsBannerComponent } from './liveticker-event-matchfacts-banner.component';

@NgModule({
	declarations: [LivetickerEventMatchfactsBannerComponent],
	exports: [LivetickerEventMatchfactsBannerComponent],
	imports: [CommonModule, TranslateModule, CommonsModule]
})
export class LivetickerEventMatchfactsBannerModule {}
