import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';

@Component({
	selector: 'stats-clubprogressranking-card',
	templateUrl: './stats-clubprogressranking-card.component.html',
	styleUrls: ['./stats-clubprogressranking-card.component.scss']
})
export class StatsClubprogressrankingCardComponent implements OnInit, OnChanges, OnDestroy {
	@Input() title: string;
	@Input() homeColor: string;
	@Input() awayColor: string;
	@Input() homeTextColor: string;
	@Input() awayTextColor: string;
	@Input() homeValue: number;
	@Input() awayValue: number;
	@Input() homeRelativeValue: number;
	@Input() awayRelativeValue: number;
	@Input() metric = 'Goals';
	@Input() isLiveticker = false;
	public maxValue = 0;
	public language: string;
	public competition: string;
	private languageSubscription: Subscription;
	private competitionSubscription: Subscription;
	public homeBorderNeeded: boolean;
	public awayBorderNeeded: boolean;
	public homeSize = '0%';
	public awaySize = '0%';
	public homeRelativeSize = '0%';
	public awayRelativeSize = '0%';

	constructor(private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>) {}

	ngOnInit(): void {
		this.languageSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate: Language) => {
			this.language = langstate.code;
		});
		this.competitionSubscription = this.cstore.pipe(select(getWorkingCompetition)).subscribe((compState) => {
			this.competition = compState.name;
		});
	}

	ngOnDestroy(): void {
		if (this.languageSubscription) {
			this.languageSubscription.unsubscribe();
		}
		if (this.competitionSubscription) {
			this.competitionSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.homeBorderNeeded = this.homeColor.toLowerCase() === '#ffffff';
		this.awayBorderNeeded = this.awayColor.toLowerCase() === '#ffffff';
		this.maxValue = Math.max(0, this.homeValue, this.homeRelativeValue, this.awayValue, this.awayRelativeValue);
		if (this.maxValue > 0) {
			this.homeSize = `${(this.homeValue / this.maxValue) * 100}%`;
			this.awaySize = `${(this.awayValue / this.maxValue) * 100}%`;
			this.homeRelativeSize = `${(this.homeRelativeValue / this.maxValue) * 100}%`;
			this.awayRelativeSize = `${(this.awayRelativeValue / this.maxValue) * 100}%`;
		}
	}
}
