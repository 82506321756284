import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivetickerSides } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoadingService } from '../loading/loading.service';

@Injectable({
	providedIn: 'root'
})
export class IsTypingService {
	private liveBlogInfosTypingProperty = {
		home: 'homeIsTyping',
		away: 'awayIsTyping',
		none: 'isTyping'
	};

	constructor(private http: HttpClient, private toastr: ToastrService, private loading: LoadingService) {}

	public send(side: LivetickerSides, status: boolean, payload: any) {
		this.loading.setLoading(true);
		const url = `https://api.bundesstreaker.${environment.stageDomain}.com/istyping`;
		const body = {
			...payload,
			[this.liveBlogInfosTypingProperty[side]]: status
		};

		console.log(`Sending isTyping to API: `, url, body);
		return this.http
			.post(url, body)
			.pipe(
				tap(() => {
					this.loading.setLoading(false);
				})
			)
			.subscribe(
				(resp: any) => {
					const method = resp.message === 'success' ? 'success' : 'warn';
					this.toastr[method](resp.message, `set ${side} isTyping to ${status}`);
				},
				(err) => {
					this.toastr.error('Error', `Failed to set ${side} isTyping to ${status}`);
					this.loading.setLoading(false);
					console.error('Error setting IsTyping: ', err);
				},
				() => {
					this.loading.setLoading(false);
				}
			);
	}
}
