<div class="container">
	<div class="row">
		<div class="col-12">
			<mat-form-field>
				<mat-label>Progress Type</mat-label>
				<mat-select [(ngModel)]="liveEntry.entryType" (ngModelChange)="update()" tabindex="1">
					<mat-option value="start_firstHalf">start_firstHalf</mat-option>
					<mat-option value="end_firstHalf">end_firstHalf</mat-option>
					<mat-option value="start_secondHalf">start_secondHalf</mat-option>
					<mat-option value="end_secondHalf">end_secondHalf</mat-option>
					<mat-option value="start_firstHalfExtra">start_firstHalfExtra</mat-option>
					<mat-option value="end_firstHalfExtra">end_firstHalfExtra</mat-option>
					<mat-option value="start_secondHalfExtra">start_secondHalfExtra</mat-option>
					<mat-option value="end_secondHalfExtra">end_secondHalfExtra (use start_penalty or finalWhistle instead)</mat-option>
					<mat-option value="start_penalty">start_penalty</mat-option>
					<mat-option value="end_penalty">end_penalty (use finalWhistle instead)</mat-option>
					<mat-option value="finalWhistle">finalWhistle</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Playtime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.minute" (ngModelChange)="updatePlaytime(); update();" placeholder="Playtime" type="number" tabindex="3" />
			</mat-form-field>
		</div>
		<div class="col-6 col-md-2">
			<mat-form-field>
				<mat-label>Injurytime</mat-label>
				<input matInput [(ngModel)]="liveEntry.playtime.injuryTime" (ngModelChange)="updatePlaytime(); update();" placeholder="Injurytime" type="number" tabindex="4" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>MatchStatus</mat-label>
				<mat-select [(ngModel)]="liveEntry.matchSection" (ngModelChange)="update()" tabindex="5">
					<mat-option value="PRE_MATCH">PRE_MATCH</mat-option>
					<mat-option value="FIRST_HALF">FIRST_HALF</mat-option>
					<mat-option value="HALF">HALF</mat-option>
					<mat-option value="SECOND_HALF">SECOND_HALF</mat-option>
					<ng-container *ngIf="customMatch === true || (competitionId !== 'DFL-COM-000001' && competitionId !== 'DFL-COM-000002')">
						<mat-option value="PRE_EXTRA">PRE_EXTRA</mat-option>
						<mat-option value="FIRST_HALF_EXTRA">FIRST_HALF_EXTRA</mat-option>
						<mat-option value="HALF_EXTRA">HALF_EXTRA</mat-option>
						<mat-option value="SECOND_HALF_EXTRA">SECOND_HALF_EXTRA</mat-option>
						<mat-option value="PRE_PENALTY">PRE_PENALTY</mat-option>
						<mat-option value="PENALTY">PENALTY</mat-option>
					</ng-container>
					<mat-option value="FINAL_WHISTLE">FINAL_WHISTLE</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12 col-md-4">
			<mat-form-field>
				<mat-label>entryDate</mat-label>
				<input matInput [(ngModel)]="liveEntry.entryDate" (ngModelChange)="update()" type="input" placeholder="entryDate" />
			</mat-form-field>
		</div>
		<div class="col-12 col-md-6 d-flex">
			<mat-form-field>
				<mat-label>Order</mat-label>
				<mat-select [(ngModel)]="orderType" (ngModelChange)="updateOrderType()">
					<mat-option value="auto">(auto) top of given minute</mat-option>
					<mat-option value="keep" *ngIf="editMode === true" [disabled]="liveEntry.playtime.minute !== minuteOfPlay.minute || liveEntry.playtime.injuryTime !== minuteOfPlay.injuryTime">(keep) maintain order on edited posts</mat-option>
					<mat-option value="parent">(parent) place above given postId</mat-option>
					<mat-option value="child">(child) place below given postId</mat-option>
					<mat-option value="number">(number) assign given order directly</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<span matPrefix *ngIf="orderType ==='parent'">parent&nbsp;</span>
				<span matPrefix *ngIf="orderType ==='child'">child&nbsp;</span>
				<input matInput [disabled]="orderType === 'auto' || orderType === 'keep'" [(ngModel)]="orderValue" (ngModelChange)="updateOrderValue()" type="input" placeholder="order" />
			</mat-form-field>
		</div>
	</div>
</div>

<div class="preview">
	<div class="liveticker-entryContainer">
		<livetickerevent-progress class="event side_{{side}}" [liveEntry]="previewEntry" [clubData]="clubData"></livetickerevent-progress>
	</div>
</div>
