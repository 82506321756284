import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { BundesmasterPerson, BundesmasterPersonApiService, emptyBundesmasterDataCollection, PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubLogoComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { combineLatest, debounceTime, distinctUntilChanged, startWith, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-person-selector',
	standalone: true,
	imports: [CommonModule, CommonsLibModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, StoreModule, PipesModule, MatIconModule, MatInputModule, MatAutocompleteModule, BundesmasterUiClubLogoComponent, MatProgressSpinnerModule, DflNgxLazyLoadModule],
	templateUrl: './bundesmaster-ui-person-selector.component.html',
	styleUrls: ['./bundesmaster-ui-person-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => BundesmasterUiPersonSelectorComponent)
		}
	]
})
export class BundesmasterUiPersonSelectorComponent implements OnChanges, ControlValueAccessor {
	private readonly destroying = new Subject<void>();
	public readonly touched = new Subject<void>();

	private clubIdFilterSubject = new Subject<string | undefined>();
	private seasonIdFilterSubject = new Subject<string | undefined>();

	public readonly formControl = new FormControl<string>('');

	public persons = emptyBundesmasterDataCollection<BundesmasterPerson>('loading');

	public isDisabled = false;

	public get hasValue(): boolean {
		return (this.formControl.value ?? '').trim().length > 0;
	}

	@Input() public clubIdFilter?: string;
	@Input() public seasonIdFilter?: string;

	constructor(private service: BundesmasterPersonApiService) {
		combineLatest([
			this.formControl.valueChanges.pipe(takeUntilDestroyed(), startWith(''), distinctUntilChanged(), debounceTime(500, undefined)),
			this.clubIdFilterSubject.pipe(takeUntilDestroyed(), startWith(undefined), distinctUntilChanged()),
			this.seasonIdFilterSubject.pipe(takeUntilDestroyed(), startWith(undefined), distinctUntilChanged())
		])
			.pipe(
				takeUntilDestroyed(),
				switchMap(([query, clubId, seasonId]) => this.service.getPersons({ query, clubId, seasonId }))
				//tap((clubs) => this.sanitizeSelection(clubs))
			)
			.subscribe((persons) => {
				this.persons = persons;
				this.updateDisabled(true);
			});
	}

	getPersonFaceImageUrl(person: BundesmasterPerson): string {
		return this.service.buildCircleImageUrl(person);
	}

	joinClubs({ allClubs }: BundesmasterPerson): string {
		return allClubs.map(({ threeLetterCode }) => threeLetterCode).join(', ');
	}

	optionSelected(event: MatAutocompleteSelectedEvent) {
		const selectedPerson: string = event.option.value;
		this.formControl.setValue(selectedPerson, { emitEvent: true });
	}

	ngOnChanges(): void {
		this.clubIdFilterSubject.next(this.clubIdFilter);
		this.seasonIdFilterSubject.next(this.seasonIdFilter);
	}

	writeValue(value: string): void {
		this.formControl.setValue(value, { emitEvent: false });
	}

	registerOnChange(handler: (value: string) => void): void {
		this.formControl.valueChanges.pipe(takeUntil(this.destroying)).subscribe(handler);
	}

	registerOnTouched(handler: () => void): void {
		this.touched.subscribe(handler);
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.updateDisabled();
	}

	private updateDisabled(emitEvent = true): void {
		if (this.persons.status === 'loading' || this.isDisabled) {
			this.formControl.disable({ emitEvent });
		} else {
			this.formControl.enable({ emitEvent });
		}
	}
}
