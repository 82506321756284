<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div *ngIf="liveEntry && liveEntry.detail" class="event lineupEvent side_{{side}}">
	<div class="head">
		<div class="headline-row">
			<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="('MATCHCENTER.LINEUP.TACTICAL_LINEUP' | translate) + ': ' + clubData[this.liveEntry.side]?.nameFull"></livetickerevent-headline>
		</div>
	</div>

	<div class="body">
		<div class="player" *ngFor="let player of liveEntry?.detail?.lineup ?? []">
			<div class="shirtNumber">{{player.shirtNumber}}</div>
			<div class="image d-flex">
				<player-image
					style="height: 24px"
					class="player-image"
					[playerImage]="player?.imageUrl | playerImage: 'FACE_CIRCLE' | imageService:0:24"
					[dimension]="24"
					placeHolderImg="/assets/placeholder/player-circle-default.png"
					[language]="language"
					[competition]="competition"
					[playerName]="player.name"
					[enabledLinking]="enablePlayerLinking"></player-image>
				<!--<span class="clublogo" [title]="clubData[player.side]?.nameFull" *ngIf="player.side === 'home' || player.side === 'away'">
				  <clublogo [clubId]="clubData[player.side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[player.side]?.logoUrl | imageService : 60"></clublogo>
			  </span>-->
			</div>
			<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', player?.name | slugify] | translateRoute) : null"> {{player?.name}} </a>
		</div>
	</div>
</div>
