<div class="container matchcenter-stats" *ngIf="statsData">
	<div class="row" *ngIf="statsData?.winProbability?.probability?.length > 0">
		<div class="col col-12 d-flex justify-content-between">
			<div class="createPost-button position-relative" (click)="openCreateDialog('clubMetricProgression', 'winProbability', { progression: progressionHome || [], matchFact: true}, 'home')" style="width: auto; border: 0" *ngIf="statsclubmetricprogressionlist?.progressionHome | async as progressionHome">
				<mat-icon>open_in_new</mat-icon>
				Home Team
			</div>
			<div class="createPost-button position-relative" (click)="openCreateDialog('clubMetricProgression', 'winProbability', { progression: progressionAway || [], matchFact: true}, 'away')" style="width: auto; border: 0" *ngIf="statsclubmetricprogressionlist?.progressionAway | async as progressionAway">
				<mat-icon>open_in_new</mat-icon>
				Away Team
			</div>
		</div>
		<div class="col col-12">
			<stats-clubmetricprogression-list #statsclubmetricprogressionlist [title]="'winProbability'" [match]="match" [winProbability]="statsData?.winProbability?.probability || []"></stats-clubmetricprogression-list>
			<livetickerevent-matchfacts-banner></livetickerevent-matchfacts-banner>
		</div>
	</div>

	<div class="row" *ngIf="statsData?.xGoals">
		<div class="col col-12">
			<div class="createPost-button">
				<mat-icon (click)="openCreateDialog('clubProgressRanking', 'xGoals', {home: {value: statsData.xGoals.homeValue, relativeValue: match?.score?.home?.live}, away: {value: statsData.xGoals.awayValue, relativeValue: match?.score?.away?.live}, metric: 'goalsMetricTitle', matchFact: true})">open_in_new</mat-icon>
			</div>
			<stats-clubprogressranking-card
				[title]="'xGoalsTitle'"
				[homeValue]="statsData.xGoals.homeValue"
				[homeColor]="home.gradientStartColor"
				[homeTextColor]="home.textColor"
				[homeRelativeValue]="match?.score?.home?.live"
				[awayValue]="statsData.xGoals.awayValue"
				[awayColor]="away.gradientStartColor"
				[awayTextColor]="away.textColor"
				[awayRelativeValue]="match?.score?.away?.live"></stats-clubprogressranking-card>
			<livetickerevent-matchfacts-banner></livetickerevent-matchfacts-banner>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button">
				<mat-icon (click)="openCreateDialog('stackedcolumn', 'goalAttemptsTitle', {shotsOnTarget: statsData.shotsOnTarget, shotsOffTarget: statsData.shotsOffTarget, primaryLabel: 'goalAttempts_onTarget', secondaryLabel: 'goalAttempts_offTarget'})">open_in_new</mat-icon>
			</div>
			<stackedcolumn-chart
				[title]="'Goal attempts'"
				[leftPrimaryValue]="statsData.shotsOnTarget?.homeValue"
				[leftPrimaryLabel]="'on target'"
				[leftSecondaryValue]="statsData.shotsOffTarget?.homeValue"
				[leftSecondaryLabel]="'off target'"
				[leftColor]="home.gradientStartColor"
				[leftTextColor]="home.textColor"
				[rightPrimaryValue]="statsData.shotsOnTarget?.awayValue"
				[rightPrimaryLabel]="'on target'"
				[rightSecondaryValue]="statsData.shotsOffTarget?.awayValue"
				[rightSecondaryLabel]="'off target'"
				[rightColor]="away.gradientStartColor"
				[rightTextColor]="away.textColor"></stackedcolumn-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('pie', 'possessionTitle', statsData.ballPossessionRatio)">open_in_new</mat-icon></div>
			<pie-chart [title]="'Possession (%)'" [leftValue]="statsData.ballPossessionRatio?.homeValue" [leftColor]="home.gradientStartColor" [rightValue]="statsData.ballPossessionRatio?.awayValue" [rightColor]="away.gradientStartColor"></pie-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'passesTitle', statsData.passes)">open_in_new</mat-icon></div>
			<bar-chart title="Passes" [title]="'Passes'" [leftValue]="statsData.passes?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.passes?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
			<text-chart title="Accuracy" [title]="'Accuracy'" [leftValue]="statsData.passAccuracy?.homeValue" [rightValue]="statsData.passAccuracy?.awayValue"></text-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<hr class="divider" />
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'distanceRunTitle', statsData.distanceCovered)">open_in_new</mat-icon></div>
			<bar-chart
				[title]="'Distance run (km)'"
				[horizontal]="false"
				[leftValue]="statsData.distanceCovered?.homeValue"
				[leftColor]="home.gradientStartColor"
				[leftTextColor]="home.textColor"
				[rightValue]="statsData.distanceCovered?.awayValue"
				[rightColor]="away.gradientStartColor"
				[rightTextColor]="away.textColor"></bar-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'sprintsTitle', statsData.sprints)">open_in_new</mat-icon></div>
			<bar-chart [title]="'Sprints'" [leftValue]="statsData.sprints?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.sprints?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'tacklesWonTitle', statsData.tacklesWon)">open_in_new</mat-icon></div>
			<bar-chart [title]="'Tackles won'" [leftValue]="statsData.tacklesWon?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.tacklesWon?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'cornersTitle', statsData.cornerKicks)">open_in_new</mat-icon></div>
			<bar-chart [title]="'Corners'" [leftValue]="statsData.cornerKicks?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.cornerKicks?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-6">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'offsidesTitle', statsData.offsides)">open_in_new</mat-icon></div>
			<bar-chart [chips]="true" [title]="'Offsides'" [leftValue]="statsData.offsides?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.offsides?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
		</div>
		<div class="col col-6">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('bar', 'foulsConcededTitle', statsData.fouls)">open_in_new</mat-icon></div>
			<bar-chart [chips]="true" [title]="'Fouls committed'" [leftValue]="statsData.fouls?.homeValue" [leftColor]="home.gradientStartColor" [leftTextColor]="home.textColor" [rightValue]="statsData.fouls?.awayValue" [rightColor]="away.gradientStartColor" [rightTextColor]="away.textColor"></bar-chart>
		</div>
	</div>

	<div class="row">
		<div class="col col-12">
			<hr class="divider" />
		</div>
	</div>

	<div class="row" *ngIf="statsData.playerRankings?.shots?.home && statsData.playerRankings?.shots?.away">
		<div class="col col-12 d-flex justify-content-center">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('player', 'shotsTitle', statsData.playerRankings.shots)">open_in_new</mat-icon></div>
			<stats-playercomparison-card [title]="'Shots'" [homeColor]="home.gradientStartColor" [awayColor]="away.gradientStartColor" [homeTextColor]="home.textColor" [awayTextColor]="away.textColor" [ranking]="statsData.playerRankings.shots"></stats-playercomparison-card>
		</div>
	</div>

	<div class="row" *ngIf="statsData?.passEfficiency?.home && statsData.passEfficiency?.away">
		<div class="col col-12">
			<div class="createPost-button">
				<mat-icon (click)="openCreateDialog('playerProgressRanking', 'passEfficiency', {home: statsData.passEfficiency?.home, away: statsData.passEfficiency?.away, matchFact: true})">open_in_new</mat-icon>
			</div>
			<stats-playerprogressranking-card [title]="'Pass Efficiency'" [homeColor]="home.gradientStartColor" [awayColor]="away.gradientStartColor" [homeTextColor]="home.textColor" [awayTextColor]="away.textColor" [ranking]="transformPlayerProgressRanking(statsData.passEfficiency)"></stats-playerprogressranking-card>
			<livetickerevent-matchfacts-banner></livetickerevent-matchfacts-banner>
		</div>
	</div>

	<div class="row" *ngIf="statsData.playerRankings?.totalDistanceCovered?.home && statsData.playerRankings?.totalDistanceCovered?.away">
		<div class="col col-12 d-flex justify-content-center">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('player', 'distanceRunTitle', statsData.playerRankings.totalDistanceCovered)">open_in_new</mat-icon></div>
			<stats-playercomparison-card [title]="'Distance run (km)'" [homeColor]="home.gradientStartColor" [awayColor]="away.gradientStartColor" [homeTextColor]="home.textColor" [awayTextColor]="away.textColor" [ranking]="statsData.playerRankings.totalDistanceCovered"></stats-playercomparison-card>
		</div>
	</div>

	<div class="row" *ngIf="statsData.playerRankings?.maximumSpeed?.home && statsData.playerRankings?.maximumSpeed?.away">
		<div class="col col-12 d-flex justify-content-center">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('player', 'fastestRunnerTitle', statsData.playerRankings.maximumSpeed)">open_in_new</mat-icon></div>
			<stats-playercomparison-card [title]="'Fastest runner (km\/h)'" [homeColor]="home.gradientStartColor" [awayColor]="away.gradientStartColor" [homeTextColor]="home.textColor" [awayTextColor]="away.textColor" [ranking]="statsData.playerRankings.maximumSpeed"></stats-playercomparison-card>
		</div>
	</div>

	<div class="row" *ngIf="statsData.playerRankings?.challengesWon?.home && statsData.playerRankings?.challengesWon?.away">
		<div class="col col-12 d-flex justify-content-center">
			<div class="createPost-button"><mat-icon (click)="openCreateDialog('player', 'duelsWonTitle', statsData.playerRankings.challengesWon)">open_in_new</mat-icon></div>
			<stats-playercomparison-card [title]="'Duels won'" [homeColor]="home.gradientStartColor" [awayColor]="away.gradientStartColor" [homeTextColor]="home.textColor" [awayTextColor]="away.textColor" [ranking]="statsData.playerRankings.challengesWon"></stats-playercomparison-card>
		</div>
	</div>
</div>
