<div class="playerRanking-card">
	<div class="player" *ngFor="let player of ranking">
		<div class="rank">{{player.rank}}.</div>
		<div class="image">
			<player-image
				class="player-image"
				[playerImage]="player.person?.imageUrl | playerImage: 'FACE_CIRCLE' | imageService:0:64"
				[dimension]="64"
				placeHolderImg="/assets/placeholder/player-circle-default.png"
				[language]="language"
				[competition]="competition"
				[playerName]="player.person.name"
				[enabledLinking]="enablePlayerLinking"></player-image>
			<span class="clublogo" [title]="clubData[player.side]?.nameFull" *ngIf="player.side === 'home' || player.side === 'away'">
				<clublogo [clubId]="clubData[player.side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[player.side]?.logoUrl | imageService : 60"></clublogo>
			</span>
		</div>
		<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', player.person?.name | slugify] | translateRoute) : null">
			<span class="first">{{player.person?.name | livetickerEventPlayername:'first'}}</span>
			<span class="last">{{player.person?.name | livetickerEventPlayername:'last'}}</span>
		</a>
		<span class="value">{{player.value | number:"1.2-2"}}</span>
		<span class="unit" *ngIf="player.unit">{{player.unit}}</span>
	</div>
</div>
