import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
	constructor(public msalService: MsalService) {}

	ngOnInit() {
		if (this.msalService.instance.getAllAccounts().length > 0) {
			this.msalService.logout();
		}
	}
}
