<h2 mat-dialog-title>TranslationConfig for MatchId {{matchId}}</h2>
<mat-dialog-content class="mat-typography action-edit">
	<dfl-loader [loading]="processing" surrounding="medium"></dfl-loader>
	<div class="container" *ngIf="!processing" [formGroup]="form">
		<div class="row" *ngFor="let targetLanguage of availableLanguages">
			<div class="col-5">
				<mat-form-field class="full-width">
					<mat-label>Target Language</mat-label>
					<input matInput [value]="targetLanguage" disabled tabindex="1" />
				</mat-form-field>
			</div>
			<div class="col-1 text-center">
				<mat-icon>keyboard_backspace</mat-icon>
			</div>
			<div class="col-6">
				<mat-form-field class="full-width">
					<mat-label>{{ form.controls[targetLanguage].value === null ? "Do not translate" : "Source Language" }}</mat-label>
					<mat-select [formControlName]="targetLanguage" tabindex="2" placeholder="Do not translate">
						<mat-option [value]="null">Do not translate</mat-option>
						<ng-container *ngFor="let sourceLanguage of availableLanguages">
							<mat-option *ngIf="sourceLanguage !== targetLanguage" [value]="sourceLanguage">{{sourceLanguage}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false">Cancel</button>
	<button mat-button color="warn" [disabled]="processing" (click)="onDelete()">Delete</button>
	<button mat-button [disabled]="processing" cdkFocusInitial (click)="onSubmit()">Publish changes</button>
</mat-dialog-actions>
