import { Injectable } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { DflDatalibraryService } from './dflDatalibrary.service';

@Injectable()
/**
 * @todo/dfl feels like this entire class already needs a refactoring :^o
 */
export class DflDatalibraryMatchdayService extends DflDatalibraryService {
	public matchdayUuid: string;
	public matchdayId: string;
	public matchdayNumber: number;

	/**
	 * Hardcoded mapping of matchdayNumber<->DflDataLibrarySeasonID for the current Season. Meh.
	 */
	public matchdays = {
		'0001K1': ['DFL-DAY-004BMT'],
		'0001K2': [
			'DFL-DAY-004BPL',
			'DFL-DAY-004BPM',
			'DFL-DAY-004BPN',
			'DFL-DAY-004BPO',
			'DFL-DAY-004BPP',
			'DFL-DAY-004BPQ',
			'DFL-DAY-004BPR',
			'DFL-DAY-004BPS',
			'DFL-DAY-004BPT',
			'DFL-DAY-004BPU',
			'DFL-DAY-004BPV',
			'DFL-DAY-004BPW',
			'DFL-DAY-004BPX',
			'DFL-DAY-004BPY',
			'DFL-DAY-004BPZ',
			'DFL-DAY-004BQ0',
			'DFL-DAY-004BQ1',
			'DFL-DAY-004BQ2',
			'DFL-DAY-004BQ3',
			'DFL-DAY-004BQ4',
			'DFL-DAY-004BQ5',
			'DFL-DAY-004BQ6',
			'DFL-DAY-004BQ7',
			'DFL-DAY-004BQ8',
			'DFL-DAY-004BQ9',
			'DFL-DAY-004BQA',
			'DFL-DAY-004BQB',
			'DFL-DAY-004BQC',
			'DFL-DAY-004BQD',
			'DFL-DAY-004BQE',
			'DFL-DAY-004BQF',
			'DFL-DAY-004BQG',
			'DFL-DAY-004BQH',
			'DFL-DAY-004BQI'
		],
		'0001K3': [
			'DFL-DAY-004BSD',
			'DFL-DAY-004BSE',
			'DFL-DAY-004BSF',
			'DFL-DAY-004BSG',
			'DFL-DAY-004BSH',
			'DFL-DAY-004BSI',
			'DFL-DAY-004BSJ',
			'DFL-DAY-004BSK',
			'DFL-DAY-004BSL',
			'DFL-DAY-004BSM',
			'DFL-DAY-004BSN',
			'DFL-DAY-004BSO',
			'DFL-DAY-004BSP',
			'DFL-DAY-004BSQ',
			'DFL-DAY-004BSR',
			'DFL-DAY-004BSS',
			'DFL-DAY-004BST',
			'DFL-DAY-004BSU',
			'DFL-DAY-004BSV',
			'DFL-DAY-004BSW',
			'DFL-DAY-004BSX',
			'DFL-DAY-004BSY',
			'DFL-DAY-004BSZ',
			'DFL-DAY-004BT0',
			'DFL-DAY-004BT1',
			'DFL-DAY-004BT2',
			'DFL-DAY-004BT3',
			'DFL-DAY-004BT4',
			'DFL-DAY-004BT5',
			'DFL-DAY-004BT6',
			'DFL-DAY-004BT7',
			'DFL-DAY-004BT8',
			'DFL-DAY-004BT9',
			'DFL-DAY-004BTA'
		],
		'0001K4': [
			'DFL-DAY-004BV5',
			'DFL-DAY-004BV6',
			'DFL-DAY-004BV7',
			'DFL-DAY-004BV8',
			'DFL-DAY-004BV9',
			'DFL-DAY-004BVA',
			'DFL-DAY-004BVB',
			'DFL-DAY-004BVC',
			'DFL-DAY-004BVD',
			'DFL-DAY-004BVE',
			'DFL-DAY-004BVF',
			'DFL-DAY-004BVG',
			'DFL-DAY-004BVH',
			'DFL-DAY-004BVI',
			'DFL-DAY-004BVJ',
			'DFL-DAY-004BVK',
			'DFL-DAY-004BVL',
			'DFL-DAY-004BVM',
			'DFL-DAY-004BVN',
			'DFL-DAY-004BVO',
			'DFL-DAY-004BVP',
			'DFL-DAY-004BVQ',
			'DFL-DAY-004BVR',
			'DFL-DAY-004BVS',
			'DFL-DAY-004BVT',
			'DFL-DAY-004BVU',
			'DFL-DAY-004BVV',
			'DFL-DAY-004BVW',
			'DFL-DAY-004BVX',
			'DFL-DAY-004BVY',
			'DFL-DAY-004BVZ',
			'DFL-DAY-004BW0',
			'DFL-DAY-004BW1',
			'DFL-DAY-004BW2'
		],
		'0001K5': [
			'DFL-DAY-004BXX',
			'DFL-DAY-004BXY',
			'DFL-DAY-004BXZ',
			'DFL-DAY-004BY0',
			'DFL-DAY-004BY1',
			'DFL-DAY-004BY2',
			'DFL-DAY-004BY3',
			'DFL-DAY-004BY4',
			'DFL-DAY-004BY5',
			'DFL-DAY-004BY6',
			'DFL-DAY-004BY7',
			'DFL-DAY-004BY8',
			'DFL-DAY-004BY9',
			'DFL-DAY-004BYA',
			'DFL-DAY-004BYB',
			'DFL-DAY-004BYC',
			'DFL-DAY-004BYD',
			'DFL-DAY-004BYE',
			'DFL-DAY-004BYF',
			'DFL-DAY-004BYG',
			'DFL-DAY-004BYH',
			'DFL-DAY-004BYI',
			'DFL-DAY-004BYJ',
			'DFL-DAY-004BYK',
			'DFL-DAY-004BYL',
			'DFL-DAY-004BYM',
			'DFL-DAY-004BYN',
			'DFL-DAY-004BYO',
			'DFL-DAY-004BYP',
			'DFL-DAY-004BYQ',
			'DFL-DAY-004BYR',
			'DFL-DAY-004BYS',
			'DFL-DAY-004BYT',
			'DFL-DAY-004BYU'
		],
		'0001K6': [
			'DFL-DAY-004C0P',
			'DFL-DAY-004C0Q',
			'DFL-DAY-004C0R',
			'DFL-DAY-004C0S',
			'DFL-DAY-004C0T',
			'DFL-DAY-004C0U',
			'DFL-DAY-004C0V',
			'DFL-DAY-004C0W',
			'DFL-DAY-004C0X',
			'DFL-DAY-004C0Y',
			'DFL-DAY-004C0Z',
			'DFL-DAY-004C10',
			'DFL-DAY-004C11',
			'DFL-DAY-004C12',
			'DFL-DAY-004C13',
			'DFL-DAY-004C14',
			'DFL-DAY-004C15',
			'DFL-DAY-004C16',
			'DFL-DAY-004C17',
			'DFL-DAY-004C18',
			'DFL-DAY-004C19',
			'DFL-DAY-004C1A',
			'DFL-DAY-004C1B',
			'DFL-DAY-004C1C',
			'DFL-DAY-004C1D',
			'DFL-DAY-004C1E',
			'DFL-DAY-004C1F',
			'DFL-DAY-004C1G',
			'DFL-DAY-004C1H',
			'DFL-DAY-004C1I',
			'DFL-DAY-004C1J',
			'DFL-DAY-004C1K',
			'DFL-DAY-004C1L',
			'DFL-DAY-004C1M'
		]
	};

	constructor(public override configService: ConfigService) {
		super(configService);
	}

	public setMatchdayDataByCompetitionId(competitionId): void {
		if (competitionId && competitionId !== '') {
			this.competitionId = competitionId;
		}
		this.CompetitionSettings = this.competitionId;
		const matchdaySettings = this.competitionSettings['matchday'];
		this.matchdayId = matchdaySettings['dflDatalibraryMatchdayId'];
		this.matchdayNumber = matchdaySettings['matchdayNumber'];
		this.matchdayUuid = matchdaySettings['matchdayId'];
	}

	/**
	 *
	 * @constructor
	 */
	get MatchdayId(): string {
		return this.matchdayId;
	}

	/**
	 *
	 * @param value
	 * @constructor
	 */
	set MatchdayId(value: string) {
		this.matchdayId = value;
	}

	/**
	 *
	 * @param number
	 * @param season
	 */
	public getMatchdayIdByNumber(number: number, season = '0001K3') {
		season = season.replace('DFL-SEA-', ''); /* you'll never know...*/
		if (this.matchdays[season] && this.matchdays[season][number - 1]) {
			return this.matchdays[season][number - 1];
		}
		return this.matchdays[season][0];
	}
}
