import { Pipe, PipeTransform } from '@angular/core';
import { BundesmasterPartialCollection } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'partialListHint'
})
export class PartialListHintPipe implements PipeTransform {
	transform(extract?: BundesmasterPartialCollection | null): string {
		if (extract === null || extract === undefined) {
			return '';
		}

		const { returnedItems, status, totalItems } = extract;

		if (status === 'allItemsIncluded') {
			return '';
		}

		if (status === 'partialWithMoreThanTotalItems') {
			return `Displaying ${returnedItems} of more than ${totalItems} results`;
		}

		return `Displaying ${returnedItems} of ${totalItems} results`;
	}
}
