import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Club, LiveBlogEntryEditorialFreetext, Match } from '@nx-bundesliga/models';
import idx from 'idx';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { from, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { LoadingService } from '../../../services/loading/loading.service';
import { MatchesService } from '../../../services/matches/matches.service';

const moment = _moment;

@Component({
	selector: 'welcome-page',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss', '../../../components/molecules/bl-liveticker-rapid-composer/event-inputs/liveticker-events.common.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {
	public season: string = null;
	public matchdays: Array<number>;
	public numberOfMatchdays = {
		'DFL-COM-000001': 34,
		'DFL-COM-000002': 34,
		'DFL-COM-000003': 1,
		'DFL-COM-000004': 2,
		'DFL-COM-000005': 2
	};
	public clubs: Club[];
	public seasons: string[];

	public defaultSeasonId = 'DFL-SEA-0001K7';
	public defaultMatchdayNumber = 1;
	public defaultCompetitionId = 'DFL-COM-000001';
	public defaultLanguage = 'en';

	public matchdayNumber = 1;
	public language = 'en';
	public competitionId = 'DFL-COM-000001';
	public seasonId = 'DFL-SEA-0001K7';

	public availableLanguages = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'ar'];

	public seasonsIds = {
		'DFL-SEA-0001K8': '2024/2025',
		'DFL-SEA-0001K7': '2023/2024',
		'DFL-SEA-0001K6': '2022/2023',
		'DFL-SEA-0001K5': '2021/2022',
		'DFL-SEA-0001K4': '2020/2021',
		'DFL-SEA-0001K3': '2019/2020',
		'DFL-SEA-0001K2': '2018/2019',
		'DFL-SEA-0001K1': '2017/2018',
		'DFL-SEA-0001K0': '2016/2017'
	};

	public competitionIds = {
		'DFL-COM-000001': 'Bundesliga',
		'DFL-COM-000002': '2. Bundesliga',
		'DFL-COM-000003': 'Supercup',
		'DFL-COM-000004': 'Relegation BL',
		'DFL-COM-000005': 'Relegation 2BL'
	};
	private _matchesServiceSubscription: Subscription;
	public loading = true;
	public publishing = false;
	public data: any;
	public matches: Match[];
	public welcomeData: {
		[key: string]: {
			liveEntry: LiveBlogEntryEditorialFreetext;
			selected: boolean;
			matchData: Match;
			success: boolean;
			error: string;
		};
	} = {};
	public liveEntry: LiveBlogEntryEditorialFreetext;
	public showPreviews = false;

	constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private toastr: ToastrService, private loadingService: LoadingService, private configService: ConfigService, private matchesService: MatchesService) {}

	ngOnInit() {
		this.loading = true;
		this.language = this.defaultLanguage;
		this.competitionId = this.defaultCompetitionId;
		this.seasonId = this.configService.getSettings([this.competitionId, 'season', 'dflDatalibrarySeasonId'], this.defaultSeasonId);
		this.liveEntry = {
			entryType: 'freetext',
			detail: {
				headline: '',
				text: ''
			},
			order: -10000,
			entryDate: moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: 'PRE_MATCH',
			side: 'none',
			playtime: {
				minute: 0,
				injuryTime: 0
			},
			conference: false
		};
		this._fetchMatchData();
	}
	ngOnDestroy() {
		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}
	}

	private _fetchMatchdays() {
		this.matchdays = Array.from(Array(this.numberOfMatchdays[this.competitionId])).map((x, i) => i + 1);
	}

	public toggleMatch(matchId: string, selected: boolean) {
		this.welcomeData[matchId].selected = selected;
	}
	public update() {
		Object.keys(this.welcomeData).forEach((matchId) => {
			this.welcomeData[matchId].liveEntry = this.transformLiveEntry(matchId);
		});
	}

	public selectAll() {
		Object.keys(this.welcomeData).forEach((matchId) => {
			this.welcomeData[matchId].selected = true;
		});
	}
	public unselectAll() {
		Object.keys(this.welcomeData).forEach((matchId) => {
			this.welcomeData[matchId].selected = false;
		});
	}
	public selectAllMD(matchday: number) {
		Object.keys(this.welcomeData).forEach((matchId) => {
			if (this.welcomeData[matchId].matchData.matchday === matchday) {
				this.welcomeData[matchId].selected = true;
			}
		});
	}
	public unselectAllMD(matchday: number) {
		Object.keys(this.welcomeData).forEach((matchId) => {
			if (this.welcomeData[matchId].matchData.matchday === matchday) {
				this.welcomeData[matchId].selected = false;
			}
		});
	}
	public calculateSelected(matchday: number) {
		const mdMatches = Object.values(this.welcomeData).filter((match: any) => match.matchData.matchday === matchday);
		const selectedMatches = mdMatches.filter((match: any) => match.selected === true);
		return `${selectedMatches.length} / ${mdMatches.length}`;
	}
	public calculateSubmissions(matchday: number) {
		const mdMatches = Object.values(this.welcomeData).filter((match: any) => match.matchData.matchday === matchday && match.selected === true);
		const unsubmittedMatches = mdMatches.filter((match: any) => match.success === null);
		const successdMatches = mdMatches.filter((match: any) => match.success === true);
		const errorMatches = mdMatches.filter((match: any) => match.success === false);
		return unsubmittedMatches.length === mdMatches.length ? `Unsubmitted: ${unsubmittedMatches.length} / ${mdMatches.length}` : `Unsubmitted: ${unsubmittedMatches.length} / ${mdMatches.length} - Successful: ${successdMatches.length} / ${mdMatches.length} - Unsuccessful ${errorMatches.length} / ${mdMatches.length}`;
	}

	public transformLiveEntry(matchId) {
		const match = this.welcomeData[matchId].matchData;
		const replacePlaceholder = (theMatch, theValue: string) => {
			return theValue
				.replace('$matchday$', idx(theMatch, (_) => _.matchday) || '')
				.replace('$away.nameFull$', idx(theMatch, (_) => _.teams.away.nameFull) || '')
				.replace('$away.nameShort$', idx(theMatch, (_) => _.teams.away.nameShort) || '')
				.replace('$away.threeLetterCode$', idx(theMatch, (_) => _.teams.away.threeLetterCode) || '')
				.replace('$home.nameFull$', idx(theMatch, (_) => _.teams.home.nameFull) || '')
				.replace('$home.nameShort$', idx(theMatch, (_) => _.teams.home.nameShort) || '')
				.replace('$home.threeLetterCode$', idx(theMatch, (_) => _.teams.home.threeLetterCode) || '');
		};
		return {
			...this.liveEntry,
			detail: {
				headline: replacePlaceholder(match, this.liveEntry.detail.headline),
				text: replacePlaceholder(match, this.liveEntry.detail.text)
			}
		};
	}

	public _fetchMatchData(): void {
		this._fetchMatchdays();
		this.welcomeData = {};
		this.loading = true;
		const subscription = this.matchesService.getMatchinfosByCompetions(this.competitionId, this.seasonId);

		// unsibscribe first
		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}

		// get matches
		this._matchesServiceSubscription = subscription.pipe(take(1)).subscribe(
			(success: Match[]) => {
				this.matches = success;
				this.matches.forEach((match) => {
					this.welcomeData[match.dflDatalibraryMatchId] = {
						liveEntry: this.liveEntry,
						selected: false,
						matchData: match,
						success: null,
						error: ''
					};
				});
				this.update();
				this.loading = false;
			},
			(error) => {
				console.error('Error fetching Matches: ', error);
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	public trackByMatchId(index, item) {
		if (item.dflDatalibraryMatchId) {
			return item.dflDatalibraryMatchId;
		}
	}

	public publish(): void {
		const selectedMatches = Object.values(this.welcomeData).filter((match: any) => match.selected === true);
		if (selectedMatches.length === 0) {
			this.toastr.error('Please select at least one match!', 'No selections');
			return;
		}
		this.loadingService.setLoading(true);
		this.publishing = true;

		from(selectedMatches)
			.pipe(
				mergeMap((match) => {
					const matchData = match.matchData;
					const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;
					const urlSuffix = [this.language, matchData.dflDatalibraryCompetitionId, matchData.dflDatalibrarySeasonId, matchData.dflDatalibraryMatchdayId, matchData.dflDatalibraryMatchId].join('/');
					const url = urlBase + '/' + urlSuffix + '/intro';
					const payload = match.liveEntry;
					console.log('RapidTickerCreatorComponent.publishEvent', payload);
					return this.http.put(url, payload).pipe(
						map((val) => [val, match]),
						catchError((val) => of([val, match]))
					);
				})
			)
			.subscribe(
				([success, match]: [any, any]) => {
					if (success.hasOwnProperty('error')) {
						const error = success;
						console.log('error', error, match);
						const status = error.hasOwnProperty('status') ? error.status : 'Unknown StatusCode';
						const name = error.hasOwnProperty('name') ? error.name : 'Error';
						const message = error.error.hasOwnProperty('message') ? error.error.message : error.hasOwnProperty('message') ? error.message : 'Unknown Error';
						this.welcomeData[match.matchData.dflDatalibraryMatchId]['success'] = false;
						this.welcomeData[match.matchData.dflDatalibraryMatchId]['error'] = `${message}: (${status}): ${name}`;
					} else {
						console.log('success', success, match);
						console.log('match', match);
						this.welcomeData[match.matchData.dflDatalibraryMatchId]['success'] = true;
						this.welcomeData[match.matchData.dflDatalibraryMatchId]['error'] = '';
						// this.toastr.success('Post has successfully been published!', 'Published');
						// this.loadingService.setLoading(false);
						// this.publishing = false;
					}
				},
				(error) => {
					this.loadingService.setLoading(false);
					this.publishing = false;
					// this.toastr.error(`${message}`, `(${status}): ${name}`);
				},
				() => {
					this.loadingService.setLoading(false);
					this.publishing = false;
				}
			);
	}
}
