import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FaceDetectionUser, PipesModule } from '@nx-bundesliga/bundesmaster/core';

@Component({
	selector: 'bundesmaster-ui-bounding-box-overlay',
	standalone: true,
	imports: [CommonModule, MatCardModule, PipesModule],
	templateUrl: './bundesmaster-ui-bounding-box-overlay.component.html',
	styleUrls: ['./bundesmaster-ui-bounding-box-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundesmasterUiBoundingBoxOverlayComponent {
	@Input() persons: FaceDetectionUser[] = [];
	@Input() noFadeIn = false;
}
