import { KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { LivetickerEventsPipeModule } from '@nx-bundesliga/bundesliga-com/components/liveticker';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BlLivetickereventWrapperModule } from '../../molecules/bl-livetickerevent-wrapper/bl-livetickerevent-wrapper.module';
import { LivetickerEventModule } from './components/liveticker-event/liveticker-event.module';
import { LivetickerComponent } from './components/liveticker/liveticker.component';

@NgModule({
	imports: [CommonsModule, LivetickerEventsPipeModule, LivetickerEventModule, BlLivetickereventWrapperModule, KeyValuePipe, MatButtonModule, MatIconModule, NgxTippyModule],
	exports: [LivetickerComponent],
	providers: [KeyValuePipe],
	declarations: [LivetickerComponent]
})
export class LivetickerModule {}
