<div class="stackedcolumn-chart" [ngClass]="{'single': single}">
	<span class="title">{{title}}</span>
	<div class="columns">
		<div class="column secondary left" [ngStyle]="{'backgroundColor': leftSecondaryColor}">
			<div class="valuebox secondary left">
				<span class="value unit secondary left" [ngStyle]="{'color': leftTextColor}">{{leftSecondaryValue}}</span>
				<span class="value metric secondary left" [ngStyle]="{'color': leftTextColor}">{{leftSecondaryLabel}}</span>
			</div>
			<div class="column primary left" [ngStyle]="{'backgroundColor': leftColor}" [ngClass]="{'surround-with-border': leftColor.toLowerCase() === '#ffffff'}">
				<div class="valuebox primary left">
					<span class="value unit primary left" [ngStyle]="{'color': leftTextColor}">{{leftPrimaryValue}}</span>
					<span class="value metric primary left" [ngStyle]="{'color': leftTextColor}">{{leftPrimaryLabel}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="single === false" class="column secondary right" [ngStyle]="{'backgroundColor': rightSecondaryColor}">
			<div class="valuebox secondary right">
				<span class="value unit secondary right" [ngStyle]="{'color': rightTextColor}">{{rightSecondaryValue}}</span>
				<span class="value metric secondary right" [ngStyle]="{'color': rightTextColor}">{{rightSecondaryLabel}}</span>
			</div>
			<div class="column primary right" [ngStyle]="{'backgroundColor': rightColor}" [ngClass]="{'surround-with-border': rightColor.toLowerCase() === '#ffffff'}">
				<div class="valuebox primary right">
					<span class="value unit primary right" [ngStyle]="{'color': rightTextColor}">{{rightPrimaryValue}}</span>
					<span class="value metric primary right" [ngStyle]="{'color': rightTextColor}">{{rightPrimaryLabel}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
