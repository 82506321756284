import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const getProtectedResourceMap = (): Map<string, Array<string | ProtectedResourceScopes> | null> => {
	const protectedResourceMap = new Map<string, Array<string>>();
	const apiConfig: [string, string[]][] = [
		['https://stories.api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://stories.api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://stories.api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://video.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://video.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://video.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesstreaker.bundesliga-dev.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://api.bundesstreaker.bundesliga-appr.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://api.bundesstreaker.bundesliga.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://graph.microsoft.com/*', ['User.Read', 'profile', 'openid', 'email']]
	];
	apiConfig.forEach((item) => {
		protectedResourceMap.set(item[0], item[1]);
	});

	return protectedResourceMap;
};

export const msalGuardConfig: MsalGuardConfiguration = {
	interactionType: InteractionType.Popup,
	loginFailedRoute: '/login/error',
	authRequest: {
		scopes: ['User.Read', 'profile', 'openid', 'email', 'api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']
	}
};

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
	interactionType: InteractionType.Popup,
	protectedResourceMap: getProtectedResourceMap()
};

export const msalConfig: Configuration = {
	auth: {
		clientId: environment.msal.clientId,
		authority: `https://login.microsoftonline.com/${environment.msal.authority}`,
		redirectUri: environment.msal.loginUrl
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: true // set to true for IE 11
	},
	system: {
		loggerOptions: {
			loggerCallback: (...message) => {
				console.log('%c [MSAL CALLBACK] ', 'background: #395fb7; color: #fff', ...message);
			},
			piiLoggingEnabled: false
		}
	}
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function loggerCallbackFactory(logLevel, message, piiEnabled) {
	console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msal.clientId,
			authority: 'https://login.microsoftonline.com/' + environment.msal.authority,
			redirectUri: environment.msal.loginUrl,
			postLogoutRedirectUri: environment.msal.logoutUrl,
			navigateToLoginRequestUrl: false
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE // set to true for IE 11
		},
		system: {
			loggerOptions: {
				loggerCallback: loggerCallbackFactory,
				piiLoggingEnabled: true,
				logLevel: environment.stage === 'development' ? LogLevel.Verbose : LogLevel.Info
			}
		}
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	const apiConfig: [string, string[]][] = [
		['https://stories.api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://stories.api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://stories.api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga-dev.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga-appr.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://content-api.bundesmaster.bundesliga.com/*', ['api://' + environment.msal.contender.clientId + '/Bundesmaster.Master']],
		['https://api.bundesstreaker.bundesliga-dev.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://api.bundesstreaker.bundesliga-appr.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://api.bundesstreaker.bundesliga.com/*', ['api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']],
		['https://graph.microsoft.com/*', ['User.Read', 'profile', 'openid', 'email']]
	];
	apiConfig.forEach((item) => {
		protectedResourceMap.set(item[0], item[1]);
	});
	// protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

	return {
		interactionType: InteractionType.Popup,
		protectedResourceMap
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Popup,
		loginFailedRoute: '/login/error',
		authRequest: {
			scopes: ['User.Read', 'profile', 'openid', 'email', 'api://' + environment.msal.clientId + '/Bundesstreaker.Ticker']
		}
	};
}
