<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div class="event statsEvent side_{{ side }}">
	<div class="head">
		<div class="playtime">
			<livetickerevent-playtime [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
			<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="!['playerProgressRanking', 'clubProgressRanking', 'playerRanking', 'clubMetricProgression', 'pressureHandling'].includes(liveEntry.detail?.type)"></livetickerevent-headline>
			<div class="headline-row">
				<ng-container *ngIf="liveEntry.detail?.type === 'playerRanking'">
					<a *ngIf="enablePlayerLinking" [routerLink]="['/', currentLang, currentCompetition, 'route-stats', 'route-players', 'route-maximumSpeed'] | translateRoute">
						<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="translateStatsString(liveEntry.detail?.title, 'LIVETICKER.MATCHFACTS.', '.TOP3.TITLE', {minute: (liveEntry.playtime?.minute || '')}) | async"></livetickerevent-headline>
					</a>
					<livetickerevent-headline *ngIf="!enablePlayerLinking" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="translateStatsString(liveEntry.detail?.title, 'LIVETICKER.MATCHFACTS.', '.TOP3.TITLE', {minute: (liveEntry.playtime?.minute || '')}) | async"></livetickerevent-headline>
					<livetickerevent-matchfacts-info *ngIf="liveEntry.detail?.matchFact === true" [liveEntry]="liveEntry" [matchFact]="liveEntry.detail?.title + '.TOP3'"></livetickerevent-matchfacts-info>
				</ng-container>
				<ng-container *ngIf="['playerProgressRanking', 'clubMetricProgression', 'pressureHandling', 'ballRecoveryTime'].includes(liveEntry.detail?.type)">
					<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="translateStatsString(liveEntry.detail?.headline || liveEntry.detail?.title, 'LIVETICKER.MATCHFACTS.', '.TITLE') | async"></livetickerevent-headline>
					<livetickerevent-matchfacts-info *ngIf="liveEntry.detail?.matchFact === true" [liveEntry]="liveEntry" [matchFact]="liveEntry.detail?.headline || liveEntry.detail?.title"></livetickerevent-matchfacts-info>
				</ng-container>
				<ng-container *ngIf="liveEntry.detail?.type === 'clubProgressRanking'">
					<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="translateStatsString(liveEntry.detail?.headline || liveEntry.detail?.title, 'LIVETICKER.MATCHFACTS.', '.AGGREGATED.TITLE') | async"></livetickerevent-headline>
					<livetickerevent-matchfacts-info *ngIf="liveEntry.detail?.matchFact === true" [liveEntry]="liveEntry" [matchFact]="liveEntry.detail?.title + '.AGGREGATED'"></livetickerevent-matchfacts-info>
				</ng-container>
				<ng-container *ngIf="liveEntry.detail?.type === 'keeperEfficiency'">
					<livetickerevent-headline [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" [headline]="translateStatsString(liveEntry.detail?.headline || liveEntry.detail?.title, 'LIVETICKER.MATCHFACTS.', '.KEEPERTITLE', {keeper: liveEntry.detail?.person?.name}) | async"></livetickerevent-headline>
					<livetickerevent-matchfacts-info *ngIf="liveEntry.detail?.matchFact === true" [liveEntry]="liveEntry" [matchFact]="liveEntry.detail?.title"></livetickerevent-matchfacts-info>
				</ng-container>
			</div>
			<span class="detailText" *ngIf="liveEntry.detail.text">{{liveEntry.detail.text}}</span>
		</div>
	</div>
	<div class="body" *ngIf="liveEntry.detail">
		<div class="stats">
			<stats-pressurehandling-card *ngIf="liveEntry.detail?.type === 'pressureHandling'" [person]="liveEntry.detail.person" [escapeRate]="liveEntry.detail.escapeRate" [pressureCount]="liveEntry.detail.pressureCount"></stats-pressurehandling-card>
			<stats-keeperefficiency-card *ngIf="liveEntry.detail?.type === 'keeperEfficiency'" [person]="liveEntry.detail.person" [savedShots]="liveEntry.detail.savedShots" [xSaves]="liveEntry.detail.xSaves"></stats-keeperefficiency-card>
			<stats-ballrecoverytime-card *ngIf="liveEntry.detail?.type === 'ballRecoveryTime'" [clubData]="clubData" [times]="liveEntry.detail?.times" [side]="liveEntry.side"></stats-ballrecoverytime-card>
			<stats-clubmetricprogression-card
				*ngIf="liveEntry.detail?.type === 'clubMetricProgression'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[club]="clubData[liveEntry.side]"
				[playtime]="liveEntry.playtime"
				[progression]="liveEntry.detail.progression"></stats-clubmetricprogression-card>
			<stats-playerprogressranking-card
				*ngIf="liveEntry.detail?.type === 'playerProgressRanking'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[homeColor]="clubData.home.gradientStartColor"
				[awayColor]="clubData.away.gradientStartColor"
				[homeTextColor]="clubData.home.textColor"
				[awayTextColor]="clubData.away.textColor"
				[ranking]="liveEntry.detail.ranking"></stats-playerprogressranking-card>
			<stats-clubprogressranking-card
				*ngIf="liveEntry.detail?.type === 'clubProgressRanking'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[homeColor]="clubData.home.gradientStartColor"
				[awayColor]="clubData.away.gradientStartColor"
				[homeTextColor]="clubData.home.textColor"
				[awayTextColor]="clubData.away.textColor"
				[metric]="translateStatsString(liveEntry.detail?.metric, 'MATCHCENTER.STAT.') | async"
				[homeValue]="liveEntry.detail.home.value"
				[awayValue]="liveEntry.detail.away.value"
				[homeRelativeValue]="liveEntry.detail.home.relativeValue"
				[awayRelativeValue]="liveEntry.detail.away.relativeValue"></stats-clubprogressranking-card>
			<stats-playercomparison-card
				*ngIf="liveEntry.detail?.type === 'player'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[homeColor]="clubData.home.gradientStartColor"
				[awayColor]="clubData.away.gradientStartColor"
				[homeTextColor]="clubData.home.textColor"
				[awayTextColor]="clubData.away.textColor"
				[ranking]="{
                                  home: {
                                    dflDatalibraryObjectId: liveEntry.detail.home.person.dflDatalibraryObjectId,
                                    playerImageUrl: liveEntry.detail.home.person.imageUrl,
                                    playerName: liveEntry.detail.home.person.name,
                                    value: liveEntry.detail.home.value
                                  },
                                  away: {
                                    dflDatalibraryObjectId: liveEntry.detail.away.person.dflDatalibraryObjectId,
                                    playerImageUrl: liveEntry.detail.away.person.imageUrl,
                                    playerName: liveEntry.detail.away.person.name,
                                    value: liveEntry.detail.away.value
                                  }
                                }"
				[isLiveticker]="true"
				[enablePlayerLinking]="enablePlayerLinking"></stats-playercomparison-card>
			<stats-playerranking-card
				*ngIf="liveEntry.detail?.type === 'playerRanking'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[homeColor]="clubData.home.gradientStartColor"
				[awayColor]="clubData.away.gradientStartColor"
				[homeTextColor]="clubData.home.textColor"
				[awayTextColor]="clubData.away.textColor"
				[clubData]="clubData"
				[ranking]="liveEntry.detail?.ranking"
				[isLiveticker]="true"
				[enablePlayerLinking]="enablePlayerLinking"></stats-playerranking-card>
			<bar-chart
				*ngIf="liveEntry.detail?.type === 'bar'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[leftValue]="liveEntry.detail.home.value"
				[leftColor]="clubData.home.gradientStartColor"
				[leftTextColor]="clubData.home.textColor"
				[rightValue]="liveEntry.detail.away.value"
				[rightColor]="clubData.away.gradientStartColor"
				[rightTextColor]="clubData.away.textColor"></bar-chart>
			<pie-chart
				*ngIf="liveEntry.detail?.type === 'pie'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[leftValue]="liveEntry.detail.home.value"
				[leftColor]="clubData.home.gradientStartColor"
				[rightValue]="liveEntry.detail.away.value"
				[rightColor]="clubData.away.gradientStartColor"></pie-chart>
			<stackedcolumn-chart
				*ngIf="liveEntry.detail?.type === 'stackedcolumn'"
				[title]="translateStatsString(liveEntry.detail?.title, 'MATCHCENTER.STAT.') | async"
				[leftPrimaryValue]="liveEntry.detail.home.primaryValue"
				[leftPrimaryLabel]="translateStatsString(liveEntry.detail.home.primaryLabel, 'MATCHCENTER.STAT.') | async"
				[leftSecondaryValue]="liveEntry.detail.home.secondaryValue"
				[leftSecondaryLabel]="translateStatsString(liveEntry.detail.home.secondaryLabel, 'MATCHCENTER.STAT.') | async"
				[leftColor]="clubData.home.gradientStartColor"
				[leftTextColor]="clubData.home.textColor"
				[rightPrimaryValue]="liveEntry.detail.away.primaryValue"
				[rightPrimaryLabel]="translateStatsString(liveEntry.detail.away.primaryLabel, 'MATCHCENTER.STAT.') | async"
				[rightSecondaryValue]="liveEntry.detail.away.secondaryValue"
				[rightSecondaryLabel]="translateStatsString(liveEntry.detail.away.secondaryLabel, 'MATCHCENTER.STAT.') | async"
				[rightColor]="clubData.away.gradientStartColor"
				[rightTextColor]="clubData.away.textColor"></stackedcolumn-chart>
		</div>
	</div>
</div>
<div class="poweredByAWS" *ngIf="liveEntry?.detail?.matchFact === true">
	<livetickerevent-matchfacts-banner [isWebview]="isWebview"></livetickerevent-matchfacts-banner>
</div>
