import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiveBlogMatch } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'bl-custom-match-progress-dialog',
	templateUrl: 'bl-custom-match-progress-dialog.component.html',
	styleUrls: ['./bl-custom-match-progress-dialog.component.scss']
})
export class BlCustomMatchProgressDialogComponent {
	@Input() match: LiveBlogMatch;
	@Input() language: string;
	public processing = false;
	public customMatch = {
		matchStatus: 'PRE_MATCH',
		score: {
			away: {
				halftime: 0,
				live: 0
			},
			home: {
				halftime: 0,
				live: 0
			}
		}
	};
	constructor(private toastr: ToastrService, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public loading: LoadingService, public dialogRef: MatDialogRef<BlCustomMatchProgressDialogComponent>) {
		if (this.data) {
			this.match = this.data.hasOwnProperty('match') ? this.data.match : null;
			this.language = this.data.hasOwnProperty('language') ? this.data.language : null;
		}
		if (this.match) {
			this.customMatch.matchStatus = this.match.matchStatus;
			this.customMatch.score = { ...this.customMatch.score, ...this.match.score } as any;
		}
	}

	public onSubmit(): void {
		this.loading.setLoading(true);
		this.processing = true;
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/custom-match`;

		const urlSuffix = '' + this.language + '/' + this.match.matchId;

		const url = urlBase + '/' + urlSuffix;
		const payload = this.customMatch;
		const method = 'put';

		console.log(`Sending custom match progress to API: `, url);
		console.log('custom match', payload);

		this.http.request(method, url, { body: payload }).subscribe(
			(success) => {
				this.toastr.success('Custom match progress successfully!', 'Published');
				this.loading.setLoading(false);
				this.processing = false;
				this.dialogRef.close();
			},

			(error) => {
				const message = error.error.hasOwnProperty('message') ? error.error.message : JSON.stringify(error.error);
				this.loading.setLoading(false);
				this.toastr.error(message, 'Error');
				this.processing = false;
			}
		);
	}
}
