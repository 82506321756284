import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Asset, BundesmasterContentApiService, BundesmasterImageinfoApiService, ImageInfoResponse } from '@nx-bundesliga/bundesmaster/core';
import { addCroppingParametersToImageUrl } from '@nx-bundesliga/shared/util/functions';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { CropperPosition, ImageCroppedEvent, ImageCropperComponent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { Dimensions } from 'ngx-image-cropper/lib/interfaces/dimensions.interface';
import { catchError, combineLatest, from, map, of, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-image-cropper',
	standalone: true,
	imports: [CommonModule, CommonsLibModule, MatButtonModule, MatIconModule, ImageCropperModule, MatProgressSpinnerModule, MatFormFieldModule],
	templateUrl: './bundesmaster-ui-image-cropper.component.html',
	styleUrls: ['./bundesmaster-ui-image-cropper.component.scss']
})
export class BundesmasterUiImageCropperComponent implements OnInit, OnDestroy {
	@ViewChild('cropper', { static: false }) cropper: ImageCropperComponent;
	@Input() minWidth?: number;
	@Input() minHeight?: number;
	@Input() targetAspectRatio: number;
	@Input() originalImageRatio = 1;
	public croppingPosition: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
	@Input() initialPosition: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
	public overwritePosition: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
	@Output() cropped: EventEmitter<any> = new EventEmitter<any>();
	croppedImage: ImageCroppedEvent;
	@Input() public imageUrl: string = null;
	public croppedImageUrl = '';
	public image: ImageInfoResponse;
	public loading = true;
	public error: any;
	private readonly destroying$ = new Subject<void>();
	constructor(private imageinfoService: BundesmasterImageinfoApiService, private assetService: BundesmasterContentApiService, private http: HttpClient) {}

	fileChangeEvent(event: any): void {
		// show message
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event;
		const originalData = (this.cropper as any).loadedImage.original.size;
		this.croppedImageUrl = this.cropImage(originalData, event.imagePosition);
		this.cropped.emit({ original: originalData, cropped: { ...event.imagePosition }, url: this.croppedImageUrl });
	}

	imageLoaded(image: LoadedImage) {
		// show cropper
	}

	cropperReady() {
		if (Object.values(this.croppingPosition).some((val) => val > 0)) {
			this.overwritePosition = { ...this.croppingPosition };
		}
		if (Object.values(this.initialPosition).some((val) => val > 0)) {
			this.overwritePosition = { ...this.initialPosition };
		}
	}

	loadImageFailed() {
		// show message
	}

	ngOnInit() {
		if (this.imageUrl) {
			console.log('this.targetAspectRatio', this.targetAspectRatio);
			this.parseUrl(this.imageUrl);
			this.convertImage(this.imageUrl);
		} else {
			this.loading = false;
			this.error = 'No Image provided';
		}
	}

	public cropImage(croppingDataOriginal: Dimensions, croppingDataCropped: CropperPosition): string {
		console.log('croppingData', croppingDataOriginal, croppingDataCropped);
		const originalHeight = this.image.original.height;
		const originalWidth = this.image.original.width;

		const croppedOriginalWidth = croppingDataOriginal.width;
		const croppedOriginalHeight = croppingDataOriginal.height;

		const heightRatio = 1 / (croppedOriginalHeight / originalHeight);
		const widthRatio = 1 / (croppedOriginalWidth / originalWidth);

		const croppedWidth = croppingDataCropped.x2 - croppingDataCropped.x1;
		const croppedHeight = croppingDataCropped.y2 - croppingDataCropped.y1;

		const cropParameters = { x1: croppingDataCropped.x1 * widthRatio, y1: croppingDataCropped.y1 * heightRatio, x2: croppedWidth * widthRatio, y2: croppedHeight * heightRatio };

		return addCroppingParametersToImageUrl(this.imageUrl, cropParameters);
	}

	public parseUrl(url: string) {
		try {
			const urlObj = new URL(url);
			if (urlObj.searchParams.has('crop')) {
				const crop = urlObj.searchParams
					.get('crop')
					.split(',')
					.map((pos: string) => parseInt(pos, 10));
				this.croppingPosition = { x1: crop[0], y1: crop[1], x2: crop[2], y2: crop[3] };
				urlObj.searchParams.delete('crop');
			}
			this.imageUrl = urlObj.toString();
		} catch (e) {
			this.loading = false;
			this.error = e;
		}
	}

	public convertImage(imageUrl: string) {
		this.loading = true;
		const croppingWidth = this.croppingPosition?.x2 - this.croppingPosition?.x1 > 0 ? this.croppingPosition?.x2 - this.croppingPosition?.x1 : 720;
		const shrinkingWidth = Math.min(croppingWidth, 720);
		this.assetService
			.getAssetByUrl(imageUrl)
			.pipe(
				switchMap((asset: Asset) =>
					combineLatest([
						of(asset),
						this.http.get(imageUrl.split('?')[0] + `?fit=${Math.min(asset.image?.width, 720)},${Math.min(asset.image?.width, 720)}`, { responseType: 'blob' }).pipe(
							switchMap((blob) => {
								return from(
									new Promise((resolve, reject) => {
										const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
										// Read the Blob as DataURL using the FileReader API
										const reader = new FileReader();
										reader.onloadend = () => {
											resolve(reader.result);
											// const base64 = getBase64StringFromDataURL(reader.result);
										};
										reader.readAsDataURL(blob);
									})
								);
							})
						)
					])
				)
			)
			.pipe(
				map(([asset, shrinkedB64]: [Asset, string]) => {
					return {
						original: {
							width: asset.image?.width,
							height: asset.image?.height
						},
						shrinked: {
							width: Math.min(asset.image?.width, 720),
							height: asset.image?.height * (Math.min(asset.image?.width, 720) / asset.image?.width),
							base64: shrinkedB64
						}
					} as ImageInfoResponse;
				}),
				catchError(() => this.imageinfoService.imageInfo(imageUrl)),
				takeUntil(this.destroying$)
			)
			.subscribe({
				next: (resp: ImageInfoResponse) => {
					this.image = resp;
					this.originalImageRatio = this.image?.original?.width / this.image?.shrinked?.width;
					this.croppingPosition = {
						x1: Math.floor(this.croppingPosition.x1 * (1 / this.originalImageRatio)),
						y1: Math.floor(this.croppingPosition.y1 * (1 / this.originalImageRatio)),
						x2: Math.floor((this.croppingPosition.x1 + this.croppingPosition.x2) * (1 / this.originalImageRatio)),
						y2: Math.floor((this.croppingPosition.y1 + this.croppingPosition.y2) * (1 / this.originalImageRatio))
					};
					this.loading = false;
				},
				error: (error) => {
					console.error(`error loading base64 image from medialibrary`, error);
					this.error = error;
					this.loading = false;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroying$.next(undefined);
		this.destroying$.complete();
	}
}
