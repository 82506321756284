import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BundesmasterStoriesApiService, SLIDE_CTA_TYPES, SlideCta, StoryTypes } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/club-selector';
import { BundesmasterUiCompetitionSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/competition-selector';
import { BundesmasterUiPersonAutoCompleteComponent } from '@nx-bundesliga/bundesmaster/ui/person-auto-complete';
import { BundesmasterUiSeasonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/season-selector';
import { BundesmasterUiMatchSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/match-selector';
import { BundesmasterUiMatchdaySelectorComponent } from '@nx-bundesliga/bundesmaster/ui/matchday-selector';
import { BundesmasterUiPersonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/person-selector';

@Component({
	selector: 'bundesmaster-dialog-cta-edit',
	standalone: true,
	imports: [
		CommonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FormsModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatOptionModule,
		MatSelectModule,
		MatCheckboxModule,
		BundesmasterUiPersonAutoCompleteComponent,
		BundesmasterUiClubSelectorComponent,
		BundesmasterUiSeasonSelectorComponent,
		BundesmasterUiCompetitionSelectorComponent,
		BundesmasterUiPersonSelectorComponent,
		BundesmasterUiMatchdaySelectorComponent,
		BundesmasterUiMatchSelectorComponent
	],
	templateUrl: './dialog-cta-edit.component.html',
	styleUrls: ['./dialog-cta-edit.component.scss']
})
export class DialogCtaEditComponent {
	public form: FormGroup;
	public ctaTypes = Object.values(SLIDE_CTA_TYPES);
	public storyType: StoryTypes;

	constructor(public dialogRef: MatDialogRef<DialogCtaEditComponent>, private readonly storiesApiService: BundesmasterStoriesApiService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: { cta: SlideCta; storyType: StoryTypes }) {
		console.log('DialogCtaEditComponent', data);
		this.storyType = data.storyType;
		this.form = this.storiesApiService.createSlideCtaFormGroup(data?.cta);
	}

	getTargetParamsControls(): { [key: string]: FormControl } {
		return this.form?.controls?.['targetParams']?.['controls'] ?? {};
	}

	changeTarget(event: MatSelectChange) {
		this.form = this.storiesApiService.createSlideCtaFormGroup(this.form.value ?? {}, event?.value ?? '');
		this.form.updateValueAndValidity({ emitEvent: true, onlySelf: false });
	}

	save() {
		this.dialogRef.close(this.form.getRawValue());
	}

	discard() {
		this.dialogRef.close(false);
	}
}
