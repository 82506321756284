import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';

@Component({
	selector: 'livetickerevent-matchfacts-banner',
	templateUrl: './liveticker-event-matchfacts-banner.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './liveticker-event-matchfacts-banner.component.scss']
})
export class LivetickerEventMatchfactsBannerComponent implements OnDestroy {
	@Input() isWebview = false;

	public awsPartnerPageUrl = 'https://www.bundesliga.com/en/bundesliga/route-info/provider/aws';
	public language = 'en';
	private lstoreSubscription: Subscription;

	constructor(private readonly lstore: Store<Language>) {
		this.lstoreSubscription = this.lstore.pipe(select(getWorkingLanguage)).subscribe((langstate) => {
			this.language = langstate.code;
			if (this.language === 'de') {
				this.awsPartnerPageUrl = 'https://www.bundesliga.com/de/bundesliga/route-info/provider/aws';
			} else {
				this.awsPartnerPageUrl = 'https://www.bundesliga.com/en/bundesliga/route-info/provider/aws';
			}
		});
	}
	ngOnDestroy(): void {
		if (this.lstoreSubscription) {
			this.lstoreSubscription.unsubscribe();
		}
	}
}
