<div mat-dialog-title>
	<span>
		Story Slide Id: {{postId}}
		<a [href]="storyLinkContender" target="_blank">
			<button type="button" color="primary" aria-label="To story" title="To story" mat-flat-button>
				Story in Contender
				<mat-icon>open_in_new</mat-icon>
			</button>
		</a>
	</span>
</div>
<mat-dialog-content class="mat-typography">
	<div class="container-fluid slide-container">
		<div class="row">
			<div class="col col-6">
				<hr />
				<mat-expansion-panel [expanded]="false">
					<mat-expansion-panel-header>
						<mat-panel-title>Original Liveticker entry</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="preview">
						<div class="liveticker-entryContainer">
							<liveticker-event [liveEntry]="liveEntry.value" [teams]="clubData" [match]="match" [class]="liveEntry.value.entryType" class="event"></liveticker-event>
						</div>
					</div>
				</mat-expansion-panel>

				<hr />

				<dfl-loader [loading]="processing" surrounding="large"></dfl-loader>

				<ng-container *ngIf="!processing">
					<div [formGroup]="slide" *ngIf="slide">
						<!-- Slide Type -->
						<mat-form-field>
							<mat-label>Slide Type</mat-label>
							<mat-select formControlName="slideType" (selectionChange)="patchForm(slide.getRawValue())">
								<mat-option *ngFor="let slideType of slideTypes | keyvalue" [value]="slideType.value">{{slideType.value}}</mat-option>
							</mat-select>
						</mat-form-field>

						<!-- Slide ID -->
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>Slide ID</mat-label>
							<input matInput type="text" formControlName="slideId" />
						</mat-form-field>

						<div class="w-100" formGroupName="playtime">
							<!-- Minute -->
							<mat-form-field class="" appearance="outline">
								<mat-label>minute</mat-label>
								<input matInput type="number" formControlName="minute" />
							</mat-form-field>

							<!-- InjuryTime -->
							<mat-form-field class="" appearance="outline">
								<mat-label>injuryTime</mat-label>
								<input matInput type="number" formControlName="injuryTime" />
							</mat-form-field>
						</div>

						<ng-container *ngIf="slide.controls.slideType.value === slideTypes.TEXT || slide.controls.slideType.value === slideTypes.VIDEO">
							<!-- Headline -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Headline</mat-label>
								<input matInput type="text" formControlName="headline" />
							</mat-form-field>

							<!-- Text -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Text</mat-label>
								<input matInput type="text" formControlName="text" />
							</mat-form-field>
						</ng-container>

						<ng-container *ngIf="slide.controls.slideType.value === slideTypes.QUOTE">
							<!-- Quote -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Quote</mat-label>
								<input matInput type="text" formControlName="quote" />
							</mat-form-field>

							<!-- Author -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Author</mat-label>
								<input matInput type="text" formControlName="author" />
							</mat-form-field>
						</ng-container>

						<ng-container formGroupName="image" *ngIf="[slideTypes.TEXT.toString(), slideTypes.QUOTE.toString()].includes(slide.controls.slideType.value.toString())">
							<ng-container [ngTemplateOutlet]="imageControlButtons"></ng-container>
							<h2>Slide Image</h2>
							<!-- Image Url -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Url</mat-label>
								<input matInput type="text" formControlName="url" />
							</mat-form-field>

							<!-- Image Copyright -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Copyright</mat-label>
								<input matInput type="text" formControlName="copyright" />
							</mat-form-field>
						</ng-container>

						<ng-container formGroupName="video" *ngIf="[slideTypes.VIDEO.toString()].includes(slide.controls.slideType.value.toString())">
							<div class="container-fluid d-flex align-items-center justify-content-center">
								<button type="button" color="primary" aria-label="Select video" title="Select Image" mat-flat-button (click)="selectVideo()">
									Select Video
									<mat-icon aria-label="select image">video_library</mat-icon>
								</button>
							</div>
							<h2>Slide Video</h2>
							<!-- VideoId Url -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Video ID</mat-label>
								<input matInput type="text" formControlName="vid" />
							</mat-form-field>

							<!-- Image Copyright -->
							<mat-form-field class="w-100" appearance="outline">
								<mat-label>Video Runtime (sec)</mat-label>
								<input matInput type="number" formControlName="duration" />
							</mat-form-field>
						</ng-container>

						<div class="w-100">
							<h2>CTA</h2>
							<div class="container-fluid d-flex align-items-center justify-content-center">
								<button mat-flat-button matTooltip="Edit slide CTA" color="primary" (click)="editSlideCta()">
									Add/Edit CTA
									<mat-icon>star</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="col col-3">
				<h3><strong>New Slide</strong></h3>
				<dfl-loader [loading]="processing" surrounding="large"></dfl-loader>

				<ng-container *ngIf="!processing">
					<bundesmaster-ui-story-slide [index]="0" [slide]="slide" [storyType]="'match_story'" *ngIf="slide">
						<ng-container headerActions>
							<button mat-icon-button matTooltip="Edit slide CTA" (click)="editSlideCta()"><mat-icon>star</mat-icon></button>
							<button *ngIf="[slideTypes.TEXT, slideTypes.QUOTE].includes(slide?.value?.slideType)" mat-icon-button matTooltip="Replace slide image" (click)="editSlideImage()"><mat-icon>image</mat-icon></button>
							<button *ngIf="[slideTypes.TEXT, slideTypes.QUOTE].includes(slide?.value?.slideType)" mat-icon-button matTooltip="Crop slide image" (click)="cropSlideImage()"><mat-icon>crop</mat-icon></button>
							<button *ngIf="[slideTypes.VIDEO].includes(slide?.value?.slideType)" mat-icon-button matTooltip="Replace slide video" (click)="selectVideo()"><mat-icon>video_library</mat-icon></button>
						</ng-container>
					</bundesmaster-ui-story-slide>
				</ng-container>
			</div>
			<div class="col col-3">
				<h3><strong>Current Slide</strong></h3>
				<dfl-loader [loading]="processing" surrounding="large"></dfl-loader>

				<ng-container *ngIf="!processing">
					<ng-template #storyLoading>
						<dfl-loader [loading]="true" surrounding="large"></dfl-loader>
					</ng-template>
					<ng-template #storyUnavailable>
						<span>Story does not yet exist. When you submit the first slide, it will be created automatically</span>
					</ng-template>
					<ng-template #storySlideUnavailable>
						<span>Story exists. No slide for this liveticker entry yet.</span>
					</ng-template>

					<ng-container *ngIf="(storySlide$ | async) as slideData else storyLoading">
						<ng-container *ngIf="slideData === 'nostory'" [ngTemplateOutlet]="storyUnavailable"></ng-container>
						<ng-container *ngIf="slideData === 'noslide'" [ngTemplateOutlet]="storySlideUnavailable"></ng-container>
						<ng-container *ngIf="slideData !== 'nostory' && slideData !== 'noslide'">
							<bundesmaster-ui-story-slide [index]="0" [slide]="createSlideForm(slideData)" [storyType]="'match_story'" *ngIf="slideData">
								<ng-container headerActions></ng-container>
							</bundesmaster-ui-story-slide>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel [expanded]="false" *ngIf="story$ | async as storyInfo">
					<mat-expansion-panel-header>
						<mat-panel-title>Additional Information</mat-panel-title>
						<mat-panel-description>Most likely only needed for debugging 🧙‍♂️</mat-panel-description>
					</mat-expansion-panel-header>

					<div class="additional-infos" *ngIf="storyInfo">
						<p>storyId: {{ storyInfo.storyId || "-" }}</p>
						<p>last_publish_date: {{ storyInfo.last_publish_date || "-" }}</p>
						<p>modified_date: {{ storyInfo.modified_date || "-" }}</p>
						<p>created_date: {{ storyInfo.created_date || "-" }}</p>
						<p>published_by: {{ storyInfo.published_by || "-" }}</p>
						<p>scheduled_by: {{ storyInfo.scheduled_by || "-" }}</p>
						<p>scheduled_date: {{ storyInfo.scheduled_date || "-" }}</p>
						<p>approved_by: {{ storyInfo.approved_by || "-" }}</p>
					</div>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
	<button mat-button [disabled]="false" (click)="onDeleteClick()">Delete Slide</button>
	<button mat-button *ngIf="slide" [disabled]="slide.invalid" (click)="onEditClick()">Publish Slide</button>
</mat-dialog-actions>

<ng-template #imageControlButtons>
	<div class="container-fluid d-flex align-items-center justify-content-center">
		<button type="button" color="primary" aria-label="Select image" title="Select Image" mat-flat-button (click)="editSlideImage()">
			Select Image
			<mat-icon aria-label="select image">image</mat-icon>
		</button>
		<button *ngIf="slide?.controls?.image?.controls?.url?.value" type="button" color="primary" aria-label="Crop image" title="Crop Image" mat-flat-button (click)="cropSlideImage()">
			Crop Image
			<mat-icon aria-label="crop image">crop</mat-icon>
		</button>
	</div>
</ng-template>
