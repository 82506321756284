import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, defaultIfEmpty, map, of, shareReplay, startWith, tap } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../../bundesmaster-environment';
import { BUNDESLIGA_SEASONS } from '../../../models/bundesliga.season.model';
import { BundesmasterDataCollection, buildBundesmasterDataCollection, emptyBundesmasterDataCollection } from '../bundesmaster-data-collection';
import { BundesmasterPerson, BundesmasterPersonsLambdaResponse } from './bundesmaster-person';

export type BundesmasterPersonCollection<TItem extends BundesmasterPerson = BundesmasterPerson> = BundesmasterDataCollection<TItem>;

export interface PersonFilter {
	readonly query?: string;
	readonly clubId?: string;
	readonly seasonId?: string;
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterPersonApiService {
	private readonly baseUrl: string;

	private readonly resolvedPersons = new Map<string, Observable<BundesmasterPerson | null>>();

	constructor(private readonly http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987';
	}

	public buildCircleImageUrl({ mostRecentClub, personId }: BundesmasterPerson, seasonId: string = BUNDESLIGA_SEASONS.CURRENT, club = mostRecentClub): string {
		if (club === undefined) {
			return '/assets/placeholder/player-circle-default.png';
		}

		return `${this.environment.apis.assets.url}/player/${personId}-${club.clubId}-${seasonId}-circle.png`.toLowerCase();
	}

	public getPerson(personId: string): Observable<BundesmasterPerson | null> {
		if (typeof personId !== 'string') {
			return of(null);
		}
		if (!this.resolvedPersons.has(personId)) {
			const request = this.http.get<BundesmasterPerson>(`${this.baseUrl}/tags/persons/${personId}`).pipe(
				catchError(() => EMPTY),
				defaultIfEmpty<BundesmasterPerson, null>(null),
				shareReplay(1)
			);

			this.resolvedPersons.set(personId, request);
		}

		return this.resolvedPersons.get(personId);
	}

	public getPersons(filter: PersonFilter = {}, size = 30): Observable<BundesmasterPersonCollection> {
		let params = new HttpParams();
		params = params.set('size', size);

		(['query', 'clubId', 'seasonId'] as const).forEach((paramName) => {
			const value = (filter[paramName] ?? '').trim();
			if (value.length > 0) {
				params = params.set(paramName, value);
			}
		});

		return this.http.get<BundesmasterPersonsLambdaResponse>(`${this.baseUrl}/tags/persons`, { params }).pipe(
			tap(({ persons }) => persons.forEach((person) => this.resolvedPersons.set(person.personId, of(person)))),
			map(({ persons, partial }) => buildBundesmasterDataCollection(persons, partial)),
			startWith(emptyBundesmasterDataCollection<BundesmasterPerson>('loading')),
			shareReplay(1)
		);
	}
}
