import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { Observable, interval, map, startWith } from 'rxjs';

@Pipe({
	name: 'timeStampDistance'
})
export class TimeStampDistancePipe implements PipeTransform {
	transform(value: Date): Observable<string> {
		return interval(1000).pipe(
			startWith(0),
			map(() => formatDistanceToNow(value, { addSuffix: true, includeSeconds: true }))
		);
	}
}
