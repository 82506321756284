import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DflJwPlayerComponent } from './dfl-jw-player.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	imports: [SharedModule, CommonModule, CommonsModule],
	exports: [DflJwPlayerComponent],
	declarations: [DflJwPlayerComponent]
})
export class DflJwPlayerModule {}
