import { Pipe, PipeTransform } from '@angular/core';
import { countyNamesByCodeA2 } from '../../models/country.model';

@Pipe({
	name: 'resolveCountryName'
})
export class ResolveCountryNamePipe implements PipeTransform {
	transform(codeA2: string): string {
		return countyNamesByCodeA2.get(codeA2) ?? codeA2;
	}
}
