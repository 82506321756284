//eslint lines-around-directive: ["error", { "before": "always", "after": "never" }]
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BundesligaEmbedPlaceholder } from '@nx-bundesliga/models';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { Themes } from '@nx-bundesliga/bundesliga-com/services/theming';

@Component({
	selector: 'embed-tweet',
	template: '<div class="embed-tweet-wrapper" #embed></div' + '>',
	styleUrls: ['./embed-tweet.scss']
})
export class EmbedTweetComponent implements AfterViewInit {
	@Input() theme: Themes = 'light';
	@Input() id: string;
	@Input() embedUrl: string;

	@Input() placeholder: BundesligaEmbedPlaceholder;
	@Input() forceLoad = false;
	@ViewChild('embed', { static: true }) embed: ElementRef;

	constructor(private loader: ScriptLoaderService) {}

	ngAfterViewInit() {
		this.load();
	}

	public load() {
		this.loader.load('twitter', this.forceLoad)[0].subscribe(
			() => {
				if (typeof (window as any).twttr === 'object') {
					// Remove the placeholder from our elementRef, because the twitter SDKs doesn't.
					this.embed.nativeElement.innerHTML = '';
					if (!this.id && this.embedUrl) {
						this.id = this.getIdFromUrl(this.embedUrl);
					}
					if (this.id) {
						(window as any).twttr.widgets.createTweet(this.id, this.embed.nativeElement, {
							theme: this.theme
						});
					}
				}
			},
			(error) => {
				console.warn(`tweet.loader.error: ${error}`);
			}
		);
	}

	public getIdFromUrl(url: string) {
		if (url) {
			const found = url.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/i);
			return (found?.length >= 4 && found[3]) || null;
		} else {
			return null;
		}
	}
}
