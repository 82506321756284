import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LiveBlogClub, MatchLineup, MatchLineupPerson, MatchLineupStartingelevenPerson, PlayerBasicEvent, PlayerBasicEventList } from '@nx-bundesliga/models';
import { combineLatest, Subscription } from 'rxjs';
import { Competition, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { environment } from '../../../../../../environments/environment';
import { PersonService } from '../../../../../services/person/person.service';
import { StatsMatchService } from '../../../../../services/stats-match/stats-match.service';

@Component({
	selector: 'lineup-collection',
	templateUrl: './lineup-collection.component.html',
	styleUrls: ['./lineup-collection.component.scss']
})
export class LineupCollectionComponent implements OnInit, OnChanges, OnDestroy {
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() basicMatchEvents: any;
	@Input() homeClubUrlName: any;
	@Input() awayClubUrlName: any;
	@Input() home: LiveBlogClub;
	@Input() away: LiveBlogClub;
	@Input() lazyload = true;

	public language = 'de';
	public competition = 'bundesliga';
	public lineupData: MatchLineup;
	private lineupSubscription: Subscription;
	private storeSubscription: Subscription;
	public isVisibleHome = true;
	public isVisibleAway = true;

	public playerBasicMatchEvents: PlayerBasicEventList;
	public defaultPlayerImage = '/assets/placeholder/player-circle-default.png';

	public svgOffsetFactorX = 3.6;
	public svgOffsetFactorY = 4.84;
	public publicWebsite = environment.system.applicationUrl;

	constructor(private statsMatchService: StatsMatchService, private readonly cstore: Store<Competition>, private readonly lstore: Store<Language>, private personService: PersonService) {}

	ngOnInit(): void {
		combineLatest([this.lstore.pipe(select(getWorkingLanguage)), this.cstore.pipe(select(getWorkingCompetition))]).subscribe(([language, competition]) => {
			this.language = language.code;
			this.competition = competition.name;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.playerBasicMatchEvents = this.transformBasicMatchEventsToPlayerEvents();
		this.lineupSubscription = this.statsMatchService.getMatchLineup(this.competitionId, this.seasonId, this.matchdayId, this.matchId).subscribe(
			(success: MatchLineup) => {
				this.lineupData = success;
			},
			(error) => {
				console.log('Error subscribing to lineup:', error);
			}
		);
	}

	transformBasicMatchEventsToPlayerEvents(): PlayerBasicEventList {
		let playerBasicMatchEvents = { 'home': {}, 'away': {} };
		if (!this.basicMatchEvents) {
			return playerBasicMatchEvents;
		}
		for (const [side, eventList] of Object.entries(this.basicMatchEvents)) {
			for (const [eventKey, event] of Object.entries(eventList)) {
				if (event) {
					let playerId;
					let playerIdOut;
					let ownGoalSide = side === 'home' ? 'away' : 'home';

					if (event['entryType'] === 'yellowCard' || event['entryType'] === 'redCard' || event['entryType'] === 'yellowRedCard') {
						playerId = event['detail']['person']['dflDatalibraryObjectId'];
						if (!playerBasicMatchEvents[side][playerId]) {
							playerBasicMatchEvents[side][playerId] = this._getEmptyPlayerBasicEvent(playerId);
						}
						if (event['entryType'] === 'yellowCard') {
							playerBasicMatchEvents[side][playerId]['cards']['yellow'] = true;
						} else if (event['entryType'] === 'redCard') {
							playerBasicMatchEvents[side][playerId]['cards']['red'] = true;
						} else if (event['entryType'] === 'yellowRedCard') {
							playerBasicMatchEvents[side][playerId]['cards']['yellowRed'] = true;
						}
					} else if (event['entryType'] === 'sub') {
						/* sub in-and-out event */
						if (event['detail']['in']['dflDatalibraryObjectId'] === event['detail']['out']['dflDatalibraryObjectId']) {
							playerId = event['detail']['in']['dflDatalibraryObjectId'];
							if (!playerBasicMatchEvents[side][playerId]) {
								playerBasicMatchEvents[side][playerId] = this._getEmptyPlayerBasicEvent(playerId);
							}
							playerBasicMatchEvents[side][playerId]['sub'] = {
								'in': true,
								'out': true
							};
						} else {
							/* sub in event */
							playerId = event['detail']['in']['dflDatalibraryObjectId'];
							if (!playerBasicMatchEvents[side][playerId]) {
								playerBasicMatchEvents[side][playerId] = this._getEmptyPlayerBasicEvent(playerId);
							}
							playerBasicMatchEvents[side][playerId]['sub'] = {
								'in': true,
								'out': false
							};

							/* sub out event */
							playerIdOut = event['detail']['out']['dflDatalibraryObjectId'];
							if (!playerBasicMatchEvents[side][playerIdOut]) {
								playerBasicMatchEvents[side][playerIdOut] = this._getEmptyPlayerBasicEvent(playerIdOut);
							}
							playerBasicMatchEvents[side][playerIdOut]['sub'] = {
								'in': false,
								'out': true
							};
						}
					} else if (event['entryType'] === 'goal' || event['entryType'] === 'ownGoal') {
						playerId = event['detail']['scorer']['dflDatalibraryObjectId'];

						if (event['entryType'] === 'goal') {
							if (!playerBasicMatchEvents[side][playerId]) {
								playerBasicMatchEvents[side][playerId] = this._getEmptyPlayerBasicEvent(playerId);
							}
							if (playerBasicMatchEvents[side][playerId]['goals'] && !playerBasicMatchEvents[side][playerId]['goals']['goals']) {
								playerBasicMatchEvents[side][playerId]['goals']['goals'] = 0;
							}
							playerBasicMatchEvents[side][playerId]['goals']['goals']++;
						} else if (event['entryType'] === 'ownGoal') {
							ownGoalSide = side === 'home' ? 'away' : 'home';
							if (!playerBasicMatchEvents[ownGoalSide][playerId]) {
								playerBasicMatchEvents[ownGoalSide][playerId] = this._getEmptyPlayerBasicEvent(playerId);
							}
							if (playerBasicMatchEvents[ownGoalSide][playerId]['goals'] && !playerBasicMatchEvents[ownGoalSide][playerId]['goals']['ownGoals']) {
								playerBasicMatchEvents[ownGoalSide][playerId]['goals']['ownGoals'] = 0;
							}
							playerBasicMatchEvents[ownGoalSide][playerId]['goals']['ownGoals']++;
						}
					}
				}
			}
		}
		playerBasicMatchEvents = this._addSvgImageOffsetsX(playerBasicMatchEvents);
		return playerBasicMatchEvents;
	}

	private _getEmptyPlayerBasicEvent(playerId: string): PlayerBasicEvent {
		const playerBasicEvent = {
			'sub': {},
			'goals': {},
			'cards': {},
			'personId': playerId,
			'svgImageOffsetsX': {}
		} as PlayerBasicEvent;
		return playerBasicEvent;
	}

	private _playerHasGoalsAndOwngoals(playerBasicMatchEvents: PlayerBasicEvent) {
		return playerBasicMatchEvents['goals'] && playerBasicMatchEvents['goals']['goals'] >= 1 && playerBasicMatchEvents['goals']['ownGoals'] >= 1;
	}

	private _addSvgImageOffsetsX(playerBasicMatchEvents: PlayerBasicEventList): PlayerBasicEventList {
		for (const [side, playerBasicMatch] of Object.entries(playerBasicMatchEvents)) {
			for (const [playerId, playerIcons] of Object.entries(playerBasicMatch)) {
				playerBasicMatchEvents[side][playerId] = this._addSvgImageOffsetsXToIcons(playerBasicMatchEvents[side][playerId]);
				playerBasicMatchEvents[side][playerId]['personId'] = playerId;
			}
		}
		return playerBasicMatchEvents;
	}

	private _addSvgImageOffsetsXToIcons(playerBasicMatchEvents: PlayerBasicEvent): PlayerBasicEvent {
		const lengthGoals = Object.keys(playerBasicMatchEvents['goals']).length;
		const lengthSubs = Object.keys(playerBasicMatchEvents['sub']).length;
		const lengthCards = Object.keys(playerBasicMatchEvents['cards']).length;

		playerBasicMatchEvents['svgImageOffsetsX'] = {
			'sub': 11,
			'goals': 11,
			'cards': 11
		};

		if (lengthSubs > 0 && lengthGoals > 0 && lengthCards > 0) {
			if (this._playerHasGoalsAndOwngoals(playerBasicMatchEvents)) {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'sub': 31,
					'goals': 13,
					'cards': -9
				};
			} else {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'sub': 31,
					'goals': 11,
					'cards': -9
				};
			}
		} else if (lengthSubs > 0 && lengthGoals > 0) {
			if (this._playerHasGoalsAndOwngoals(playerBasicMatchEvents)) {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'sub': 21,
					'goals': 3
				};
			} else {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'sub': 21,
					'goals': 1
				};
			}
		} else if (lengthSubs > 0 && lengthCards > 0) {
			playerBasicMatchEvents['svgImageOffsetsX'] = {
				'sub': 21,
				'cards': 1
			};
		} else if (lengthGoals > 0 && lengthCards > 0) {
			if (this._playerHasGoalsAndOwngoals(playerBasicMatchEvents)) {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'goals': 23,
					'cards': 1
				};
			} else {
				playerBasicMatchEvents['svgImageOffsetsX'] = {
					'goals': 21,
					'cards': 1
				};
			}
		}
		return playerBasicMatchEvents;
	}

	errorHandlerHTMLImg(event) {
		event.target.src = this.defaultPlayerImage;
	}

	errorHandlerSVGImage(event) {
		event.target.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', this.defaultPlayerImage);
	}

	public getPlayerAliasFirstName(playerName: string): string {
		return this.personService.getPersonFirstNameByFullname(playerName);
	}

	public getPlayerAliasLastName(playerName: string): string {
		return this.personService.getPersonLastNameByFullname(playerName);
	}

	public getPlayerAliasTruncated(playerName: string): string {
		const maxLength = 12;
		const delimiter = '...';
		return this.personService.getPersonAliasTruncatedByFullname(playerName, maxLength, delimiter);
	}

	ngOnDestroy(): void {
		if (this.lineupSubscription) {
			this.lineupSubscription.unsubscribe();
		}
		if (this.storeSubscription) {
			this.storeSubscription.unsubscribe();
		}
	}

	trackByPlayerId(index, startingPlayer: MatchLineupStartingelevenPerson | MatchLineupPerson): string {
		return startingPlayer.dflDatalibraryObjectId;
	}

	/*public setVisibleSide(side: string) {
    if (side && side === 'away') {
      this.isVisibleHome = false;
      this.isVisibleAway = true;
    } else {
      this.isVisibleHome = true;
      this.isVisibleAway = false;
    }
  }*/
	public setVisibleSide(side: string) {
		if (side && side === 'away') {
			this.isVisibleAway = !this.isVisibleAway;
		} else {
			this.isVisibleHome = !this.isVisibleHome;
		}
	}
}
