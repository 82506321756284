import { Countdown } from '@nx-bundesliga/models';
import { LiveBlogAutoEvents, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';

/**
 *
 * @returns {any}
 */
export function getGradient(side: LivetickerSides, clubData: LiveBlogClubs): { start; end } {
	if (!clubData || !side || side === 'none') {
		return { start: '#4c4c4c', end: '#4c4c4c' };
	}

	return {
		start: clubData[side].gradientStartColor,
		end: clubData[side].gradientEndColor
	};
}

/**
 * Returns the text color associated with this cards club.
 * If it's a neutral event.
 *
 * @returns {string}
 */
export function getTextColor(side: LivetickerSides, clubData: LiveBlogClubs): string {
	let color: string;
	if (!clubData || !side || side === 'none') {
		color = '#fff';
	} else {
		color = clubData[side].textColor;
	}

	return color;
}

/**
 * Returns the text color class associated with this events club.
 * If it's a neutral event.
 *
 * @returns {string}
 */
export function getTextColorClass(side: LivetickerSides, clubData: LiveBlogClubs): string {
	let color: string;
	if (!clubData || !side || side === 'none') {
		color = 'text_black';
	} else {
		color = clubData[side].textColor === '#FFFFFF' ? 'text_white' : 'text_black';
	}

	return color;
}

/**
 * Returns the background property as object, containing the team specific gradient.
 * @param side
 * @param clubData
 */
export function getBackgroundColor(side: LivetickerSides, clubData: LiveBlogClubs): string {
	const { start, end } = getGradient(side, clubData);
	return start;
}

/**
 * Switches the associated side of an event.
 * None stays none.
 *
 * @param side
 */
function switchSides(side: LivetickerSides): LivetickerSides {
	switch (side) {
		case 'away':
			return 'home';

		case 'home':
			return 'away';

		default:
			return 'none';
	}
}

/**
 *
 * @param {LiveBlogAutoEvents} liveEntry
 * @param {string} prefix
 * @returns {string}
 */
export function getSide(liveEntry: LiveBlogAutoEvents, prefix = ''): string {
	let side = liveEntry.side as LivetickerSides;

	// Overwrite certain entryTypes here to adhere to current business-logic
	if (!side || ['image', 'embed'].includes(liveEntry.entryType)) {
		side = 'none';
	}

	// Overwrite the side, if the event is an ownGoal!
	// maybe also update the liveEntry object??
	if (liveEntry.entryType === 'ownGoal') {
		side = switchSides(side);
	}

	// Remove sides if the game hasn't started yet
	if (liveEntry.matchSection === 'PRE_MATCH') {
		side = 'none';
	}

	return prefix + side;
}

/**
 *
 * @param {LivetickerSides} side
 * @param {LiveBlogClubs} clubData
 * @returns string
 */
export function getNodgeColor(side: LivetickerSides, clubData: LiveBlogClubs): string {
	let color: string;
	if (!clubData || !side || side === 'none') {
		color = 'transparent';
	} else {
		color = clubData[side].gradientStartColor;
	}
	return color;
}

/**
 *
 * @param seconds
 * @param milliseconds
 * @param trailing
 */
export function secondsToCountdown(seconds: number, milliseconds = false, trailing = true): Countdown {
	const t = milliseconds ? seconds / 1000 : seconds;
	return {
		days: Math.max(Math.floor(t / (60 * 60 * 24)), 0)
			.toString()
			.padStart(2, '0'),
		hours: Math.max(Math.floor((t / (60 * 60)) % 24), 0)
			.toString()
			.padStart(2, '0'),
		minutes: Math.max(Math.floor((t / 60) % 60), 0)
			.toString()
			.padStart(2, '0'),
		seconds: Math.max(Math.floor(t % 60), 0)
			.toString()
			.padStart(2, '0')
	};
}
