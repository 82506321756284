import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { LlmImageTagEnum, llmImageTagQuestions } from '@nx-bundesliga/bundesmaster/shared';
import { Subject } from 'rxjs';

export interface LlmTagDialogInput {
	readonly selected: readonly LlmImageTagEnum[];
}

export interface LlmTagDialogOutput {
	readonly selected: readonly LlmImageTagEnum[];
}

@Component({
	selector: 'bundesmaster-ui-llm-tag-dialog',
	standalone: true,
	imports: [CommonModule, FormsModule, MatButtonModule, MatDialogModule, MatListModule],
	templateUrl: './bundesmaster-ui-llm-tag-dialog.component.html',
	styleUrls: ['./bundesmaster-ui-llm-tag-dialog.component.scss']
})
export class BundesmasterUiLlmTagDialogComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();

	public selectedLlmTags = new Set<LlmImageTagEnum>();

	public readonly possibleLlmTags = new Set<LlmImageTagEnum>(llmImageTagQuestions.map(({ tag }) => tag).sort());

	constructor(public dialogRef: MatDialogRef<BundesmasterUiLlmTagDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: LlmTagDialogInput) {}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit(): void {
		this.selectedLlmTags = new Set(this.data?.selected ?? []);
	}

	selectionChange(event: MatSelectionListChange): void {
		event.options.forEach((option) => (option.selected ? this.selectedLlmTags.add(option.value) : this.selectedLlmTags.delete(option.value)));
	}

	public add() {
		const output: LlmTagDialogOutput = { selected: [...this.selectedLlmTags.values()] };
		this.dialogRef.close(output);
	}

	public cancel() {
		this.dialogRef.close(null);
	}
}
