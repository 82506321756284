<div class="bar-chart" [ngClass]="{'horizontal': horizontal, 'vertical': !horizontal, 'chips': chips, 'single': single}">
	<span class="title" *ngIf="single === false">{{title}}</span>
	<div class="bars">
		<div class="bar left" [ngStyle]="{'backgroundColor': leftColor, 'width': leftWidth}" [ngClass]="{'surround-with-border': leftColor.toLowerCase() === '#ffffff'}">
			<span class="title" *ngIf="single === true" [ngStyle]="{'color': leftTextColor}">{{title}}</span>
			<span class="value left" [ngStyle]="{'color': leftTextColor}">
				{{leftValue}}
				<ng-container *ngIf="leftUnit">{{leftUnit}}</ng-container>
			</span>
		</div>
		<div *ngIf="single === false" class="bar right" [ngStyle]="{'backgroundColor': rightColor, 'width': rightWidth}" [ngClass]="{'surround-with-border': rightColor.toLowerCase() === '#ffffff'}">
			<span class="value right" [ngStyle]="{'color': rightTextColor}">
				{{rightValue}}
				<ng-container *ngIf="rightUnit">{{rightUnit}}</ng-container>
			</span>
		</div>
	</div>
</div>
