import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	public loading$ = new ReplaySubject<boolean>(1);

	constructor() {
		this.loading$.next(false);
	}

	public setLoading(status: boolean): void {
		console.log('LoadingService.setLoading', status);
		this.loading$.next(status);
	}
}
