<div class="keeperefficiency-card d-flex align-items-center justify-content-center flex-column flex-md-row">
	<div class="efficiencyGeneral d-flex flex-row align-items-center justify-content-center justify-content-md-between">
		<img
			*ngIf="person"
			class="player-image"
			[ngClass]="{'no-playerImage': !person?.imageUrl}"
			[dflLazyLoad]="(person.imageUrl | playerImage: 'PORTRAIT_S')  | imageService:0:180"
			[alt]="person.name"
			defaultImage="/assets/placeholder/player-portrait-default.svg"
			errorImage="/assets/placeholder/player-portrait-default.svg"
			src="/assets/placeholder/player-portrait-default.svg"
			offset="200" />

		<div class="efficiency">
			<span class="triangle" [ngClass]="{'down': savedShots - xSaves < 0}"></span>
			<span>{{ savedShots - xSaves | number:"1.0-1" }}</span>
		</div>
	</div>

	<div class="efficiencyDetails d-flex flex-column">
		<div class="savedShots">
			<span>{{"LIVETICKER.EVENTS.keeperEfficiency.savedShots" | translate}}</span>
			<span>{{savedShots | number:"1.0-1"}}</span>
		</div>
		<div class="expectedSaves">
			<span>{{"LIVETICKER.EVENTS.keeperEfficiency.xSaves" | translate}}</span>
			<span>{{xSaves | number:"1.0-1"}}</span>
		</div>
	</div>
</div>
