<div class="container" id="fixtures">
	<div class="row">
		<div class="col-12">
			<page-title>Custom Matches | Language {{language}}</page-title>
		</div>
	</div>
	<div class="row">
		<div class="col-12 d-flex flex-column flex-md-row justify-content-center">
			<button mat-raised-button [color]="'primary'" class="full-width" [routerLink]="'/create-custom-match'">Create Custom Match</button>
		</div>
		<div class="col-12" style="height: 32px"></div>
	</div>
	<div class="row">
		<div class="col-12 d-flex flex-column flex-md-row justify-content-center">
			<div class="fixtures-dropdown-wrapper" *ngIf="availableLanguages && availableLanguages.length > 1">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="language" (selectionChange)="onChangeLanguage(language)" [disableOptionCentering]="true">
						<mat-option *ngFor="let langOption of availableLanguages" [value]="langOption">{{langOption}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<div class="fixturesWrapper">
				<mat-form-field>
					<mat-label>Filter</mat-label>
					<input matInput (keyup)="applyFilter($event)" placeholder="search for teamname (home or away)" #filterinput />
				</mat-form-field>
				<table mat-table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="plannedKickOff">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>plannedKickOff</th>
						<td mat-cell *matCellDef="let match"><span class="kickoffDatetime">{{ match.plannedKickOff | date: 'EEEE, dd-MMM-y | H:mm ' : undefined : language}}</span></td>
					</ng-container>
					<ng-container matColumnDef="match">
						<th mat-header-cell *matHeaderCellDef>match</th>
						<td mat-cell *matCellDef="let match">
							<div class="matchDataRow" id="fixtures_{{match.dflDatalibraryMatchId}}">
								<div class="teamHome clubHome">
									<a class="matchcenterLink">
										<div class="clubName">{{match.teams.home.nameFull}}</div>
										<div class="clubNameShort">{{match.teams.home.nameShort}}</div>
										<div class="clubLogo">
											<clublogo [dimension]="40" [title]="match.teams.home.nameFull" [clubLogoUrl]="match.teams.home.logoUrl"></clublogo>
										</div>
									</a>
								</div>

								<div class="matchResults text-center">
									<a class="matchcenterLink">
										<div *ngIf="match.score; then tplScore else tplNoScore"></div>
										<ng-template #tplScore>
											<div class="score">
												<div *ngIf="isLiveMatch(match) === true" class="isLiveWrapper">
													<live-animation *ngIf="match.minuteOfPlay && match.minuteOfPlay.minute && match.minuteOfPlay.minute >= 0" [playtime]="match.minuteOfPlay" [matchStatus]="match.matchStatus" layout="regular"></live-animation>
												</div>
												<div *ngIf="isLiveMatch(match) === false" class="isNotLiveWrapper"></div>
												<span class="scoreLive">
													<span>{{ match.score.home.live }}</span>
													:
													<span>{{ match.score.away.live }}</span>
												</span>
												<span *ngIf="isLiveMatch(match) === false" class="scoreHt">
													(
													<span>{{ match.score.home.halftime }}</span>
													:
													<span>{{ match.score.away.halftime }}</span>
													)
												</span>
											</div>
										</ng-template>
										<ng-template #tplNoScore>
											<div class="score">
												<div class="scoreDefault">
													<span>-</span>
													:
													<span>-</span>
												</div>
											</div>
										</ng-template>
										<div class="stadium-name" *ngIf="match.stadiumName">{{match.stadiumName}}</div>
									</a>
								</div>

								<div class="teamAway clubAway">
									<a class="matchcenterLink">
										<div class="clubLogo">
											<clublogo [dimension]="40" [title]="match.teams.away.nameFull" [clubId]="match.teams.away.dflDatalibraryClubId" [clubLogoUrl]="match.teams.away.logoUrl"></clublogo>
										</div>
										<div class="clubName">{{match.teams.away.nameFull}}</div>
										<div class="clubNameShort">{{match.teams.away.nameShort}}</div>
									</a>
								</div>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef>actions</th>
						<td mat-cell *matCellDef="let match">
							<div class="d-flex align-items-center justify-content-around">
								<a [routerLink]="['/', 'create-custom-match', language, match.matchId]" mat-raised-button color="primary" class="">Edit</a>
								<a [routerLink]="['/', 'custom-match', language, match.dflDatalibraryCompetitionId, match.dflDatalibrarySeasonId, match.dflDatalibraryMatchdayId, match.matchId]" mat-raised-button color="primary" class="">Liveticker</a>
							</div>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="['plannedKickOff', 'match', 'actions']"></tr>
					<tr mat-row *matRowDef="let row; columns: ['plannedKickOff', 'match', 'actions'];"></tr>

					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell text-center" colspan="3" *ngIf="dataSource?.data?.length > 0"><h3>No data matching the filter "{{filterinput.value}}"</h3></td>
						<td class="mat-cell text-center" colspan="3" *ngIf="dataSource?.data?.length === 0"><h4>No matches yet</h4></td>
					</tr>
				</table>
				<dfl-loader [loading]="loading"></dfl-loader>
				<mat-paginator class="paginator" [showFirstLastButtons]="true" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]" [hidePageSize]="false" aria-label="Select page"> </mat-paginator>
			</div>
		</div>
	</div>
</div>
