<div class="toolbar">
	<div class="row">
		<div class="item">
			<div class="btn-group-container">
				<div class="btn-group clublogos">
					<a [href]="publicWebsite + '/' + ([language, match.dflDatalibraryCompetitionId | toCompetition, 'route-clubs',(match.teams?.home?.dflDatalibraryClubId | toClub | async)?.name?.slugifiedFull, 'route-squad'].join('/') | translateRoute)" mat-flat-button target="_blank">
						<clublogo [clubLogoUrl]="match.teams?.home?.logoUrl | imageService : 60"></clublogo>
					</a>
					<a [href]="'https://twitter.com/search?q=%23' + match.teams?.home?.threeLetterCode + match.teams?.away?.threeLetterCode + '&src=typed_query'" class="single" mat-flat-button target="_blank">#{{ match.teams?.home?.threeLetterCode }}{{ match.teams?.away?.threeLetterCode }}</a>
					<a [href]="publicWebsite + '/' + ([language, match.dflDatalibraryCompetitionId | toCompetition, 'route-clubs', (match.teams?.away?.dflDatalibraryClubId | toClub | async)?.name?.slugifiedFull, 'route-squad'].join('/') | translateRoute)" mat-flat-button target="_blank">
						<clublogo [clubLogoUrl]="match.teams?.away?.logoUrl | imageService : 60"></clublogo>
					</a>
				</div>
			</div>
		</div>

		<div class="item" *ngIf="!customMatch">
			<div class="btn-group-container">
				<span class="icon">
					<mat-icon>laptop_chrome</mat-icon>
					<!--<span class="label">WEB</span>-->
				</span>
				<div class="btn-group" ngxTippy="Open liveticker on WEB">
					<a [href]="openWebTicker(language)" mat-flat-button target="_blank">{{language | uppercase}}</a>
				</div>
			</div>

			<div class="btn-group-container">
				<span class="icon">
					<mat-icon>phone_iphone</mat-icon>
					<!--<span class="label">APP</span>-->
				</span>
				<div class="btn-group" ngxTippy="Open liveticker on WEBVIEW">
					<a [href]="openAppTicker(language)" mat-flat-button target="_blank">{{language | uppercase}}</a>
				</div>
			</div>
		</div>

		<div class="item firebaseLink">
			<div class="btn-group-container">
				<div class="btn-group" ngxTippy="Open Firebase Mobile Console">
					<a
						mat-flat-button
						target="_blank"
						[href]="'https://console.firebase.google.com/project/bundesliga-official/database/' + firebaseStageMobile + '/data/' + language + '/' + (customMatch ? ['custom', 'matches',  match.matchId] : [match.dflDatalibraryCompetitionId, 'seasons', match.dflDatalibrarySeasonId, 'matchdays', match.dflDatalibraryMatchdayId, match.dflDatalibraryMatchId]).join('/')">
						<img src="/assets/logo/firebase.svg" height="22px" />
						FB Mobile
					</a>
				</div>
				<div class="btn-group" ngxTippy="Open Firebase Web Console">
					<a
						mat-flat-button
						target="_blank"
						[href]="'https://console.firebase.google.com/project/bundesliga-web-official/database/' + firebaseStage + '/data/' + language + '/' + (customMatch ? ['custom', 'matches',  match.matchId] : [match.dflDatalibraryCompetitionId, 'seasons', match.dflDatalibrarySeasonId, 'matchdays', match.dflDatalibraryMatchdayId, match.dflDatalibraryMatchId]).join('/')">
						<img src="/assets/logo/firebase.svg" height="22px" />
						FB Web
					</a>
				</div>
			</div>
		</div>

		<div class="item" *ngIf="!customMatch">
			<div class="btn-group-container">
				<span class="icon">
					<mat-icon>live_tv</mat-icon>
				</span>
				<div class="btn-group" ngxTippy="Open Livestream in seperate window" [matMenuTriggerFor]="menuLivestream">
					<button mat-flat-button>Stream</button>
				</div>
				<mat-menu #menuLivestream="matMenu">
					<button (click)="openLivestream()" ngxTippy="Stream via HLS (max 30sec delay)" mat-menu-item>
						<mat-icon>live_tv</mat-icon>
						HLS
					</button>
					<button ngxTippy="Direct stream is not yet available" mat-menu-item>
						<mat-icon>live_tv</mat-icon>
						Direct (coming soon)
					</button>
				</mat-menu>
			</div>
		</div>

		<bl-sts-feed-controls [matchId]="match.dflDatalibraryMatchId" *ngIf="!customMatch"></bl-sts-feed-controls>

		<div class="item settingMenuLink" *ngIf="!customMatch">
			<div class="btn-group-container">
				<bl-ticker-matchsettings-controls [match]="match" [language]="language"></bl-ticker-matchsettings-controls>
			</div>
		</div>

		<div class="item" *ngIf="cards">
			<div class="btn-group-container infoIcon" [ngxTippy]="cardsTmpl" [tippyProps]="{interactive: true, interactiveBorder: 20, arrow: true, placement: 'bottom'}">
				<span class="icon">
					<mat-icon>class</mat-icon>
				</span>
				<div class="btn-group">
					<a mat-flat-button>
						<span *ngIf="cards.hasOwnProperty('home') && cards.hasOwnProperty('away')">{{ cards.home?.length }} | {{ cards.away?.length }}</span>
					</a>
				</div>
			</div>
			<div class="info" #cardsTmpl>
				<div class="container-fluid">
					<div class="row">
						<div class="col-6 text-center border-right font-weight-bold">Home</div>
						<div class="col-6 text-center border-left font-weight-bold">Away</div>
					</div>
					<div class="row">
						<div class="col-6 border-right">
							<p *ngFor="let card of cards.home">
								<img [src]="card.detail?.person?.imageUrl" height="100" width="100" alt="" class="d-flex mx-auto" />
								<span class="d-block">
									<img class="card align-bottom" [src]="'/assets/liveticker/' + card.entryType + '.svg'" />
									{{card.detail.person.name}} ({{card.playtime.minute}}
									<ng-container *ngIf="card.playtime.injuryTime">' +{{card.playtime.injuryTime}}</ng-container>
									)
								</span>
							</p>
						</div>
						<div class="col-6 border-left">
							<p *ngFor="let card of cards.away">
								<img [src]="card.detail?.person?.imageUrl" height="100" width="100" alt="" class="d-flex mx-auto" />
								<span class="d-block">
									<img class="card align-bottom" [src]="'/assets/liveticker/' + card.entryType + '.svg'" />
									{{card.detail.person.name}} ({{card.playtime?.minute}}
									<ng-container *ngIf="card.playtime?.injuryTime">' +{{card.playtime?.injuryTime}}</ng-container>
									)
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="item" *ngIf="score && score.home && score.away">
			<div class="btn-group-container infoIcon" [ngxTippy]="goalsTmpl" [tippyProps]="{interactive: true, interactiveBorder: 20, arrow: true, placement: 'bottom'}">
				<span class="icon">
					<mat-icon>adjust</mat-icon>
				</span>
				<div class="btn-group">
					<a mat-flat-button>
						<span class="score" *ngIf="score.home?.hasOwnProperty('live') && score.away?.hasOwnProperty('live')">{{ score.home?.live }} : {{ score.away?.live }}</span>
						<span class="halftime" *ngIf="!!score.home?.hasOwnProperty('halftime') && !!score.away?.hasOwnProperty('halftime')">({{ score.home?.halftime }} : {{ score.away?.halftime }})</span>
					</a>
				</div>
				<div class="info" #goalsTmpl>
					<div class="container-fluid">
						<div class="row">
							<div class="col-6 text-center border-right font-weight-bold">
								Home
								<img class="card" [src]="'/assets/liveticker/goal.svg'" />
							</div>
							<div class="col-6 text-center border-left font-weight-bold">
								Away
								<img class="card" [src]="'/assets/liveticker/goal.svg'" />
							</div>
						</div>
						<div class="row">
							<div class="col-6 border-right">
								<p *ngFor="let goal of goals.home">
									<img [src]="goal.detail?.scorer?.imageUrl" height="100" width="100" alt="" class="d-flex mx-auto" />
									<span class="d-block">
										{{goal.detail?.score?.home}}:{{goal.detail?.score?.away}} {{goal.detail?.scorer?.name}} ({{goal.playtime?.minute}}
										<ng-container *ngIf="goal.playtime?.injuryTime">' +{{goal.playtime?.injuryTime}}</ng-container>
										)
									</span>
								</p>
							</div>
							<div class="col-6 border-left">
								<p *ngFor="let goal of goals.away">
									<img [src]="goal.detail?.scorer?.imageUrl" height="100" width="100" alt="" class="d-flex mx-auto" />
									<span class="d-block">
										{{goal.detail?.score?.home}}:{{goal.detail?.score?.away}} {{goal.detail?.scorer?.name}} ({{goal.playtime?.minute}}
										<ng-container *ngIf="goal.playtime?.injuryTime">' +{{goal.playtime?.injuryTime}}</ng-container>
										)
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="item" *ngIf="minuteOfPlay">
			<div class="btn-group-container">
				<span class="icon">
					<mat-icon>access_time</mat-icon>
				</span>
				<div class="btn-group">
					<a mat-flat-button>
						{{ minuteOfPlay.minute }}'
						<span *ngIf="minuteOfPlay.injuryTime">+{{ minuteOfPlay.injuryTime }}</span>
					</a>
				</div>
			</div>
		</div>

		<div class="item" *ngIf="match.matchStatus">
			<div class="btn-group-container">
				<span class="icon">
					<mat-icon>outlined_flag</mat-icon>
				</span>
				<div class="btn-group">
					<a mat-flat-button>{{match.matchStatus}}</a>
				</div>
			</div>
		</div>

		<div class="item customMatchProgressLink" *ngIf="customMatch">
			<div class="btn-group-container">
				<div class="btn-group">
					<a mat-flat-button target="_blank" (click)="openCustomMatchProgressDialog()">
						<mat-icon>build</mat-icon>
					</a>
				</div>
			</div>
		</div>

		<div class="item">
			<bl-ticker-typing-controls (isTypingChange)="setIsTyping($event)" [typing]="match.liveBlogInfos"></bl-ticker-typing-controls>
		</div>

		<div class="item">
			<div class="btn-group-container">
				<div class="btn-group">
					<a (click)="setLayout('toggle_controls')" mat-flat-button ngxTippy="Show/Hide controls">
						<mat-icon>details</mat-icon>
					</a>
					<a (click)="setLayout('reset_areaconfig')" mat-flat-button ngxTippy="Reset to default">
						<mat-icon>refresh</mat-icon>
					</a>
					<a (click)="setLayout('add_col')" mat-flat-button ngxTippy="Add Column">
						<mat-icon>playlist_add</mat-icon>
					</a>
					<a (click)="setLayout('direction')" mat-flat-button ngxTippy="Toggle horizontal/vertical">
						<mat-icon>dehaze</mat-icon>
					</a>
					<a (click)="setLayout('toggle_ticker')" mat-flat-button ngxTippy="Expand first column (toggle)">
						<mat-icon>keyboard_tab</mat-icon>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
