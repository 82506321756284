import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { BUNDESLIGA_SEASONS } from '../../models/bundesliga.season.model';
import { ContenderRawPerson } from './contender-raw-person';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterWeizenRawPersonApiService {
	private readonly baseUrl: string;

	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987';
	}

	public getRawPlayers(season: string = BUNDESLIGA_SEASONS.CURRENT): Observable<ContenderRawPerson[]> {
		return this.http.get<ContenderRawPerson[]>(`${this.baseUrl}/weizen/persons-raw-data/${season}`).pipe(map((players) => players.filter((player) => player.active)));
	}
}
