<mat-form-field>
	<mat-label>Player</mat-label>
	<mat-hint *ngIf="hasValue && formControl.value | resolvePersonById | async as resolvedPerson">
		<ng-container *ngIf="resolvedPerson">
			<img class="player-image" [dflLazyLoad]="getPersonFaceImageUrl(resolvedPerson)" [scrollTarget]="personsScrolltarget?.panel?.nativeElement ?? undefined" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" width="12" height="12" />
			<ng-container *ngIf="!resolvedPerson.aliasName">{{resolvedPerson.displayName}}</ng-container>
			<ng-container *ngIf="!!resolvedPerson.aliasName">{{resolvedPerson.aliasName}} ({{resolvedPerson.displayName}})</ng-container>
		</ng-container>
	</mat-hint>
	<mat-spinner matSuffix *ngIf="persons.status === 'loading'" [diameter]="15"></mat-spinner>
	<input matInput type="text" [formControl]="formControl" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" #personsScrolltarget (optionSelected)="optionSelected($event)">
		<mat-option *ngFor="let person of persons.items" [value]="person.personId">
			<div class="d-flex flex-row align-items-center option-padding gap">
				<img class="player-image" [dflLazyLoad]="getPersonFaceImageUrl(person)" [scrollTarget]="personsScrolltarget?.panel?.nativeElement ?? undefined" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" width="48" height="48" />

				<div class="d-flex flex-column">
					<div *ngIf="!person.aliasName">{{person.displayName}}</div>
					<div *ngIf="!!person.aliasName">
						{{person.aliasName}}
						<small>({{person.displayName}})</small>
					</div>
					<small>{{person.firstActiveYear}} - {{person.lastActiveYear}} | {{joinClubs(person)}} | {{person.personId}}</small>
				</div>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
