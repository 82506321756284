<mat-card appearance="outlined">
	<div class="">
		<mat-card-header>
			<mat-card-title>Please select team affiliation</mat-card-title>
			<mat-card-subtitle>
				<mat-checkbox color="primary" [(ngModel)]="sendTypingStatus" [ngModelOptions]="{standalone: true}">also set typing indicator now</mat-checkbox>
			</mat-card-subtitle>
		</mat-card-header>

		<mat-card-content class="simple">
			<div class="content">
				<div class="side home">
					<button (click)="emitSelection('home')" mat-flat-button color="primary">
						<mat-icon>keyboard_arrow_left</mat-icon>
						HOME
					</button>
				</div>
				<div class="side neutral">
					<button (click)="emitSelection('none')" mat-flat-button color="primary">
						<mat-icon>keyboard_arrow_up</mat-icon>
						NEUTRAL
					</button>
				</div>
				<div (click)="emitSelection('away')" class="side away">
					<button mat-flat-button color="primary">
						<mat-icon>keyboard_arrow_right</mat-icon>
						AWAY
					</button>
				</div>
			</div>
			<p class="hint">
				Hint: You can use your
				<strong>[CURSOR KEYS]</strong>
				to quickly make a decision
			</p>
		</mat-card-content>
	</div>
</mat-card>
