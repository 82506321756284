import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { DflJwPlayerModule } from '@nx-bundesliga/bundesliga-com/components/dfl-jw-player';
import { BlCustomMatchProgressDialogComponent } from './bl-custom-match-progress-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';

@NgModule({
	declarations: [BlCustomMatchProgressDialogComponent],
	exports: [BlCustomMatchProgressDialogComponent],
	imports: [CommonsModule, CommonModule, MatIconModule, MatButtonModule, MatDialogModule, DflJwPlayerModule, FormsModule, MatProgressSpinnerModule, MatInputModule, MatSelectModule]
})
export class BlCustomMatchProgressDialogModule {}
