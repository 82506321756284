import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlRapidTickerSideChooserComponent } from './bl-rapid-ticker-side-chooser.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [BlRapidTickerSideChooserComponent],
	exports: [BlRapidTickerSideChooserComponent],
	imports: [CommonModule, FormsModule, MatCardModule, MatButtonModule, MatIconModule, MatCheckboxModule]
})
export class BlRapidTickerSideChooserModule {}
