<mat-dialog-content class="mat-typography">
	<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" [preserveContent]="true" [dynamicHeight]="true">
		<mat-tab label="Media Library">
			<nx-bundesliga-media-library-overview (assetClicked)="imageSelected($event)"></nx-bundesliga-media-library-overview>
		</mat-tab>
		<mat-tab label="Upload">
			<bundesmaster-ui-upload-zone [disabled]="!!file" [multiple]="false" accept="image/*" (fileAdded)="fileSelected($event)"></bundesmaster-ui-upload-zone>
			<bundesmaster-ui-upload-item [closeable]="true" [file]="file" *ngIf="file" (uploaded)="fileUploaded($event)"></bundesmaster-ui-upload-item>
		</mat-tab>
	</mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end" [formGroup]="form" *ngIf="data?.hideDimensions !== true">
	<div class="dimensions" formArrayName="dimensions">
		<ng-container *ngFor="let dimension of dimensions | keyvalue; let i = index;">
			<mat-checkbox *ngIf="form.controls.dimensions.length >= i -1" [formControlName]="i">
				{{dimension.key}}
				<mat-hint>({{dimension.value.width}}x{{dimension.value.height}})</mat-hint>
			</mat-checkbox>
		</ng-container>
	</div>
	<button mat-button [matDialogClose]="null">Close</button>
</mat-dialog-actions>
