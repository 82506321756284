import { Injectable } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

export interface MatchInfoBasic {
	competitionId: string;
	seasonId: string;
	matchdayId: string;
	matchId: string;
	matchdayNumber: number;
}

@Injectable()
export class DflDatalibraryService {
	public competitionSettings: any;
	public competitionId = 'DFL-COM-000001';

	constructor(public configService: ConfigService) {
		this.CompetitionSettings = this.competitionId;
	}

	set CompetitionSettings(competitionId) {
		this.competitionSettings = this.configService.getSettings(competitionId);
	}

	get CompetitionSettings(): string {
		return this.competitionSettings;
	}

	get CompetitionId(): string {
		return this.competitionId;
	}

	set CompetitionId(value: string) {
		this.competitionId = value;
	}
}
