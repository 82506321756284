export interface OpenSearchClubHexColors {
	readonly primary: string;
	readonly primaryText: string;
}

export interface OpenSearchCompactClub {
	readonly clubId: string;
	readonly name: string;
	readonly threeLetterCode: string;
}

export interface OpenSearchClub {
	readonly clubId: string;
	readonly threeLetterCode: string;
	readonly longName: string;
	readonly clubName: string;
	readonly shortName?: string;
	readonly mostRecentStadiumName?: string;
	readonly logoUrl?: string;
	readonly searchTerms: readonly string[];
	readonly hexColors: OpenSearchClubHexColors;
	readonly activities: { readonly [seasonId: string]: readonly string[] };
	readonly onlyEngagedInBlacklistedCompetitions?: boolean;
}
