import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BUNDESLIGA_SEASONS, BundesmasterMatchday, BundesmasterMatchdayApiService, TaggingDialogInput, TaggingDialogResult, emptyBundesmasterDataCollection } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiSeasonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/season-selector';
import { Subject, startWith, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-matchdays',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatDialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCardModule, MatProgressSpinnerModule, BundesmasterUiSeasonSelectorComponent],
	templateUrl: './bundesmaster-ui-dialog-select-matchdays.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-matchdays.component.scss']
})
export class BundesmasterUiDialogSelectMatchdaysComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();

	public matchdays = emptyBundesmasterDataCollection<BundesmasterMatchday>('loading');

	private selected = new Set<string>();

	public readonly seasonSelectorFormControl = new FormControl(BUNDESLIGA_SEASONS.CURRENT);

	public isLoading = false;

	constructor(public readonly dialogRef: MatDialogRef<BundesmasterUiDialogSelectMatchdaysComponent, TaggingDialogResult>, @Inject(MAT_DIALOG_DATA) public readonly data: TaggingDialogInput, service: BundesmasterMatchdayApiService) {
		this.seasonSelectorFormControl.valueChanges
			.pipe(
				takeUntil(this.destroying$),
				startWith(BUNDESLIGA_SEASONS.CURRENT),
				switchMap((seasonId) => service.getMatchdays({ seasonId }))
			)
			.subscribe((matchdays) => (this.matchdays = matchdays));
	}

	toggleSelectMatchday({ matchdayId }: BundesmasterMatchday): void {
		if (this.selected.has(matchdayId)) {
			this.selected.delete(matchdayId);
		} else {
			this.selected.add(matchdayId);
		}
	}

	isSelected({ matchdayId }: BundesmasterMatchday): boolean {
		return this.selected.has(matchdayId);
	}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit() {
		this.selected = new Set(this.data?.selected ?? []);
	}

	add() {
		this.dialogRef.close([...this.selected]);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
