<div class="container-fluid">
	<div class="row" *ngIf="error">
		<mat-icon>error</mat-icon>
		<pre>{{error | json}}</pre>
	</div>
	<div class="row" *ngIf="loading === false else loadingTmpl">
		<div class="col-10 cropperCol">
			<image-cropper
				#cropper
				*ngIf="image"
				[imageBase64]="image?.shrinked?.base64"
				[aspectRatio]="targetAspectRatio ?? 1"
				[maintainAspectRatio]="targetAspectRatio ? true : false"
				[cropper]="overwritePosition"
				format="png"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded($event)"
				(cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"></image-cropper>
		</div>
		<div class="col-2 previewCol" *ngIf="croppedImage">
			<h2>Crop</h2>
			<img [src]="croppedImage?.base64" *ngIf="cropper?.imageVisible" alt="" style="max-width: 100%" />
			<div>Width: {{croppedImage?.width * originalImageRatio | number: '0.0-0'}}px</div>
			<mat-error *ngIf="minWidth && croppedImage && croppedImage?.width * originalImageRatio < minWidth">The cropped image should be at least {{minWidth}} in width</mat-error>
			<div>Height: {{croppedImage?.height * originalImageRatio | number: '0.0-0'}}px</div>
			<mat-error *ngIf="minHeight && croppedImage && croppedImage?.height * originalImageRatio < minHeight">The cropped image should be at least {{minHeight}} in height</mat-error>
			<div class="imageUrl" *ngIf="croppedImageUrl">{{croppedImageUrl}}</div>
		</div>
	</div>
</div>
<ng-template #loadingTmpl>
	<div class="d-flex align-items-center justify-content-center">
		<mat-progress-spinner diameter="60" mode="indeterminate" [color]="'primary'"></mat-progress-spinner>
	</div>
</ng-template>
