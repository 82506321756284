<mat-dialog-content class="mat-typography">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<nx-bundesliga-bundesmaster-ui-image-cropper class="d-block" [imageUrl]="data.imageUrl" (cropped)="cropImage($event)" [targetAspectRatio]="data.targetAspectRatio" [minWidth]="data.minWidth" [minHeight]="data.minHeight"></nx-bundesliga-bundesmaster-ui-image-cropper>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button (click)="cancel()">Cancel</button>
	<button mat-flat-button cdkFocusInitial color="primary" (click)="confirm()">Use</button>
</mat-dialog-actions>
