import { SLIDE_TYPES, OpenSearchStory } from './story.model';

export const mockStoryOne: OpenSearchStory = {
	storyType: 'article_story',
	storyId: '92EPZosBKvkdKziTf_jY',
	sourceArticleId: '23619',
	authors: ['robin.moschinski@dfl.de'],
	title: 'Hattrick und Startrekord: Kane ballert Bayern zum Kantersieg',
	language: 'de',
	status: 'published',
	created_date: '2023-10-17T14:58:06+0200',
	modified_date: '2023-12-17T14:58:06+0200',
	last_publish_date: '2023-11-17T14:58:06+0200',
	scheduled_date: '2024-10-17T14:58:06+0200',
	editorial_date: '2022-10-17T14:58:06+0200',
	live: false,
	top: false,
	contentLabel: 'no-reco',
	tags: {
		competitions: ['DFL-COM-000001'],
		clubs: ['DFL-CLU-00000G', 'DFL-CLU-00000S'],
		seasons: ['DFL-SEA-0001K7'],
		matchdays: ['DFL-DAY-004C3L'],
		matches: ['DFL-MAT-J03YEU'],
		players: ['DFL-OBJ-J00ZZ3', 'DFL-OBJ-0000RP'],
		tags: ['summary']
	},

	slides: [
		{
			slideType: SLIDE_TYPES.TEXT,
			headline: 'Kane ballert 🔫',
			text: 'Hattrick und Startrekord. Krass.',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD10_BVBFCB_CKB_302.webp?crop=400px%2C0px%2C479px%2C853px',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.QUOTE,
			quote: 'Kane ist der erste Bayern-Spieler, der in seinen ersten fünf Bundesliga-Spieielen sieben Mal traf',
			author: 'Lothar Matthäus',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD10_BVBFCB_CKB_313.webp?crop=163px%2C0px%2C1769px%2C3145px',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.TEXT,
			headline: 'Kane ballert 🔫',
			text: 'Hattrick und Startrekord. Krass.',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD07_FCBSCF_SW_189.webp?crop=1562px%2C0px%2C1874px%2C3333px',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.TEXT,
			headline: 'Kane ballert 🔫',
			text: 'Hattrick und Startrekord. Krass.',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD10_BVBFCB_CKB_313.webp',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.QUOTE,
			quote: 'Kane ist der erste Bayern-Spieler, der in seinen ersten fünf Bundesliga-Spieielen sieben Mal traf',
			author: 'Lothar Matthäus',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD10_BVBFCB_CKB_313.webp?crop=163px%2C0px%2C1769px%2C3145px',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.TEXT,
			headline: 'Kane ballert 🔫',
			text: 'Hattrick und Startrekord. Krass.',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD07_FCBSCF_SW_189.webp?crop=1562px%2C0px%2C1874px%2C3333px',
				copyright: 'DFL'
			}
		},

		{
			slideType: SLIDE_TYPES.TEXT,
			headline: 'Kane ballert 🔫',
			text: 'Hattrick und Startrekord. Krass.',
			image: {
				url: 'https://assets.bundesliga-dev.com/contender/2023/10/2324_MD10_BVBFCB_CKB_313.webp',
				copyright: 'DFL'
			}
		}
	]
};
