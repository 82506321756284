import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterByValue',
	pure: true
})
export class FilterByValuePipe implements PipeTransform {
	private readonly defaultSeparator = '.';

	private resolve(obj: any, path: string | string[]) {
		const properties = Array.isArray(path) ? path : path.split(this.defaultSeparator);
		return properties.reduce((prev, curr) => prev?.[curr], obj);
	}

	transform<T>(collection: readonly T[] | null | undefined, search: string, ...paths: string[]): T[] {
		paths = paths.length > 0 ? paths : [''];

		if (collection && Array.isArray(collection)) {
			return collection.filter((item) => paths.some((path) => this.resolve(item, path)?.toLowerCase().includes(search?.toLowerCase())));
		}

		return [];
	}
}
