import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { BarProgressChartModule } from '@nx-bundesliga/bundesliga-com/components/stats';
import { StatsClubmetricprogressionListComponent } from './component/stats-clubmetricprogression-list/stats-clubmetricprogression-list.component';

@NgModule({
	imports: [CommonsModule, BarProgressChartModule, MatTableModule, ReactiveFormsModule],
	exports: [StatsClubmetricprogressionListComponent],
	declarations: [StatsClubmetricprogressionListComponent],
	providers: []
})
export class StatsClubmetricprogressionListModule {}
