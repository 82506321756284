import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { TaggingDialogInput, TaggingDialogResult, completeCompetitionList } from '@nx-bundesliga/bundesmaster/core';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-competitions',
	standalone: true,
	imports: [CommonModule, MatButtonModule, FormsModule, MatListModule, MatDialogModule],
	templateUrl: './bundesmaster-ui-dialog-select-competitions.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-competitions.component.scss']
})
export class BundesmasterUiDialogSelectCompetitionsComponent implements OnInit {
	public static dialogHeightMode = 'dynamic' as const;

	public readonly competitions = completeCompetitionList;

	public selected = new Set<string>();

	constructor(public readonly dialogRef: MatDialogRef<BundesmasterUiDialogSelectCompetitionsComponent, TaggingDialogResult>, @Inject(MAT_DIALOG_DATA) public readonly data: TaggingDialogInput) {}

	ngOnInit() {
		this.selected = new Set(this.data?.selected ?? []);
	}

	selectionChange(event: MatSelectionListChange): void {
		event.options.forEach((option) => (option.selected ? this.selected.add(option.value) : this.selected.delete(option.value)));
	}

	add() {
		this.dialogRef.close([...this.selected]);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
