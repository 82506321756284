import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Club, Match } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Subscription } from 'rxjs';
import { ClubService } from '../../../../../services/club/club.service';
import { DflDatalibraryMatchdayService } from '../../../../../services/dfldatalibrary/dflDatalibraryMatchday.service';
import { DflDatalibrarySeasonService } from '../../../../../services/dfldatalibrary/dflDatalibrarySeason.service';
import { MatchesService } from '../../../../../services/matches/matches.service';

@Component({
	selector: 'FixturesComponent',
	templateUrl: './fixtures.component.html',
	styleUrls: ['./fixtures.component.scss']
})
export class FixturesComponent implements OnInit, OnChanges, OnDestroy {
	@Input() seasonId: string;
	@Input() matchdayNumber: any;
	@Input() competitionId: string;
	@Input() clubName: string;
	@Input() language = 'en';
	loading = true;
	data: any;
	public matches: Match[];
	public matchesShowKickoffDate: any;
	public matchesLiveState: any;
	public matchesOverTimeLiveState: any;
	public matchesPenaltyState: any;
	public matchcenterUrls: any;
	public currentMatchdayNumber: string;
	public defaultClubName = '';
	public defaultSeasonId = 'DFL-SEA-0001K3';
	public defaultMatchdayNumber = 1;
	public defaultCompetitionId = 'DFL-COM-000001';
	public defaultLanguage = 'en';
	public currentSeasonId: string;

	public clubs: Club[];
	public clubsObject: any = {};

	private _clubServiceSubscription: Subscription;
	private _matchesServiceSubscription: Subscription;

	constructor(private matchesService: MatchesService, private matchdayService: DflDatalibraryMatchdayService, private seasonService: DflDatalibrarySeasonService, private config: ConfigService, private clubService: ClubService) {}

	ngOnInit() {
		this.loading = true;
		this._fetchClubs();
	}

	ngOnChanges() {
		const competitionIds = {
			'DFL-COM-000001': 'DFL-COM-000001',
			'DFL-COM-000002': 'DFL-COM-000002',
			'DFL-COM-000003': 'DFL-COM-000001',
			'DFL-COM-000004': 'DFL-COM-000001',
			'DFL-COM-000005': 'DFL-COM-000002'
		};
		this.currentSeasonId = this.config.getSettings(competitionIds[this.competitionId] + '.season.dflDatalibrarySeasonId', this.defaultSeasonId);
		this.currentMatchdayNumber = this.config.getSettings(competitionIds[this.competitionId] + '.matchday.matchdayNumber', this.defaultMatchdayNumber);
		if (!this.matchdayNumber) {
			this.matchdayService.setMatchdayDataByCompetitionId(competitionIds[this.competitionId]);
			this.matchdayNumber = this.matchdayService.matchdayNumber;
		}
		this._fetchMatchData();
	}

	/**
	 * destory current subscriptions
	 */
	ngOnDestroy() {
		if (this._clubServiceSubscription) {
			this._clubServiceSubscription.unsubscribe();
		}

		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}
	}

	private _fetchClubs(): void {
		const competitionIds = {
			'DFL-COM-000001': 'bundesliga',
			'DFL-COM-000002': '2bundesliga',
			'DFL-COM-000003': 'bundesliga',
			'DFL-COM-000004': 'bundesliga',
			'DFL-COM-000005': '2bundesliga'
		};
		this._clubServiceSubscription = this.clubService.getAllClubsByCompetitionAndSeason(competitionIds[this.competitionId], 'editorialorder').subscribe(
			(data: Club[]) => {
				this.clubs = data || [];
				this.clubsObject = this.clubs.reduce((result, item) => {
					const key = item['threeLetterCode'];
					result[key] = item;
					return result;
				}, {});
			},
			(error) => {
				this.clubs = [];
			}
		);
	}

	private _fetchMatchData(): void {
		let subscription = null;
		const isFilteredByClub = !!(this.clubName && this.clubName !== '');
		if (isFilteredByClub) {
			subscription = this.matchesService.getAllMatchesByClubname(this.clubName, this.competitionId, this.seasonId);
		} else {
			subscription = this.matchesService.getBasicMatchinfosByMatchday(this.matchdayNumber, this.competitionId, this.seasonId);
		}

		// unsibscribe first
		if (this._matchesServiceSubscription) {
			this._matchesServiceSubscription.unsubscribe();
		}

		// get matches
		this._matchesServiceSubscription = subscription.subscribe(
			(success: Match[]) => {
				this.matches = success;
				this._setAndFilterMatchData(isFilteredByClub ? true : false);
			},
			(error) => {
				this.data = error;
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	private _setAndFilterMatchData(isFilteredByClub = false): void {
		/* sort the values by kickofftime or matchday range*/
		if (isFilteredByClub === true) {
			this.matches.sort((a, b) => (a.matchdayRange.start > b.matchdayRange.start ? 1 : b.matchdayRange.start > a.matchdayRange.start ? -1 : 0));
		} else {
			this.matches.sort((a, b) => (a.plannedKickOff > b.plannedKickOff ? 1 : b.plannedKickOff > a.plannedKickOff ? -1 : 0));
		}
		let lastPlannedKickoff = null;
		this.matchesShowKickoffDate = [];
		this.matchesLiveState = [];
		this.matchesOverTimeLiveState = [];
		this.matchcenterUrls = [];
		this.matchesPenaltyState = [];

		Object.keys(this.matches).forEach((key) => {
			const match = this.matches[key] as Match;
			if (!match['plannedKickOff']) {
				this.matchesShowKickoffDate[key] = true;
			} else if (lastPlannedKickoff !== match['plannedKickOff']) {
				lastPlannedKickoff = match['plannedKickOff'];
				this.matchesShowKickoffDate[key] = true;
			} else {
				this.matchesShowKickoffDate[key] = false;
			}
			this.matchcenterUrls[key] = this._getMatchCenterRouterLink(match);
			this.matchesLiveState[key] = this.matchesService.isLiveMatch(match);
			this.matchesPenaltyState[key] = this.matchesService.isPenalty(match);
			this.matchesOverTimeLiveState[key] = this.matchesService.isOverTimeLiveMatch(match);
		});
		this.loading = false;
	}

	private _getMatchCenterRouterLink(match): string[] {
		return this.matchesService.getMatchCenterRouterLink(match, this.language);
	}

	public trackByMatchId(index, item) {
		if (item.dflDatalibraryMatchId) {
			return item.dflDatalibraryMatchId;
		}
	}
}
