import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'split'
})
export class SplitPipe implements PipeTransform {
	transform(val: string | null, splitBy: string): string[] {
		if (val && splitBy) {
			return val.split(splitBy);
		}
		return [];
	}
}
