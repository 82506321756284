<ng-container *ngIf="!legacy && src && width && width > 0 && height && height > 0">
	<img [ngSrc]="src" [alt]="title" [width]="width" [height]="height" class="logo fixedDimensions" [ngClass]="addBorder ? 'outline' : null " />
</ng-container>

<ng-container *ngIf="!legacy && src && dimension && dimension > 0 && !(width && width > 0 && height && height > 0)">
	<img [ngSrc]="src" [alt]="title" [width]="dimension" [height]="dimension" class="logo default" [ngClass]="addBorder ? 'outline' : null " />
</ng-container>

<ng-container *ngIf="!legacy && src && !(dimension && dimension > 0) && !(width && width > 0 && height && height > 0)">
	<img [ngSrc]="src" [alt]="title" class="logo default" fill [ngClass]="addBorder ? 'outline' : null " />
</ng-container>

<img *ngIf="legacy && !lazyload && src" [src]="src" [alt]="title" class="logo default" />
<img *ngIf="legacy && lazyload && src" [dflLazyLoad]="src" [errorImage]="lazyLoadErrorImage" [src]="lazyLoadPlaceholderImage" [alt]="title" class="logo default" [ngClass]="addBorder ? 'outline' : null " />
