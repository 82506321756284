<div *ngIf="liveEntry && liveEntry.detail" class="nodge side_{{side}}" [style.borderColor]="nodgeColor"></div>
<div *ngIf="liveEntry && liveEntry.detail" class="event penaltyEvent side_{{side}}">
	<div class="head">
		<livetickerevent-playtime *ngIf="liveEntry.matchSection !== 'PENALTY'" [liveEntry]="liveEntry" [clubData]="clubData"></livetickerevent-playtime>
		<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
	</div>

	<div class="body">
		<div class="shooter">
			<div class="image clubAndShooter" *ngIf="clubData && clubData[side]?.dflDatalibraryClubId && liveEntry.detail?.shooter && liveEntry.detail?.shooter?.imageUrl">
				<player-image
					[playerImage]="liveEntry.detail.shooter.imageUrl| playerImage: 'FACE_CIRCLE' | imageService:0:64"
					[dimension]="64"
					placeHolderImg="/assets/placeholder/player-circle-default.png"
					[language]="language"
					[competition]="competition"
					[playerName]="liveEntry?.detail?.shooter?.name"
					[enabledLinking]="enablePlayerLinking"></player-image>
				<span class="clublogo" [title]="clubData[side]?.nameFull">
					<clublogo *ngIf="isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
					<clublogo *ngIf="!isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [width]="27" [height]="18"></clublogo>
				</span>
			</div>
			<div class="image onlyClub" *ngIf="clubData && clubData[side]?.dflDatalibraryClubId && !liveEntry.detail?.shooter?.name">
				<clublogo *ngIf="isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="48"></clublogo>
				<clublogo *ngIf="!isBundesliga" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [width]="27" [height]="18"></clublogo>
			</div>
			<div class="description">
				<h2 class="penalty">
					<ng-container *ngIf="!liveEntry.detail.saved">{{ "LIVETICKER.EVENTS.penalty" | translate }}</ng-container>
					<ng-container *ngIf="liveEntry.detail.saved">{{ "LIVETICKER.EVENTS.penalty_saved" | translate }}</ng-container>
				</h2>
				<ng-container *ngIf="liveEntry.detail?.shooter?.name">
					<div class="title">{{ "LIVETICKER.shooter" | translate }}:</div>
					<a class="name" [class.disabled]="!enablePlayerLinking" [routerLink]="enablePlayerLinking ? (['/', language, competition, 'route-player', liveEntry.detail.shooter?.name | slugify] | translateRoute) : null">
						<span class="first">{{liveEntry.detail.shooter?.name | livetickerEventPlayername:'first'}}</span>
						<span class="last">{{liveEntry.detail.shooter?.name | livetickerEventPlayername:'last'}}</span>
					</a>
				</ng-container>
			</div>
		</div>
	</div>
</div>
