/**
 * Executes a given parameter if it's a function, otherwise just returns the parameter.
 * If fn is a function and payload is set, then is it spreaded into fn (i.e. fn(...payload)).
 *
 * @param fn {Function}
 * @param payload
 * @returns {any}
 */
//
export function executeIfFunction(fn: (args?: any[]) => unknown, ...payload: any[]) {
	return typeof fn === 'function' ? fn(...(payload ? payload : [])) : fn;
}

/**
 *
 * @param cases
 * @returns {(defaultCase?: any) => (key: any) => any}
 */
function switchCaseBase(cases: any): (defaultCase?: any) => (key: any) => any {
	return (defaultCase?: any) => (key: any) => cases[key] || defaultCase;
}

/**
 *
 * @param cases
 * @returns {(defaultCase?: any) => (key: any) => any}
 */
export function switchCase(cases: any): (defaultCase?: any) => (key: any) => any {
	return (defaultCase?: any) => (key: any) => executeIfFunction(switchCaseBase(cases)(defaultCase)(key));
}

/**
 * Transforms a ISO-369-1 language code to a ISO-3166 code.
 * That is "en" to "en_US".
 *
 * @param {string} language ISO-369-1
 * @returns {string}
 */
export function iso369to3166(language: string): string {
	switch (language) {
		case 'de':
			return 'de_DE';
		case 'es':
			return 'es_ES';
		case 'ja':
			return 'ja_JP';
		case 'en':
		default:
			return 'en_US';
	}
}

/***
 * @param {string} seasonName
 * @returns {string}
 */
export function mapSeasonToDatalibraryId(seasonName: string): string {
	switch (seasonName) {
		case '2019-2020':
			return 'DFL-SEA-0001K3';
		case '2018-2019':
			return 'DFL-SEA-0001K2';
		case '2017-2018':
			return 'DFL-SEA-0001K1';
		case '2016-2017':
			return 'DFL-SEA-0001K0';
	}

	return getCurrentDatalibrarySeasonId();
}

/**
 * @todo/dfl should get the current season from config node in firebase
 * @returns {string}
 */
export function getCurrentDatalibrarySeasonId() {
	return 'DFL-SEA-0001K2';
}

/**
 * Returns true only if n is of type number and greater than zero.
 *
 * @param n:number
 */
export function isNotNull(n: number | undefined): boolean {
	return !!n && !isNaN(n) && n > 0;
}

/**
 * Returns a compare function of type "(any, any) => number" over key.
 *
 * @param key
 */
export function getCompareFn(key: string): (a: { [key: string]: number }, b: { [key: string]: number }) => number {
	return (a: { [key: string]: number }, b: { [key: string]: number }): number => {
		if (a[key] < b[key]) {
			return -1;
		}

		if (a[key] > b[key]) {
			return 1;
		}

		return 0;
	};
}

/**
 * Decodes the article slug for a given EditorialArticle.
 * Required by WEB-811 to display kanji characters in the url bar, because CIS is encoding all values and neither
 * API nor CIS teams have time/budget to change this.
 *
 * @param article
 */
export function decodeArticleSlug(article: any) {
	if (article.type !== 'custom_tile') {
		article.slug = decodeURIComponent(article.slug);
	}

	return article;
}

// normalizes a string that is used to search for texts
// trims, converts to lower case and removes diacritics
export function normalizeForTextSearch(input?: string): string {
	return (input?.toLowerCase() ?? '')
		.trim()
		.normalize('NFD')
		.replace(/\p{Diacritic}/gu, '');
}

export function getDomainSuffix(stage?: string): '-dev' | '-appr' | '' {
	return stage === 'dev' ? '-dev' : stage === 'int' ? '-appr' : '';
}
