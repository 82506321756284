import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LivetickerEventPlaytimeComponent } from './livetickerevent-playtime.component';

@NgModule({
	declarations: [LivetickerEventPlaytimeComponent],
	exports: [LivetickerEventPlaytimeComponent],
	imports: [CommonModule]
})
export class LivetickerEventPlaytimeModule {}
