<div class="teaser">
	<ng-content select="[teaser]"></ng-content>
	<div class="content">
		<span class="title">
			<ng-content select="[head]"></ng-content>
		</span>
		<p class="description">
			<ng-content select="[content]"></ng-content>
		</p>
		<div class="actions">
			<ng-content select="[actions]"></ng-content>
		</div>
	</div>
</div>
