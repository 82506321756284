import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BlCustomMatchProgressDialogModule } from '../../molecules/bl-custom-match-progress-dialog/bl-custom-match-progress-dialog.module';
import { BlMatchHighlightDialogModule } from '../../molecules/bl-match-highlight-dialog/bl-match-highlight-dialog.module';
import { BlStsFeedControlsModule } from '../../molecules/bl-sts-feed-controls/bl-sts-feed-controls.module';
import { BlTickerMatchsettingsControlsModule } from '../../molecules/bl-ticker-matchsettings-controls/bl-ticker-matchsettings-controls.module';
import { BlTranslationConfigDialogModule } from '../../molecules/bl-translation-config-dialog/bl-translation-config-dialog.module';
import { MatMatchHeaderComponent } from './mat-match-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { BlTickerTypingControlsModule } from '../../molecules/bl-ticker-typing-controls/bl-ticker-typing-controls.module';
import { BlLineupsModule } from '../../molecules/bl-lineups/bl-lineups.module';

@NgModule({
	declarations: [MatMatchHeaderComponent],
	exports: [MatMatchHeaderComponent],
	imports: [CommonsModule, MatToolbarModule, MatIconModule, MatButtonModule, BlTickerTypingControlsModule, BlLineupsModule, BlMatchHighlightDialogModule, BlCustomMatchProgressDialogModule, BlTranslationConfigDialogModule, NgxTippyModule, MatMenuModule, BlTickerMatchsettingsControlsModule, BlStsFeedControlsModule]
})
export class MatMatchHeaderModule {}
