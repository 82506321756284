import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';
import { BundesmasterUiLoadingBarTextComponent } from '../loading-bar-text/bundesmaster-ui-loading-bar-text.component';
import messages from '../loading-messages';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-loading-bar',
	standalone: true,
	imports: [CommonModule, MatProgressBarModule, BundesmasterUiLoadingBarTextComponent],
	templateUrl: './bundesmaster-ui-loading-bar.component.html',
	styleUrls: ['./bundesmaster-ui-loading-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundesmasterUiLoadingBarComponent {
	@Input() text = true;
	@Input() loading = true;
	@Input() mode: ProgressBarMode = 'indeterminate';
	@Input() message: string = messages[Math.floor(Math.random() * messages.length)];
	@Input() margin = true;
}
