import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompetitionPipe } from '@nx-bundesliga/bundesliga-com/components/commons';
import { TranslateRoutePipe } from '@nx-bundesliga/commons';
import { Languages, LiveBlogAutoEventCard, LiveBlogAutoEventGoal, LiveBlogMatch, LiveBlogScores, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import { DflDatalibraryService } from '@nx-bundesliga/shared/data-access/dfl-data-library';
import { environment } from '../../../../environments/environment';
import { getAllCardsFromEvents, getAllGoalsFromEvents } from '../../../framework/common/functions/match';
import { IsTypingService } from '../../../services/liveticker-backend/is-typing.service';
import { BlCustomMatchProgressDialogComponent } from '../../molecules/bl-custom-match-progress-dialog/bl-custom-match-progress-dialog.component';
import { LivestreamService } from '../../molecules/livestream/livestream.service';
import { LivestreamData, LivestreamModalName } from '../../molecules/livestream/livestream.tokens';

export interface HomeAway<T> {
	home: T[];
	away: T[];
}

@Component({
	selector: 'bl-mat-match-header',
	templateUrl: './mat-match-header.component.html',
	styleUrls: ['./mat-match-header.component.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatMatchHeaderComponent implements OnChanges {
	@Output() layoutChange: EventEmitter<string> = new EventEmitter<string>();
	@Input() match: LiveBlogMatch;
	@Input() language: Languages;
	@Input() customMatch = false;

	public firebaseStage = environment.msal.firebaseDomain;
	public firebaseStageMobile = environment.msal.firebaseDomainMobile;

	public publicWebsite = environment.system.applicationUrl;

	public goals: HomeAway<LiveBlogAutoEventGoal> = { home: [], away: [] };
	public cards: HomeAway<LiveBlogAutoEventCard> = { home: [], away: [] };
	public minuteOfPlay: Playtime;
	public score: LiveBlogScores | any;

	constructor(private typingService: IsTypingService, public dialog: MatDialog, private livestreamService: LivestreamService, private translateRoute: TranslateRoutePipe, private dataLibrary: DflDatalibraryService, private competitionPipe: CompetitionPipe) {}
	ngOnChanges(changes: SimpleChanges): void {
		const liveBlogEntriesNodeName = environment.liveBlogEntries;
		this.goals = getAllGoalsFromEvents(this.match[liveBlogEntriesNodeName]) || { home: [], away: [] };
		this.cards = getAllCardsFromEvents(this.match[liveBlogEntriesNodeName]) || { home: [], away: [] };
		this.minuteOfPlay = this.match.hasOwnProperty('minuteOfPlay') ? this.match.minuteOfPlay : { injuryTime: 0, minute: 0 };
		this.score = this.match.hasOwnProperty('score') ? this.match.score : { away: { live: '-' }, home: { live: '-' } };
	}

	public setIsTyping([side, status]: [LivetickerSides, boolean]) {
		const payload = {
			language: this.language,
			competitionId: this.match.dflDatalibraryCompetitionId,
			seasonId: this.match.dflDatalibrarySeasonId,
			matchdayId: this.match.dflDatalibraryMatchdayId,
			matchId: this.customMatch ? this.match.matchId : this.match.dflDatalibraryMatchId
		};

		this.typingService.send(side, status, payload);
	}

	public setLayout(key: string): void {
		this.layoutChange.emit(key);
	}

	public openLivestream() {
		if (!this.customMatch) {
			const livestreamData: LivestreamData = {
				modalName: LivestreamModalName.livestreamHLS,
				competitionId: this.match.dflDatalibraryCompetitionId,
				seasonId: this.match.dflDatalibrarySeasonId,
				matchdayId: this.match.dflDatalibraryMatchdayId,
				matchId: this.match.dflDatalibraryMatchId,
				matchName: `${this.match.teams?.home?.nameShort} VS ${this.match.teams?.away?.nameShort}`
			};
			this.livestreamService.triggerHLSLivestream(livestreamData);
		}
	}

	public openWebTicker(language: Languages): string {
		// https://www.bundesliga.com/en/bundesliga/matchday/2019-2020/11/1-fsv-mainz-05-vs-1-fc-union-berlin/liveticker
		const url = [this.publicWebsite, language, this.competitionPipe.transform(this.match.dflDatalibraryCompetitionId), language === 'de' ? 'spieltag' : 'matchday', this.dataLibrary.getSeasonNameById(this.match.dflDatalibrarySeasonId, true), this.match.matchday, this.match.slugs?.slugLong, 'route-liveticker'].join('/');

		return this.translateRoute.transform(url) as string;
	}

	public openAppTicker(language: Languages): string {
		// https://webview.bundesliga.com/en/bundesliga/matchday/2019-2020/11/1-fsv-mainz-05-vs-1-fc-union-berlin/liveticker
		const url = [`https://webview.${environment.stageDomain}.com`, language, 'liveticker', this.match.dflDatalibraryCompetitionId, this.match.dflDatalibrarySeasonId, this.match.dflDatalibraryMatchdayId, this.match.dflDatalibraryMatchId].join('/');

		return url;
	}

	openCustomMatchProgressDialog() {
		const dialogRef = this.dialog.open(BlCustomMatchProgressDialogComponent, {
			data: {
				match: this.match,
				language: this.language
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
}
