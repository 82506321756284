<div class="fixturesWrapper" *ngIf="matches && matches.length > 0">
	<div class="container-fluid">
		<div class="matchInfos" *ngFor="let match of matches; trackBy: trackByMatchId; let i=index">
			<!--<pre>{{match|json}}</pre>-->
			<div *ngIf="matchesShowKickoffDate[i] === true" class="row kickoffDatetime">
				<div class="col-12">
					<span *ngIf="match?.['group']" class="float-left">GROUP: {{ match?.['group'] }} </span>
					<ng-container *ngIf="match.plannedKickOff">
						<span>{{ match.plannedKickOff | date: 'EEEE, dd-MMM-y | H:mm ' : undefined : language}}</span>
					</ng-container>
					<ng-container *ngIf="!match.plannedKickOff">
						<span>{{ match.matchdayRange?.start | date: 'EEEE, dd-MMM-y' : undefined : language}} - {{ match.matchdayRange?.end | date: 'EEEE, dd-MMM-y' : undefined : language}}</span>
					</ng-container>
				</div>
			</div>
			<div class="matchDataRow" id="fixtures_{{match.dflDatalibraryMatchId}}">
				<div class="teamHome clubHome">
					<a [routerLink]="matchcenterUrls[i]" class="matchcenterLink">
						<div class="clubName">{{match.teams?.home?.nameFull}}</div>
						<div class="clubNameShort">{{match.teams?.home?.nameShort}}</div>
						<div class="clubLogo">
							<clublogo [dimension]="40" [title]="match.teams?.home?.nameFull" [clubId]="match.teams?.home?.dflDatalibraryClubId" [clubLogoUrl]="match.teams?.home?.logoUrl"></clublogo>
						</div>
					</a>
				</div>

				<div class="matchResults text-center">
					<a [routerLink]="matchcenterUrls[i]" class="matchcenterLink">
						<div *ngIf="match.score; then tplScore else tplNoScore"></div>
						<ng-template #tplScore>
							<div class="score">
								<div *ngIf="matchesLiveState[i] === true" class="isLiveWrapper">
									<live-animation *ngIf="match.minuteOfPlay && match.minuteOfPlay?.minute && match.minuteOfPlay?.minute >= 0" [playtime]="match.minuteOfPlay" [matchStatus]="match.matchStatus" layout="regular"></live-animation>
								</div>
								<div *ngIf="matchesLiveState[i] === false" class="isNotLiveWrapper"></div>
								<span class="scoreLive">
									<span>{{ match.score?.home?.live }}</span>
									:
									<span>{{ match.score?.away?.live }}</span>
								</span>
								<span *ngIf="matchesLiveState[i] === false" class="scoreHt">
									(
									<span>{{ match.score?.home?.halftime }}</span>
									:
									<span>{{ match.score?.away?.halftime }}</span>
									)
								</span>
							</div>
						</ng-template>
						<ng-template #tplNoScore>
							<div class="score">
								<div class="scoreDefault">
									<span>-</span>
									:
									<span>-</span>
								</div>
							</div>
						</ng-template>
					</a>
				</div>

				<div class="teamAway clubAway">
					<a [routerLink]="matchcenterUrls[i]" class="matchcenterLink">
						<div class="clubLogo">
							<clublogo [dimension]="40" [title]="match.teams?.away?.nameFull" [clubId]="match.teams?.away?.dflDatalibraryClubId" [clubLogoUrl]="match.teams?.away?.logoUrl"></clublogo>
						</div>
						<div class="clubName">{{match.teams?.away?.nameFull}}</div>
						<div class="clubNameShort">{{match.teams?.away?.nameShort}}</div>
					</a>
					<a [routerLink]="matchcenterUrls[i]" mat-raised-button color="primary" class="matchcenterButton">Liveticker</a>
				</div>
				<bl-ticker-matchsettings-controls [match]="match" [language]="language" class="settingsButton"></bl-ticker-matchsettings-controls>
			</div>
			<!--/matchData-->
		</div>
		<!--/ngFor-->

		<div *ngIf="!matches[0].plannedKickOff" class="row kickoffDatetime">
			<div class="col-12">
				<span>{{ "FIXTURES.matchdayNotFixed" }}</span>
			</div>
		</div>
	</div>
</div>
<dfl-loader [loading]="loading"></dfl-loader>
