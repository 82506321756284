import { BundesmasterPartialCollection } from '../bundesmaster-data-collection';

export interface BundesmasterPersonAssociatedClub {
	readonly clubId: string;
	readonly threeLetterCode: string;
	readonly name: string;
	readonly shirtNumber?: number;
}

export interface BundesmasterPerson {
	readonly personId: string;
	readonly displayName: string;
	readonly aliasName?: string;
	readonly firstActiveYear: number;
	readonly lastActiveYear: number;

	readonly mostRecentClub?: BundesmasterPersonAssociatedClub;
	readonly allClubs: readonly BundesmasterPersonAssociatedClub[];
	readonly clubsBySeason: { readonly [seasonId: string]: readonly BundesmasterPersonAssociatedClub[] };
}

export interface BundesmasterPersonsLambdaResponse {
	readonly persons: readonly BundesmasterPerson[];
	readonly partial: BundesmasterPartialCollection;
}
