import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ConsentCategories {
	C0001: boolean;
	C0002: boolean;
	C0003: boolean;
	C0004: boolean;
	C0005: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class CookieConsentServiceStub {
	public consentCategoriesDefault: ConsentCategories = {
		'C0001': true,
		'C0002': true,
		'C0003': true,
		'C0004': true,
		'C0005': true
	};
	private consentCategories: ConsentCategories = this.consentCategoriesDefault;
	private language: string;
	private isBrowser = true;
	public consents$: BehaviorSubject<ConsentCategories> = new BehaviorSubject<ConsentCategories>(this.consentCategories);

	constructor() {
		this.Init();
	}

	Init(): void {
		this.consents$.next(this.consentCategoriesDefault);
	}

	public registerConsentChanged() {}
	public consentChanged(consentData: any) {}
}
