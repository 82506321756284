import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { BundesmasterMatch, BundesmasterMatchApiService } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'resolveMatchId'
})
export class ResolveMatchIdPipe implements PipeTransform {
	constructor(private readonly service: BundesmasterMatchApiService) {}

	private short({ season, home: { threeLetterCode: home }, guest: { threeLetterCode: guest } }: BundesmasterMatch): string {
		return `${home} vs. ${guest} (${season})`;
	}

	private long({ season, competitionName, matchday, home: { name: home }, guest: { name: guest } }: BundesmasterMatch): string {
		return `MD${matchday} ${competitionName} ${season}: ${home} vs. ${guest}`;
	}

	transform(matchId: string, format: 'long' | 'short' = 'short'): Observable<string> {
		return this.service.getMatch(matchId).pipe(
			map((match) => (match ? this[format](match) : matchId)),
			startWith(matchId)
		);
	}
}
