import { Injectable } from '@angular/core';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { DflDatalibraryService } from './dflDatalibrary.service';

@Injectable()
export class DflDatalibraryMatchService extends DflDatalibraryService {
	public matchId: string;

	constructor(public override configService: ConfigService) {
		super(configService);
		this.matchId = 'DFL-MAT-003AVY'; /* just a default value*/
	}

	get MatchId(): string {
		return this.matchId;
	}

	set MatchId(value: string) {
		this.matchId = value;
	}
}
