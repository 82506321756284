import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveBlogClubs, LiveBlogInfos, LiveBlogMatch } from '@nx-bundesliga/models';
import * as _moment from 'moment';
import { BlLivetickereventDialogsCreateComponent } from '../bl-livetickerevent-dialogs/bl-livetickerevent-dialogs.component';

const moment = _moment;

@Component({
	selector: 'bl-stats-feed',
	templateUrl: './stats-feed.component.html',
	styleUrls: ['./stats-feed.component.scss']
})
export class StatsFeedComponent implements OnInit, OnDestroy {
	@Input() language: string;
	@Input() liveBlogInfos: LiveBlogInfos;
	@Input() match: LiveBlogMatch;
	@Input() clubData: LiveBlogClubs;
	constructor(public http: HttpClient, public dialog: MatDialog) {}

	ngOnInit() {}
	ngOnDestroy() {}
	public openCreateDialog(dialogData: { type: string; title: string; data: any; side: string }) {
		let chartData = {};
		if (dialogData.type === 'bar' || dialogData.type === 'pie') {
			chartData = { home: {}, away: {} };
			chartData['home']['value'] = dialogData.data.homeValue;
			chartData['away']['value'] = dialogData.data.awayValue;
		}
		if (dialogData.type === 'player') {
			chartData = { home: { person: {} }, away: { person: {} } };
			chartData['home']['person']['dflDatalibraryObjectId'] = dialogData.data.home.dflDatalibraryObjectId;
			chartData['home']['person']['imageUrl'] = dialogData.data.home.playerImageUrl;
			chartData['home']['person']['name'] = dialogData.data.home.playerName;
			chartData['home']['value'] = dialogData.data.home.value;

			chartData['away']['person']['dflDatalibraryObjectId'] = dialogData.data.away.dflDatalibraryObjectId;
			chartData['away']['person']['imageUrl'] = dialogData.data.away.playerImageUrl;
			chartData['away']['person']['name'] = dialogData.data.away.playerName;
			chartData['away']['value'] = dialogData.data.away.value;
		}
		if (dialogData.type === 'stackedcolumn') {
			chartData = { home: {}, away: {} };
			chartData['home']['primaryValue'] = dialogData.data.shotsOnTarget.homeValue;
			chartData['home']['secondaryValue'] = dialogData.data.shotsOffTarget.homeValue;
			chartData['home']['primaryLabel'] = dialogData.data.primaryLabel;
			chartData['home']['secondaryLabel'] = dialogData.data.secondaryLabel;

			chartData['away']['primaryValue'] = dialogData.data.shotsOnTarget.awayValue;
			chartData['away']['secondaryValue'] = dialogData.data.shotsOffTarget.awayValue;
			chartData['away']['primaryLabel'] = dialogData.data.primaryLabel;
			chartData['away']['secondaryLabel'] = dialogData.data.secondaryLabel;
		}
		if (dialogData.type === 'clubProgressRanking') {
			chartData = { home: {}, away: {} };
			chartData['home']['value'] = dialogData.data.home.value;
			chartData['home']['relativeValue'] = dialogData.data.home.relativeValue;

			chartData['away']['value'] = dialogData.data.away.value;
			chartData['away']['relativeValue'] = dialogData.data.away.relativeValue;

			chartData['metric'] = dialogData.data.metric;
			chartData['matchFact'] = dialogData.data.matchFact;
		}
		if (dialogData.type === 'playerProgressRanking') {
			chartData = { ranking: [] };
			['home', 'away'].forEach((side) => {
				dialogData.data[side].forEach((el, i) => {
					const element = { person: {}, rank: i + 1, value: el.value };
					element['person']['dflDatalibraryObjectId'] = el.dflDatalibraryObjectId;
					element['person']['imageUrl'] = el.playerImageUrl;
					element['person']['name'] = el.playerName;
					element['side'] = side;
					chartData['ranking'].push(element);
				});
			});
			chartData['matchFact'] = dialogData.data.matchFact;
		}
		if (dialogData.type === 'clubMetricProgression') {
			chartData['progression'] = dialogData.data.progression;
			chartData['matchFact'] = dialogData.data.matchFact;
		}
		const localLiveEntry = {
			entryType: 'stats',
			detail: {
				...chartData,
				headline: '',
				text: '',
				title: dialogData.title,
				matchFact: chartData['matchFact'] || false,
				type: dialogData.type
			},
			eventId: '',
			entryDate: '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.match.matchStatus || 'PRE_MATCH',
			side: dialogData.side || 'none',
			playtime: {
				minute: this.match.minuteOfPlay.minute || 0,
				injuryTime: this.match.minuteOfPlay.injuryTime || 0
			}
		};
		const dialogRef = this.dialog.open(BlLivetickereventDialogsCreateComponent, {
			data: {
				match: this.match,
				liveEntry: localLiveEntry,
				language: this.language,
				clubData: this.clubData
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);
		});
	}
}
