import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BarChartModule } from '../stats-charts/bar-chart/bar-chart.module';
import { StatsBallrecoverytimeCardComponent } from './stats-ballrecoverytime-card.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule, CommonModule, BarChartModule],
	exports: [StatsBallrecoverytimeCardComponent],
	declarations: [StatsBallrecoverytimeCardComponent],
	providers: []
})
export class StatsBallrecoverytimeCardModule {}
