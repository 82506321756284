import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';
import { LiveBlogAutoEvents, LiveBlogClubs } from '@nx-bundesliga/models';

@Component({
	selector: 'livetickerevent-progress',
	templateUrl: './livetickerevent-progress.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-progress.component.scss'],
	animations: [
		trigger('liveblogPostAnimation', [
			transition('void => false', [style({ opacity: 0 }), animate('250ms ease-in', style({ opacity: 1 }))]),
			transition('void => true', [
				sequence([
					query('.head .playtime, .head .headline livetickerevent-score, .head .headline livetickerevent-headline', [style({ opacity: 0 })], { optional: true }),
					query(':self', [style({ transform: 'scaleY(0)' }), animate('600ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({ transform: 'scaleY(1)' }))], { optional: true }),
					query('.head .playtime, .head .headline livetickerevent-score, .head .headline livetickerevent-headline', [style({ opacity: 0, transform: 'translateX(-10%)' }), stagger(200, [animate('250ms cubic-bezier(0.25, 1, 0.5, 1)', style({ opacity: 1, transform: 'translateX(0)' }))])], { optional: true })
				])
			])
		])
	]
})
export class LivetickereventProgressComponent {
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() clubData: LiveBlogClubs;
	@HostBinding('@liveblogPostAnimation') @Input() conference = false;
	@Input() isWebview = false;

	constructor() {}
}
