import { createReducer, on } from '@ngrx/store';
import { languages, Languages } from '../../models/language.model';
import * as languageActions from './language.actions';

export const languageFeatureKey = 'language';

export interface State {
	available: Languages;
	active: string;
}
export const initialState: State = {
	available: languages,
	active: languages['de'].code
};

export const reducer = createReducer(
	initialState,
	on(languageActions.setLanguage, (state, payload) => ({
		...state,
		active: payload.code
	})),
	on(languageActions.setLanguages, (state, payload) => ({
		...state,
		available: payload.languages
	}))
);
