import { NgModule } from '@angular/core';
import { BarChartComponent } from './bar-chart.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule],
	exports: [BarChartComponent],
	declarations: [BarChartComponent],
	providers: []
})
export class BarChartModule {}
