<div class="eventwrapper side_{{side}}">
	<div *ngIf="liveEntry && liveEntry.value" class="nodge side_{{side}}"></div>

	<div class="nodges">
		<div *ngIf="conference" class="nodge_conference"></div>
		<div *ngIf="liveEntry?.value?.hidden" class="nodge_hidden"></div>
		<div *ngIf="liveEntry?.value?.story" class="nodge_story"></div>
	</div>

	<ng-content></ng-content>

	<div class="overlay_hidden" *ngIf="liveEntry?.value?.hidden"></div>
	<div class="overlay_actions" [ngClass]="{'d-none': !enableOverlay}">
		<div class="icons">
			<div class="infoIcon" [ngxTippy]="infoTmpl" [tippyProps]="{interactive: true, interactiveBorder: 50, arrow: true}">
				<mat-icon class="enabled">info</mat-icon>
				<div class="info" #infoTmpl>
					<p>id: {{postId}}</p>
					<p>entryDate: {{liveEntry.value?.entryDate}}</p>
					<p>order: {{liveEntry.value?.order}}</p>
				</div>
			</div>

			<mat-icon *ngIf="!hiddenProcessing" [ngxTippy]="liveEntry?.value?.hidden ? 'Unhide' : 'Hide'" [ngClass]="{'enabled': isPostControlsEnabled(),'post_hidden': liveEntry?.value?.hidden}" (click)="toggleHidden(!liveEntry?.value?.hidden)">
				<ng-container *ngIf="liveEntry?.value?.hidden">visibility_off</ng-container>
				<ng-container *ngIf="!liveEntry?.value?.hidden">visibility</ng-container>
			</mat-icon>
			<mat-spinner *ngIf="hiddenProcessing" ngxTippy="Entry is beeing processed" diameter="24"></mat-spinner>

			<ng-container *ngIf="!customMatch">
				<mat-icon *ngIf="!conferenceProcessing" ngxTippy="Publish entry to Conference Ticker" [ngClass]="{'enabled': isPostControlsEnabled(), 'conference_enabled': conference}" (click)="toggleConference(!conference)">forum</mat-icon>
				<mat-spinner *ngIf="conferenceProcessing" ngxTippy="Entry is beeing published to Conference Ticker" diameter="24"></mat-spinner>
			</ng-container>

			<mat-icon *ngIf="!customMatch" [ngClass]="{'enabled': isCrosspostEnabled()}" (click)="openCrosspostDialog()" ngxTippy="Crossposting to other languages">shuffle</mat-icon>
			<mat-icon [ngClass]="{'enabled': isPostControlsEnabled()}" (click)="openEditDialog()" ngxTippy="Edit liveticker entry">edit</mat-icon>
			<mat-icon [ngClass]="{'enabled': isPostControlsEnabled()}" (click)="openDeleteDialog()" ngxTippy="Delete liveticker entry">delete</mat-icon>
			<mat-icon [ngClass]="{'enabled': isPostControlsEnabled()}" (click)="openStorySlideDialog()" ngxTippy="Create/Edit Story Slide">web_stories</mat-icon>
			<mat-icon [ngClass]="{'enabled': isStoryControlsImageEnabled()}" (click)="openAttachSlideImageDialog()" ngxTippy="Pick Image for Story Slide">perm_media</mat-icon>
		</div>
	</div>
</div>
