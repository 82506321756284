<div class="pressurehandling-card" *ngIf="escapeRate && escapeRate > 0 && escapeRate <= 100">
	<div class="metrics">
		<div class="escapeRate-container flex-column flex-md-row">
			<div class="escapeRate">
				<span>{{ escapeRate }}</span>
				<span class="percent d-inline d-md-none">%</span>
			</div>
			<div class="escapeDetails">
				<div class="playerName" *ngIf="person?.name">
					<span class="percent d-none d-md-block">%</span>
					<div class="d-flex flex-row flex-md-column">
						<span class="first">{{person?.name | livetickerEventPlayername:'first'}}</span>
						<span class="last">{{person?.name | livetickerEventPlayername:'last'}}</span>
					</div>
				</div>
				<div class="underPressure" *ngIf="pressureCount">{{ "LIVETICKER.EVENTS.pressureHandling.UNDER_PRESSURE" | translate:{count: pressureCount} }}</div>
			</div>
		</div>
	</div>
	<img
		class="player-image"
		[ngClass]="{'no-playerImage': !person?.imageUrl}"
		[dflLazyLoad]="(person.imageUrl | playerImage: 'PORTRAIT_S')  | imageService:0:180"
		[alt]="person.name"
		defaultImage="/assets/placeholder/player-portrait-default.svg"
		errorImage="/assets/placeholder/player-portrait-default.svg"
		src="/assets/placeholder/player-portrait-default.svg"
		offset="200" />
</div>
