import { Component, Input } from '@angular/core';
import { LiveBlogPerson } from '@nx-bundesliga/models';

@Component({
	selector: 'stats-pressurehandling-card',
	templateUrl: './stats-pressurehandling-card.component.html',
	styleUrls: ['./stats-pressurehandling-card.component.scss']
})
export class StatsPressurehandlingCardComponent {
	@Input() person: LiveBlogPerson;
	@Input() escapeRate: number;
	@Input() pressureCount: number;
	@Input() title = '';

	constructor() {}
}
