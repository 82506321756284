<h2 mat-dialog-title>Edit Liveticker entry</h2>
<mat-dialog-content class="mat-typography action-edit">
	<h3>Id: {{postId}}</h3>

	<liveticker-event [liveEntry]="liveEntry.value" [teams]="clubData" [match]="match" [class]="previousLiveEntry.value.entryType" class="event"></liveticker-event>
	<hr />

	<bl-rapid-ticker-composer
		#composer
		[language]="language"
		[matchStatus]="newLiveEntry.value.matchSection"
		[minuteOfPlay]="newLiveEntry.value.playtime"
		[liveBlogInfos]=""
		[liveBlogTeams]="clubData"
		[matchId]="match.dflDatalibraryMatchId"
		[seasonId]="match.dflDatalibrarySeasonId"
		[competitionId]="match.dflDatalibraryCompetitionId"
		[matchdayId]="match.dflDatalibraryMatchdayId"
		[side]="newLiveEntry.value.side"
		[liveEntry]="newLiveEntry.value"
		[editMode]="true"
		[standalone]="true"
		[customMatch]="customMatch"></bl-rapid-ticker-composer>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false">Cancel</button>
	<button mat-button [disabled]="processing" cdkFocusInitial (click)="onEditClick()">Publish changes</button>
</mat-dialog-actions>
