import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'stackedcolumn-chart',
	templateUrl: './stackedcolumn-chart.component.html',
	styleUrls: ['./stackedcolumn-chart.component.scss']
})
export class StackedcolumnChartComponent implements OnChanges {
	@Input() title: string;
	@Input() leftPrimaryLabel: string;
	@Input() rightPrimaryLabel: string;
	@Input() leftSecondaryLabel: string;
	@Input() rightSecondaryLabel: string;
	@Input() leftPrimaryValue: number;
	@Input() rightPrimaryValue: number;
	@Input() leftSecondaryValue: number;
	@Input() rightSecondaryValue: number;
	@Input() leftColor: string;
	@Input() rightColor: string;
	@Input() leftTextColor: string;
	@Input() rightTextColor: string;
	@Input() single = false;
	public leftSecondaryColor = 'transparent';
	public rightSecondaryColor = 'transparent';

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('leftColor') || changes.hasOwnProperty('rightColor')) {
			this.leftSecondaryColor = this.leftColor.toLowerCase() === '#ffffff' ? 'rgba(195,195,195, 0.4)' : this.hexToRgb(this.leftColor);
			this.rightSecondaryColor = this.rightColor.toLowerCase() === '#ffffff' ? 'rgba(195,195,195, 0.4)' : this.hexToRgb(this.rightColor);
		}
	}

	private hexToRgb(hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, 0.4)` : '';
	}
}
