<div class="ballrecoverytime-card d-flex align-items-center justify-content-center flex-row">
	<div class="labels clubLabel d-flex flex-column w-100">
		<div *ngIf="side !== 'none'">
			<clublogo class="clubLogo" [clubId]="clubData[side]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[side]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
			<span class="clubName">{{clubData[side]?.nameFull}}</span>
		</div>
	</div>
	<div class="labels d-flex flex-column">
		<ng-container *ngFor="let entry of times">
			<div *ngIf="side !== 'none'">{{"LIVETICKER.EVENTS.ballRecoveryTime." + entry.label | translate}}</div>

			<div *ngIf="side === 'none'">
				<clublogo class="clubLogo" [clubId]="clubData[entry.label]?.dflDatalibraryClubId" [clubLogoUrl]="clubData[entry.label]?.logoUrl | imageService : 48" [dimension]="24"></clublogo>
				<span class="clubName">{{clubData[entry.label]?.threeLetterCode}}</span>
			</div>
		</ng-container>
	</div>
	<bar-chart
		*ngIf="times.length >= 2 && clubData"
		class="w-100"
		[horizontal]="false"
		[leftValue]="times[0].value"
		[leftColor]="side === 'none' ? clubData[times[0].label || 'home'].gradientStartColor : clubData[side].gradientStartColor"
		[leftTextColor]="side === 'none' ? clubData[times[0].label || 'home'].textColor : clubData[side].textColor"
		leftUnit="s"
		[rightValue]="times[1].value"
		[rightColor]="side === 'none' ? clubData[times[1].label || 'away'].gradientStartColor : clubData[side].gradientStartColor"
		[rightTextColor]="side === 'none' ? clubData[times[1].label || 'away'].textColor : clubData[side].textColor"
		rightUnit="s"></bar-chart>
</div>
