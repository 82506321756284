import { NgModule } from '@angular/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { StatsPressurehandlingCardComponent } from './component/stats-pressurehandling-card/stats-pressurehandling-card.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule, CommonsLibModule],
	exports: [StatsPressurehandlingCardComponent],
	declarations: [StatsPressurehandlingCardComponent],
	providers: []
})
export class StatsPressurehandlingCardModule {}
