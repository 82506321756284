<div class="container" id="fixtures">
	<div class="row">
		<div class="col-12">
			<page-title>Matchday {{matchdayNumber}} | Competition {{competitionId}} | Season {{seasonId}} | Language {{language}}</page-title>
		</div>
	</div>
	<div class="row">
		<div class="col-12 d-flex flex-column flex-md-row justify-content-center">
			<div class="fixtures-dropdown-wrapper" *ngIf="clubs && clubs.length > 0">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="clubName" (selectionChange)="onChangeClub(clubName)" [disableOptionCentering]="true">
						<mat-option value="">All Clubs</mat-option>
						<mat-option *ngFor="let club of clubs" [value]="club.threeLetterCode">{{club.name.small}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="competitionId" (selectionChange)="onChangeCompetition(competitionId)" [disableOptionCentering]="true">
						<mat-option value="" disabled>Competitions</mat-option>
						<mat-option *ngFor="let comp of competitionIds | keyvalue" [value]="comp.key">{{comp.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="matchdays && matchdays.length > 0">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="matchdayNumber" (selectionChange)="onChangeMatchday(matchdayNumber)">
						<mat-option [value]="0" disabled>All Matchdays</mat-option>
						<mat-option *ngFor="let matchdayOption of matchdays" [value]="matchdayOption" [ngClass]="{'font-weight-bold': matchdayOption === defaultMatchdayNumber}">{{ "Matchday" }} {{matchdayOption}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="seasonsIds">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="seasonId" (selectionChange)="onChangeSeason(seasonId)" [disableOptionCentering]="true">
						<mat-option *ngFor="let seasonOption of filterSeasonIds(competitionId) | keyvalue" [value]="seasonOption.key">{{seasonOption.value}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="fixtures-dropdown-wrapper" *ngIf="availableLanguages && availableLanguages.length > 1">
				<mat-form-field appearance="fill" class="fixtures-dropdown">
					<mat-select [(value)]="language" (selectionChange)="onChangeLanguage(language)" [disableOptionCentering]="true">
						<mat-option *ngFor="let langOption of availableLanguages" [value]="langOption">{{langOption}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<FixturesComponent [language]="language" [competitionId]="competitionId" [seasonId]="seasonId" [matchdayNumber]="matchdayNumber" [clubName]="clubName"></FixturesComponent>

	<div class="container-fluid" *ngIf="clubName === ''">
		<div class="row fixturesNavigation">
			<div class="col-6">
				<button *ngIf="matchdayNumber > 1" mat-raised-button color="primary" (click)="prevMatchday()" class="float-right">Previous Matchday</button>
			</div>
			<div class="col-6">
				<button *ngIf="matchdayNumber < 34" mat-raised-button color="primary" (click)="nextMatchday()" class="float-left">Next Matchday</button>
			</div>
		</div>
	</div>
</div>
