//eslint lines-around-directive: ["error", { "before": "always", "after": "never" }]
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BundesligaEmbedPlaceholder } from '@nx-bundesliga/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
@Component({
	selector: 'embed-instagram',
	template: '',
	styles: [':host { margin: 20px auto; }']
})
export class EmbedInstagramComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() id: string;
	@Input() placeholder: BundesligaEmbedPlaceholder;
	@Input() embedSnipped: BundesligaEmbedPlaceholder;
	@Input() forceLoad = false;
	@HostBinding('innerHTML') html: SafeHtml;
	private embedPlaceholderLoaded = new BehaviorSubject<boolean>(false);
	constructor(private loader: ScriptLoaderService, private sanitizer: DomSanitizer, private http: HttpClient) {}
	/**
	 * Retrieves the embed metadata from instagrams oEmbed endpoint. This is also executed on the server in order to show the placeholder
	 * and speed up initial page load, but might not be needed and could probably reduce startup/parse time if removed.
	 * @todo/dfl/perf
	 */
	ngOnInit() {
		if (!this.embedSnipped) {
			this.getEmbedData().subscribe((response) => {
				this.html = this.sanitizer.bypassSecurityTrustHtml(response.html);
				this.embedPlaceholderLoaded.next(true);
			});
		} else {
			this.html = this.sanitizer.sanitize(SecurityContext.NONE, this.embedSnipped);
			this.embedPlaceholderLoaded.next(true);
		}
	}
	/**
	 * Tries to include the instagram sdk IF we're in the browser and the oEmbed call's been successful.
	 */
	ngAfterViewInit() {
		this.embedPlaceholderLoaded
			.pipe(
				filter((loaded) => loaded),
				mergeMap(() => this.loader.load('instagram', this.forceLoad)[0])
			)
			.subscribe(
				() => {
					if (typeof (window as any).instgrm === 'object') {
						(window as any).instgrm.Embeds.process();
					}
				},
				(error) => {
					console.warn(`instagram.loader.error: ${error}`);
				}
			);
	}
	ngOnDestroy(): void {
		if (this.embedPlaceholderLoaded) {
			this.embedPlaceholderLoaded.unsubscribe();
		}
	}
	/**
	 * Queries the oEmbed endpoint at instagram to get the actual embed code and some additional metadata.
	 *
	 * @returns {Observable<any>}
	 */
	private getEmbedData(): Observable<any> {
		return this.http.get<any>(`https://api.instagram.com/oembed/?url=instagr.am/p/${this.id}/&omitscript=true`);
	}
}
