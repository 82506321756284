<div class="container-fluid" [formGroup]="form">
	<div class="row">
		<div class="col-xs-12" [ngClass]="{'col-sm-7 col-lg-8 col-xl-9': enableDateRange}">
			<form (ngSubmit)="searchSubmit()">
				<mat-form-field class="w-100">
					<mat-label>Search</mat-label>
					<input matInput placeholder="Start typing.." type="text" data-lpignore="true" [(ngModel)]="_search" [ngModelOptions]="{standalone: true}" cdkFocusInitial />
					<button matSuffix mat-button (click)="searchSubmit()"><mat-icon>search</mat-icon></button>
					<mat-hint>Search by title, caption, file name, copyright or Photo Database ID</mat-hint>

					<mat-progress-spinner *ngIf="loading" diameter="16" mode="indeterminate"></mat-progress-spinner>
				</mat-form-field>
			</form>
		</div>
		<div class="col-xs-12 col-sm-5 col-lg-4 col-xl-3" *ngIf="enableDateRange">
			<mat-form-field class="w-100" formGroupName="dateRange">
				<mat-label>Enter a date range</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate placeholder="Start date" formControlName="start" />
					<input matEndDate placeholder="End date" formControlName="end" />
				</mat-date-range-input>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
	</div>

	<div class="row" *ngIf="enableFilter">
		<div class="col-12">
			<mat-form-field class="w-100">
				<mat-chip-grid #chipList formGroupName="filter">
					<ng-container *ngFor="let tagGroups of form.controls.filter.getRawValue() | keyvalue">
						<mat-chip *ngFor="let tagValue of tagGroups.value; let index = index" (removed)="removeTag(tagGroups?.key, tagValue)">
							{{ tagGroups?.key | titlecase }}: {{ tagValue | tryResolveAnyStsId | async }}
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</ng-container>
					<input class="w-100" placeholder="Tags" [matChipInputFor]="chipList" matChipInputAddOnBlur="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTagFilter($event)" />
				</mat-chip-grid>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('clubs')" matTooltip="Add a CLUB filter">
					<mat-icon>sports_soccer</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('players')" matTooltip="Add a PERSON filter">
					<mat-icon>group</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('competitions')" matTooltip="Add a COMPETITION filter">
					<mat-icon>stars</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('matches')" matTooltip="Add a MATCH filter">
					<mat-icon>event_available</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('matchdays')" matTooltip="Add a MATCHDAY filter">
					<mat-icon>event_available</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="addFilterByDialog('seasons')" matTooltip="Add a SEASON filter">
					<mat-icon>event_available</mat-icon>
				</button>
				<button *ngIf="enableMotifFilter" mat-icon-button matSuffix (click)="addMotifFilter()" matTooltip="Add a MOTIF filter">
					<mat-icon>image</mat-icon>
				</button>
				<button *ngIf="enableLlmTagFilter" mat-icon-button matSuffix (click)="addLlmTagFilter()" matTooltip="Add a LLM TAG filter">
					<mat-icon>psychology</mat-icon>
				</button>
				<button mat-icon-button matSuffix (click)="clearTags()">
					<mat-icon>clear</mat-icon>
				</button>
			</mat-form-field>
		</div>
	</div>

	<div class="row" formGroupName="selectFilter" *ngIf="enableSelectFilter">
		<div class="col-12" [ngClass]="{'col-md-6': (_selectFilter | keyvalue)?.length > 1}" *ngFor="let selects of _selectFilter | keyvalue">
			<mat-form-field class="w-100">
				<mat-select [formControlName]="selects.key">
					<mat-option [value]="">no selection</mat-option>
					<mat-option *ngFor="let select of selects.value" [value]="select.value">{{ select.name }}</mat-option>
				</mat-select>
				<mat-label>{{selects.key}}</mat-label>
			</mat-form-field>
		</div>
	</div>
</div>
