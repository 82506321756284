import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { LiveBlogClubs, LiveBlogEntries } from '@nx-bundesliga/models';
import { getAllBasicMatchEvents } from '../../../framework/common/functions/match';

@Component({
	selector: 'bl-lineup-feed',
	templateUrl: './lineup-feed.component.html',
	styleUrls: ['./lineup-feed.component.scss']
})
export class LineupFeedComponent implements OnInit, OnChanges, OnDestroy {
	@Input() liveBlogTeams: LiveBlogClubs;
	@Input() liveBlogEntries: LiveBlogEntries;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	public basicMatchEvents: any;
	constructor() {}

	ngOnInit() {}
	ngOnChanges() {
		this.basicMatchEvents = getAllBasicMatchEvents(this.liveBlogEntries);
	}
	ngOnDestroy() {}
}
