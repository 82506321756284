import { BundesmasterPartialCollection } from '../bundesmaster-data-collection';

export interface BundesmasterMatchClub {
	readonly threeLetterCode: string;
	readonly name: string;
	readonly clubId: string;
}

export interface BundesmasterMatch {
	readonly matchId: string;
	readonly season: string;
	readonly seasonId: string;
	readonly competitionName: string;
	readonly competitionId: string;
	readonly home: BundesmasterMatchClub;
	readonly guest: BundesmasterMatchClub;
	readonly matchdayId: string;
	readonly matchday: number;
	readonly dateRange?: {
		gte?: string;
		lte?: string;
	};
}

export interface BundesmasterMatchesLambdaResponse {
	readonly matches: readonly BundesmasterMatch[];
	readonly partial: BundesmasterPartialCollection;
}
