<div class="btn-group-container">
	<div class="btn-group" ngxTippy="Matchhighlights and TranslationSettings" [matMenuTriggerFor]="matchSettingsMenu">
		<button mat-flat-button>
			<mat-icon>settings</mat-icon>
		</button>
	</div>
</div>

<mat-menu #matchSettingsMenu="matMenu">
	<div mat-menu-item ngxTippy="Add/Edit Matchhighlight Clip of this match" (click)="openMatchHighlightDialog()"> <mat-icon>videocam</mat-icon> MatchHighlight </div>
	<div mat-menu-item ngxTippy="Modify Translation Settings" (click)="openTranslationConfigDialog()"> <mat-icon>translate</mat-icon> Translation Settings </div>
</mat-menu>
