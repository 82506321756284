import { NgModule } from '@angular/core';
import { BarProgressChartModule } from '../stats-charts/bar-progress-chart/bar-progress-chart.module';
import { StatsClubmetricprogressionCardComponent } from './component/stats-clubmetricprogression-card/stats-clubmetricprogression-card.component';
import { SharedModule } from '@nx-bundesliga/bundesliga-com/framework/core';

@NgModule({
	imports: [SharedModule, BarProgressChartModule],
	exports: [StatsClubmetricprogressionCardComponent],
	declarations: [StatsClubmetricprogressionCardComponent],
	providers: []
})
export class StatsClubmetricprogressionCardModule {}
