import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { BundesligaMatchStates, LiveBlogClubs, LiveBlogInfos, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { IsTypingService } from '../../../services/liveticker-backend/is-typing.service';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
	selector: 'bl-rapid-ticker-creator',
	templateUrl: './bl-ticker-rapid-creator.component.html',
	styleUrls: ['./bl-ticker-rapid-creator.component.scss']
})
export class BlTickerRapidCreatorComponent implements OnInit {
	@ViewChild('stepper', { static: false }) stepper: MatStepper;

	@Input() liveBlogInfos: LiveBlogInfos;
	@Input() liveBlogTeams: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() customMatch = false;

	public selectedSide: LivetickerSides;
	public publishingError: any = null;
	public step1completed = false;
	public step2completed = false;

	constructor(private typingService: IsTypingService, private http: HttpClient, private toastr: ToastrService, private loading: LoadingService) {}

	ngOnInit() {}

	public selectSide([side, status]: [LivetickerSides, boolean]): void {
		this.selectedSide = side;
		this.step1completed = true;
		// remove this line if angular material fixes the issue: https://github.com/angular/components/issues/15310
		this.stepper.selected.completed = true;
		// see above
		this.stepper.next();
		this.setTypingStatus(side, status);

		console.log('RapidTickerCreatorComponent.selectSide', side);
		console.log('this.stepper.selectedIndex', this.stepper.selectedIndex);
	}

	public publishEvent(payload: any): void {
		this.loading.setLoading(true);
		const urlBase = `https://api.bundesstreaker.${environment.stageDomain}.com/liveblogpost`;
		const urlSuffix = [this.language, this.competitionId, this.seasonId, this.matchdayId, this.matchId].join('/');
		const url = urlBase + '/' + urlSuffix;

		console.log('RapidTickerCreatorComponent.publishEvent', payload);
		console.log(`Sending isTyping to API: `, url, payload);

		this.step2completed = true;
		// remove this line if angular material fixes the issue: https://github.com/angular/components/issues/15310
		this.stepper.selected.completed = true;
		// see above
		this.stepper.next();

		this.http.post(url, payload).subscribe(
			(success) => {
				this.toastr.success('Post has successfully been published!', 'Published');
				this.loading.setLoading(false);
				this.resetStepper();
			},

			(error) => {
				const status = error.hasOwnProperty('status') ? error.status : 'Unknown StatusCode';
				const name = error.hasOwnProperty('name') ? error.name : 'Error';
				const message = error.error.hasOwnProperty('message') ? error.error.message : error.hasOwnProperty('message') ? error.message : 'Unknown Error';
				this.publishingError = error;
				this.loading.setLoading(false);
				this.toastr.error(`${message}`, `(${status}): ${name}`);
				// this.resetStepper();
			}
		);
	}

	public handleStatusCancel(value: any) {
		this.publishingError = null;
		this.resetStepper();
	}
	public handleStatusBack(value: any) {
		this.publishingError = null;
		this.step2completed = false;
		this.stepper.previous();
	}

	public resetStepper(): void {
		console.log('RapidTickerCreatorComponent.resetStepper');
		this.selectedSide = null;
		this.publishingError = null;
		this.step1completed = false;
		this.step2completed = false;
		this.stepper.reset();
	}

	private setTypingStatus(side: LivetickerSides, status: boolean) {
		if (status) {
			const payload = {
				language: this.language,
				competitionId: this.competitionId,
				seasonId: this.seasonId,
				matchdayId: this.matchdayId,
				matchId: this.matchId
			};

			this.typingService.send(side, status, payload);
		}
	}
}
