import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { DflSimpleIconModule } from '@nx-bundesliga/bundesliga-com/components/dfl-simple-icon';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { LineupCollectionComponent } from './lineup-collection.component';

@NgModule({
	imports: [CommonsModule, TranslateModule, DflSimpleIconModule, RouterModule, DflNgxLazyLoadModule],
	exports: [LineupCollectionComponent],
	declarations: [LineupCollectionComponent],
	providers: []
})
export class LineupCollectionModule {}
