<mat-form-field>
	<mat-label>Matchday</mat-label>
	<mat-hint *ngIf="hasValue && formControl.value | resolveMatchDayId | async as resolvedMatchday">
		<ng-container *ngIf="resolvedMatchday !== formControl.value"> {{resolvedMatchday}} </ng-container>
	</mat-hint>
	<mat-spinner matSuffix *ngIf="matchdays.status === 'loading'" [diameter]="15"></mat-spinner>
	<input matInput type="text" [formControl]="formControl" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
		<mat-option *ngFor="let matchday of matchdays.items" [value]="matchday.matchdayId">
			<div class="d-flex flex-column option-padding">
				<div>{{matchday.matchdayLabel}}</div>
				<small>{{matchday.season}} | {{matchday.matchdayId}}</small>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
