import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogEntries, LiveBlogInfos, LiveBlogMatch } from '@nx-bundesliga/models';
import { getSide } from '../../../../../framework/common/functions/liveticker.functions';

@Component({
	selector: 'liveticker',
	templateUrl: './liveticker.component.html',
	styleUrls: ['./liveticker.component.scss'],
	animations: [trigger('blockInitialRenderAnimation', [transition(':enter', [])]), trigger('updateNotification', [state('visible', style([{ transform: 'translateY(0)' }])), transition('void => visible', [style({ transform: 'translateY(200%)' }), animate('600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)')])])]
})
export class LivetickerComponent implements OnInit, AfterViewInit {
	@Input() liveBlogEntries: LiveBlogEntries;
	@Input() liveBlogInfos: LiveBlogInfos;
	@Input() teams: LiveBlogClubs;
	@Input() match: LiveBlogMatch;
	@ViewChild('livetickerEntryContainer') livetickerEntryContainer: ElementRef;

	@Input() enableCardEvents = true;
	@Input() enableGoalEvents = true;
	@Input() enableSubstitutionEvents = true;
	@Input() enableBasicEvents = true;
	@Input() enableImageEvents = true;
	@Input() enableVideoEvents = true;
	@Input() enableEmbedEvents = true;
	@Input() enableProgressEvents = true;
	@Input() enableVarEvents = true;
	@Input() enableStatsEvents = true;
	@Input() enableTypingIndicator = true;

	@Input() language = 'en';
	@Input() customMatch = false;

	loadingFailed = false;
	loadingFailedError: string;

	isBrowser: boolean;
	typingIndicatorOffsetTop = 0;
	updatesAvailable = false;

	liveEntryOverlay = true;

	isWebview = false;
	elementTransitionStorage = {};

	@HostListener('window:scroll', ['$event'])
	checkScrollPosition(event) {
		// only disable typingindicator if distance to top plus approximate height of typingIndicator is not scrolled
		const yOffset = this.getYOffset();
		if (yOffset < this.typingIndicatorOffsetTop + 50) {
			this.updatesAvailable = false;
		}
	}

	constructor(private cdref: ChangeDetectorRef) {
		this.isBrowser = true;

		// fix angularfire2 breaking on prod build sometimes
		// https://github.com/firebase/angularfire/issues/970
		if (this.isBrowser) {
			localStorage.removeItem('firebase:previous_websocket_failure');
		}
	}
	ngOnInit() {
		this.isWebview = false;
	}

	ngAfterViewInit() {
		this.refreshTypingIndicatorOffsetTop();
	}

	/**
	 *
	 * @returns {number}
	 */
	private getYOffset() {
		const supportPageOffset = window.pageYOffset !== undefined;
		const isCSS1Compat = (window.document.compatMode || '') === 'CSS1Compat';

		return supportPageOffset ? window.pageYOffset : isCSS1Compat ? window.document.documentElement.scrollTop : window.document.scrollingElement.scrollTop || window.document.body.scrollTop;
	}

	/**
	 *
	 * @param {AnimationEvent} event
	 */
	animationStarted(event: AnimationEvent) {
		if (this.isBrowser) {
			const elementStyle = event.element.currentStyle || window.getComputedStyle(event.element);
			const elementHeight = event.element.clientHeight + parseInt(elementStyle.marginBottom, 10) + parseInt(elementStyle.marginTop, 10);
			if (elementHeight < this.getYOffset() - this.typingIndicatorOffsetTop) {
				if (event.fromState === 'void') {
					window.scrollBy(0, elementHeight);
				} else {
					this.elementTransitionStorage[event['_data']] = elementHeight;
				}
			}
		}
	}

	/**
	 *
	 * @param {AnimationEvent} event
	 */
	animationDone(event: AnimationEvent) {
		if (this.isBrowser) {
			if (this.getYOffset() > this.typingIndicatorOffsetTop) {
				this.updatesAvailable = true;
			}
			if (this.elementTransitionStorage[event['_data']] < this.getYOffset() - this.typingIndicatorOffsetTop && event.toState === 'void') {
				// scroll up by the height of the typingindicator plus the margin of it
				window.scrollBy(0, this.elementTransitionStorage[event['_data']] * -1);
				delete this.elementTransitionStorage[event['_data']];
			}
		}
	}

	public refreshTypingIndicatorOffsetTop(): void {
		if (this.isBrowser && this.livetickerEntryContainer && this.enableTypingIndicator && this.livetickerEntryContainer.nativeElement && this.livetickerEntryContainer.nativeElement.querySelector('livetickerevent-isTyping')) {
			this.typingIndicatorOffsetTop = this.livetickerEntryContainer.nativeElement.querySelector('livetickerevent-isTyping').offsetTop || 0;
		}
	}

	/**
	 *
	 */
	tryReload(): void {
		// emit this to the outside instead. yo.
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isLiveOrFinalMatchstatus(matchStatus) {
		const matchStates = ['FIRST_HALF', 'HALF', 'SECOND_HALF', 'PRE_EXTRA', 'FIRST_HALF_EXTRA', 'HALF_EXTRA', 'SECOND_HALF_EXTRA', 'PRE_PENALTY', 'PENALTY', 'FINAL_WHISTLE'];
		return Object.values(matchStates).includes(matchStatus);
	}

	/**
	 *
	 * @param {LiveBlogAutoEvents} entry
	 * @returns {string}
	 */
	getUnifiedSide(entry: LiveBlogAutoEvents, prefix = 'side_') {
		return getSide(entry, prefix);
	}

	public getProvidedTeamsIfExist(liveEntry: LiveBlogAutoEvents, teams: LiveBlogClubs): LiveBlogClubs {
		if (liveEntry.hasOwnProperty('teams') && liveEntry['teams']) {
			return liveEntry['teams'];
		} else {
			return teams;
		}
	}

	/**
	 *
	 * @param index
	 * @param item
	 * @returns {any}
	 */
	public trackLivetickerEvent(index, item) {
		return item['key'];
	}

	/**
	 *
	 */
	public onUpdatesAvailableClick() {
		this.updatesAvailable = false;
		window.scrollTo(0, this.typingIndicatorOffsetTop);
	}
}
