import { Injectable } from '@angular/core';
import { MatchLineup, MatchStats } from '@nx-bundesliga/models';
import { Observable } from 'rxjs';
import { IFirebaseService } from '../firebase/IFirebase.service';

@Injectable({
	providedIn: 'root'
})
export class StatsMatchService extends IFirebaseService {
	constructor() {
		super();
	}
	/**
	 * Returns an observable for the entire firebase matchday/match stats node
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 * @param matchId
	 *
	 */
	public getMatchStats(competitionId: string, seasonId: string, matchdayId: string, matchId: string): Observable<MatchStats> {
		const path = ['', 'all', competitionId, 'seasons', seasonId, 'matchdays', matchdayId, matchId, 'stats'].join('/');
		return this._getDataFromFirebase(path);
	}

	/**
	 * Returns an observable for the entire firebase matchday/match stats node
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 * @param matchId
	 *
	 */
	public getMatchLineup(competitionId: string, seasonId: string, matchdayId: string, matchId: string): Observable<MatchLineup> {
		const path = ['', 'all', competitionId, 'seasons', seasonId, 'matchdays', matchdayId, matchId, 'lineup'].join('/');
		return this._getDataFromFirebase(path);
	}
}
