<div class="liveEntryScore side_{{side}}" [ngClass]="textColor" *ngIf="conference && liveEntry && clubData">
	<span class="clublogo home" [title]="clubData.home.nameFull">
		<clublogo [clubId]="clubData.home.dflDatalibraryClubId" [clubLogoUrl]="clubData.home.logoUrl | imageService : 48" [dimension]="24"></clublogo>
	</span>
	<span class="score" *ngIf="liveEntry.detail?.score && liveEntry.detail?.score.home >= 0 && liveEntry.detail?.score.away >= 0">{{liveEntry.detail.score.home}} - {{liveEntry.detail.score.away}}</span>
	<span class="score" *ngIf="!liveEntry.detail?.score">vs</span>
	<span class="clublogo away" [title]="clubData.away.nameFull">
		<clublogo [clubId]="clubData.away.dflDatalibraryClubId" [clubLogoUrl]="clubData.away.logoUrl | imageService : 48" [dimension]="24"></clublogo>
	</span>
</div>
