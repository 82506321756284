import { BLACKLISTED_COMPETITION_IDS } from '@nx-bundesliga/bundesmaster/shared';

export type TYPE_BUNDESLIGA_COMPETITIONS = 'DFL-COM-000001' | 'DFL-COM-000002' | 'DFL-COM-000003' | 'DFL-COM-000004' | 'DFL-COM-000005';
export type TYPE_BUNDESLIGA_SEASONS = 'DFL-SEA-0001K1' | 'DFL-SEA-0001K2' | 'DFL-SEA-0001K3' | 'DFL-SEA-0001K4' | 'DFL-SEA-0001K5' | 'DFL-SEA-0001K6' | 'DFL-SEA-0001K7';

export enum BUNDESLIGA_COMPETITIONS {
	BUNDESLIGA = 'DFL-COM-000001',
	BUNDESLIGA2 = 'DFL-COM-000002',
	BUNDESLIGA_RELEGATION = 'DFL-COM-000004',
	BUNDESLIGA2_RELEGATION = 'DFL-COM-000005',
	SUPERCUP = 'DFL-COM-000003'
}

export const competitions: Competitions = {
	bundesliga: {
		displayName: 'Bundesliga',
		name: 'bundesliga',
		id: 'DFL-COM-000001'
	},
	'2bundesliga': {
		displayName: '2. Bundesliga',
		name: '2bundesliga',
		id: 'DFL-COM-000002'
	}
};

export const additionalCompetitions: Competitions = {
	euro: {
		displayName: 'Euro',
		name: 'euro',
		id: 'DFL-COM-J0002E'
	},
	supercup: {
		displayName: 'Supercup',
		name: 'supercup',
		id: 'DFL-COM-000003'
	},
	bundesliga_relegation: {
		displayName: 'Bundesliga Relegation',
		name: 'bundesliga_relegation',
		id: 'DFL-COM-000004'
	},
	'2bundesliga_relegation': {
		displayName: '2. Bundesliga Relegation',
		name: '2bundesliga_relegation',
		id: 'DFL-COM-000005'
	}
};

export const completeCompetitionList = Object.values({ ...competitions, ...additionalCompetitions }).filter((competition) => !BLACKLISTED_COMPETITION_IDS.has(competition.id));

export interface Competition {
	displayName: string;
	name: string;
	id: string;
}

export interface Competitions {
	[key: string]: Competition;
}
