import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BundesmasterClubApiService } from '@nx-bundesliga/bundesmaster/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { Subject, combineLatest, map, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-club-logo',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, CommonsLibModule],
	templateUrl: './bundesmaster-ui-club-logo.component.html',
	styleUrls: ['./bundesmaster-ui-club-logo.component.scss']
})
export class BundesmasterUiClubLogoComponent implements OnChanges, OnDestroy {
	public readonly destroying = new Subject<void>();

	@HostBinding('style.width')
	public get cssWidth(): string {
		return `${this.size}px`;
	}

	@HostBinding('style.height')
	public get cssHeight(): string {
		return `${this.size}px`;
	}

	@Input()
	public clubId = '';

	@Input()
	public size = 64;

	private readonly clubIdSubject = new Subject<string>();

	public logoUrl: string | null = null;

	constructor(service: BundesmasterClubApiService) {
		combineLatest([this.clubIdSubject, service.getClubsCollection()])
			.pipe(
				takeUntil(this.destroying),
				map(([clubId, { itemMap }]) => itemMap.get(clubId)?.logoUrl ?? null)
			)
			.subscribe((url) => (this.logoUrl = url));
	}

	ngOnDestroy(): void {
		this.destroying.next();
		this.destroying.complete();
	}

	ngOnChanges(): void {
		this.clubIdSubject.next(this.clubId);
	}
}
