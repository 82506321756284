import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

interface IdTokenClaims extends AuthenticationResult {
	idTokenClaims: {
		acr?: string;
	};
}

@Injectable({
	providedIn: 'root'
})
export class UserService {
	public isLoggedIn$ = new BehaviorSubject<boolean>(false);
	public user$ = new BehaviorSubject<AccountInfo | null>(null);
	public isLoggedIn = false;
	public user: AccountInfo | null = null;
	private readonly destroying$ = new Subject<void>();

	constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, public router: Router) {}

	public init() {
		this.checkAccount();
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
				takeUntil(this.destroying$)
			)
			.subscribe((result) => {
				this.checkAccount();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
				takeUntil(this.destroying$)
			)
			.subscribe((result: EventMessage) => {
				console.log('loginFailure', result);
				if (result.error) {
					// forgot password error
					// Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
					if (result.error.message.includes('AADB2C90118')) {
					}
					this.checkAccount();
					this.router.navigate(['/login']);
				}
			});
	}

	private checkAccount() {
		this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
		this.isLoggedIn$.next(this.isLoggedIn);
		this.user = this.isLoggedIn ? this.authService.instance.getAllAccounts()[0] : null;
		this.user$.next(this.user);
	}
}
