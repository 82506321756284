<div class="progressEvent event progressEvent_{{liveEntry.entryType}}" [ngClass]="{'conference': conference}">
	<span *ngIf="conference === false" class="progressEventName">{{'LIVETICKER.' + liveEntry.entryType | translate}}</span>

	<div class="head" *ngIf="conference === true">
		<div class="playtime">
			<dfl-simple-icon icon="whistle"></dfl-simple-icon>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference"></livetickerevent-score>
			<livetickerevent-headline [headline]="'LIVETICKER.' + liveEntry.entryType | translate" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference"></livetickerevent-headline>
		</div>
	</div>
</div>
