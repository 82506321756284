import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LivetickerEventHeadlineComponent } from './livetickerevent-headline.component';

@NgModule({
	declarations: [LivetickerEventHeadlineComponent],
	exports: [LivetickerEventHeadlineComponent],
	imports: [CommonModule]
})
export class LivetickerEventHeadlineModule {}
