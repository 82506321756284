<form [formGroup]="checkboxGroup">
	<div class="clubmetricprogression-list">
		<span class="title">{{title}}</span>
		<table mat-table [dataSource]="winProbability" class="mat-elevation-z8">
			<ng-container matColumnDef="playtime">
				<th mat-header-cell *matHeaderCellDef>Playtime.</th>
				<td mat-cell *matCellDef="let element">
					<div class="playtime">
						<div *ngIf="element?.minuteOfPlay?.minute >= 0" class="minute">{{element?.minuteOfPlay?.minute}}'</div>
						<div *ngIf="element?.minuteOfPlay?.minute && element?.minuteOfPlay?.injuryTime" class="injuryTime">+ {{element?.minuteOfPlay?.injuryTime}}</div>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="home">
				<th mat-header-cell *matHeaderCellDef>Home</th>
				<td mat-cell *matCellDef="let element; let i = index" formArrayName="checkboxesHome">
					<input type="checkbox" [formControlName]="i" />
					{{element.homeTeamWinProbability}} %
				</td>
			</ng-container>

			<ng-container matColumnDef="draw">
				<th mat-header-cell *matHeaderCellDef>Draw</th>
				<td mat-cell *matCellDef="let element; let i = index">{{element.drawProbability}} %</td>
			</ng-container>

			<ng-container matColumnDef="away">
				<th mat-header-cell *matHeaderCellDef>Away</th>
				<td mat-cell *matCellDef="let element; let i = index" formArrayName="checkboxesAway">
					<input type="checkbox" [formControlName]="i" />
					{{element.guestTeamWinProbability}} %
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="['playtime', 'home', 'draw', 'away']"></tr>
			<tr mat-row *matRowDef="let row; columns: ['playtime', 'home', 'draw', 'away'];"></tr>
		</table>
	</div>
</form>
