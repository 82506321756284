// https://stackoverflow.com/a/52311051
export function fileToBase64(file?: File): Promise<string | undefined> {
	return new Promise<string | undefined>((resolve, reject) => {
		if (!file) resolve(undefined);
		const reader = new FileReader();
		reader.readAsDataURL(file as Blob);
		reader.onload = () => {
			let encoded = reader.result;
			if (encoded) {
				encoded = encoded.toString().replace(/^data:(.*,)?/, '');
				if (encoded.length % 4 > 0) {
					encoded += '='.repeat(4 - (encoded.length % 4));
				}
				resolve(encoded);
			} else {
				resolve(undefined);
			}
		};
		reader.onerror = (error) => reject(error);
	});
}
